import { useState, useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useDebounce, useFocusToggle, useFetch } from '../../hooks'
import { combineClassNames, storeSearchKeys } from '../../utils'

import { SearchQuery } from './searchQuery'
import { SearchSuggestion } from './SearchSuggestion'
import { ALL_CATEGORIES_URL } from '../../constants'
import { NormalSearchIcon, SecondLocationIcon, CloseIcon } from '../../icons'

const SearchBar = () => {
  let history = useHistory()

  const [selectedCategory, setSelectedCategory] = useState({})

  const { register, handleSubmit, watch, reset, setValue, getValues } = useForm(
    { mode: 'all' }
  )

  const { searchFor, cId, searchUrl } = SearchQuery(
    selectedCategory,
    setSelectedCategory,
    reset
  )

  const { data, isLoading } = useFetch('ALL_CATEGORIES', ALL_CATEGORIES_URL)

  const { show, toggleHandler } = useFocusToggle()

  const onSearchSubmit = data => {
    storeSearchKeys({
      id: Math.floor(Math.random() * 10000),
      searchValue: data?.searchValue,
      close: true,
      time: Date.now()
    })
    window.scrollTo({
      top: -10,
      behavior: 'smooth'
    })
    toggleHandler()
    history.push(searchUrl(data?.searchValue))
  }

  const debouncedSearchValue = useDebounce(watch()?.searchValue, 300)

  useEffect(() => {
    if (cId && !isLoading)
      setSelectedCategory(
        data?.data?.filter(
          category => category?.id?.replace(/-/g, '') === cId?.replace(/-/g, '')
        )?.[0]
      )
    return () => {}
  }, [cId, data?.data, isLoading])

  return (
    <div className={combineClassNames('nav-searchbar', { touched: show })}>
      <form onSubmit={handleSubmit(onSearchSubmit)}>
        {/* <div className='nav-searchbar-location'>
          <SecondLocationIcon />
          <span>Kathmandu</span>
        </div>
        <div className='nav-searchbar-divider' />
        */}
        <div
          className='nav-searchbar-input'
          onBlur={toggleHandler}
          onFocus={toggleHandler}
        >
          <input
            type='search'
            name='searchValue'
            placeholder={
              searchFor ? `Search For ${searchFor}` : 'Search for anything'
            }
            {...register('searchValue')}
            autoComplete='off'
          />
          <button type='submit' className='nav-searchbar-input-searchIcon'>
            <NormalSearchIcon />
          </button>
        </div>

        <SearchSuggestion
          searchQuery={debouncedSearchValue}
          setValue={setValue}
          getValues={getValues}
        />
      </form>
    </div>
  )
}

export default SearchBar
