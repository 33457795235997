import React, { forwardRef } from 'react'

export const RadioInput = forwardRef((props, ref) => {
  const { label, ...rest } = props
  return (
    <div className='form-item label--wrapper'>
      <input className='input--radio' type='radio' {...rest} ref={ref} />
      <label className='label--form for--radio text--mute' htmlFor={rest?.id}>
        {label}
      </label>
    </div>
  )
})
