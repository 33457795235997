import { createAction, createReducer } from '@reduxjs/toolkit'

export const gettingCategories = createAction('LOAD_CATEGORY/REQUEST')
export const gettingCategoriesFail = createAction('LOAD_CATEGORY_FAIL/RESPONSE')
export const gettingCategoriesSuccess = createAction(
  'LOAD_CATEGORY_SUCCESS/RESPONSE'
)

const initialState = {
  categories: [],
  gettingCategories: false,
  gettingCategoriesSuccess: false,
  gettingCategoriesError: null,
}

const categoryReducers = createReducer(initialState, {
  [gettingCategories]: state => {
    state.gettingCategories = true
  },
  [gettingCategoriesSuccess]: (state, action) => {
    const categories = Object.values(action.payload?.data?.data || {})
    state.categories = categories
    state.gettingCategories = false
    state.gettingCategoriesSuccess = true
  },
  [gettingCategoriesFail]: (state, action) => {
    state.gettingCategories = false
    state.gettingCategoriesSuccess = false
    state.gettingCategoriesError = action.payload
  },
})

export default categoryReducers
