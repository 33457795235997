import { getOS } from '../../utils'
import { BriefcaseIcon, MobileAppIcon } from '../../icons'

let os = getOS()

export const SUB_NAV_DATA = [
  {
    name: 'For Business',
    icon: <BriefcaseIcon />,
    link: '/',
    target: '_self'
  },
  {
    name: 'Mobile App',
    icon: <MobileAppIcon />,
    link:
      os === 'iOS'
        ? 'https://apps.apple.com/ae/app/hamrobazar/id1603597504'
        : 'https://play.google.com/store/apps/details?id=com.hamrobazar.android&hl=en_SG&gl=US',
    target: '_blank'
  }
]
