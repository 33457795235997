import { isEmpty } from 'lodash'
import { isJson } from './isJson'

export const storeSearchKeys = ({ id, searchValue, close }) => {
  let original = JSON.parse(localStorage.getItem('search'))
  if (original === null) original = []
  original = [...original].filter((searchKey) => {
    if (isJson(searchKey))
      return JSON.parse(searchKey).searchValue !== searchValue
    else return searchKey
  })
  const searchKeys = [
    ...original,
    JSON.stringify({
      id: id,
      searchValue: searchValue,
      close: close ? close : false,
      createdTime: Date.now()
    })
  ]
  searchValue?.length !== 0 &&
    localStorage.setItem('search', JSON.stringify(searchKeys))
}

export const removeSearchKeys = (id) => {
  let searchKeys = localStorage.getItem('search')
  if (isJson(searchKeys)) searchKeys = JSON.parse(searchKeys)
  searchKeys = searchKeys?.filter((key) => {
    if (isJson(key)) return JSON.parse(key)?.id !== id
    else return key?.id !== id
  })
  localStorage.setItem('search', JSON.stringify(searchKeys))
}

export const getSearchKeysFromLocalStorage = () => {
  let data = JSON.parse(localStorage.getItem('search'))
  if (!isEmpty(data))
    return data?.sort(function (a, b) {
      if (isJson(a)) a = JSON.parse(a)
      if (isJson(b)) b = JSON.parse(b)
      return new Date(b.createdTime) - new Date(a.createdTime)
    })
  return data
}

export const removeDuplicate = (arr) => {
  const ids = arr.map((a) => a.id)

  const filtered = arr.filter(({ id }, index) => !ids.includes(id, index + 1))
  return filtered
}
