import { Link } from 'react-router-dom'

import { useWindowDimensions } from '../../hooks'

import { NavMenu } from './index'
import SearchBar from '../search/SearchBar'
import { HBFullLogo, HBLogo } from '../../icons'

export const MainNav = ({
	auth = {},
	displayPostForm = () => {},
	unreadNotification = 0,
}) => {
	const windowDimension = useWindowDimensions()
	return (
		<div className='main-nav container'>
			<a href='/' className='main-nav-logo'>
				{windowDimension?.width <= 992 ? (
					<HBLogo className='main-nav-logo-small' />
				) : (
					<HBFullLogo className='main-nav-logo-full' />
				)}
			</a>
			<SearchBar />
			<NavMenu
				auth={auth}
				displayPostForm={displayPostForm}
				unreadNotification={unreadNotification}
			/>
		</div>
	)
}
