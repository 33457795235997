import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = props => (
  <div className="res--loader">
    <ContentLoader
      speed={2}
      width={1000}
      height={400}
      viewBox="0 0 1000 400"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="19" y="25" rx="10" ry="10" width="126" height="95" />
      <rect x="183" y="25" rx="10" ry="10" width="126" height="95" />
      <rect x="345" y="26" rx="10" ry="10" width="126" height="95" />
      <rect x="20" y="164" rx="0" ry="0" width="550" height="28" />
      <rect x="20" y="205" rx="0" ry="0" width="550" height="28" />
      <rect x="20" y="248" rx="0" ry="0" width="550" height="28" />
      <rect x="21" y="295" rx="0" ry="0" width="550" height="28" />
      <rect x="22" y="358" rx="9" ry="9" width="116" height="23" />
    </ContentLoader>
  </div>
)

export default MyLoader
