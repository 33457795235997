import { useQuery } from 'react-query'
import { axiosInstance } from '../utils/axiosInstance'

/**
 * It returns an object with the following properties:
 *
 * - error: The error object returned by the API call
 * - isLoading: A boolean value that indicates whether the API call is in progress
 * - data: The data returned by the API call
 * - refetch: A function that can be used to manually refetch the data
 * - isError: A boolean value that indicates whether the API call has failed
 * - isSuccess: A boolean value that indicates whether the API call has succeeded
 *
 * The function takes the following parameters:
 *
 * - uniqueKey: A unique key that is used to identify the API call. This is required by the useQuery
 * hook.
 * - URL: The URL of the API endpoint
 * - isAutoFetching: A boolean value that indicates whether the data should be fetched automatically.
 * Defaults to true.
 * - options: An object that contains additional options that are passed to the useQuery hook.
 * - config: An object that contains additional
 * @param uniqueKey - This is the unique key that will be used to identify the query.
 * @param URL - The URL to fetch data from.
 * @param [isAutoFetching=true] - If you want to fetch data on component mount, set this to true.
 * @param [options] - This is an object that will be used to uniquely identify the query.
 * @param [config] - This is the config object that you would pass to axios.
 * @returns error: any
 *   isLoading: boolean
 *   data: any
 *   refetch: () => Promise<any>
 *   isError: boolean
 *   isSuccess: boolean
 */
export const useFetch = (
  uniqueKey,
  URL,
  isAutoFetching = true,
  options = {},
  config = {},
  refetchInInterval = false
) => {
  const { error, isLoading, data, refetch, isError, isSuccess } = useQuery(
    [uniqueKey, { ...options }],
    async () => {
      const { data } = await axiosInstance().get(URL, config)
      return data
    },
    {
      retry: false,
      enabled: isAutoFetching,
      refetchInterval: refetchInInterval ? 60000 : false
    }
  )
  return { error, isLoading, data, refetch, isError, isSuccess }
}
