import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const LoginToContinue = ({ text, link, allText }) => {
  let location = useLocation()
  return (
    <div className="logintocontinue">
      {allText ? (
        <p className="text--mute">{text}</p>
      ) : (
        <p className="text--mute">You must be Logged in to {text}</p>
      )}

      <Link
        to={{ pathname: '/login', state: { from: location.pathname } }}
        className="btn btn--form"
      >
        Log In
      </Link>
    </div>
  )
}

export default LoginToContinue
