import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const getNotificationApi = async () => {
  const user = localStorage.getItem('user_auth')
  if (user) {
    const res = await axiosInstance().get(`${baseAPI}/api/Notification`)
    return res
  }
  return null
}

export const readNotificationApi = async nid => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/Notification?Id=${nid}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0,
    }
  )
  return res
}

/**
 * Read All Notification
 */
export const readAllNotiApi = async () => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/Notification/ViewAll`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0,
    }
  )
  return res
}
