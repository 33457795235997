import { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { useTextChange } from '../../hooks'
import { PostImageService, DeleteImageService } from '../../services'
import { removeOnKeyPress } from '../../utils'

import { NavigationBtn } from './NavigationBtn'
import { Input } from '../common/InputField'
import { AddImage } from '../image'

import { RULES } from '../pages/Auth/rules'

export const StepOne = (props) => {
  const { productMedia, setProductMedia, ...rest } = props
  const [initialState, setInitialState] = useState(1)

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors, isValid }
  } = useFormContext()

  const { mutate: uploadImage, isLoading: isImgAdding } = PostImageService(
    productMedia,
    setProductMedia
  )
  const { mutate, isLoading: isImgDeleting } =
    DeleteImageService(setProductMedia)

  const deleteImageHandler = (imgData) => {
    setProductMedia((curr) => curr?.filter((data) => data?.id !== imgData?.id))
    mutate(imgData?.locationKey)
  }
  useEffect(() => {
    const subscription = watch((value, { name, type }) => setInitialState(2))
    return () => subscription.unsubscribe()
  }, [watch])

  const textChange = useTextChange(watch)
  return (
    <>
      <div className='post--ads'>
        <Input
          type='text'
          maxLength='50'
          placeholder='Ad Title'
          errors={errors?.name?.message}
          info='For example: Brand, model, color, and size.'
          {...register('name', RULES.adsName)}
          limits={50}
          textchange={textChange?.name || ''}
          onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
          divclass='group'
        />
        <Controller
          control={control}
          name='productMedia'
          rules={{ required: 'Image is required' }}
          render={({ field: { onChange, value, ref } }) => (
            <AddImage
              ref={ref}
              errors={errors?.productMedia?.message}
              productMedia={productMedia}
              setProductMedia={setProductMedia}
              uploadImage={uploadImage}
              isLoading={isImgAdding}
              setValue={setValue}
              deleteImageHandler={deleteImageHandler}
              initialState={initialState}
            />
          )}
        />
      </div>

      <NavigationBtn
        isLoading={isImgDeleting || isImgAdding}
        isValid={isValid}
        {...rest}
      />
    </>
  )
}
