import React, { useEffect, useRef } from 'react'

const InBetweenAds = (props) => {
  const { keyProp } = props
  const adContainer = useRef(null)
  const showGoogleAds = true

  // useEffect(() => {
  //   window.adsbygoogle = window.adsbygoogle || []
  //   window.adsbygoogle.push({})
  // }, [keyProp])

  useEffect(() => {
    // Check if the ad has been loaded
    if (!adContainer.current.innerHTML.includes('google_ads_iframe')) {
      adContainer.current.parentNode.style.display = 'none'
    }
  }, [adContainer])

  return (
    <>
      {showGoogleAds ? (
        <div
          className='inbetween-product google-ads'
          key={keyProp}
          style={{ minWidth: '250px' }}
          ref={adContainer}
        >
          <script
            async
            src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4509747508536978'
            crossOrigin='anonymous'
          ></script>
          <ins
            className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-format='fluid'
            data-ad-layout-key='-fv+64+31-d5+c4'
            data-ad-client='ca-pub-4509747508536978'
            data-ad-slot='5997898760'
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      ) : null}
    </>
  )
}

export default InBetweenAds
