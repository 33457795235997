import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'
import { DEVICE_ID, getDeviceSource } from '../constants'

export const usePost = URL => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const mutationFn = async () => {
    const res = await axiosInstance().post(URL, {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0,
    })
    return res
  }
  const { mutate, data, isLoading, error } = useMutation(mutationFn)

  useEffect(() => {
    mutate()
  }, [URL])

  return { isLoading, error, data }
}
