import { useState, useEffect, useRef } from 'react'

export const useDebounce = (value = '', delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const timer = useRef(null)

  useEffect(() => {
    timer.current = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(timer.current)
    }
  }, [value, delay])

  return debouncedValue
}
