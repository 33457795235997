import { Input } from './index'
import { removeOnKeyPress } from '../../../utils'

export const TextNumberField = ({ register, errors, attribute }) => {
  const filedType = (filedType) => {
    if (filedType === '1') return 'number'
    if (filedType === '2') return 'text'
  }

  return (
    <Input
      type={filedType(attribute?.fieldType)}
      placeholder={`${attribute?.placeholder}${
        attribute?.isRequired ? '*' : ''
      }`}
      errors={errors?.[`${attribute?.fieldName}`]?.message}
      name={attribute?.fieldName}
      {...register(`${attribute?.fieldName}`, {
        required: {
          value: attribute?.isRequired,
          message: `${attribute?.fieldName} is required`
        }
      })}
      onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
    />
  )
}
