import appConfig from '../appConfig'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'
import { DEVICE_ID, getDeviceSource } from '../constants'

const baseAPI = appConfig.baseApiURI

export const addRevewApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/User/userReview`,
    jdata,
    config
  )
  return res
}

export const getUserApi = async (uid) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: ''
    }
  }
  if (uid) {
    const res = await axiosInstance().get(
      `${baseAPI}/api/User?UserId=${uid}`,
      config
    )
    return res
  } else {
    const res = await axiosInstance().get(
      `${baseAPI}/api/User?UserId=${uid}`,
      config
    )
    return res
  }
}

export const getAnalyticsApi = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const res = axiosInstance().get(`${baseAPI}/api/User/Analytics`, config)
  return res
}

export const getIpAPi = async () => {
  const res = axiosInstance().get(`${baseAPI}/api/Account/TestIP`)
  return res
}

export const addFeedbackApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/User/UserFeedback`,
    jdata,
    config
  )
  return res
}
