import { useState, useEffect } from 'react'
import { useWindowDimensions } from './useWindowDimensions'

/**
 * Toggle custom hook
 * @param [initialValue=false] - The initial value of the toggle.
 * @param [isFilterToggle=false] - boolean
 * @returns The hook returns an array with two elements: the current value and a function that updates
 * it.
 */
export const useToggle = (initialValue = false, isFilterToggle = false) => {
  const windowDimensions = useWindowDimensions()
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (isFilterToggle) {
      if (windowDimensions.width > 1200) return setValue(true)
      if (windowDimensions.width <= 1200) return setValue(false)
      return setValue(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions.width, isFilterToggle])

  const toggle = () => setValue(value => !value)

  return [value, toggle]
}
