/**
 * It takes an array and returns a new array with the same elements in a random order
 * @param [array] - The array to randomize.
 * @returns The array is being returned.
 */
export function randomizeArray(array = []) {
  let currentIndex = array?.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}
