import { useEffect, useState } from 'react'

import PatternOne from './TopBannerAdsPattern/PatternOne'
import PatternTwo from './TopBannerAdsPattern/PatternTwo'
import PatternThree from './TopBannerAdsPattern/PatternThree'
import PatternFour from './TopBannerAdsPattern/PatternFour'
import PatternFive from './TopBannerAdsPattern/PatternFive'
import PatternSix from './TopBannerAdsPattern/PatternSix'
import PatternSeven from './TopBannerAdsPattern/PatternSeven'
import PatternEight from './TopBannerAdsPattern/PatternEight'

const GAdsTop = ({ ads }) => {
  const [AdsPattern, setAdsPattern] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
  })

  useEffect(() => {
    if (ads && ads?.length > 0) {
      ads?.forEach(ad => {
        setAdsPattern(current => ({
          ...current,
          [ad.patternSettingCount]: [...current[ad.patternSettingCount], ad],
        }))
      })
    }
  }, [ads])

  return (
    <div className="topBanner" style={{ textAlign: 'center' }}>
      {AdsPattern[1]?.length > 0 && (
        <PatternOne AdsPattern={AdsPattern[1]} patternName="pattern-one" />
      )}
      {AdsPattern[2]?.length > 0 && <PatternTwo AdsPattern={AdsPattern[2]} />}
      {AdsPattern[3]?.length > 0 && <PatternThree AdsPattern={AdsPattern[3]} />}

      {AdsPattern[4]?.length > 0 && <PatternFour AdsPattern={AdsPattern[4]} />}

      {AdsPattern[5]?.length > 0 && <PatternFive AdsPattern={AdsPattern[5]} />}
      {AdsPattern[6]?.length > 0 && <PatternSix AdsPattern={AdsPattern[6]} />}
      {AdsPattern[7]?.length > 0 && <PatternSeven AdsPattern={AdsPattern[7]} />}
      {AdsPattern[8]?.length > 0 && <PatternEight AdsPattern={AdsPattern[8]} />}
    </div>
  )
}

export default GAdsTop
