import { useMutation } from 'react-query'

import { uploadImageApi, deleteImageApi } from '../api/ads'
import { popToast } from '../components/common/PopToast'

/**
 * It returns an object with a mutate function and an isLoading boolean. The mutate function takes in
 * an image file and uploads it to the server. The isLoading boolean is true when the mutate function
 * is being executed
 * @param image - The image array that is being updated
 * @param setImage - This is the function that will be called when the mutation is successful.
 * @returns An object with two properties: mutate and isLoading.
 */
export const PostImageService = (image = [], setImage = () => {}) => {
  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await uploadImageApi(data)
    },
    {
      onSuccess: (data) => {
        setImage([...image, data?.data?.data])
      },
      onError: (error) => {
        popToast('Unable to upload image', 'TOP_RIGHT')
      }
    }
  )
  return { mutate, isLoading }
}

/**
 * It returns an object with a mutate function, a isLoading boolean, and an isError boolean
 * @param [setImage] - This is a function that will be called when the mutation is successful.
 * @returns An object with the following properties:
 *   mutate: A function that takes in a data object and returns a promise
 *   isLoading: A boolean that is true when the mutate function is being called
 *   isError: A boolean that is true when the mutate function has an error
 */
export const DeleteImageService = (setImage = () => {}) => {
  const { mutate, isLoading, isError } = useMutation(
    async (data) => {
      return await deleteImageApi(data)
    },
    {
      onError: (error) => {
        popToast('Unable to delete image', 'TOP_RIGHT')
      }
    }
  )
  return { mutate, isLoading, isError }
}
