import { useEffect, useState } from 'react'

const useDivPositioning = (id, ref, trigger) => {
  const [leftDropdown, setLeftDropdown] = useState(false)

  useEffect(() => {
    const mainDiv = document.getElementById(id)
    const positionLeft = mainDiv?.getBoundingClientRect().left
    const width = mainDiv?.offsetWidth
    const totalYaxisMainDiv = positionLeft + width
    const positionLeftDropDown = ref?.current?.getBoundingClientRect().left
    const widthDropDown = ref?.current?.offsetWidth
    const totalYaxisRef = positionLeftDropDown + widthDropDown
    if (totalYaxisMainDiv - totalYaxisRef <= 0) setLeftDropdown(true)
    else setLeftDropdown(false)
  }, [trigger])
  return leftDropdown
}

export default useDivPositioning
