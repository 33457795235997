/**
 * It takes an array, an id of an item in the array, and a new index for that item, and returns a new
 * array with the item moved to the new index.
 * @param arr - the array to move the item in
 * @param id - the id of the item you want to move
 * @param newIndex - The new index of the item started from 0.
 * @returns The array with the item moved to the new index.
 */

export function moveArrayPosition(arr, id, newIndex, idName = 'id') {
  const oldIndex = arr.findIndex((item) => item?.[idName] === id)

  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}
