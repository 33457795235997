import { createAction, createReducer } from '@reduxjs/toolkit'
import { logout } from './authReducers'

export const getNotification = createAction('GET_NOT/REQ')
export const getNotificationFail = createAction('GET_NOT_FAI/RES')
export const getNotificationSuccess = createAction('GET_NOT_SUC/RES')

export const readNotification = createAction('READ_NOTIF')
export const setunreadChat = createAction('UNREAD_CHAT_COUNT')
export const clearUnread = createAction('CLEWAR_UNREAD')

export const allnotiRead = createAction('ALL_READ/REQ')
export const allnotiReadSuccess = createAction('ALL_READ_SUCCESS/RES')
export const allnotiReadError = createAction('ALL_READ_FAIL/RES')

const initialState = {
  notif: [],
  gettingNotification: false,
  gettingNotificationSuccess: false,
  gettingNotificationFail: null,

  unreadNoti: 0,
  unreadChat: 0,

  readingAll: false,
}

const notificationReducers = createReducer(initialState, {
  [getNotification]: state => {
    state.gettingNotification = true
  },
  [getNotificationSuccess]: (state, action) => {
    state.notif = action?.payload?.data?.data
    state.gettingNotification = false
    state.gettingNotificationSuccess = true
    state.unreadNoti = action?.payload?.data?.data.filter(elem => {
      return !elem.viewed
    })?.length
  },
  [getNotificationFail]: (state, action) => {
    state.getNotification = false
    state.gettingNotificationSuccess = false
    state.gettingNotificationFail = action?.payload?.data?.data
  },

  [readNotification]: (state, action) => {
    // console.log(action);
    let index = state.notif.findIndex(obj => obj.id === action?.payload)
    state.notif[index].viewed = true
  },

  [setunreadChat]: (state, action) => {
    state.unreadChat = action.payload
  },

  [clearUnread]: state => {
    state.unreadNoti = 0
    state.unreadChat = 0
  },

  [allnotiRead]: state => {
    state.readingAll = true
  },
  [allnotiReadSuccess]: state => {
    state.notif = state.notif.map(a => ({ ...a, viewed: true }))
    state.readingAll = false
  },
  [allnotiReadError]: state => {
    state.readingAll = false
  },

  [logout]: state => {
    state.notif = []
    state.unreadNoti = 0
    state.unreadChat = 0
  },
})

export default notificationReducers
