/* eslint-disable array-callback-return */
export const objToQueryParam = (data = {}) => {
  const urlQueryParams = Object.entries(data)
    .map(([key, val]) => {
      if (val?.length > 0) {
        return `${key}=${val}`
      }
    })
    .filter(Boolean)
    .join('&')
  return urlQueryParams
}
