/**
 * @template {() => void} FunctionT
 * @param {FunctionT} fn
 * @param {number} thresholdMs
 * @returns {FunctionT}
 */
export function throttle(fn, thresholdMs) {
  if (!thresholdMs) return fn
  let blocked = false
  let queuedArgs = null
  return function throttledFunction(...args) {
    if (blocked) {
      queuedArgs = args
      return
    }
    const now = Date.now()
    fn.apply(this, args)
    blocked = true
    setTimeout(() => {
      blocked = false
      if (queuedArgs) fn.apply(this, args)
      queuedArgs = null
    }, thresholdMs)
  }
}
