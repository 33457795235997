import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useContext, useEffect } from 'react'
import { OpenStreetmapDispatcher } from './OpenStreetMap'

export const MapDivContainer = (props) => {
  const { children, height, ...rest } = props

  const [{ position, recenter }] = useContext(OpenStreetmapDispatcher)
  return (
    <MapContainer
      style={{ height: height ?? '100%', width: '100%' }}
      center={[position?.lat, position?.lng]}
      zoom={13}
      {...rest}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      {children}
      {recenter && (
        <RecenterAutomatically lat={position.lat} lng={position.lng} />
      )}
    </MapContainer>
  )
}
const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap()
  useEffect(() => {
    map.setView([lat, lng])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng])
  return null
}
