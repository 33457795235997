import React, { forwardRef, useState } from 'react'
import ErrorMessage from './ErrorMessage'

export const Textarea = forwardRef((props, ref) => {
  const { errors, defaultValue, info, maxLen = 500, ...rest } = props
  const [showLabel, setShowLabel] = useState(false)

  const onInput = (e) => {
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  return (
    <div
      className={`form-group pos-rel ${
        showLabel || defaultValue?.length > 0 ? ' show--label' : ''
      }`}
      onFocus={() => setShowLabel(true)}
      onBlur={() => setShowLabel(false)}
    >
      <textarea
        className={`input ${errors ? 'warning' : ''}`}
        defaultValue={defaultValue}
        onInput={(e) => onInput(e)}
        onChange={(e) => onchange(e)}
        ref={ref}
        {...rest}
      />
      {errors && <ErrorMessage message={errors} />}
      <label className='the--label'>Description</label>
      <div className='limits'>
        <span>{props?.textchange?.length ?? defaultValue?.length}</span>/
        {maxLen}
      </div>
      {info && <small>{info} </small>}
    </div>
  )
})
