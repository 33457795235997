import { getCookie } from '../../utils/cookies'
import { DEVICE_ID, getDeviceSource } from '../../constants'

export const addProductPayload = (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const payload = {
    name: data?.name || '',
    description: data?.description,
    price: data?.price,
    categoryId: data?.subCategory || '',
    brandCategoryId: data?.brand || '',
    location: data?.location || null,
    condition: data?.condition || '',
    imageUrl: data?.productMedia?.[0]?.locationKey || '',
    negotiable: data?.negotiable || false,
    delivery: data?.delivery || false,
    locationRadius: data?.locationRadius | false,
    isAdultContent: data?.isAdultContent || false,
    productAttribute: data?.productAttribute || null,
    productMedia: data?.productMedia || null,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0,
    recaptchaToken: data?.recaptchaToken || '',
    expiryDate: data?.expiryDate === 4 ? data?.date : data?.expiryDate || ''
  }
  const postProductPayload = JSON.stringify({
    ...payload
  })

  return payload
}
