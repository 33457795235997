import { useMutation } from 'react-query'

export const DynamicLinks = () => {
  const { mutate, isLoading, data } = useMutation(async (url) => {
    return await postUrl(url)
  })
  return { mutate, isLoading, data }
}

const postUrl = async (url) => {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    dynamicLinkInfo: {
      link: url,
      domainUriPrefix: 'https://shareurl.hamrobazaar.com',
      androidInfo: {
        androidPackageName: 'com.hamrobazar.android'
      },
      iosInfo: {
        iosBundleId: 'com.hamrobazaar.ios'
      }
    }
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }
  const res = await fetch(
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBY9PL1H4JLEvAKC77EoyGx8zOm4xbaUP0',
    requestOptions
  )
  const result = await res.json()

  return result
}
