import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { useTextChange } from '../../hooks'
import {
  PostImageService,
  DeleteImageService,
  UpdateProduct
} from '../../services'
import DateFormatter from '../../utils/DateFormatter'
import { removeOnKeyPress, moveArrayPosition } from '../../utils'

import { AddImage } from '../image'
import {
  Textarea,
  Input,
  PriceInput,
  SelectInput,
  Checkbox
} from '../common/InputField'
import Spinner from '../loaders/Spinner'
import { RULES } from '../pages/Auth/rules'

const EditAdForm = ({ gdata, setShowModal = () => {}, refetch }) => {
  const [deletedImages, setDeletedImages] = useState([])
  const [productMedia, setProductMedia] = useState(
    moveArrayPosition(gdata?.productMedia, gdata?.imageUrl, 0, 'locationKey')
  )

  const { mutate: updateProduct, isLoading } = UpdateProduct(
    setShowModal,
    refetch
  )

  const { mutate: uploadImage, isLoading: isImgAdding } = PostImageService(
    productMedia,
    setProductMedia
  )

  const {
    mutate,
    isLoading: isImgDeleting,
    isError: isImgDeleteError
  } = DeleteImageService(setProductMedia)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid }
  } = useForm({ mode: 'all' })

  const changeText = useTextChange(watch)

  const deleteImageHandler = (imgData) => {
    setProductMedia((curr) => curr?.filter((data) => data?.id !== imgData?.id))
    setDeletedImages((curr) => [
      ...curr,
      { id: imgData?.id, locationKey: imgData?.locationKey }
    ])
  }

  const updateProducts = (newData) => {
    if (!isEmpty(deletedImages))
      mutate(deletedImages?.map((img) => img?.locationKey))

    const pack = {
      ...newData,
      id: gdata?.id,
      imageUrl: newData?.productMedia[0].locationKey
    }
    if (!isEmpty(deletedImages) && !isImgDeleting && !isImgDeleteError) {
      updateProduct(pack)
    } else {
      updateProduct(pack)
    }
  }

  return (
    <div className='ad--edit'>
      <form onSubmit={handleSubmit(updateProducts)}>
        <div className={'post--ads'}>
          <AddImage
            {...register('productMedia', {
              required: isEmpty(productMedia) ? 'Image is required' : false
            })}
            errors={errors?.productMedia?.message}
            productMedia={productMedia}
            setProductMedia={setProductMedia}
            uploadImage={uploadImage}
            isLoading={isImgAdding}
            setValue={setValue}
            deleteImageHandler={deleteImageHandler}
          />

          <Input
            type='text'
            maxLength='50'
            placeholder='Ad Title'
            errors={errors?.name?.message}
            defaultValue={gdata?.name || ''}
            name='name'
            info='For example: Brand, model, color, and size.'
            {...register('name', RULES.adsName)}
            limits={50}
            textchange={changeText?.name}
            onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
          />
          <Textarea
            maxLength='500'
            placeholder='Description'
            errors={errors?.description?.message}
            defaultValue={gdata?.description || ''}
            name='description'
            info='Add all the product or service specifications.'
            textchange={changeText?.description}
            {...register('description', RULES.description)}
            onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
          />
          <PriceInput
            type='number'
            placeholder='Price'
            errors={errors?.price?.message}
            defaultValue={gdata?.price || ''}
            name='price'
            step='any'
            onWheel={(e) => e.target.blur()}
            pattern='^\d+(?:\.\d{1,2})?$'
            {...register('price', {
              ...RULES.price,
              validate: (value) => RULES?.price?.validate(gdata, value)
            })}
          />
          {gdata?.condition !== -1 && (
            <>
              <SelectInput
                placeholder='Condition'
                defaultValue={gdata?.condition || ''}
                name='condition'
                defaultOption='Condition'
                {...register('condition', RULES.condition)}
                options={[
                  { value: 1, label: 'Brand New' },
                  { value: 2, label: 'Like New' },
                  { value: 3, label: 'Used' },
                  { value: 4, label: 'Not Working' }
                ]}
              />
              <Checkbox
                name='delivery'
                title='delivery'
                id='delivery'
                defaultChecked={gdata?.delivery || false}
                {...register('delivery')}
              />
              <Checkbox
                name='negotiable'
                title='negotiable'
                description='Can negotiate for price'
                id='negotiable'
                defaultChecked={gdata?.negotiable || false}
                {...register('negotiable')}
              />
            </>
          )}

          <Input
            type='date'
            min={DateFormatter(Date.now())}
            placeholder='Expiry Date'
            errors={errors?.expiryDate?.message}
            defaultValue={DateFormatter(gdata?.expiryDate)}
            name='expiryDate'
            expiredMessage={`This Ad is expired on ${DateFormatter(
              gdata?.expiryDate
            )}`}
            {...register('expiryDate', { required: 'Date is required' })}
            expired={Date.now() > new Date(gdata.expiryDate) ? true : false}
          />

          <div className='form-group'>
            <button
              className='btn btn--form'
              type='submit'
              disabled={isSubmitting || !isValid || isImgAdding || isLoading}
            >
              <Spinner /> Update Ad
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditAdForm
