export const RULES = {
  phoneNumber: {
    required: 'Phone number is required',
    pattern: {
      value: /^\(?[+ ]?([0-9]{13})$/,
      message: 'Phone number must be 10 digits'
    }
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 6,
      message: 'Password must be at least 6 characters'
    },
    maxLength: {
      value: 20,
      message: 'Password must be at most 20 characters'
    }
  },
  username: {
    required: 'Username is required'
  },
  description: {
    required: 'Description is required',
    maxLength: {
      value: 500,
      message: 'Description must be at most 500 characters'
    }
  },
  adsName: {
    required: 'Ad Name is required',
    maxLength: {
      value: 50,
      message: 'Ad Name must be at most 50 characters'
    },
    minLength: {
      value: 3,
      message: 'Ad Name must be at least 3 characters'
    }
  },
  price: {
    required: 'Price is required',
    validate: (data, value) => {
      if (data?.isHBSelect && Number(value) < Number(data.price))
        return `Price should not be less than offer price (Offer price: रु ${data.price})`
      if (Number(value) < 0) return `Price should be greater than or equal to 1`
    }
  },
  condition: {
    required: 'Condition is required'
  },
  comment: {
    required: 'Comment is required',
    maxLength: {
      value: 150,
      message: 'Comment must be at most 150 characters'
    }
  }
}
