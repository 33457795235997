export const AD_SLOT = {
  TOP_BANNER: 'top_banner',
  IN_BETWEEN: 'inbetween',
  ROAD_BLOCK: 'road_block',
  RIGHT_BOX: 'right_box',
  CONTENT_BLOCK: 'content_block',
  SPONSORED: 'sponsored',
  LEFT_BOX: 'left_box'
}

export const AD_PAGE = {
  HOME: 'home',
  CATEGORY: 'category',
  SEARCH: 'search',
  USER_PROFILE: 'user_profile',
  CATEGORY_LISTING: 'category_listing',
  EXPIRE_ADS: 'expire_ads',
  DETAIL: 'detail'
}

export const AD_TYPE = {
  STATIC: 'static',
  ADSENCE: 'adsence',
  DFP: 'dfp',
  ADMOBE: 'admobe'
}

export const LATEST_UPLOADS = 'latest_uploads'
export const RECOMMENDED = 'recommended'
export const GRID_VIEW = 'grid_view'
export const LINEAR_VIEW = 'detail'
