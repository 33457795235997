import { getCookie } from '../../utils/cookies'
import { DEVICE_ID, getDeviceSource, PAGE_SIZE } from '../../constants'

export const searchProductPayload = (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const payload = {
    pageNumber: data?.pageNumber || 1,
    pageSize: data?.pageSize || PAGE_SIZE,
    latitude: data?.latitude || 0,
    longitude: data?.longitude || 0,
    deviceId: data?.deviceId || deviceId,
    deviceSource: data?.deviceSource || deviceSource,
    isHBSelect: data?.isHBSelect || false,
    searchParams: {
      searchValue: data?.searchValue || '',
      searchBy: data?.searchBy || '',
      searchByDistance: data?.searchByDistance || 0
    },
    filterParams: {
      condition: data?.condition || 0,
      priceFrom: data?.priceFrom || 0,
      priceTo: data?.priceTo || 0,
      isPriceNegotiable:
        Number(data?.isPriceNegotiable) === 1
          ? true
          : Number(data?.isPriceNegotiable) === 2
          ? false
          : null,
      category: data?.category || '',
      brand: data?.brand || '',
      categoryIds: data?.categoryIds || '',
      brandIds: data?.brandIds || '',
      advanceFilter: data?.advanceFilter || ''
    },
    sortParam: data?.sortParam || 0
  }
  const searchPayload = JSON.stringify({
    ...payload
  })

  return searchPayload
}
