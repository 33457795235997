import ReactGA from 'react-ga'

/**
 * It returns a function that tracks an event with the given category, action, and label
 * @param [category=Category] - The category of the event.
 * @returns A function that takes in an action and label and then calls the ReactGA.event function with
 * the category, action, and label.
 */
const TRACKING_ID = 'UA-1328262-1'

export const useGAEventTracker = (category = 'Category') => {
  ReactGA.initialize(TRACKING_ID)

  const trackEvent = (
    category = 'Category',
    action = 'action',
    label = 'label'
  ) => {
    ReactGA.event({
      category,
      action,
      label
    })
  }

  return trackEvent
}
