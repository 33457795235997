/**
 * It takes an image url, and returns a new image url with the specified height, width, format, and
 * scale
 * example : convertImgType(
  {
    imageUrl: pm?.locationKey,
    oldImageUrl: data?.oldImageUrl,
    oldLocationKey: pm?.oldLocationKey,
    size: 'small',
    imgSize: '_small' || '_large',
    showOriginal: true || false
  },
  768,
  1024,
  'webp',
  60
)
OR
convertImgType('img_url', 768, 1024, 'webp', 60)

 *
 * @param imgData - This is the image object or string that you get from the API.
 * @param imageUrl (string): The URL of the image.
 * @param oldLocationKey (string): The old location key of the image.
 * @param oldImageUrl (string): The old image URL.
 * @param showOriginal (boolean): A flag indicating whether the original image should be shown.
 * @param size (string): The size of the image. Can be either small or large.
 * @param imgSize (string): The size of the image. Can be either _small or _large.
 * @param [height=500] - The height of the image you want to return.
 * @param [width=500] - The width of the image.
 * @param [format=webp] - The format of the image. Can be either 'webp' or 'jpg'.
 * @param scale - The scale of the image.
 */
export const convertImgType = (
  imgData,
  height = 500,
  width = 500,
  format = 'webp',
  scale
) => {
  if (typeof imgData === 'string') {
    return imageUrl(imgData, height, width, format, scale)
  }

  if (!(imgData?.oldLocationKey || imgData?.oldImageUrl)) {
    if (imgData?.showOriginal) return imgData?.imageUrl

    if (imgData?.size === 'small')
      return imageUrl(imgData?.imageUrl, 300, 300, format, scale)

    return imageUrl(imgData?.imageUrl, height, width, format, scale)
  }

  let splittedUrl = imgData?.imageUrl
  splittedUrl = imgData?.imageUrl.split('.').reverse()
  splittedUrl[1] =
    splittedUrl[1] +
    `${imgData?.imgSize === '_small' ? imgData?.imgSize : '_large'}`
  return createNewUrl(splittedUrl)
}

function createNewUrl(splittedUrl) {
  let newUrl = ''
  for (let i = splittedUrl?.length - 1; i >= 0; i--) {
    if (i === 0) {
      return newUrl + splittedUrl[i]
    } else {
      newUrl += splittedUrl[i] + '.'
    }
  }
}

export function imageUrl(
  url,
  height = 500,
  width = 500,
  format = 'webp',
  scale
) {
  return `${url}?x-image-process=image/resize,m_lfit,h_${height},w_${width}/format,${format}${
    scale ? `/resize,p_${scale},limit_0` : ''
  }`
}
