import { useHistory } from 'react-router-dom'

export function getPluralAdForm(count) {
  return count === 1 ? 'Ad' : 'Ads'
}

export const RedirectUserProfile = () => {
  const history = useHistory()

  const redirect = (createdById, auth) => {
    const url =
      auth?.user?.userId === createdById ? '/profile' : '/user/' + createdById

    return history.push(url)
  }
  return redirect
}
