import { createAction, createReducer } from '@reduxjs/toolkit'

export const getadvertisement = createAction('GET_ADV/REQ')
export const getadvertisementSuccess = createAction('GET_ADV_SUCC/RES')
export const getadvertisementFail = createAction('GET_ADV_FAIL/RES')

export const getHomeAds = createAction('GET_HOME_ADS/REQUEST')
export const getHomeAdsSuccess = createAction('GET_HOME_ADS/RESPONSE')
export const getHomeAdsFail = createAction('GET_HOME_ADS/RESPONSE')

export const getCategoryAds = createAction('GET_CATEGORY_ADS/REQUEST')
export const getCategoryAdsSuccess = createAction('GET_CATEGORY_ADS/RESPONSE')
export const getCategoryAdsFail = createAction('GET_CATEGORY_ADS/RESPONSE')

export const getSearchAds = createAction('GET_SEARCH_ADS/REQUEST')
export const getSearchAdsSuccess = createAction('GET_SEARCH_ADS/RESPONSE')
export const getSearchAdsFail = createAction('GET_SEARCH_ADS/RESPONSE')

export const getProfileAds = createAction('GET_PROFILE_ADS/REQUEST')
export const getProfileAdsSuccess = createAction('GET_PROFILE_ADS/RESPONSE')
export const getProfileAdsFail = createAction('GET_PROFILE_ADS/RESPONSE')

export const clearmidAds = createAction('CLEAR_ADV')

const initialState = {
  midAds: [],
  getmidAds: false,
  getmidAdsSuccess: false,
  getmidAdsError: null,

  homeAds: [],
  gettingHomeAds: false,
  gettingHomeAdsSuccess: false,
  gettingHomeAdsError: null,

  categoryAds: [],
  gettingCategoryAds: false,
  gettingCategoryAdsSuccess: false,
  gettingCategoryAdsError: null,

  searchAds: [],
  gettingSearchAds: false,
  gettingSearchAdsSuccess: false,
  gettingSearchAdsError: null,

  profileAds: [],
  gettingProfileAds: false,
  gettingProfileAdsSuccess: false,
  gettingProfileAdsError: null,
}

const advertisementReducer = createReducer(initialState, {
  [getadvertisement]: state => {
    state.getmidAds = true
  },
  [getadvertisementSuccess]: (state, action) => {
    state.getmidAds = false
    state.midAds = action?.payload?.data?.data
  },
  [getadvertisementFail]: (state, action) => {
    state.getmidAds = false
    state.getmidAdsSuccess = false
    state.getmidAdsError = action?.payload?.data?.data
  },

  [getHomeAds]: state => {
    state.gettingHomeAds = true
  },
  [getHomeAdsSuccess]: (state, action) => {
    state.homeAds = action.payload
    state.gettingHomeAds = false
  },

  [getSearchAds]: state => {
    state.gettingSearchAds = true
  },
  [getSearchAdsSuccess]: (state, action) => {
    state.searchAds = action.payload
    state.gettingSearchAds = false
  },

  [getCategoryAds]: state => {
    state.gettingCategoryAds = true
  },
  [getCategoryAdsSuccess]: (state, action) => {
    state.categoryAds = action.payload
    state.gettingCategoryAds = false
  },

  [getProfileAds]: state => {
    state.gettingProfileAds = true
  },
  [getProfileAdsSuccess]: (state, action) => {
    state.profileAds = action.payload
    state.gettingProfileAds = false
  },

  [clearmidAds]: state => {
    state.midAds = []
  },
})

export default advertisementReducer
