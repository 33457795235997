import { logout } from '../../../Redux/reducers/authReducers'
import store from '../../../Redux/store'
import { invalidateTokenApi } from '../../../api/auth'
import { decryptObjData } from '../../../utils/encryptDecryptData'

export const Logout = async (auth, isProfile = false) => {
  const localAuth = decryptObjData(localStorage.getItem('user_auth'))

  const pack = {
    token: localAuth?.value?.access_token,
    refreshToken: localAuth?.value?.refreshToken
  }
  const res = await invalidateTokenApi(pack)

  if (res?.status === 200) {
    store.dispatch(logout(localAuth?.value?.userId))
    if (isProfile) return window.location.replace('/')
    return window.location.reload()
  }
}
