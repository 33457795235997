import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { popToast } from '../../components/common/PopToast'
import { blockUserByIdApi, unblockUserByIdApi } from '../../api/auth'

export const BlockUserService = (setShowModal) => {
  const history = useHistory()
  const { mutate, isLoading } = useMutation(
    async (id) => {
      return await blockUserByIdApi(id)
    },
    {
      onSuccess: (data) => {
        setShowModal('')
        history.push('/')
        popToast(
          data?.status?.message?.[0] || 'User blocked Succesfully',
          'TOP_RIGHT'
        )
      },
      onError: (error) => {
        popToast(
          error.response.data?.status?.message?.[0] ||
            'Unable to block user! Please try again later.',
          'TOP_RIGHT'
        )
        setShowModal('')
      }
    }
  )
  return { mutate, isLoading }
}

export const UnblockUserService = (refetch) => {
  const { mutate, isLoading } = useMutation(
    async (id) => {
      return await unblockUserByIdApi(id)
    },
    {
      onSuccess: (data) => {
        popToast(
          data?.status?.message?.[0] || 'User unblocked Succesfully',
          'TOP_RIGHT'
        )
        refetch()
      },
      onError: (error) => {
        popToast(
          error.response.data?.status?.message?.[0] ||
            'Unable to unblock user! Please try again later.',
          'TOP_RIGHT'
        )
      }
    }
  )
  return { mutate, isLoading }
}
