import moment from 'moment'

export const CONDITION_OPTIONS = [
  { value: 1, label: 'Brand New' },
  { value: 2, label: 'Like New' },
  { value: 3, label: 'Used' },
  { value: 4, label: 'Not Working' }
]

export const NEGOTIABLE_OPTS = [
  { value: true, label: 'Negotiable' },
  { value: false, label: 'Fixed' }
]

const dateValue = (numOfDays) => {
  return moment().add(numOfDays, 'd').format('YYYY-MM-DD')
}

export const EXPIRY_DATE_OPTS = [
  {
    value: dateValue(30),
    label: 'In 1 month'
  },
  {
    value: dateValue(15),
    label: 'In 15 Days'
  },
  {
    value: dateValue(7),
    label: 'In 7 Days'
  },
  { value: 4, label: 'Pick a date' }
]

export const STEP_HEAD = {
  1: { heading: 'Upload Image', subHeading: 'Next Step: Details' },
  2: { heading: 'Details', subHeading: 'Next Step: Price' },
  3: { heading: 'Price', subHeading: 'Next Step: Confirmation' },
  4: { heading: 'Confirmation', subHeading: '' }
}
