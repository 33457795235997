import { useState, useEffect } from 'react'
import ReactGA from 'react-ga'

const TRACKING_ID = 'UA-1328262-1'
/**
 * Google Analytics hook for tracking page views
 */

export const useGaTracker = () => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(TRACKING_ID)
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized])
}
