import { useEffect, useState, useCallback } from 'react'

/**
 * It takes in an array of images and returns an array of images with the first image being the cover
 * image
 * @param data - The data object that contains the images.
 * @returns An object with the following properties:
 * images: an array of objects
 * isLoading: a boolean
 * mainImage: a string
 * changeMainImage: a function
 */
export const useGalleryImgSet = (data) => {
  const [images, setImages] = useState(
    swap(data?.imageUrl, 'locationKey', data?.productMedia)
  )
  const [mainImage, setMainImage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (data && data?.productMedia) {
      setMainImage(data?.imageUrl)
    }
    setIsLoading(false)
  }, [data])

  function swap(id, key, data) {
    const newMediaItems = [...data]
    const index = newMediaItems.findIndex((item) => item?.[key] === id)
    const [clickedItem] = newMediaItems.splice(index, 1)
    newMediaItems.unshift(clickedItem)
    return newMediaItems
  }

  const swapHandler = (id) => {
    setIsLoading(true)
    const imgData = swap(id, 'id', data?.productMedia)
    setImages(imgData)
    setIsLoading(false)
  }

  const changeMainImage = useCallback(
    (url) => {
      if (url === mainImage) return
      setMainImage(url)
    },
    [mainImage]
  )

  useEffect(() => {
    const img = new Image()
    img.src = mainImage
    img.onload = () => {
      setIsLoading(false)
    }
  }, [mainImage])

  return { images, isLoading, mainImage, changeMainImage, swapHandler, swap }
}
