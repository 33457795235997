import React, { useEffect } from 'react'
import { Img } from 'react-image'

import { useGAEventTracker } from '../../hooks'
import { validateURL } from '../../utils'
import { CloseIcon } from '../../icons'

const RoadBlock = ({ ad, children, show, close }) => {
  const GAEvent = useGAEventTracker()

  useEffect(() => {
    if (show && ad) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show, ad])

  return (
    <>
      {ad && show && (
        <div className='roadblock'>
          <div className='roadblock-container'>
            <Img
              src={
                window.innerWidth > 767
                  ? ad.adContentDesktop
                  : ad.adContentMobile
              }
              container={(children) => {
                return (
                  <div className='blockRoad-img'>
                    <p className='roadblock-text'>Advertisement</p>
                    <a
                      onClick={() => {
                        GAEvent(ad?.pageName, ad?.slotName, ad?.pageHtml)
                        close()
                      }}
                      href={validateURL(ad?.link || 'https://hamrobazaar.com/')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {children}
                    </a>
                    <CloseIcon onClick={close} />
                  </div>
                )
              }}
            />
          </div>
        </div>
      )}
      {children}
    </>
  )
}

export default RoadBlock
