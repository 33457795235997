import React, { useEffect } from 'react'

const SquareAds = props => {
  const { keyProp } = props
  const showGoogleAds = true
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [keyProp])

  return (
    <>
      {showGoogleAds ? (
        <React.Fragment key={keyProp}>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4509747508536978"
            crossOrigin="anonymous"
          ></script>
          <ins
            class="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-4509747508536978"
            data-ad-slot="2005455508"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </React.Fragment>
      ) : null}
    </>
  )
}

export default SquareAds
