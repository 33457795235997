import { createAction, createReducer } from '@reduxjs/toolkit'

export const closeRoadBlock = createAction('CLOSE_ROAD_BLOCK')

export const setRecent = createAction('SET_TAB/RECENT_TAB')

export const setRecommendation = createAction('SET_TAB/RECOMMENDATION_TAB')

export const setActiveTab = createAction('SET_ACTIVE_TAB')

export const setRecaptchaToken = createAction('SET_RECAPTCHA')

export const setCategoryMenuLimit = createAction('SER_CATEGORYMENU_LIMIT')

const initialState = {
  roadblock: true,
  tab: 'recent',
  recaptchaToken: null,
  categoryMenuLimit: 25,
}

const uiReducer = createReducer(initialState, {
  [closeRoadBlock]: state => {
    state.roadblock = false
  },
  [setRecent]: state => {
    state.tab = 'recent'
  },
  [setRecommendation]: state => {
    state.tab = 'recommendation'
  },
  [setActiveTab]: (state, action) => {
    state.tab = action.payload
  },
  [setRecaptchaToken]: (state, action) => {
    state.recaptchaToken = action.payload
  },
  [setCategoryMenuLimit]: (state, action) => {
    state.categoryMenuLimit = action.payload
  },
})

export default uiReducer
