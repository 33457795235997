import { forwardRef, useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { useWindowDimensions } from '../../hooks'
import { combinedData } from './utils'
import { uuid } from '../../utils'

import { LinearCard, TileCard } from '../cards/productCard'
import InBetweenAds from '../Ads/GoogleAds/InBetweenAds'
import FeedAdsView from '../Ads/ProductAds/FeedAdsView'
import EmptyAdMessage from '../common/EmptyAdMessage'
import CardLoader from '../loaders/CardLoader'
import { TileSkeleton } from '../skeletons'
import styled from '@emotion/styled'

export const ProductList = ({
	data,
	hasNextPage,
	fetchNextPage,
	feedAds,
	isLoading = false,
	view = 'detail',
	isFetchingNextPage,
	showDynamicAds = false,
	refetch,
}) => {
	const windowDimension = useWindowDimensions()
	const newData = combinedData(data, feedAds, showDynamicAds)

	let GRID_VIEW_PRODUCR_SIZE = windowDimension?.width < 580 ? 2 : 3

	const gridViewData = useCallback(() => {
		return Array.from(
			{ length: Math.ceil(data?.length / GRID_VIEW_PRODUCR_SIZE) },
			(_, i) =>
				data?.slice(
					i * GRID_VIEW_PRODUCR_SIZE,
					i * GRID_VIEW_PRODUCR_SIZE + GRID_VIEW_PRODUCR_SIZE
				)
		)
	}, [data, GRID_VIEW_PRODUCR_SIZE])

	const newGridData = combinedData(gridViewData(), feedAds, showDynamicAds, 2)

	const renderItem = useCallback(
		({ isProductAd, isDynamicAd, isFeedAd, ad }) => {
			if (isProductAd) {
				return view === 'detail' ? (
					<LinearCard data={ad} refetch={refetch} />
				) : (
					<GridCard data={ad} refetch={refetch} />
				)
			}
			if (isDynamicAd) {
				const key = `adsense-ad-for-${ad.id}`
				return <InBetweenAds key={key} keyProp={key} />
			}

			if (isFeedAd) {
				return <FeedAdsView ads={ad} />
			}
			return null
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[view]
	)

	const ListEl = styled.div`
		display: flex;
		flex-direction: column;
	`
	const List = forwardRef((props, ref) => {
		return <ListEl {...props} ref={ref} />
	})

	const FooterLoading = () => {
		return (
			<>
				{isFetchingNextPage ? (
					<>
						{view === 'detail' ? (
							<CardLoader numOfArray={2} />
						) : (
							<div className='product-list-gridLoader'>
								<TileSkeleton numOfArray={4} />
							</div>
						)}
					</>
				) : null}
			</>
		)
	}

	return (
		<div className='product-list'>
			{isLoading ? (
				<>
					{view === 'detail' ? (
						<CardLoader />
					) : (
						<div className='product-list-gridLoader'>
							<TileSkeleton numOfArray={6} />
						</div>
					)}
				</>
			) : !isLoading && data?.length < 1 ? (
				<EmptyAdMessage
					message={`There is no ads available`}
					imageName='no-ad'
				/>
			) : (
				<Virtuoso
					useWindowScroll
					style={{ scrollBehavior: 'smooth' }}
					data={view === 'detail' ? newData : newGridData}
					endReached={() => (hasNextPage ? fetchNextPage() : null)}
					itemContent={(_index, productItem) => renderItem(productItem)}
					components={{
						Footer: FooterLoading,
						List: List,
					}}
				/>
			)}
		</div>
	)
}

const GridCard = ({ data, refetch }) => {
	return (
		<div className='product-list-grid'>
			{data?.map((productItem, i) => (
				<TileCard refetch={refetch} key={uuid + i} data={productItem} />
			))}
		</div>
	)
}
