/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import useScrollDirection from '../../utils/useScrollDirection'
import { useFetch, useWindowDimensions } from '../../hooks'

import { ReactComponent as HomeIcon } from '../../icons/mobilenav/home.svg'
import { ReactComponent as PostIcon } from '../../icons/mobilenav/add.svg'
import { ReactComponent as MessageIcon } from '../../icons/mobilenav/message.svg'
import { ReactComponent as NotificationIcon } from '../../icons/mobilenav/notification.svg'
import { ReactComponent as CrossIcon } from '../../icons/common/close.svg'
import {
  clearUnread,
  getNotification
} from '../../Redux/reducers/notificationReducers'
import { getProfile } from '../../Redux/reducers/authReducers'
import { NOTIFICATION } from '../../constants'

const MobileNav = ({
  auth,
  notif,
  unreadchat,
  getNotification,
  clearUnread,
  getProfile
}) => {
  const location = useLocation()
  const scrollDirection = useScrollDirection()
  const [useApp, setUseApp] = useState(false)
  const windowDimensions = useWindowDimensions()

  const notificationDataResponse = useFetch(
    'NOTIFICATIONS',
    NOTIFICATION,
    auth?.isAuthenticated,
    {},
    {},
    true
  )

  const numberOfUnreadNotifications =
    notificationDataResponse?.data?.data?.filter((elem) => {
      return !elem.viewed
    })?.length

  useEffect(() => {
    if (auth?.user?.userId) {
      getProfile(auth?.user?.userId)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.userId])

  return (
    <>
      {useApp && (
        <div className='useapp mobile--only'>
          <a
            href='https://play.google.com/store/apps/details?id=com.hamrobazar.android'
            className='link'
          >
            Use the App
          </a>
          <span
            className='close'
            onClick={() => {
              setUseApp(false)
            }}
          >
            <CrossIcon />
          </span>
        </div>
      )}

      {location?.pathname !== '/chat' && windowDimensions?.width < 576 ? (
        <div
          className={
            'mobile-nav bottomNav ' +
            (scrollDirection === 'down' ? 'down' : 'up')
          }
        >
          <div className='nav-item'>
            <NavLink to='/' className='home' exact>
              <HomeIcon
                onClick={() => {
                  if (window.location.pathname === '/') {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                  }
                }}
              />
            </NavLink>
          </div>
          <div className='nav-item'>
            <NavLink
              to='/chat'
              className={' chat ' + (unreadchat !== 0 ? ' notify  ' : '  ')}
              onClick={() => clearUnread()}
              count={unreadchat}
            >
              <MessageIcon />
            </NavLink>
          </div>
          <div className='nav-item'>
            <NavLink to='/post' className='post'>
              <PostIcon />
            </NavLink>
          </div>
          <div className='nav-item'>
            <NavLink
              to='/notifications'
              className={
                !auth?.isAuthenticated
                  ? ''
                  : numberOfUnreadNotifications !== 0
                  ? ' notify  '
                  : '  '
              }
              count={numberOfUnreadNotifications}
            >
              <NotificationIcon />
            </NavLink>
          </div>
          <div className='nav-item'>
            {auth.isAuthenticated ? (
              <NavLink to='/profile'>
                {auth?.profile?.profileImgLocation ? (
                  <img
                    className='circle-img'
                    loading='lazy'
                    onError={(e) => {
                      e.currentTarget.classList.add('loading')
                    }}
                    src={auth?.profile?.profileImgLocation}
                    alt=''
                  />
                ) : (
                  <div className='fake-dp circle-img'>
                    <img
                      src='https://res.cloudinary.com/dem2xvk2e/image/upload/v1630584142/hamrobazar/logo_ulnmny.svg'
                      alt='pp'
                      className='fake--img'
                      loading='lazy'
                      onError={(e) => {
                        e.currentTarget.classList.add('loading')
                      }}
                    />
                  </div>
                )}
              </NavLink>
            ) : (
              <NavLink to='/login' className='login--link'>
                <i className='fas fa-user-circle'></i>
              </NavLink>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

const mapStateToPros = (state) => ({
  auth: state.auth,
  notif: state.noti,
  thread: state.chats.threads,
  unreadchat: state.noti.unreadChat
})

const mapDispatchToProps = {
  clearUnread,
  getProfile,
  getNotification
}

export default connect(mapStateToPros, mapDispatchToProps)(MobileNav)
