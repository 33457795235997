import appConfig from '../appConfig'

import { axiosInstance } from '../utils/axiosInstance'

export async function HBVersion() {
  const res = await axiosInstance().get('api/AppData/HBVersion')

  if (res?.status === 200) {
    console.table(res.data)
  }
  console.table(appConfig.app)
}
