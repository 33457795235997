/* eslint-disable no-unused-vars */
import { put, call, takeLatest, all } from 'redux-saga/effects'

import {
	forgotPasswordApi,
	getChatTokenAPi,
	getUserInfoApi,
	invalidateTokenApi,
	loginUser,
	resetPasswordApi,
	updatePassApi,
	updateUserInfoApi,
	signupUser,
} from '../../api/auth'

import {
	forgotPassword,
	forgotPasswordError,
	forgotPasswordSuccess,
	getProfile,
	getProfileFailed,
	getProfileSuccess,
	getToken,
	getTokenError,
	loadUser,
	loadUserFail,
	loadUserSuccess,
	login,
	loginFailed,
	loginSuccess,
	resendOtp,
	resetPassword,
	resetPasswordError,
	resetPasswordSuccess,
	signup,
	signupFailed,
	signupSuccess,
	updateProfile,
	updateProfileFailed,
	updateProfileSuccess,
	verifyOtp,
	verifyOtpFailed,
	verifyOtpSuccess,
	getChatToken,
	getChatTokenSuccess,
	getChatTokenError,
	updatePassword,
	updatePasswordFailed,
	updatePasswordSuccess,
	updateUser,
	logout,
} from '../reducers/authReducers'
import { addSpinner, removeSpinner } from '../reducers/spinnerReducers'
import { verifyOtpCode, resendOtpCode } from '../../api/auth'
import { toast } from 'react-toastify'
import { addError, removeError } from '../reducers/alertReducers'
import { decryptObjData } from '../../utils/encryptDecryptData'
import { popToast } from '../../components/common/PopToast'

//Wait interval 5 yield
function* sleep(time) {
	yield new Promise(resolve => setTimeout(resolve, time))
}

//Login Generators
function* userLogin(action) {
	try {
		yield put(addSpinner())
		yield put(removeError())
		let res = yield call(loginUser, action.payload)
		yield put(loginSuccess(res))
		yield put(removeSpinner())
	} catch (error) {
		yield put(addError(error.response))
		yield put(loginFailed(error))
		yield put(removeSpinner())
		yield call(sleep, 5000)
		yield put(removeError())
	}
}

//Loading user profile Generators
function* loadUsers() {
	try {
		const fromLocal = decryptObjData(localStorage.getItem('user_auth'))
		if (fromLocal.value) {
			yield put(loadUserSuccess(fromLocal.value))
		}
	} catch (error) {
		yield put(loadUserFail(error))
	}
}

//Signup Generators function
function* signUp(action) {
	try {
		yield put(removeError())
		yield put(addSpinner())
		const res = yield call(signupUser, action.payload)
		yield put(signupSuccess())

		yield put(removeSpinner())
	} catch (error) {
		if (error.response.status === 500) {
			toast.error('Server Error', {
				position: toast.POSITION.BOTTOM_LEFT,
			})
		} else {
			yield put(addError(error.response))
		}
		yield put(signupFailed())
	} finally {
		yield put(removeSpinner())
		yield call(sleep, 5000)
		yield put(removeError())
	}
}

//Verify otp
function* verifyOTP(action) {
	try {
		yield call(verifyOtpCode, action.payload)
		toast.success('OTP Verify Success', {
			position: toast.POSITION.BOTTOM_LEFT,
		})
		yield put(verifyOtpSuccess())
	} catch (error) {
		if (error?.response?.status === 405) {
			yield put(addError(['OTP Not Valid']))
		}
		const message = error.response?.data?.message || 'OTP Verification Failed'
		yield put(addError([message]))
		toast.error(message, {
			position: toast.POSITION.BOTTOM_LEFT,
			toastId: 'otpfailed0123',
			limit: 2,
			autoClose: 5000,
		})
		yield put(verifyOtpFailed())
		if (
			error.response?.data?.message ===
			'Too many request from this IP. Please try after sometime.'
		) {
			setTimeout(() => {
				window.location.replace('/')
			}, 5000)
		}
	}
}

//RESENDING OTP
function* reOTP(action) {
	try {
		yield call(resendOtpCode, action.payload)
		toast.success('OTP Resend Successful', {
			position: toast.POSITION.BOTTOM_LEFT,
		})
	} catch (error) {
		return Promise.reject(error)
	}
}

//Get Profile
function* getTheProfile(action) {
	try {
		const res = yield call(getUserInfoApi, action.payload)
		yield put(getProfileSuccess(res))
	} catch (error) {
		yield put(getProfileFailed(error))
	}
}

//Update profile
function* updateTheProfile(action) {
	try {
		yield put(addSpinner())
		const res = yield call(updateUserInfoApi, action.payload)
		yield put(updateProfileSuccess(res))
		yield put(getProfile())
		const res2 = yield call(getUserInfoApi)
		yield put(updateUser(res2.data.data))
		toast.success('Profile Updated Successfully', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			progress: undefined,
			theme: 'colored',
		})
		yield put(removeSpinner())
	} catch (error) {
		yield put(removeSpinner())
		popToast(error?.response?.data?.status?.message[0])
		yield put(addError(error.response.data.status.message[0]))
		yield call(sleep, 5000)
		yield put(removeError())
		yield put(updateProfileFailed())
	}
}

//Forgot Pswd
function* forgotThePswd(action) {
	try {
		yield put(removeError())
		yield put(addSpinner())
		const res = yield call(forgotPasswordApi, action.payload)
		yield put(forgotPasswordSuccess(res))
		toast.dark('Create New Password', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
		yield put(removeSpinner())
		yield put(removeError())
	} catch (error) {
		yield put(
			addError(
				error.response.data.status === undefined
					? ['Error!']
					: error.response.data
			)
		)
		yield put(removeSpinner())
		yield put(forgotPasswordError())
	}
}

//Forgot Pswd
function* resetThePassword(action) {
	try {
		yield put(addSpinner())
		const res = yield call(resetPasswordApi, action.payload)
		yield put(resetPasswordSuccess(res))
		toast.dark('Password Reset Done. Login Now', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
		yield put(removeSpinner())
	} catch (error) {
		yield put(removeSpinner())
		yield put(resetPasswordError(error.response))
	}
}

/**
 * Update token by refresh token
 */
function* updateTheToken(action) {
	try {
		// const res = yield call(refreshTokenApi, action.payload)
		// yield put(getTokenSuccess(res))
	} catch (error) {
		yield put(getTokenError(error))
	}
}

/**
 * Get chat token
 */
function* getTheChatToken(action) {
	try {
		if (localStorage.getItem('ctoken')) {
			yield put(getChatTokenSuccess(localStorage.getItem('ctoken')))
		} else {
			const res = yield call(getChatTokenAPi, action.payload)
			yield put(getChatTokenSuccess(res))
		}
	} catch (error) {
		yield put(getChatTokenError())
	}
}

/**
 * Update pass
 */
function* updateThePassword(action) {
	try {
		yield put(addSpinner())
		const res = yield call(updatePassApi, action.payload)
		yield put(updatePasswordSuccess(res))
		const localAuth = decryptObjData(localStorage.getItem('user_auth'))
		const pack = {
			token: localAuth?.value?.access_token,
			refreshToken: localAuth?.value?.refreshToken,
		}
		yield call(invalidateTokenApi, pack)
		toast.success('Password Updated', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
		yield put(logout())
		yield put(removeSpinner())
	} catch (error) {
		yield put(removeSpinner())
		yield put(addError(error.response))
		yield put(updatePasswordFailed(error))
		yield call(sleep, 5000)
		yield put(removeError())
	}
}

//The auth Watchers
export default function* authSaga() {
	yield all([
		takeLatest(login, userLogin),
		takeLatest(loadUser, loadUsers),
		takeLatest(signup, signUp),
		takeLatest(forgotPassword, forgotThePswd),
		takeLatest(resetPassword, resetThePassword),
		takeLatest(verifyOtp, verifyOTP),
		takeLatest(resendOtp, reOTP),
		takeLatest(getProfile, getTheProfile),
		takeLatest(updateProfile, updateTheProfile),
		takeLatest(getToken, updateTheToken),
		takeLatest(getChatToken, getTheChatToken),
		takeLatest(updatePassword, updateThePassword),
	])
}
