import { useCallback, useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useDebounce, useClickOutside, useReadLocalStorage } from '../../hooks'

import { OpenStreetMapProvider } from 'leaflet-geosearch'
import SearchData from './SearchData'
import { CloseIcon } from '../../icons'
import { OpenStreetmapDispatcher } from './OpenStreetMap'

export const MapHead = ({ setShowLocation }) => {
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [searchValue, setSearchValue] = useState()

  const [{ displayName }] = useContext(OpenStreetmapDispatcher)

  const debouncedSearchValue = useDebounce(searchValue)

  const countrycode = useReadLocalStorage('country_code') //either np for nepal and ae for UAE

  const provider = new OpenStreetMapProvider({
    params: {
      countrycodes:
        countrycode === 977 ? 'np' : countrycode === 971 ? 'ae' : 'np'
    }
  })

  // Input search Handler
  const searchLocationHandler = useCallback((e) => {
    setSearchValue(e.target.value)
  }, [])

  let domNode = useClickOutside(() => {
    setShowSearchSuggestions(false)
  })

  useEffect(() => {
    setSearchValue(displayName)
  }, [displayName])

  useEffect(() => {
    const searchRequest = async () => {
      if (debouncedSearchValue?.length >= 2) {
        try {
          const results = await provider.search({ query: debouncedSearchValue })
          setSuggestions(results)
        } catch (error) {
          setSuggestions([{ label: 'Cannot get data!' }])
          console.error(error)
        }
      }
    }
    searchRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue])

  return (
    <>
      <div className='header'>
        <div className='one'>
          <span className='label-address'>Set Your Location</span>
        </div>
        <CloseIcon
          className='close-icon'
          onClick={() => setShowLocation(false)}
        />
      </div>
      <div className='map-searchbar'>
        <div className='map-searchbar-input'>
          <input
            className=' chosen-add search-location-input'
            type='text'
            value={searchValue ?? ''}
            placeholder='Search Location'
            onChange={searchLocationHandler}
            onFocus={() => setShowSearchSuggestions(true)}
          />
          {!isEmpty(suggestions) && showSearchSuggestions && (
            <SearchData
              ref={domNode}
              data={suggestions}
              setShowSearchSuggestions={setShowSearchSuggestions}
            />
          )}
        </div>
      </div>
    </>
  )
}
