import { useMutation } from 'react-query'
import { markSoldApi } from '../../../api/ads'
import { popToast } from '../PopToast'

export function SoldHandler(refetch, setShowModal) {
  const { mutate: holdUnHoldMutate, isLoading } = useMutation(
    async (productId) => {
      return await markSoldApi(productId)
    },
    {
      onSuccess: ({ data }) => {
        popToast(data?.status?.message?.[0], 'bottom-right')
        refetch()
        setShowModal('')
      },
      onError: (error) => {
        popToast(
          error?.response?.data?.errors?.ProductId?.[0] ||
            'Unable to mark product as Sold. Please try again later',
          'bottom-right'
        )
        setShowModal('')
      }
    }
  )

  const MarkAsSold = (productId) => {
    holdUnHoldMutate(productId)
  }
  return { MarkAsSold, isLoading }
}
