import { all, fork } from 'redux-saga/effects'
import adsSaga from './adsSagas'
import dealsSaga from './dealsSaga'

import authSagas from './authSagas'
import cartwishSagas from './cartwishSagas'
import catgeorySaga from './categorySaga'
import commentSaga from './commentsSagas'
import notificationSagas from './notificationSagas'
import searchSagas from './searchSagas'
import userSagas from './userSagas'
import recomendationSaga from './recomendationSaga'
import chatSagas from './chatSagas'
import featuredSaga from './featuredSaga'
import advertisementSagas from './advertisementSagas'
import tabSaga from './tabSagas'

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(adsSaga),
    fork(catgeorySaga),
    fork(commentSaga),
    fork(searchSagas),
    fork(cartwishSagas),
    fork(notificationSagas),
    fork(dealsSaga),
    fork(userSagas),
    fork(recomendationSaga),
    fork(chatSagas),
    fork(featuredSaga),
    fork(advertisementSagas),
    fork(tabSaga),
  ])
}
