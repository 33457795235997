import { useState } from 'react'
import { connect } from 'react-redux'

import {
  addWishList,
  removeWishItem
} from '../../../Redux/reducers/cartwishlistReducers'

import {
  productCondition,
  productStatus,
  convertImgType
} from '../../../helpers'
import { combineClassNames } from '../../../utils'

import { AddRemoveWishItem } from '../../../helpers'

import { ProgressiveImg } from '../../image'
import { ProductTypeTag } from './ProductTypeTag'
import { NewTabOpen } from '../../index'
import PopupMenu from '../../common/menu/PopupMenu'
import { popToast } from '../../common/PopToast'
import GalleryModel from './GalleryModel'
import { ProductPrice } from './ProductPrice'

import {
  ADULT_CONTENT_THUMBNAIL,
  ADULT_CONTENT_THUMBNAIL_PLACEHOLDER
} from '../../../constants'
import { SaveIcon, MaximizeIcon } from '../../../icons'

const TileCard = ({ data, auth, refetch }) => {
  const { saveOrRemoveItem } = AddRemoveWishItem(refetch)

  const [previewImage, setPreviewImage] = useState(false)

  const saveHandler = (data) => {
    if (!auth?.isAuthenticated) return popToast()
    saveOrRemoveItem(data?.id, data?.isSaved ? 'remove' : 'add')
  }

  const tileCardClassNames = combineClassNames(
    'card-product-tile',
    {
      'card-product-tile-boostProduct': data?.productType === 1
    },
    { 'card-product-tile-hbSelectProduct': data?.isHBSelect }
  )

  return (
    <div className={tileCardClassNames}>
      <div
        className={`card-product-tile-img card-product-tile-img-${productStatus(
          data
        )?.replace(' ', '-')}`}
      >
        <ProductTypeTag data={data} />
        <NewTabOpen productData={data}>
          <ProgressiveImg
            previewURL={
              data?.isAdultContent
                ? ADULT_CONTENT_THUMBNAIL_PLACEHOLDER
                : convertImgType(
                    {
                      imageUrl: data?.imageUrl,
                      oldImageUrl: data?.oldImageUrl,
                      oldLocationKey: false,
                      imgSize: '_small'
                    },
                    40,
                    60,
                    'webp',
                    40
                  )
            }
            largeImageURL={
              data?.isAdultContent
                ? ADULT_CONTENT_THUMBNAIL
                : convertImgType(
                    {
                      imageUrl: data?.imageUrl,
                      oldImageUrl: data?.oldImageUrl,
                      oldLocationKey: false,
                      imgSize: '_large'
                    },
                    320,
                    320,
                    'webp'
                  )
            }
            alt={data?.name}
            className='tileImage'
          />
        </NewTabOpen>
        {productStatus(data) && (
          <span className='status-label'>{productStatus(data)}</span>
        )}
        {data?.isAdultContent ? null : (
          <div className='maximize-icon'>
            <MaximizeIcon
              title='Click to view full image'
              onClick={() => setPreviewImage(true)}
            />
          </div>
        )}
      </div>
      {previewImage && (
        <GalleryModel data={data} setPreviewImage={setPreviewImage} />
      )}
      <div className='card-product-tile-info'>
        <NewTabOpen productData={data}>
          <h2 className='card-product-tile-info-productTitle'>{data.name}</h2>
        </NewTabOpen>
        <PopupMenu
          isAuth={auth?.isAuthenticated}
          data={data}
          refetch={refetch}
          auth={auth}
        />
      </div>
      <div className='card-product-tile-condition'>
        <span>
          <NewTabOpen productData={data}>
            {productCondition(data?.condition)}
          </NewTabOpen>
        </span>
      </div>
      <div className='card-product-tile-priceAndSave'>
        <ProductPrice data={data} />
        {data?.createdById === auth?.user?.userId ? null : (
          <div
            className={combineClassNames(
              'card-product-tile-priceAndSave-save',
              {
                'card-product-tile-priceAndSave-save-productSaved':
                  data?.isSaved
              }
            )}
            onClick={() => saveHandler(data)}
            title={`${data?.isSaved ? 'Remove Saved' : 'Save'} Product`}
          >
            <SaveIcon />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateProps = (state) => ({
  auth: state.auth,
  wish: state.cartwish
})

const mapDispatchToProps = {
  addWishList,
  removeWishItem
}

export default connect(mapStateProps, mapDispatchToProps)(TileCard)
