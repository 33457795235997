import PreviewCard from '../cards/PreviewCard'
import { BackIcon } from '../../icons'

export const StepFour = props => {
  const { methods, isError, isSuccess, isLoading, ...rest } = props
  return (
    <>
      <div className={'post--ads '}>
        <div className='label'>
          <span>Your Ad will look like this</span>
        </div>
        <div className='preview--ad'>
          <PreviewCard data={methods?.watch()} />
        </div>

        <div className='progress--wrapper '>
          {isLoading ? (
            <div className='progress progress--loader '>
              <div className='progress-bar' role='progressbar'></div>
            </div>
          ) : null}
          <span className='progress--text'>
            {isError
              ? 'Sorry, we are unable to post your ad. Please try again.'
              : isSuccess
              ? ' Uploaded '
              : rest?.isLoading
              ? 'Uploading...'
              : null}
          </span>
        </div>
      </div>
      <div className='post-footer post-footer-backNext wrapper'>
        <span
          onClick={() => rest?.setFormStep(curr => curr - 1)}
          className='link hide'
        >
          <BackIcon />
          <span className='card__title'>Back</span>
        </span>

        <button
          disabled={isLoading}
          type='submit'
          className={`btn btn--form ${isLoading ? 'disabled' : ''}`}
        >
          Add Product
        </button>
      </div>
    </>
  )
}
