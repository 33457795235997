import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import {
  useClickOutside,
  useToggleOverflow,
  useScrollPosition,
  useWindowDimensions
} from '../../../hooks'

import { MenuHandler } from './MenuHandler'
import { PopupModal } from './PopupModal'
import { MenuItem } from './MenuItem'
import { Report } from './ReportMenu'
import { SocialMediaShare } from '../share'
import EditAd from '../../Ads/EditAd'
import { combineClassNames } from '../../../utils/classnames'
import { DynamicLinks } from '../../../services'
import MODAL_NAME from './modalName.json'
import { VerticalDotsIcon } from '../../../icons'
import useDivPositioning from './hooks/useDivPositioning'

const PopupMenu = ({ isAuth = false, data, refetch, auth }) => {
  const location = useLocation()
  const history = useHistory()
  const scrollPosition = useScrollPosition()
  const windowDimensions = useWindowDimensions()

  const [showMenu, setShowMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const dropdownClassName = combineClassNames(
    'card__action dropdown card-dropdown',
    { active: showMenu }
  )
  const toggleMenu = () => {
    setShowMenu((currentVal) => !currentVal)
  }
  let domNode = useClickOutside(() => {
    if (showMenu && windowDimensions?.width > 580) setShowMenu(false)
  })
  let domNodeMob = useClickOutside(() => {
    if (showMenu && windowDimensions?.width < 580) setShowMenu(false)
  })
  const dropDownRef = useRef()

  const leftDropdown = useDivPositioning('trending', dropDownRef, showMenu)

  const isProfile =
    isAuth &&
    location.pathname === '/profile' &&
    data?.creatorInfo?.createdById === auth?.user?.userId
      ? true
      : false

  const { MarkAsSold, isSoldLoading, isLoading, DeleteProduct } = MenuHandler(
    refetch,
    setShowModal
  )
  const {
    mutate: dynamicLinks,
    isLoading: isLinkGenerating,
    data: link
  } = DynamicLinks()

  const { menuData } = MenuItem({
    isProfile,
    data: data,
    refetch,
    setShowMenu,
    setShowModal,
    dynamicLinks
  })

  useToggleOverflow(showModal, true)

  return (
    <React.Fragment>
      <div className={dropdownClassName} ref={domNode}>
        <span className='menu-dots' onClick={toggleMenu}>
          <VerticalDotsIcon />
        </span>

        {showMenu && (
          <div
            className={combineClassNames('dropdown-container', {
              showLeft: leftDropdown
            })}
            ref={dropDownRef}
          >
            <div ref={domNodeMob} className='dropdown-container-inner'>
              {menuData
                ?.filter((data) => data?.show)
                ?.map((menu) => (
                  <div
                    className={`drop__item ${menu?.className || ''}`}
                    key={menu?.label}
                    onClick={() => menu?.onclick()}
                  >
                    {menu?.icon}
                    <span className='drop__link'>{menu?.label}</span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      {showModal === MODAL_NAME?.sold && (
        <PopupModal
          setShowModal={setShowModal}
          title='Mark as Sold'
          info='Are you sure , you want to mark this product as Sold?'
          onClick={() => MarkAsSold(data?.id)}
          isLoading={isSoldLoading}
        />
      )}
      {showModal === MODAL_NAME?.delete && (
        <PopupModal
          setShowModal={setShowModal}
          title='Delete Ad'
          info='Are you sure , you want to delete this Ad?'
          onClick={() => DeleteProduct(data?.id)}
          isLoading={isLoading}
          btnClass='danger'
        />
      )}
      {showModal === MODAL_NAME?.edit && (
        <>
          {window.innerWidth < 480 ? (
            history.push({
              pathname: `/profile/edit-ad/${data?.id}`,
              state: { position: scrollPosition },
              refetch: refetch
            })
          ) : (
            <EditAd
              pid={data?.id}
              refetch={refetch}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
      {showModal === MODAL_NAME?.share && (
        <SocialMediaShare
          setShowModal={setShowModal}
          hideFooter={true}
          data={data}
          link={link}
          isLoading={isLinkGenerating}
        />
      )}
      {showModal === MODAL_NAME?.report && (
        <Report setShowModal={setShowModal} data={data} />
      )}
    </React.Fragment>
  )
}

export default PopupMenu
