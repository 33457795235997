import { useEffect, useState } from 'react'
import { getBrandNamesApi } from '../../api/brand'
import BrandTagLoader from '../loaders/products/BrandTagLoader'

const BrandTags = ({ id, setBrands }) => {
  const [loading, setloading] = useState(true)
  const [tags, settags] = useState([])
  const [limitCount, setLimitCount] = useState(100)
  const [ActiveTags, setActiveTags] = useState([])

  useEffect(() => {
    setActiveTags([])
    if (!id) {
      settags([])
      setloading(false)
      return
    }
    let isMounted = true
    if (isMounted) setloading(true)
    const getBrandNames = async () => {
      const res = await getBrandNamesApi(id)
      if (isMounted) {
        setloading(false)
        settags(res?.data?.data)
      }
    }
    getBrandNames()
    return () => {}
  }, [id])

  const tagClickHandler = tagData => {
    const found = ActiveTags.indexOf(tagData) !== -1
    if (found)
      return setActiveTags(curr => {
        return curr.filter(item => item?.id !== tagData?.id)
      })
    return setActiveTags(curr => [...curr, tagData])
  }

  useEffect(() => {
    setBrands(ActiveTags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActiveTags])

  return (
    <>
      {loading ? (
        <BrandTagLoader />
      ) : (
        tags?.length !== 0 && (
          <div className="category--Tags">
            <div className="section--title">Available Brands</div>
            <hr />
            <div className="tag--row">
              {tags.slice(0, limitCount).map(tag => (
                <div
                  className={
                    `label cTag ` +
                    (ActiveTags.indexOf(tag) !== -1 ? 'active' : '')
                  }
                  key={tag.id}
                  onClick={() => {
                    tagClickHandler(tag)
                  }}
                >
                  <div className="bName"> {tag.brandName}</div>
                </div>
              ))}
              {tags?.length > 10 &&
                (limitCount <= 10 ? (
                  <div
                    className="label bTag "
                    onClick={() => setLimitCount(tags?.length + 1)}
                  >
                    <div className="label cTag ">
                      <div className="bName"> + Show More </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="label bTag "
                    onClick={() => setLimitCount(10)}
                  >
                    <div className="label cTag">
                      <div className="bName"> - Show Less </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      )}
    </>
  )
}

export default BrandTags
