import { useInfiniteQuery } from 'react-query'

/**
 * It fetches data from an API and returns an object with the data, loading state, and error state
 * @param uniqueKey - This is a unique key for the query.
 * @param URL - The URL to fetch data from.
 */
export const useInfiniteFetch = (uniqueKey, fetchData, payload) => {
  const {
    isLoading,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage
  } = useInfiniteQuery([uniqueKey, payload], fetchData, {
    getNextPageParam: (lastPage, pages) => {
      const totalRecords = pages[0]?.meta?.totalPages || pages[0]?.totalPages
      if (lastPage?.meta) {
        if (pages?.length === totalRecords) return undefined
        return pages?.length + 1
      }
      if (pages?.length === totalRecords) return undefined
      return pages?.length + 1
    }
  })

  return {
    isLoading,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage
  }
}
