import { useHistory } from 'react-router-dom'
import { currency } from '../../../utils'
import { productURL } from '../../../utils/urlChanger'

export const ProductPrice = ({ data, className = 'productPrice-tile' }) => {
  const history = useHistory()
  return (
    <div
      className={`productPrice ${className}`}
      onClick={() => history.push(productURL(data))}
    >
      {data?.newPrice !== 0 ? (
        <>
          <span className='regularPrice'> {currency(data?.price)}</span>
          <span className='cuttingPrice'> {currency(data?.newPrice)}</span>
        </>
      ) : (
        <span className='regularPrice'>{currency(data?.price)}</span>
      )}
    </div>
  )
}
