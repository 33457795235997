import { createAction, createReducer } from '@reduxjs/toolkit'
import distinct from '../../utils/distinct'

//wish
export const addWishInRecommended = createAction('UP_SAVED_RECOMMENDED')
export const removeWishInRecommended = createAction('DOWN_SAVED_RECOMMENDED')

export const gettingRecommendation = createAction('GET_RECOMMENDATION/REQUEST')
export const recommendationSuccess = createAction(
  'RECOMMENDATION_SUCCESS/RESPONSE'
)
export const recommendationError = createAction('RECOMMENDATION_FAIL/RESPONSE')

export const getmoreRecommendation = createAction(
  'GET_MORE_RECOMMENDATION/REQUEST'
)
export const getmoreRecommendationSuccess = createAction(
  'GET_MORE_RECOMMENDATION_SUCCESS/RESPONSE'
)
export const getmoreRecommendationError = createAction(
  'GET_MORE_RECOMMENDATION_FAIL/RESPONSE'
)

export const clearRecommendation = createAction('CLEAR_RECO')

const initialState = {
  recommendation: [],
  gettingRecommendation: false,
  recommendationLoadedFromApi: false,
  recommendationSuccess: false,
  recommendationError: null,
  recommendationFinished: false,

  getmoreRecommendation: false,
  getmoreRecommendationSuccess: false,
  getmoreRecommendationError: null,
}

const recommendationReducers = createReducer(initialState, {
  [gettingRecommendation]: state => {
    state.gettingRecommendation = true
  },
  [recommendationSuccess]: (state, action) => {
    const recommendation = action.payload?.data?.data
    if (state?.recommendation?.length !== 0) {
      state.recommendation = [
        ...state.recommendation,
        ...recommendation,
      ].filter(distinct)
    } else {
      state.recommendation = recommendation
    }
    state.gettingRecommendation = false
    state.recommendationSuccess = true
    state.recommendationLoadedFromApi = true
    if (recommendation?.length === 0) {
      state.recommendationFinished = true
    }
  },
  [recommendationError]: (state, action) => {
    state.gettingRecommendation = false
    state.recommendationSuccess = false
    state.recommendationLoadedFromApi = false
    state.recommendationError = action.payload
  },

  //Load more recommendation
  [getmoreRecommendation]: state => {
    state.getmoreRecommendation = true
  },
  [getmoreRecommendationSuccess]: (state, action) => {
    state.getmoreRecommendationSuccess = true
    state.getmoreRecommendation = false
    const reco = Object.values(action.payload?.data?.data || {})
    state.recommendation = [...state.recommendation, ...reco]
    if (reco?.length === 0) {
      state.recommendationFinished = true
    }
  },
  [getmoreRecommendationError]: (state, action) => {
    state.getmoreRecommendation = false
    state.getmoreRecommendationSuccess = false
    state.getmoreRecommendationError = action.payload
  },

  [addWishInRecommended]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.recommendation.some(a => a.id === action.payload.id)) {
      const theIndex = state.recommendation.findIndex(
        a => a.id === action.payload.id
      )
      state.recommendation[theIndex].isSaved = true
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = true
  },
  //Remove wish
  [removeWishInRecommended]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.recommendation.some(a => a.id === action.payload.id)) {
      const theIndex = state.recommendation.findIndex(
        a => a.id === action.payload.id
      )
      state.recommendation[theIndex].isSaved = false
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = false
  },

  //Clear reco
  [clearRecommendation]: state => {
    state.recommendation = []
  },
})

export default recommendationReducers
