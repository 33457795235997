import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AddProductService } from '../../services';
import {
  useRecaptcha,
  useWindowDimensions,
  useTextChange,
  useFetch,
} from '../../hooks';
import { useGetCategoryAndBrand } from './useGetCategoryAndBrand';
import { addProductPayload } from '../../api/payload';

import { StepOne, StepTwo, StepThree, StepFour, SuccessScreen } from './index';
import { StepInfoCard } from './StepInfoCard';
import { BackIcon, CloseIcon } from '../../icons';
import { GET_SUCCESS_ADS_URL } from '../../constants';

const AddProduct = ({ click, auth }) => {
  const [formStep, setFormStep] = useState(1);
  const methods = useForm({ mode: 'all' });
  // eslint-disable-next-line no-unused-vars
  const [updateRecapatchOnFormSubmit, setupdateRecapatchOnFormSubmit] =
    useState(false);
  const history = useHistory();
  const windowDimension = useWindowDimensions();

  const textChange = useTextChange(methods?.watch);

  const [productMedia, setProductMedia] = useState([]);
  const [productAttributeData, setProductAttributeData] = useState([]);

  const { mutate, isLoading, isError, error, isSuccess } =
    AddProductService(setFormStep);

  const recaptchaToken = useRecaptcha(formStep);
  const {
    categoryOptions,
    subCategoryOptions,
    brandOptions,
    isLoading: isCategoryLoading,
    fetchingBrand,
  } = useGetCategoryAndBrand(methods);

  const postProduct = (data) => {
    setupdateRecapatchOnFormSubmit((curr) =>
      setupdateRecapatchOnFormSubmit(!curr),
    );

    const productData = addProductPayload({
      ...data,
      productAttribute: productAttributeData,
      recaptchaToken,
    });
    mutate(productData);
  };

  const backhandler = () => {
    if (windowDimension?.width < 768) return history.goBack();
    return click(false);
  };

  const isProfileComplete = auth?.user?.profileComplete;

  const { data: successAds } = useFetch('ALL_SUCCESS_ADS', GET_SUCCESS_ADS_URL);

  return (
    <div className="post--modal">
      <div className="ad--post">
        <div className="ad--post__steps wrapper">
          <nav className="nav">
            <li className="postback-mob">
              <span className="link " onClick={backhandler}>
                <BackIcon />
                <span className="card__title">Post Ad</span>
              </span>
            </li>
            <li>
              <button className="close" onClick={backhandler}>
                <CloseIcon />
              </button>
            </li>
          </nav>
          {formStep < 5 ? (
            <StepInfoCard
              formStep={formStep}
              isProfileComplete={isProfileComplete}
            />
          ) : null}
        </div>
        <FormProvider {...methods}>
          <div className="post--form wrapper ">
            <form onSubmit={methods.handleSubmit(postProduct)}>
              {formStep === 1 ? (
                <StepOne
                  productMedia={productMedia}
                  setProductMedia={setProductMedia}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  methods={methods}
                />
              ) : formStep === 2 ? (
                <StepTwo
                  setProductAttributeData={setProductAttributeData}
                  formStep={formStep}
                  setFormStep={setFormStep}
                  methods={methods}
                  categoryOptions={categoryOptions}
                  subCategoryOptions={subCategoryOptions}
                  brandOptions={brandOptions}
                  isLoading={isCategoryLoading}
                  fetchingBrand={fetchingBrand}
                  fieldData={textChange}
                />
              ) : formStep === 3 ? (
                <StepThree
                  formStep={formStep}
                  setFormStep={setFormStep}
                  methods={methods}
                  fieldData={textChange}
                />
              ) : formStep === 4 ? (
                <StepFour
                  formStep={formStep}
                  setFormStep={setFormStep}
                  methods={methods}
                  isLoading={isLoading}
                  isError={isError}
                  error={error}
                  isSuccess={isSuccess}
                />
              ) : formStep === 5 ? (
                <SuccessScreen
                  closeForm={backhandler}
                  product={methods?.watch()}
                  succesAd={successAds?.data}
                />
              ) : null}
            </form>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AddProduct);
