import { useMutation } from 'react-query'
import { deleteAdApi } from '../../../api/ads'
import { popToast } from '../PopToast'

export function DeleteHandler(refetch, setShowModal) {
  const { mutate: deleteMutate, isLoading } = useMutation(
    async (productId) => {
      return await deleteAdApi(productId)
    },
    {
      onSuccess: ({ data }) => {
        popToast(data?.status?.message?.[0], 'bottom-right')
        refetch()
        setShowModal('')
      },
      onError: (error) => {
        popToast(
          error?.response?.data?.errors?.ProductId?.[0] ||
            'Unable to delete product. Please try again later',
          'bottom-right'
        )
        setShowModal('')
      }
    }
  )

  const DeleteProduct = (productId) => {
    deleteMutate(productId)
  }
  return { DeleteProduct, isLoading }
}
