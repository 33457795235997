import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { getFeatureAdsApi } from '../../api/ads'

export const FeaturedAds = (setData) => {
  const { mutate, isLoading } = useMutation(
    async ({ pageName, slotName }) => {
      return await getFeatureAdsApi(pageName, slotName)
    },
    {
      onSuccess: (data) => {
        setData(data)
      },
      onError: () => {
        setData([])
      }
    }
  )
  return { mutate, isLoading }
}

export const useGetFeatureAds = (pageName, slotName) => {
  const [data, setData] = useState([])
  const { mutate, isLoading } = FeaturedAds(setData)

  useEffect(() => {
    if (pageName) return mutate({ pageName, slotName })
    return

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName])

  return { isLoading, data }
}
