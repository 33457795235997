import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { convertImgType } from '../../helpers'
import { useWindowDimensions } from '../../hooks'
import { combineClassNames, urlChanger, uid } from '../../utils'

import { ChevronIcon } from '../../icons'
import { useEffect, useState } from 'react'

export const SidebarItem = ({
	item,
	isActive,
	toggleSidebarItem,
	parentItem,
	selectedSubCategory,
	toggleSidebar = () => {},
	childClass,
}) => {
	const windowDimensions = useWindowDimensions()
	const history = useHistory()
	const imgSrc = convertImgType(item?.image, 24, 24)
	const [isActiveState, setIsActiveState] = useState(isActive)

	const redirectRoute = clickedItem => {
		if (!isEmpty(clickedItem?.categories)) toggleSidebarItem(clickedItem?.id)

		if (windowDimensions?.width <= 768 && !isEmpty(parentItem)) {
			toggleSidebar()
		}

		if (isEmpty(clickedItem?.categories)) {
			return history.push(
				`/category/${clickedItem?.name && urlChanger(clickedItem?.name)}/${
					parentItem?.id
				}/${clickedItem?.id}`
			)
		}
		return history.push(
			`/category/${clickedItem?.name && urlChanger(clickedItem?.name)}/${
				clickedItem.id
			}`
		)
	}
	useEffect(() => {
		if (windowDimensions?.width <= 768)
			document.querySelector('.sidebar-category-title-active')?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center',
			})
	}, [])

	return (
		<div
			className={`sidebar-category-item ${
				isActiveState ? 'sidebar-category-open' : ''
			} ${childClass}`}
		>
			<div
				className={combineClassNames('sidebar-category-title noselect', {
					'sidebar-category-title-active': isActiveState,
				})}
				onClick={() => {
					isActiveState && !selectedSubCategory
						? setIsActiveState(false)
						: redirectRoute(item)
				}}
			>
				<span className='image-block'>
					{item?.image ? (
						<img
							src={imgSrc}
							alt={item?.name}
							className={combineClassNames('image', {
								'hbSelect-icon': item?.isHBSelect,
							})}
						/>
					) : null}
				</span>

				<div className='category-name-heading'>
					<span className='category-name '>{item?.name} </span>
					<span className='category-totalNumber'>({item?.count})</span>
				</div>
				{isEmpty(item?.categories) ? null : (
					<ChevronIcon className='sidebar-category-title-toggle-btn' />
				)}
			</div>
			{isEmpty(item?.categories) ? null : (
				<div className='sidebar-category-content dropdown_menu-6 bounce'>
					{item?.categories?.map(data => (
						<SidebarItem
							key={data?.id + uid()}
							item={data}
							parentItem={item}
							isActive={selectedSubCategory === data?.id ? true : false}
							toggleSidebar={toggleSidebar}
							selectedSubCategory={selectedSubCategory}
							childClass='child-category'
						/>
					))}
				</div>
			)}
			<div className='divider divider-horizontal' />
		</div>
	)
}
