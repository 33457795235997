/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { hydrate } from 'react-dom'
import axios from 'axios'
import App from './App'
import store from './Redux/store'
import { Provider } from 'react-redux'
import './icons/fontawesome/css/all.css'
import './scss/main.scss'
import './assets/styles/global.scss'
import * as serviceWorker from './serviceWorker'
import { bootMonitoringService } from './monitoring'
import './polyfills'
import { isJson, encryptData } from './utils'

bootMonitoringService()

export default function () {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
  }

  axios.defaults.headers.common['ApiKey'] = process.env.REACT_APP_API_KEY
  const localAuth = localStorage.getItem('user_auth')
  const userData = isJson(localAuth)
  if (userData?.value) {
    if (typeof userData === 'object')
      localStorage.setItem('user_auth', encryptData(userData))
  }

  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('hb__root')
  )

  serviceWorker.register()
}
