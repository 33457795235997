import { useState } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

export const SingleSelect = (props) => {
  const {
    options,
    errors,
    rules,
    placeholder,
    menuPlacement = 'bottom',
    styles = {},
    ...rest
  } = props
  const [showLabel, setShowLabel] = useState(false)

  const customStyles = {
    ...styles,
    menu: (provided, state) => ({
      ...provided,
      zIndex: '2'
    })
  }

  return (
    <div className='select-single'>
      <label className={`label ${showLabel ? 'label-show' : ''}`}>
        {placeholder ?? 'Select...'}
      </label>
      <Controller
        {...rest}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              key={value}
              menuPlacement={menuPlacement}
              className={`days-select ${errors ? 'select-err' : ''}`}
              options={options}
              isMulti={false}
              placeholder={placeholder}
              value={options?.find((c) => c.value === value)}
              onChange={(val) => {
                onChange(val.value)
              }}
              onFocus={() => setShowLabel(true)}
              onBlur={() => {
                onBlur()
                setShowLabel(false)
              }}
              styles={customStyles}
            />
          )
        }}
      />
    </div>
  )
}
