import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'url-search-params-polyfill'
import 'abortcontroller-polyfill'

import 'core-js/es/promise'
import 'core-js/es/symbol'
import 'core-js/es/object'
import 'core-js/es/function'
import 'core-js/es/parse-int'
import 'core-js/es/parse-float'
import 'core-js/es/number'
import 'core-js/es/math'
import 'core-js/es/string'
import 'core-js/es/date'
import 'core-js/es/array'
import 'core-js/es/regexp'
import 'core-js/es/map'
import 'core-js/es/weak-map'
import 'core-js/es/set'
import 'core-js/es/array'

import ResizeObserver from 'resize-observer-polyfill'
import smoothscroll from 'smoothscroll-polyfill'
// kick off the polyfill!
smoothscroll.polyfill()

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}
