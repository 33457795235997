import React from 'react'

import { MapAdvanceFilter } from './MapAdvanceFilter'

export const AdvanceFilter = (props) => {
  return (
    <>
      {props?.advanceFilterData
        ?.sort(function (a, b) {
          return a.filterDisplayOrder - b.filterDisplayOrder
        })
        ?.map((filter) => (
          <MapAdvanceFilter filter={filter} key={filter?.id} {...props} />
        ))}
    </>
  )
}
