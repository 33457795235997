import { useHistory } from 'react-router-dom'

import { convertImgType } from '../../../helpers'
import { Logout } from '../../../services'

import { Image } from '../../image'

import { RightArrow, Contact, SettingIcon, LogoutIcon } from '../../../icons'

export const ProfileDropdown = ({ classname, auth }) => {
  const history = useHistory()
  const profileActions = profileDropdownActions(history, auth)

  return (
    <div className={`dropdown-profile ${classname}`}>
      <div
        className='profileDropdown-header'
        onClick={() => history?.push('/profile')}
      >
        <div className='profileDropdown-header-avatar'>
          <Image
            src={convertImgType(auth?.profile?.profileImgLocation, 32, 32)}
            alt={`${auth?.profile?.fullName}-profile`}
          />
        </div>
        <div className='profileDropdown-header-info'>
          <span className='profileDropdown-header-info-fullname'>
            {auth?.profile?.fullName}
          </span>
          <span className='profileDropdown-header-info-visit'>
            Visit Profile
            <RightArrow />
          </span>
        </div>
      </div>
      <div className='profileDropdown-divider' />
      <div className='profileDropdown-actions'>
        {profileActions?.map((item) => (
          <div
            className='profileDropdown-actions-item'
            key={item?.name?.trim()}
            onClick={item?.action}
          >
            <div className='profileDropdown-actions-item-icon'>
              {item?.icon}
            </div>
            <div className='profileDropdown-actions-item-name'>
              {item?.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const profileDropdownActions = (history, auth) => {
  return [
    {
      name: 'Contact Support',
      icon: <Contact />,
      action: () => history?.push('/contact')
    },
    {
      name: 'Setting',
      icon: <SettingIcon />,
      action: () => history?.push('/settings/password')
    },
    {
      name: 'Log Out',
      icon: <LogoutIcon />,
      action: () =>
        Logout(auth, window.location.pathname === '/profile' ? true : false)
    }
  ]
}
