import { useMutation } from 'react-query'

import { popToast } from '../../components/common/PopToast'
import { readNotificationApi, readAllNotiApi } from '../../api/notification'

/**
 * It returns an object with a mutate function that calls the readNotificationApi function and a
 * isLoading boolean
 * @param refetch - This is the refetch function that we get from the useQuery hook.
 * @returns An object with two properties: mutate and isLoading.
 */
export const ViewNotificationService = (refetch) => {
  const { mutate, isLoading } = useMutation(
    async (id) => {
      return await readNotificationApi(id)
    },
    {
      onSettled: () => {
        refetch()
      },
      onError: (error) => {
        popToast(
          error.response.data?.status?.message?.[0] ||
            'Unable to view notification! Please try again later.',
          'TOP_RIGHT'
        )
      }
    }
  )

  return { mutate, isLoading }
}

/**
 * It returns an object with a mutate function and an isLoading boolean. The mutate function is used to
 * call the readAllNotiApi function, which is a function that makes a request to the backend to mark
 * all notifications as read. The isLoading boolean is used to show a loading indicator when the mutate
 * function is called
 * @param refetch - This is the refetch function that we get from the useQuery hook.
 * @returns An object with two properties: mutate and isLoading.
 */
export const ViewAllNotifications = (refetch) => {
  const { mutate, isLoading } = useMutation(
    async () => {
      return await readAllNotiApi()
    },
    {
      onSettled: () => {
        refetch()
      },
      onError: (error) => {
        popToast(
          error.response.data?.status?.message?.[0] ||
            'Unable to view notification! Please try again later.',
          'TOP_RIGHT'
        )
      }
    }
  )

  return { mutate, isLoading }
}
