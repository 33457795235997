import { createAction, createReducer } from '@reduxjs/toolkit'

export const addWishList = createAction('ADD_WISH/REQUEST')
export const addWishListSuccess = createAction('ADD_WISH_SUCCESS/RESPONSE')
export const addWishListError = createAction('ADD_WISH_ERROR/RESPONSE')

export const gettingCart = createAction('GET_CART/REQUEST')
export const gettingCartSuccess = createAction('GET_CART_SUCCESS/RESPONSE')
export const gettingCartError = createAction('GET_CART_ERROR/RESPONSE')

export const settingCart = createAction('SET_CART/REQUEST')
export const settingCartSuccess = createAction('SET_CART_SUCCESS/RESPONSE')
export const settingCartError = createAction('SET_CART_ERROR/RESPONSE')

export const addQuantity = createAction('ADD_QTY')
export const subQuantity = createAction('SUB_QTY')

export const removeCartItem = createAction('REMOVE_CART_ITEM/REQ')
export const removeCartItemSuccess = createAction(
  'REMOVE_CART_ITEM_SUCCESS/RES'
)
export const removeCartItemError = createAction('REMOVE_CART_ITEM_ERROR/RES')

export const removeWishItem = createAction('REMOVE_WISH_ITEM/REQ')
export const removeWishItemSuccess = createAction(
  'REMOVE_WISH_ITEM_SUCCESS/RES'
)
export const removeWishItemError = createAction('REMOVE_WISH_ITEM_FAIL/RES')

export const gettingWishList = createAction('GETTING_WISH/REQUEST')
export const gettingMoreWishList = createAction('GETTING_MORE_WISH/REQUEST')
export const gettingWishListSuccess = createAction(
  'GETTING_WISH_SUCCESS/RESPONSE'
)
export const gettingWishListError = createAction('GETTING_WISH_ERROR/RESPONSE')

export const placeOrder = createAction('PLACE_ORDER/REQ')
export const placeOrderSuccess = createAction('PLACE_ORDER_SUCCESS/RES')
export const placeOrderError = createAction('PLACE_ORDER_ERROR/RES')

export const getOrder = createAction('GET_ORDER/REQ')
export const getOrderSuccess = createAction('GET_ORDER_SUCCESS/RES')
export const getOrderError = createAction('GET_ORDER_ERROR/RES')

export const clearWish = createAction('CLEAR-WISH')

const initialState = {
  wishlist: [],
  gettingWishlist: false,
  gettingWishlistSuccess: false,
  gettingWishlistError: null,
  wishFinished: false,

  cart: [],
  gettingCart: false,
  gettingCartSuccess: false,
  gettingCartError: null,

  order: [],
  getOrder: false,
  getOrderSucces: false,
  getOrderError: null,

  placeOrder: false,
  placeOrderSuccess: false,
  placeOrderError: null,

  settingCart: false,
  settingCartSuccess: false,
  settingCartError: null,

  removeCartItem: false,
  removeCartItemSuccess: false,
  removeCartItemError: null,

  addingWish: false,
  pendingAdd: [],

  removeWish: false,
  pendingRemove: [],
}

const cartwishlistReducers = createReducer(initialState, {
  //Getting wish
  [gettingWishList]: state => {
    state.gettingWishlist = true
  },
  [gettingWishListSuccess]: (state, action) => {
    const wish = action?.payload?.data?.data
    state.wishlist = [...state.wishlist, ...wish]
    if (
      action.payload.data.meta.pageNumber ===
      action.payload.data.meta.totalPages
    ) {
      state.wishFinished = true
    } else state.wishFinished = false
    if (state.wishlist?.length === 0) {
      state.wishFinished = true
    }
    state.gettingWishlist = false
    state.gettingWishlistSuccess = true
  },
  [gettingWishListError]: (state, action) => {
    state.gettingWishlist = false
    state.gettingWishlistSuccess = false
    state.gettingWishlistError = action?.payload?.data
  },

  //Clear
  [clearWish]: state => {
    state.wishlist = []
  },

  //Wishlist
  [addWishList]: (state, action) => {
    state.addingWish = true
    if (action.payload.userAds) state.pendingAdd.push(action.payload.data)
    else state.pendingAdd.push(action.payload)
  },
  [addWishListSuccess]: (state, action) => {
    state.addingWish = false
    state.pendingAdd = state.pendingAdd.filter(a => a.id !== action.payload.id)
  },
  [addWishListError]: (state, action) => {
    state.addingWish = false
    state.pendingAdd = state.pendingAdd.filter(a => a.id !== action.payload.id)
  },

  [removeWishItem]: (state, action) => {
    state.removeWish = true
    if (action.payload.userAds) state.pendingRemove.push(action.payload.data)
    else state.pendingRemove.push(action.payload)
  },
  [removeWishItemSuccess]: (state, action) => {
    state.pendingRemove = state.pendingRemove.filter(
      a => a.id !== action.payload.id
    )
    state.removeWish = false
    if (state.wishlist.findIndex(b => b.id === action.payload) !== -1) {
      state.wishlist = state.wishlist.filter(a => a.id !== action.payload)
    }
  },
  [removeWishItemError]: (state, action) => {
    state.removeWish = false
    state.pendingRemove = state.pendingRemove.filter(
      a => a.id !== action.payload
    )
  },

  //For cart adding
  [gettingCart]: state => {
    state.gettingCart = true
  },
  [gettingCartSuccess]: (state, action) => {
    if (action?.payload?.length !== 0) {
      state.cart = action?.payload
    } else {
      state.cart = []
    }
    state.gettingCart = false
    state.gettingCartSuccess = true
  },
  [gettingCartError]: (state, action) => {
    state.gettingCart = false
    state.gettingCartSuccess = false
    state.gettingWishlistError = action?.payload?.data?.data
  },

  //Add cart
  [settingCart]: state => {
    state.settingCart = true
  },
  [settingCartSuccess]: (state, action) => {
    state.cart.push(action?.payload)
    state.settingCart = false
    state.settingCartSuccess = true
  },
  [settingCartError]: (state, action) => {
    state.gettingCart = false
    state.gettingCartSuccess = false
    state.gettingCartError = action?.payload?.data?.data
  },

  //Adding the quantity
  [addQuantity]: (state, action) => {
    //Finding the index of this product
    let objIndex = state.cart.findIndex(
      obj => obj.productId === action?.payload
    )
    //Updating state
    state.cart[objIndex].quantity = state.cart[objIndex].quantity + 1
  },

  //Subtracting the quantity
  [subQuantity]: (state, action) => {
    //Finding the index of this product
    let objIndex = state.cart.findIndex(
      obj => obj.productId === action?.payload
    )
    //Updating state
    state.cart[objIndex].quantity = state.cart[objIndex].quantity - 1
  },

  //Removing cart item
  [removeCartItem]: state => {
    state.removeCartItem = true
  },
  [removeCartItemSuccess]: (state, action) => {
    state.cart = state.cart.filter(p => p.productId !== action.payload)
    state.removeCartItem = false
  },

  //plaCing orders
  [placeOrder]: state => {
    state.placeOrder = true
  },
  [placeOrderSuccess]: state => {
    localStorage.removeItem('products')
    state.placeOrder = false
    state.placeOrderSuccess = true
  },
  [placeOrderError]: (state, action) => {
    state.placeOrder = false
    state.placeOrderSuccess = false
    state.placeOrderError = action?.payload?.data
  },

  //Set Order
  [getOrder]: state => {
    state.getOrder = true
  },
  [getOrderSuccess]: (state, action) => {
    state.getOrder = false
    state.getOrderSucces = true
    state.order = action?.payload?.data?.data || []
  },
  [getOrder]: (state, action) => {
    state.getOrder = true
    state.getOrderSucces = false
    state.getOrderError = action?.payload?.data
  },
})

export default cartwishlistReducers
