import { useEffect, useRef, useState, useMemo } from 'react'
import { throttle } from '../utils/functions'

export const useSlider = (initialScrollWidth = 200) => {
  const [scrollWidth, setscrollWidth] = useState(initialScrollWidth)
  const carousel = useRef(null)
  const feature = useRef(null)

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  useEffect(() => {
    setscrollWidth(feature?.current?.getBoundingClientRect().width || 200)
  }, [])

  const scrollNext = () => {
    if (carousel?.current) {
      carousel.current.scrollLeft += scrollWidth
    }
  }
  const scrollPrev = () => {
    if (carousel?.current) {
      carousel.current.scrollLeft -= scrollWidth
    }
  }
  const scrollX = useMemo(
    () =>
      throttle(() => {
        let scroll = carousel.current.scrollLeft
        if (prevRef?.current) {
          prevRef?.current?.classList.toggle('disabled', scroll < 150)
        }
        if (nextRef?.current) {
          nextRef?.current?.classList.toggle(
            'disabled',
            scroll + scrollWidth > carousel.current.scrollWidth - 50
          )
        }
      }, 300),
    [scrollWidth]
  )
  return {
    carousel,
    feature,
    prevRef,
    nextRef,
    scrollX,
    scrollPrev,
    scrollNext,
  }
}
