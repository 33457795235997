import { put, call, takeLatest, all } from 'redux-saga/effects'
import {
  getAllComments,
  postComments,
  deleteCommentApi
} from '../../api/comments'
import { popToast } from '../../components/common/PopToast'
import {
  gettingComments,
  gettingCommentsError,
  gettingCommentsSuccess,
  postComment,
  postCommentSuccess,
  postCommentError,
  deleteComment,
  deleteCommentSuccess,
  deleteCommentError,
  deleteChildCommentSuccess,
  deleteChildCommentError,
  deleteChildComment
} from '../reducers/adsReducers'

//Load Ads for Homepage
function* getCommentsOfAd(action) {
  try {
    let res = yield call(getAllComments, action.payload)
    yield put(gettingCommentsSuccess(res))
  } catch (error) {
    console.log(error)
    yield put(gettingCommentsError(error))
  }
}

//Posting the comments
function* posttheComment(action) {
  try {
    let res = yield call(postComments, action.payload)
    yield put(postCommentSuccess(res))
  } catch (error) {
    popToast(
      error?.response?.data?.status?.message[0] || 'Unable to post comment',
      'top',
      2000
    )
    yield put(postCommentError(error))
  }
}

//Delete the comments
function* deletetheComment(action) {
  try {
    yield call(deleteCommentApi, action.payload)
    yield put(deleteCommentSuccess(action.payload))
  } catch (error) {
    yield put(deleteCommentError(error))
  }
}

//Delete chikd comments
function* deletetheChildComment(action) {
  try {
    yield call(deleteCommentApi, action.payload.cid)
    yield put(deleteChildCommentSuccess(action.payload))
  } catch (error) {
    yield put(deleteChildCommentError(error))
  }
}

//The auth Watchers
export default function* commentSaga() {
  yield all([
    takeLatest(gettingComments, getCommentsOfAd),
    takeLatest(postComment, posttheComment),
    takeLatest(deleteComment, deletetheComment),
    takeLatest(deleteChildComment, deletetheChildComment)
  ])
}
