import { all, put, takeLatest } from 'redux-saga/effects'
import {
  getThreads,
  getThreadsError,
  getThreadsSuccess,
  updateThreads,
  updateThreadsError,
  updateThreadsSuccess,
} from '../reducers/chatReducers'

/*
Get thread
*/
function* getTheThread(action) {
  try {
    yield put(getThreadsSuccess(action.payload))
  } catch (error) {
    console.log(error.response)
    yield put(getThreadsError(error))
  }
}

/*
  Update Thread
*/
function* updateTheThread(action) {
  try {
    yield put(updateThreadsSuccess(action.payload))
  } catch (error) {
    yield put(updateThreadsError())
  }
}

export default function* chatSagas() {
  yield all([
    takeLatest(getThreads, getTheThread),
    takeLatest(updateThreads, updateTheThread),
  ])
}
