import { useEffect } from 'react'

/* A custom hook that is used to reset the form fields. */
export const useResetAttributeField = (
  resetField = () => {},
  textChange = {},
  productAttributes = {},
  deliveryDataAttributes = {}
) => {
  useEffect(() => {
    const attributesField = productAttributes?.map(({ fieldName }) => fieldName)
    attributesField?.map((field) => resetField(`${field}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChange?.category, textChange?.subCategory])

  useEffect(() => {
    const attributesField = deliveryDataAttributes?.map(
      ({ fieldName }) => fieldName
    )
    attributesField?.map((field) => resetField(`${field}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChange?.subCategory, textChange?.delivery])
}
