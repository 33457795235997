const sorter = (a, b) => {
  if (a.lastMessageTimeStamp < b.lastMessageTimeStamp) {
    return 1
  }
  if (a.lastMessageTimeStamp > b.lastMessageTimeStamp) {
    return -1
  }
  return 0
}

export default sorter
