import { useState, useCallback } from 'react'

/**
 * It returns an array with two elements, the first element is the current state of the toggle, and the
 * second element is a function that can be used to toggle the state
 * @param initialState - The initial state of the toggle.
 * @returns An array with two elements. The first element is the current state of the toggle, and the
 * second element is a function that can be used to toggle the state.
 */
export const useTabToggle = (initialState) => {
  const [active, setActive] = useState(initialState)

  const toggle = useCallback((state) => {
    setActive((current) => (state === current ? current : state))
  }, [])

  return [active, toggle, setActive]
}
