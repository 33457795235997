import { connect } from 'react-redux'

import NotificationList from '../../pages/Notification/NotificationList'

const NotificationDropDown = ({ auth }) => {
  return (
    <div className='dropdown-container-body'>
      <NotificationList
        className='dropDownNotification'
        auth={auth}
        numberOfNotifications={8}
      />
    </div>
  )
}

const mapStateToPros = (state) => ({
  auth: state.auth
})
export default connect(mapStateToPros)(NotificationDropDown)
