import { useMutation } from 'react-query'
import { send } from 'emailjs-com'
import { popToast } from '../components/common/PopToast'

export const SendMailService = (reset) => {
  const { mutate, isLoading, data } = useMutation(
    async (data) => {
      return await send(
        'service_84k855n',
        'template_32xck9w',
        data,
        'AARy1FbSZ6iJ7n3vk'
      )
    },
    {
      onSuccess: () => {
        popToast(
          'Thank your for boosting with us. We will contact you soon.',
          'TOP_RIGHT'
        )
        reset()
      },
      onError: () =>
        popToast(
          'Sorry we are unable to connect. Please try again',
          'TOP_RIGHT'
        )
    }
  )
  return { mutate, isLoading, data }
}
