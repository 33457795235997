import { NoProduct, NoList, NoReview, NoBlockList } from '../../icons'

const imgArr = {
  'no-ad': <NoProduct />,
  'no-list': <NoList />,
  'no-review': <NoReview />,
  'no-block-list': <NoBlockList />
}

const EmptyAdMessage = ({ message, imageName, link }) => {
  return (
    <div className='empty no-list'>
      {imgArr?.[imageName]}
      <p className='title text-muted text-muted--empty'>
        {message} {link && link}
      </p>
    </div>
  )
}

export default EmptyAdMessage
