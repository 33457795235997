import { put, call, takeLatest, all } from 'redux-saga/effects'
import { getAllCategory } from '../../api/category'
import { toast } from 'react-toastify'
import {
  gettingCategories,
  gettingCategoriesFail,
  gettingCategoriesSuccess,
} from '../reducers/categoryReducers'
import { addErrorMessage } from '../reducers/alertReducers'

//Load Ads category for Homepage
function* getAllCat() {
  try {
    let res = yield call(getAllCategory)
    //Checking the Server Status. If its 500 than handling it manually
    if (res?.status === 500) {
      toast.error('Server Error', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      yield put(gettingCategoriesFail('Server Error'))
    } else {
      yield put(gettingCategoriesSuccess(res))
    }
  } catch (error) {
    if (
      error?.response?.data?.status?.message[0] ===
      'Error: Too many request from this IP. Please try after sometime.'
    ) {
      yield put(addErrorMessage(error?.response?.data?.status?.message[0]))
    }
    if (error?.response?.status === 500) {
      toast.error('Server Error', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else {
      yield all(
        error.map(err =>
          put(
            toast.error(err.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
          )
        )
      )
    }
    yield put(gettingCategoriesFail(error))
  }
}

//The auth Watchers
export default function* catgeorySaga() {
  yield all([takeLatest(gettingCategories, getAllCat)])
}
