import { forwardRef } from 'react'

export const ToggleInput = forwardRef((props, ref) => {
  const { onClick = () => {}, ...rest } = props
  return (
    <div className='option-radio'>
      <label className='switch' htmlFor={rest?.id}>
        <input type='checkbox' {...rest} ref={ref} />
        <div onClick={() => onClick()} className='slider round'></div>
      </label>
    </div>
  )
})
