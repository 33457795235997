import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useNavActionToggle = () => {
  const location = useLocation()

  const [dropdownToggle, setDropdownToggle] = useState('')

  const toggleHandler = (state) => {
    setDropdownToggle((curr) => {
      if (state === curr) return ''
      return state
    })
  }

  useEffect(() => {
    toggleHandler('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return { dropdownToggle, toggleHandler }
}

export const CHAT_TOGGLE = 'CHAT_TOGGLE'
export const NOTIFICATION_TOGGLE = 'NOTIFICATION_TOGGLE'
export const PROFILE_TOGGLE = 'PROFILE_TOGGLE'
