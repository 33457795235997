import React from 'react'
import { Link } from 'react-router-dom'

export const CautionMsg = ({ device }) => {
  return (
    <p className={`caution-msg caution-msg--${device}`}>
      Note: We recommend you to physically inspect the product/ Service before
      making payment. Avoid paying fees or advance payment to sellers.{' '}
      <span className='caution-msg-link'>
        <Link to='/safety'>Click here for more details</Link>
      </span>
    </p>
  )
}
