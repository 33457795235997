import { createAction, createReducer } from '@reduxjs/toolkit'
import distinct from '../../utils/distinct'

export const gettingAds = createAction('GET_ADS/REQUEST')
export const gettingAdsSuccess = createAction('GET_ADS_SUCCESS/RESPONSE')
export const gettingAdsError = createAction('GET_ADS_FAIL/RESPONSE')

export const gettingAd = createAction('GET_AD/REQUEST')
export const gettingAdSuccess = createAction('GET_AD_SUCCESS/RESPONSE')
export const gettingAdError = createAction('GET_AD_FAIL/RESPONSE')

export const loadingmoreAd = createAction('LOADMORE_AD/REQUEST')
export const loadingmoreAdSuccess = createAction('LOADMORE_AD_SUCCESS/RESPONSE')
export const loadingmoreAdError = createAction('LOADMORE_AD_FAIL/RESPONSE')

export const postAd = createAction('POST_AD/REQUEST')
export const postAdSuccess = createAction('POST_AD_SUCCESS/RESPONSE')
export const postAdError = createAction('POST_AD_FAIL/RESPONSE')
export const removePostAdError = createAction('REMOVE_POST_AD_ERROR')

export const updateAd = createAction('UPDATE_AD/REQUEST')
export const updateAdSuccess = createAction('UPDATE_AD_SUCCESS/RESPONSE')
export const updateAdError = createAction('UPDATE_AD_FAIL/RESPONSE')

export const deleteAd = createAction('DELETE_AD/REQUEST')
export const deleteAdSuccess = createAction('DELETE_AD_SUCCESS/RESPONSE')
export const deleteAdError = createAction('DELETE_AD_FAIL/RESPONSE')

export const uploadImage = createAction('UPLOAD_IMAGE/REQUEST')
export const uploadImageSuccess = createAction('UPLOAD_IMAGE_SUCCESS/RESPONSE')
export const uploadImageError = createAction('UPLOAD_IMAGE_FAIL/RESPONSE')

// swapping
export const swappingImages = createAction('SWAPIMAGES')

export const deleteImage = createAction('DELETE_IMAGE/REQUEST')
export const deleteImageSuccess = createAction('DELETE_IMAGE_SUCCESS/RESPONSE')
export const deleteImageError = createAction('DELETE_IMAGE_FAIL/RESPONSE')

// for deleting imageMediaAttributes
export const deleteImageMediaSuccess = createAction(
  'DELETE_IMAGE_MEDIA_SUCCESS/RESPONSE'
)
export const deleteImageMediaError = createAction(
  'DELETE_IMAGE_MEDIA_FAIL/RESPONSE'
)
export const deleteImageMedia = createAction('DELETE_IMAGE_MEDIA/REQUEST')

export const gettingComments = createAction('GET_COMMENTS/REQUEST')
export const gettingCommentsSuccess = createAction(
  'GET_COMMENTS_SUCCESS/RESPONSE'
)
export const gettingCommentsError = createAction('GET_COMMENTS_FAIL/RESPONSE')

export const postComment = createAction('POST_COMMENT/REQUEST')
export const postCommentSuccess = createAction('POST_COMMENT_SUCCESS/RESPONSE')
export const postCommentError = createAction('POST_COMMENT_FAIL/RESPONSE')

export const deleteComment = createAction('DELETE_COMMENT/REQUEST')
export const deleteCommentSuccess = createAction(
  'DELETE_COMMENT_SUCCESS/RESPONSE'
)
export const deleteCommentError = createAction('DELETE_COMMENT_FAIL/RESPONSE')

export const deleteChildComment = createAction('DELETE_CHILD_COMMENT/REQUEST')
export const deleteChildCommentSuccess = createAction(
  'DELETE_CHILD_COMMENT_SUCCESS/RESPONSE'
)
export const deleteChildCommentError = createAction(
  'DELETE_CHILD_COMMENT_FAIL/RESPONSE'
)

export const gettingUserAds = createAction('GET_USER_AD/REQUEST')
export const gettingUserAdsSuccess = createAction(
  'GET_USER_AD_SUCCESS/RESPONSE'
)
export const gettingUserAdsError = createAction('GET_USER_AD_FAIL/RESPONSE')

// ads report
export const adsReport = createAction('ADS_REPORT/REQUEST')
export const adsReportSuccess = createAction('ADS_REPORT_SUCCESS/RESPONSE')
export const adsReportError = createAction('ADS_REPORT_FAIL/RESPONSE')

// ads hold
export const adsHold = createAction('ADS_HOLD/REQUEST')
export const adsHoldSuccess = createAction('ADS_HOLD_SUCCESS/RESPONSE')
export const adsHoldError = createAction('ADS_HOLD_FAIL/RESPONSE')

// ads unHold
export const adsUnHold = createAction('ADS_UNHOLD/REQUEST')
export const adsUnHoldSuccess = createAction('ADS_UNHOLD_SUCCESS/RESPONSE')
export const adsUnHoldError = createAction('ADS_UNHOLD_FAIL/RESPONSE')

// ads sold
export const adsSold = createAction('ADS_SOLD/REQUEST')
export const adsSoldSuccess = createAction('ADS_SOLD_SUCCESS/RESPONSE')
export const adsSoldError = createAction('ADS_SOLD_FAIL/RESPONSE')

// ads clear
export const adsClear = createAction('ADS_CLEAR')
export const clearStepAds = createAction('CLEAR_STEP_ADS')

//wish
export const addWish = createAction('UP_SAVED')
export const removeWish = createAction('DOWN_SAVED')

export const clearuserAd = createAction('CLEAR_UAD')

const initialState = {
  ads: [],
  gettingAds: false,
  gettingAdsSuccess: false,
  gettingAdsError: null,
  adsfinished: false,

  ad: {},
  gettingAd: false,
  gettingAdSuccess: false,
  gettingAdError: null,

  postAd: false,
  postAdSuccess: false,
  postAdError: false,

  images: [],
  uploadingImage: false,
  uploadImageSuccess: false,
  uploadImageError: null,

  //update ad
  updatingAd: false,
  updateAdSuccess: false,
  updateAdError: null,

  comments: [],
  gettingComments: false,
  gettingCommentsSuccess: false,
  gettingCommentsError: null,

  postComment: false,
  postCommentSuccess: false,
  postCommentError: null,

  deleteComment: false,
  deleteCommentSuccess: false,
  deleteCommentError: null,

  deleteChildComment: false,
  deleteChildCommentSuccess: false,
  deleteChildCommentError: null,

  userad: [],
  gettingUserAd: false,
  gettingUserAdSuccess: false,
  gettingUserAdError: null,
  uadsFinished: false,

  // ad report
  gettingReports: false,
  reportsSuccess: false,
  reportsError: null,

  // ad hold
  adHoldInit: false,
  adHoldSuccess: false,
  adHoldError: null,

  // ad unhold
  adUnHoldInit: false,
  adUnHoldSuccess: false,
  adUnHoldError: null,

  //SLider Ads
  sliderAds: [],
  getSliderAds: false,
  getSliderAdsSuccess: false,
  getSliderAdsError: null,

  //Side Ads
  sideAds: [],
  getSideAds: false,
  getSideAdsSuccess: false,
  getSideAdsError: null
}

const adsReducers = createReducer(initialState, {
  [gettingAds]: state => {
    state.gettingAds = true
  },
  [gettingAdsSuccess]: (state, action) => {
    const ads = action.payload?.data?.data
    if (state?.ads?.length !== 0) {
      state.ads = [...state.ads, ...ads]
      state.ads = distinct(state.ads)
    } else if (ads?.length === 0) {
      state.adsfinished = true
    } else {
      state.ads = ads
    }
    state.gettingAds = false
    state.gettingAdsSuccess = true
  },
  [gettingAdsError]: (state, action) => {
    state.gettingAds = false
    state.gettingAdsSuccess = false
    state.gettingAdsError = action.payload
  },

  [postAd]: state => {
    state.postAd = true
  },
  [postAdSuccess]: (state, action) => {
    state.postAdSuccess = true
    state.postAdError = false
    state.userad.unshift(action?.payload)
  },
  [postAdError]: (state, action) => {
    state.postad = true
    state.postAdSuccess = false
    state.postAdError = true
  },
  [removePostAdError]: state => {
    state.postAdSuccess = false
    state.postAdError = false
    state.postAd = false
  },
  [deleteAdSuccess]: (state, action) => {
    state.userad = state.userad.filter(ad => ad.id !== action.payload)
  },

  [updateAd]: state => {
    state.updatingAd = true
  },
  [updateAdSuccess]: (state, action) => {
    state.updatingAd = false
    state.updateAdSuccess = true
    //Updating logic
    state.userad = state.userad.map(d =>
      d.id !== action.payload.data.data.id ? d : action.payload.data.data
    )
  },
  [updateAdError]: (state, action) => {
    state.updatingAd = false
    state.updateAdError = action.payload
  },

  [uploadImage]: state => {
    state.uploadingImage = true
  },
  [uploadImageSuccess]: (state, action) => {
    state.uploadingImage = false
    const img = action.payload?.data?.data
    if (state.images.length !== 0) {
      state.images = [...state.images, img]
    } else {
      state.images = [img]
    }
    state.uploadImageSuccess = true
  },
  [uploadImageError]: (state, action) => {
    state.uploadingImage = false
    state.uploadImageSuccess = false
    state.uploadImageError = action.payload?.data?.data
  },
  // swapp
  [swappingImages]: (state, action) => {
    // action.payload;
    let temp = state.images[action.payload]
    state.images[action.payload] = state.images[0]
    state.images[0] = temp
  },

  // delete  image media
  [deleteImageMediaSuccess]: (state, action) => {
    state.userstate.userad.filter(pM => pM.id !== action.payload)
  },

  [deleteImageSuccess]: (state, action) => {
    state.images = state.images.filter(x => x.id !== action.payload)
  },

  [loadingmoreAd]: state => {
    state.loadingmoreAd = true
  },
  [loadingmoreAdSuccess]: (state, action) => {
    const ads = Object.values(action.payload?.data?.data || {})
    state.ads = [...state.ads, ...ads]
    state.loadingmoreAd = true
    state.loadingmoreAdSuccess = true
    if (ads?.length === 0) {
      state.adsfinished = true
    }
  },
  [loadingmoreAdError]: (state, action) => {
    state.loadingmoreAd = false
    state.loadingmoreAdSuccess = false
    state.loadingmoreAdError = action.payload
    state.adsfinished = true
  },

  //Clear Ads
  [adsClear]: state => {
    state.ads = []
    state.uadsFinished = false
  },

  //User ad clear
  [clearuserAd]: state => {
    state.userad = []
    state.uadsFinished = false
  },

  [gettingAd]: state => {
    state.gettingAd = true
  },
  [gettingAdSuccess]: (state, action) => {
    const ad = action.payload?.data.data
    state.ad = ad
    state.adRes = action.payload?.data
    state.gettingAd = false
    state.gettingAdSuccess = true
  },
  [gettingAdError]: (state, action) => {
    state.gettingAd = false
    state.gettingAdSuccess = false
    state.gettingAdError = action.payload
  },

  [gettingComments]: state => {
    state.gettingComments = true
  },
  [gettingCommentsSuccess]: (state, action) => {
    const comments = Object.values(action.payload?.data?.data || {})
    state.comments = comments
    state.gettingComments = false
    state.gettingCommentsSuccess = true
  },
  [gettingCommentsError]: (state, action) => {
    state.gettingComments = false
    state.gettingCommentsSuccess = false
    state.gettingCommentsError = action.payload
  },

  [postComment]: state => {
    state.postComment = true
  },
  [postCommentSuccess]: (state, action) => {
    if (action?.payload?.data?.data?.isParent === false) {
      let findIndex = state.comments.findIndex(
        f => f.id === action?.payload?.data?.data?.parentId
      )
      //Checking if the child comment is null
      if (state.comments[findIndex].childComment === null) {
        state.comments[findIndex].childComment = [action?.payload?.data?.data]
      } else {
        state.comments[findIndex].childComment.push(action?.payload?.data?.data)
      }
    } else {
      state.comments.push(action?.payload?.data?.data)
    }
    state.postComment = false
    state.postCommentSuccess = true
  },
  [postCommentError]: (state, action) => {
    state.postComment = false
    state.postCommentSuccess = false
    state.postCommentError = action.payload
  },

  [deleteComment]: state => {
    state.deleteComment = true
  },
  [deleteCommentSuccess]: (state, action) => {
    state.comments = state.comments.filter(
      comment => comment.id !== action?.payload
    )
    state.deleteComment = false
    state.deleteCommentSuccess = true
  },
  [deleteCommentError]: (state, action) => {
    state.deleteComment = false
    state.deleteCommentSuccess = false
    state.deleteCommentError = action.payload
  },

  [deleteChildComment]: state => {
    state.deleteChildComment = true
  },
  [deleteChildCommentSuccess]: (state, action) => {
    let findIndex = state.comments.findIndex(f => f.id === action?.payload?.pid)
    state.comments[findIndex].childComment = state.comments[
      findIndex
    ].childComment.filter(comment => comment.id !== action?.payload?.cid)
    state.deleteChildComment = false
    state.deleteChildCommentSuccess = true
  },
  [deleteChildCommentError]: (state, action) => {
    state.deleteChildComment = false
    state.deleteChildCommentSuccess = false
    state.deleteChildCommentError = action.payload
  },

  [gettingUserAds]: state => {
    state.gettingUserAd = true
  },
  [gettingUserAdsSuccess]: (state, action) => {
    const ads = action.payload?.data?.data
    if (ads?.length === 0 || ads?.length < 10) {
      state.uadsFinished = true
    }
    if (state.userad?.length !== 0) {
      state.userad = [...state.userad, ...ads]
    } else {
      state.userad = ads
    }
    state.gettingUserAd = false
    state.gettingUserAdSuccess = true
  },
  [gettingUserAdsError]: (state, action) => {
    state.gettingUserAd = false
    state.gettingUserAdError = action.payload?.data?.data
  },

  // ad reports
  [adsReport]: state => {
    state.gettingReports = true
  },

  [adsReportSuccess]: (state, action) => {
    state.gettingReports = false
    state.reportsSuccess = true
  },
  [adsReportError]: (state, action) => {
    state.gettingReports = false
    state.reportsSuccess = false
    state.reportsError = action.payload?.data?.data
  },

  // ad hold
  [adsHold]: state => {
    state.adHoldInit = true
  },

  [adsHoldSuccess]: (state, action) => {
    state.adHoldInit = false
    state.adHoldSuccess = true
    /*
      Updating local State to hold
    */
    //Find index of specific object using findIndex method.
    let objIndex = state.userad.findIndex(
      obj => obj.id === action?.payload?.productId
    )
    //Updating state
    state.userad[objIndex].status = 5
  },
  [adsHoldError]: (state, action) => {
    state.adHoldInit = false
    state.adHoldSuccess = false
    state.adHoldError = action.payload?.data?.data
  },

  // ad unhold
  [adsUnHold]: state => {
    state.adUnHoldInit = true
  },

  [adsUnHoldSuccess]: (state, action) => {
    state.adUnHoldInit = false
    state.adUnHoldSuccess = true
    /*
      Updating local State to unhold
    */
    //Find index of specific object using findIndex method.
    let objIndex = state.userad.findIndex(
      obj => obj.id === action?.payload?.productId
    )
    //Updating state
    state.userad[objIndex].status = 1
  },
  [adsUnHoldError]: (state, action) => {
    state.adUnHoldInit = false
    state.adUnHoldSuccess = false
    state.adUnHoldError = action.payload?.data?.data
  },

  // ad unhold
  [adsSold]: state => {
    state.adsSold = true
  },

  [adsSoldSuccess]: (state, action) => {
    state.adsSold = false
    state.adSoldSuccess = true
    //Find index of specific object using findIndex method.
    let objIndex = state.userad.findIndex(obj => obj.id === action?.payload)
    //Updating state
    state.userad[objIndex].status = 6
  },
  [adsSoldError]: (state, action) => {
    state.adsSold = false
    state.adsSoldSuccess = false
    state.adsSoldError = action.payload?.data?.data
  },

  //Clear Local ads
  [clearStepAds]: state => {
    state.postAd = false
    state.postAdSuccess = false
    state.images = []
  },

  //Adding wish success
  [addWish]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.ads.some(a => a.id === action.payload.id)) {
      const theIndex = state.ads.findIndex(a => a.id === action.payload.id)
      state.ads[theIndex].isSaved = true
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = true
  },
  //Remove wish
  [removeWish]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.ads.some(a => a.id === action.payload.id)) {
      const theIndex = state.ads.findIndex(a => a.id === action.payload.id)
      state.ads[theIndex].isSaved = false
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = false
  }
})

export default adsReducers
