import React, { useEffect } from 'react'

const BannerAds = props => {
  const { keyProp } = props
  const showGoogleAds = true

  // useEffect(() => {
  //   window.adsbygoogle = window.adsbygoogle || []
  //   window.adsbygoogle.push({})
  // }, [keyProp])

  return (
    <React.Fragment key={keyProp}>
      {showGoogleAds ? (
        <>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4509747508536978"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-4509747508536978"
            data-ad-slot="4450875194"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </>
      ) : null}
    </React.Fragment>
  )
}

export default BannerAds
