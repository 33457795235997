import { useQuery } from 'react-query'
import { axiosInstance } from '../utils/axiosInstance'

/**
 * It's a custom hook that uses the useQuery hook from the react-query library to fetch data from an
 * API.
 * @param url - The url to fetch
 * @param uniqueKey - This is a unique key that will be used to identify the query.
 * @returns The return value of the function passed to useQuery.
 */

export const useGetFetch = (url, uniqueKey) => {
  const { error, isLoading, data } = useQuery(uniqueKey, async () => {
    const { data } = await axiosInstance().get(url)
    return data
  })
  return { error, isLoading, data }
}
