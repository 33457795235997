import { lazy, Suspense, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import importRetry from '../utils/importRetry'

import Navbar from './navbar/Navbar'
import MobileNav from './layout/MobileNav'
import PrivateRoute from '../utils/PrivateRoute'
import ScrollTOTop from '../utils/ScrollToTop'
import HBLoader from './loaders/HBLoader'
import CategorySearchResult from './pages/ads/Category/Category'
import { useGaTracker, useWindowDimensions } from '../hooks'
// import Business from './pages/Business'

const Home = lazy(() => importRetry(() => import('./pages/Home')))

const PageNotFound = lazy(() =>
  importRetry(() => import('./pages/PageNotFound'))
)
const Profile = lazy(() =>
  importRetry(() => import('./pages/user/profile/MyProfile'))
)
const ProductDetail = lazy(() =>
  importRetry(() => import('./pages/ads/ProductDetail'))
)
const Login = lazy(() => importRetry(() => import('./pages/Auth/Login')))
const Signup = lazy(() => importRetry(() => import('./pages/Auth/Signup')))
const Otp = lazy(() => importRetry(() => import('./pages/Auth/Otp')))
// const Chat = lazy(() => importRetry(() => import('./pages/chat/ChatPage')))
const Search = lazy(() => importRetry(() => import('./pages/SearchResult')))

const AddProductMob = lazy(() =>
  importRetry(() => import('./addProduct/AddProductMob'))
)

const ForgotPassword = lazy(() =>
  importRetry(() => import('./pages/Auth/ForgotPassword'))
)
const ResetPassword = lazy(() =>
  importRetry(() => import('./pages/Auth/ResetPassword'))
)
const OtherProfile = lazy(() =>
  importRetry(() => import('./pages/user/profile/OtherProfile'))
)
const Notifications = lazy(() =>
  importRetry(() => import('./pages/Notification/Notification'))
)

const ChatList = lazy(() => import('./pages/chat/ChatList'))

const Settings = lazy(() => importRetry(() => import('./pages/user/Settings')))
const Terms = lazy(() => importRetry(() => import('./pages/terms/Terms')))
const Faq = lazy(() => importRetry(() => import('./pages/terms/Faq')))
const Safety = lazy(() => importRetry(() => import('./pages/terms/Safety')))
const PostRules = lazy(() =>
  importRetry(() => import('./pages/terms/PostRules'))
)
const VerifyEmail = lazy(() =>
  importRetry(() => import('./pages/Auth/VerifyEmail'))
)
const RegisterOldUser = lazy(() =>
  importRetry(() => import('./pages/Auth/RegisterOldUser'))
)
const VerifyPhone = lazy(() =>
  importRetry(() => import('./pages/Auth/VerifyPhone'))
)
const Contact = lazy(() => importRetry(() => import('./pages/terms/Contact')))
const ErrorBoundary = lazy(() =>
  importRetry(() => import('./layout/ErrorBoundry'))
)
const EditAdMobile = lazy(() => importRetry(() => import('./Ads/EditAdMobile')))
const HbSelect = lazy(() => importRetry(() => import('./pages/HbSelect')))
const BoostAd = lazy(() => importRetry(() => import('./pages/boost/BoostAd')))

const Routes = () => {
  const location = useLocation()
  const windowDimensions = useWindowDimensions()
  const currentLocation = location.pathname
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  useEffect(() => {
    const urlArr = ['/terms', '/safety', '/rules']
    const isTrue = urlArr.includes(currentLocation)
    setIsMobileNavOpen(isTrue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  useGaTracker()

  return (
    <Router>
      <Suspense fallback={<HBLoader />}>
        <ErrorBoundary>
          <ToastContainer />
          <ScrollTOTop />
          <div className='hb__parent'>
            <Navbar />
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/oldlogin' component={VerifyEmail} />
              <Route exact path='/registerouser' component={RegisterOldUser} />
              <Route exact path='/updateolduser' component={VerifyPhone} />
              <Route exact path='/forgot-password' component={ForgotPassword} />
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/otp' component={Otp} />
              <Route exact path='/' component={Home} />
              <PrivateRoute exact path='/profile' component={Profile} />
              <PrivateRoute
                exact
                path='/profile/edit-ad/:id'
                component={EditAdMobile}
              />
              <Route exact path='/notifications' component={Notifications} />
              <Route exact path='/user/:uid' component={OtherProfile} />
              <Route exact path='/search/product' component={Search} />
              <Route
                exact
                path='/category/:name/:pcid/:cid'
                component={CategorySearchResult}
              />
              <Route
                exact
                path='/category/:name/:pcid'
                component={CategorySearchResult}
              />
              <Route
                exact
                path='/:categoryName/:name/:id'
                component={ProductDetail}
              />
              <Route
                path='/:categoryName/:brandName/:name/:id'
                component={ProductDetail}
              />
              <PrivateRoute
                exact
                path='/settings/password'
                component={Settings}
              />
              {/* <PrivateRoute exact path='/settings/theme' component={Settings} /> */}
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/safety' component={Safety} />
              <Route exact path='/rules' component={PostRules} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/faq' component={Faq} />
              <Route exact path='/hb-select' component={HbSelect} />
              <Route exact path='/boost' component={BoostAd} />
              {/*
              //TODO: add after business page is completed
              <Route exact path='/business' component={Business} />
              */}
              {window.innerWidth < 768 && [
                <PrivateRoute
                  exact
                  path='/post'
                  key='/post'
                  component={AddProductMob}
                />,

                <PrivateRoute
                  exact
                  path='/chat'
                  key='/chat'
                  component={ChatList}
                />
              ]}
              <Route path='*' component={PageNotFound} />
            </Switch>
            {!isMobileNavOpen && windowDimensions?.width < 576 && <MobileNav />}
          </div>
        </ErrorBoundary>
      </Suspense>
    </Router>
  )
}

export default Routes
