import React from 'react'
import { Link } from 'react-router-dom'

import { useGAEventTracker, useWindowDimensions } from '../../../../hooks'
import { uuid, validateURL } from '../../../../utils'

import InBetweenAds from '../../../Ads/GoogleAds/InBetweenAds'

import { AD_TYPE } from '../../../../constants'
import { Image } from '../../../image'

const MAX_STACKED_ADS = 2

const AdImage = ({ ad, height }) => {
  const src = window.innerWidth > 767 ? ad.adContentDesktop : ad.adContentMobile
  return <Image src={src} height={height} width='100%' alt='static-products' />
}

const SingleFeedAdView = ({ ad }) => {
  const GAEvent = useGAEventTracker()
  const windowDimesion = useWindowDimensions()

  const height = windowDimesion?.width > 767 ? 120 : 110

  if (ad.type?.toLowerCase() === AD_TYPE?.ADSENCE) {
    return <InBetweenAds ad={ad} keyProp={`adsense-${ad.id}`} />
  }

  switch (ad?.linkType) {
    case 'external':
      return (
        <a
          onClick={() => GAEvent(ad?.pageName, ad?.slotName, ad?.pageHtml)}
          href={validateURL(ad?.link || 'hamrobazaar.com')}
          target='_blank'
          rel='noreferrer'
        >
          <AdImage ad={ad} height={height} />
        </a>
      )
    case 'profile':
      return (
        <Link to={`/user/${ad.link}`}>
          <AdImage ad={ad} height={height} />
        </Link>
      )
    case 'product':
      return (
        <Link to={`/product/${ad.link}`}>
          <AdImage ad={ad} height={height} />
        </Link>
      )
    default:
      return null
  }
}

const FeedAdsView = ({ ads, height }) => {
  if (!ads) return null
  const adsToDisplay = Array.isArray(ads)
    ? ads?.slice(0, MAX_STACKED_ADS)
    : [ads]

  const singleAdWrapperStyle = {
    marginRight: adsToDisplay?.length > 1 ? '2px' : undefined,
    marginBottom: '15px',
    marginTop: '15px',
    width: '100%'
  }

  // const adsToDisplay = NthArray(MAX_STACKED_ADS_PER_PAGE, maxAds)

  const adElements = adsToDisplay
    ?.filter((ad) => ad?.type?.toLowerCase() !== 'admob')
    .map((ad, i) => (
      <div
        key={uuid + i}
        className='inBetweenAds'
        data-name='inbetween'
        style={singleAdWrapperStyle}
      >
        <SingleFeedAdView ad={ad} height={height} />
      </div>
    ))

  if (adsToDisplay?.length <= 1) {
    return adElements
  }

  const isSmallScreen = window.innerWidth < 768
  const containerStyle = isSmallScreen ? {} : { display: 'flex' }
  return <div style={containerStyle}>{adElements}</div>
}

export function estimateFeedAdsViewHeight(ads, itemHeight) {
  if (window.innerWidth < 768) {
    return itemHeight * ads?.length + 10 * (ads?.length - 1)
  } else {
    return itemHeight
  }
}

export default FeedAdsView
