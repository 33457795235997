import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const getBrandNamesApi = async id => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/AppData?Id=${id}`, {
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0,
  })
  return res
}
