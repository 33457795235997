import { useState, useEffect, forwardRef } from 'react'
import { isEmpty } from 'lodash'

import addImage from '../../utils/addImage'
import ErrorMessage from '../common/InputField/ErrorMessage'
import { PlusIcon, HBIcon, CloseIcon } from '../../icons'

export const AddImage = forwardRef((props, ref) => {
  const {
    productMedia,
    setProductMedia,
    uploadImage,
    isLoading,
    setValue,
    deleteImageHandler,
    initialState
  } = props
  // eslint-disable-next-line no-unused-vars
  const [loaderImg, setLoaderImg] = useState('')

  const imageHandler = (e) => {
    addImage(setLoaderImg, uploadImage, e, null)
  }

  const swapHandler = (index) => {
    setProductMedia((prevState) => {
      let data = [...prevState]
      let temp = data[0]
      data[0] = data[index]
      data[index] = temp
      return data
    })
  }

  useEffect(() => {
    if (initialState === 1) {
      setValue('productMedia', productMedia, {
        shouldValidate: false
      })
    } else {
      setValue('productMedia', productMedia, {
        shouldValidate: true
      })
    }
  }, [initialState, productMedia, setValue])

  return (
    <div className='form-group'>
      <div className='image-row'>
        {!isEmpty(productMedia) && (
          <>
            {productMedia?.map((image, index) => (
              <div className='photo_item' key={image?.id || index}>
                <img
                  src={image?.locationKey}
                  key={Date.now()}
                  alt='loading...'
                  loading='lazy'
                  onError={(e) => {
                    e.currentTarget.style.opacity = '0'
                    e.currentTarget.classList.add('loading')
                  }}
                />
                <button
                  type='button'
                  className='delete-img'
                  onClick={() => deleteImageHandler(image)}
                  title='Delete Image'
                >
                  <CloseIcon />
                </button>
                <button
                  type='button'
                  className={'star ' + (index === 0 ? ' active ' : ' ')}
                  onClick={(e) => swapHandler(index)}
                  index={index}
                >
                  <HBIcon />
                </button>
              </div>
            ))}
          </>
        )}
        {productMedia?.length < 7 ? (
          <div className='photo_item '>
            <label
              htmlFor='uploadImage'
              className={`select-img ${props?.errors ? 'required-error' : ''}`}
            >
              <PlusIcon />

              <small
                className={`text-muted ${
                  props?.errors ? 'required-error-text-color' : ''
                }`}
              >
                {isLoading ? 'wait..' : 'Choose a photo *'}
              </small>
            </label>
            <input
              type='file'
              accept='image/*'
              onChange={(e) => imageHandler(e)}
              onClick={(event) => {
                event.target.value = null
              }}
              name='productMedia'
              ref={ref}
              id='uploadImage'
            />
          </div>
        ) : null}
      </div>
      {props?.errors && <ErrorMessage message={props?.errors} />}
    </div>
  )
})
