import { createAction, createReducer } from '@reduxjs/toolkit'

export const gettingDeals = createAction('GET_DEALS/REQUEST')
export const gettingDealsSuccess = createAction('GET_DEALS_SUCCESS/RESPONSE')
export const gettingDealsError = createAction('GET_DEALS_FAIL/RESPONSE')

export const clearDeals = createAction('CLEAR_DEALS')

const initialState = {
  deals: [],
  gettingDeals: false,
  gettingDealsSuccess: false,
  gettingDealsError: null,
  dealsfinished: false,
}

const dealsReducers = createReducer(initialState, {
  [gettingDeals]: state => {
    state.gettingDeals = true
  },
  [gettingDealsSuccess]: (state, action) => {
    const deals = action.payload?.data?.data
    if (state.deals?.length !== 0) {
      state.deals = [...state.deals, ...deals]
    } else {
      state.deals = deals
    }
    state.gettingDeals = false
    state.gettingDealsSuccess = true
    if (deals?.length === 0) {
      state.dealsfinished = true
    }
  },
  [gettingDealsError]: (state, action) => {
    state.gettingDeals = false
    state.gettingDealsSuccess = false
    state.dealsfinished = action.payload
  },

  //CLear Deals
  [clearDeals]: state => {
    state.deals = []
  },
})

export default dealsReducers
