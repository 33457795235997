import { useHistory } from 'react-router-dom'

import { productURL } from '../../../utils/urlChanger'

import { convertImgType } from '../../../helpers'
import { NewTabOpen } from '../../common'
import { ProgressiveImg } from '../../image'
import { ProductTypeTag } from './ProductTypeTag'

import { ADULT_CONTENT_THUMBNAIL } from '../../../constants'

const WishListCard = ({ data }) => {
  const history = useHistory()
  return (
    <div className='search-card'>
      <div className='image'>
        <ProductTypeTag data={data} />
        <ProgressiveImg
          previewURL={convertImgType(
            data?.isAdultContent ? ADULT_CONTENT_THUMBNAIL : data?.imageUrl,
            112,
            120,
            'webp',
            60
          )}
          largeImageURL={convertImgType(
            data?.isAdultContent ? ADULT_CONTENT_THUMBNAIL : data?.imageUrl,
            112,
            120
          )}
          alt={data?.name}
          className='tileImage'
          onClick={() => history.push(productURL(data))}
        />
      </div>
      <div className='title'>
        <NewTabOpen productData={data}>
          <h2 className='card-product-tile-info-productTitle'>{data.name}</h2>
        </NewTabOpen>
      </div>
    </div>
  )
}
export default WishListCard
