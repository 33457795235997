import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { GetTopSearchCategorySerice } from './getTopSearch-service'

import { useWindowDimensions } from '../../hooks'
import { urlChanger, uid } from '../../utils'
import { popToast } from '../common/PopToast'
import { ScrollingCarousel } from '../carousel'
import { ButtonLoader } from '../index'

import { SaveIcon, CategoryIcon } from '../../icons'

const TopCategories = ({ auth, className = '', toggleSidebar = () => {} }) => {
  const history = useHistory()
  const windowDimensions = useWindowDimensions()
  const { isLoading, data } = GetTopSearchCategorySerice()

  const redirectHandler = () => {
    if (auth?.isAuthenticated) {
      history.push({
        pathname: '/profile',
        state: { mylist: '/' }
      })
    } else {
      popToast()
    }
  }

  const categoryRedirect = (category) => {
    const url = `/category/${
      category?.searchText && urlChanger(category?.searchText)
    }/${category?.categoryId}`

    return history.push(url)
  }

  return (
    <>
      <div className={`topCategories ${className}`}>
        <div className='topCategories-container'>
          <div className='topCategories-header'>
            {windowDimensions.width > 992 ? (
              <h2>Top Categories</h2>
            ) : (
              <CategoryIcon onClick={toggleSidebar} />
            )}
          </div>
          <div className='topCategories-list'>
            {isLoading ? (
              <ButtonLoader numOfArray={4} />
            ) : (
              <>
                <ScrollingCarousel
                  className='scroll-slider'
                  rightIcon={<></>}
                  leftIcon={<></>}
                >
                  {data?.data?.map((item) => (
                    <button
                      onClick={() => categoryRedirect(item)}
                      className='button button-light'
                      key={item?.categoryId + uid}
                    >
                      {item?.searchText}
                    </button>
                  ))}
                </ScrollingCarousel>
              </>
            )}
          </div>
        </div>
        <div className='topCategories-save'>
          <h3 onClick={redirectHandler}>
            <SaveIcon />
            Saved
          </h3>
        </div>
      </div>
      <div className='divider divider-horizontal' />
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  unreadNotification: state.noti.unreadNot
})

export default connect(mapStateToProps)(TopCategories)
