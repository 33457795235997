/**
 * This function can be used to combine classnames.
 * It takes any number of arguments.
 * Arguments can be string or an object with the key as
 * the classname and the value as a flag whether to display the classname.
 * Example:
 * ```
 * combineClassNames('my-class', { 'my-conditional-1': true, 'my-conditional-2': false })
 * ```
 * gives the output:
 * ```
 * my-class my-conditional-1
 * ```
 * @param  {...(string | Record<string, unknown>)} args
 * @returns {string}
 */
export function combineClassNames(...args) {
  const enabledClassNames = args.reduce((enabledClassNames, classNameOrObj) => {
    if (!classNameOrObj) return enabledClassNames
    if (typeof classNameOrObj === 'string') {
      enabledClassNames.push(classNameOrObj)
    } else {
      const filteredClassNames = Object.entries(classNameOrObj)
        .filter(([, flag]) => !!flag)
        .map(([className]) => className)
      enabledClassNames.push(...filteredClassNames)
    }
    return enabledClassNames
  }, [])
  return enabledClassNames.join(' ')
}
