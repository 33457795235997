import { createAction, createReducer } from '@reduxjs/toolkit'

export const setTabHome = createAction('TAB_HOME/REQ')
export const setTabHomeSucess = createAction('TAB_HOME_SUCCESS/RES')
export const setRecaptchaToken = createAction('SET_RECAPTCHA_SUCESS/RES')

const initialState = {
  tab: 0,
  recaptchaToken: null,
}

const tabReducer = createReducer(initialState, {
  [setTabHomeSucess]: (state, action) => {
    state.tab = action.payload
  },
  [setRecaptchaToken]: (state, action) => {
    state.recaptchaToken = action.payload
  },
})

export default tabReducer
