import { useState } from 'react'

/**
 * It returns a view state and a function that toggles the view state between 'detail' and 'grid'
 * @param [initialValue=detail] - The initial value of the state.
 * @returns An array with two elements. The first element is the current view, and the second element
 * is a function that toggles the view.
 */
export const useToggleView = (initialValue = 'detail') => {
  const [view, setView] = useState(initialValue)

  const toggleView = () => {
    setView(view === 'detail' ? 'grid' : 'detail')
  }

  return [view, toggleView]
}
