import { productCondition } from '../../helpers'

const PreviewCard = ({ data }) => {
  return (
    <>
      <div className='card detailed--card'>
        <div className='card__left'>
          <div className='product-gallery'>
            <div className='main-pic'>
              {data?.productMedia?.length !== 0 && (
                <a href='#!' className='nopointer'>
                  <img
                    src={
                      data?.productMedia?.[0]?.locationKey ||
                      '/images/loading_anu9cy.png'
                    }
                    alt='loading...'
                    loading='lazy'
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/loading_anu9cy.png'
                    }}
                  />
                </a>
              )}
            </div>
            <div className='thumbs'>
              {data?.productMedia?.length > 0 &&
                data?.productMedia?.map((photo, index) => {
                  return (
                    <div className='sec-pic' key={index}>
                      <img src={photo?.locationKey} alt='product-img' />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className='card__right'>
          <div className='card__top'>
            <div className='card__title'>
              <h2>{data?.name || 'NaN'}</h2>
            </div>
          </div>
          <div className='card__mid'>
            <div className='prod-desc'>
              <p>{data?.description || 'NaN'}</p>
              <div className='price-tags'>
                <div className='price'>
                  <span className='currency'>रु </span>
                  <span className='prc'>
                    {parseFloat(data?.price)?.toLocaleString('en-IN', {
                      timeZone: 'UTC'
                    })}
                  </span>
                  <span className='lbl lbl-cancel'>
                    {productCondition(data?.condition)}
                  </span>
                </div>
              </div>
              <div className='address-time'>
                <span className='address'>
                  {data?.location?.locationDescription || 'NaN'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewCard
