export * from './api-url.constant'
export * from './rules'
export * from './ads-constant'
export * from './country-constant'

export const ADULT_CONTENT_THUMBNAIL =
  'https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/AdultContent.png?x-image-process=image/resize,m_lfit,h_300,w_300'
export const ADULT_CONTENT_THUMBNAIL_PLACEHOLDER =
  'https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/AdultContent.png?x-image-process=image/resize,m_lfit,h_140,w_160'

export const NO_IMAGE_THUMNAIL =
  'https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/NoImage.png?x-image-process=image/resize,m_lfit,h_240,w_240/format,webp'

export const APP_CRASH =
  'https://res.cloudinary.com/dem2xvk2e/image/upload/v1635853286/hamrobazar/Asset_13_vmkv4e.png'

export const DEVICE_ID = 'deviceId'

export const getDeviceSource = () => {
  var isMobile = false
  var UA = navigator.userAgent
  isMobile =
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
  var deviceSource = isMobile ? 'mobile' : 'web'
  return deviceSource
}

const PUBLIC_URL = process.env.PUBLIC_URL
export const LOGIN_SEARCH_GIF =
  'https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/Search.gif'
export const SIGNUP_SEARCH_GIF = LOGIN_SEARCH_GIF
// 'https://res.cloudinary.com/dem2xvk2e/image/upload/v1635751900/hamrobazar/ezgif-7-a8ae4e83b0f9_rwmvoy.gif'

export const LOGIN_BUTTON = PUBLIC_URL + 'images/download_from_playstore.png'

export const IS_HB_SELECT_ID = '00000000-0000-0000-0000-000000000000'
export const PAGE_SIZE = 27

export const STATIC_ERROR_MESSAGE = 'Error, An error occured, please try again!'
