export function combinedData(
	data,
	feedAds = [],
	showDynamicAds = false,
	nth_product = 5
) {
	const newData = data?.reduce(
		(productAdsWithFeedAds, productAd, productAdIndex) => {
			const shouldDisplayAd = (productAdIndex + 1) % nth_product === 0
			productAdsWithFeedAds.push({
				isProductAd: true,
				ad: productAd,
			})
			if (shouldDisplayAd) {
				const indexOfAdToDisplayFromFeed =
					(productAdIndex + 1) / nth_product - 1
				const adToDisplayFromFeed = feedAds?.[indexOfAdToDisplayFromFeed]
				if (adToDisplayFromFeed) {
					productAdsWithFeedAds.push({
						isFeedAd: true,

						ad: adToDisplayFromFeed,
					})
				} else if (showDynamicAds) {
					productAdsWithFeedAds.push({
						isDynamicAd: true,
						ad: productAd,
					})
				}
			}
			return productAdsWithFeedAds
		},
		[]
	)
	return newData
}
