import { QueryClient } from 'react-query'

/* Creating a new instance of the QueryClient class. */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
