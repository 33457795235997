import { useClickOutside } from '../../hooks'
import { ReactComponent as CloseIcon } from '../../icons/common/close.svg'
import Spinner from '../loaders/Spinner'

const PopupModal = ({
  isOpen,
  setIsOpen,
  header,
  confirmClick,
  title,
  confirm,
  cancel,
  dangerConfirm,
  dangerReject,
  cancelClick = () => {}
}) => {
  let domNode = useClickOutside(() => {
    setIsOpen(false)
  })
  return isOpen ? (
    <div className='modal--backdrop'>
      <div className='modal modal--common modal--sm' ref={domNode}>
        <div className='modal-wrapper'>
          <div className='modal--header'>
            <h2 className='modal--title'>{header}</h2>
            <button
              className='close '
              onClick={() => {
                setIsOpen(false)
              }}
              type='button'
            >
              <CloseIcon />
            </button>
          </div>
          <div className='modal--body'>
            <>
              <h2 className='title--normal'>{title}</h2>
              <div className='button--groups '>
                <button
                  className={`btn ${dangerConfirm ? 'danger' : ''}`}
                  onClick={confirmClick}
                  type='button'
                >
                  <Spinner />
                  {confirm}
                </button>
                <button
                  className={`btn ${dangerReject ? 'danger' : ''}`}
                  onClick={() => {
                    setIsOpen(false)
                    cancelClick()
                  }}
                  type='button'
                >
                  {cancel}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default PopupModal
