import { put, call, takeLatest, all } from 'redux-saga/effects'
import { getDealsApi } from '../../api/dealsApi'
import {
  gettingDeals,
  gettingDealsSuccess,
  gettingDealsError,
} from '../reducers/dealsReducers'

//Load deals for Homepage
function* getHomeDeals(action) {
  try {
    let res = yield call(getDealsApi, action.payload)
    yield put(gettingDealsSuccess(res))
  } catch (error) {
    yield all(console.log(error))
    yield put(gettingDealsError(error))
  }
}

//The auth Watchers
export default function* adsSaga() {
  yield all([takeLatest(gettingDeals, getHomeDeals)])
}
