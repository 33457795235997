export const Navigation = ({ items, current, onClick, factory }) => {
  return (
    <div className='carousel-navigation'>
      {items.map((_, i) => (
        <div onMouseOver={() => onClick(i)} key={i}>
          {factory(current === i)}
        </div>
      ))}
    </div>
  )
}
