import { checkExpiryTime } from '../utils'

export function productCondition(condition) {
  return condition === 1
    ? 'Brand New'
    : condition === 2
    ? 'Like New'
    : condition === 3
    ? 'Used'
    : condition === -1
    ? 'Not Working'
    : 'Not Working'
}

export function productStatus(data) {
  const status = Number(data?.status)
  return status === 5
    ? 'On Hold'
    : status === 6
    ? 'Sold'
    : checkExpiryTime(data?.expiryDate)
    ? 'Expired'
    : null
}
