// import * as packageInfo from '../package.json'

/**
 * Application wide configuration.
 */
const appConfig = {
  baseApiURI: process.env.REACT_APP_BASE_API_URI || 'https://api.hamrobazaar.com/',
  devMode: process.env.REACT_APP_ENV === 'development',
  isProdMode: process.env.REACT_APP_ENV === 'production',
  isTestMode: process.env.REACT_APP_ENV === 'test',
  baseURL: process.env.REACT_APP_BASE_URL || 'https://hamrobazaar.com',
  app: {
    name: 'Hamrobazar FE',
    version: '3.2.5',
    time: 'Wed 20 Sept 2023 12:00 PM',
    desc: 'Success ads added to add product and EMI calculator button added',
  },
};

export default appConfig;
