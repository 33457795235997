export const currency = (number = 0, currency = 'NPR') => {
  let formattedNumber
  switch (currency) {
    case 'AED':
      formattedNumber = new Intl.NumberFormat('en-AE', {
        currency: 'AED',
        style: 'currency'
      })
        .format(number)
        .replace('.00', '')
        .trim()

      break
    case 'NPR':
      formattedNumber =
        '\u0930\u0942. ' +
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'NPR'
        })
          .format(number)
          .replace('NPR', '')
          .replace('.00', '')
          .trim()

      break
    default:
      formattedNumber = number
      break
  }
  return formattedNumber
}
