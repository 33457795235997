import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

export const useRecaptcha = fetchRecaptcha => {
  const [recaptchaToken, setRecaptchaToken] = useState()

  const recaptcha = async () => {
    await new Promise((resolve, reject) => {
      let grecaptcha = window.grecaptcha
      if (grecaptcha) {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCH_KEY, {
              action: 'submit',
            })
            .then(function (token) {
              setRecaptchaToken(token)
              resolve(token)
            })
            .catch(err => reject(err))
        })
      }
    }).catch(err => {
      toast.warn('Unable to get recaptcha token!')
    })
  }

  useEffect(() => {
    recaptcha()
  }, [fetchRecaptcha])

  return recaptchaToken
}
