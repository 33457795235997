import { useState } from 'react'

import { useGAEventTracker, useClickOutside, useFetch } from '../../hooks'
import { combineClassNames } from '../../utils'

import {
  useNavActionToggle,
  CHAT_TOGGLE,
  NOTIFICATION_TOGGLE,
  PROFILE_TOGGLE
} from './useNavActionToggle'

import ChatDropDown from '../common/dropdown/ChatDropDown'
import NotificationDropDown from '../common/dropdown/NotificationDropDown'

import { Message, NotificatonIcon, AddIcon } from '../../icons'
import { NOTIFICATION } from '../../constants'

export const NavbarAction = ({ auth = {}, displayPostForm = {} }) => {
  const gaEvent = useGAEventTracker()
  const { dropdownToggle, toggleHandler } = useNavActionToggle()
  const [index, setIndex] = useState(0)

  const dropdownRef = useClickOutside(() => {
    if (dropdownToggle !== PROFILE_TOGGLE) toggleHandler('')
  })

  const notificationDataResponse = useFetch(
    'NOTIFICATIONS',
    NOTIFICATION,
    auth?.isAuthenticated,
    {},
    {},
    true
  )

  const numberOfUnreadNotifications =
    notificationDataResponse?.data?.data?.filter(elem => {
      return !elem.viewed
    })?.length

  return (
    <div
      className={`menu-items ${auth?.isAuthenticated ? 'add-dropdown' : null}`}
      ref={dropdownRef}
    >
      <div
        className={combineClassNames('menu-container dropdown', {
          'menu-container-auth': auth?.isAuthenticated
        })}
      >
        {auth?.isAuthenticated ? (
          <>
            <div
              className={combineClassNames(
                'menu-container-item menu-container-item-icon',
                {
                  'menu-container-item-icon-active':
                    dropdownToggle === CHAT_TOGGLE
                }
              )}
              onClick={e => {
                e.preventDefault()
                toggleHandler(CHAT_TOGGLE)
                setIndex(curr => curr + 1)
              }}
            >
              {dropdownToggle === CHAT_TOGGLE ? (
                <div
                  className={
                    'dropdown-container dropdown-container-chat' +
                    (dropdownToggle ? ' active ' : '')
                  }
                  style={dropdownToggle ? { height: '83vh' } : { height: '0' }}
                >
                  <ChatDropDown auth={auth} index={index} />
                </div>
              ) : null}
              <Message />
            </div>
            <div
              className={combineClassNames(
                'menu-container-item menu-container-item-icon',
                {
                  'menu-container-item-icon-active':
                    dropdownToggle === NOTIFICATION_TOGGLE
                }
              )}
              onClick={e => {
                e.preventDefault()
                toggleHandler(NOTIFICATION_TOGGLE)
              }}
            >
              <NotificatonIcon />
              {numberOfUnreadNotifications > 0 ? (
                <div class='badge-with-number'>
                  {parseInt(numberOfUnreadNotifications)}
                </div>
              ) : null}
              {dropdownToggle === NOTIFICATION_TOGGLE ? (
                <div
                  className={
                    'dropdown-container dropdown-container-notification' +
                    (dropdownToggle ? ' active ' : '')
                  }
                  style={dropdownToggle ? { height: '83vh' } : { height: '0' }}
                >
                  <NotificationDropDown />
                </div>
              ) : null}
            </div>
          </>
        ) : null}

        <div
          className='menu-container-item menu-container-item-addBtn'
          onClick={e => {
            e.preventDefault()
            gaEvent('Post Add', 'Top-Nav', 'post add clicked')
            displayPostForm()
            toggleHandler('')
          }}
        >
          <AddIcon />
          <span>Post for free</span>
        </div>
      </div>
    </div>
  )
}
