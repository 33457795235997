import { useEffect } from 'react'
import { useWindowDimensions } from './useWindowDimensions'

/**
 * "If the showFilter prop is true and the window width is less than 768, set the body overflow to
 * hidden. If the showFilter prop is true and the hidden prop is true, set the body overflow to hidden.
 * Otherwise, set the body overflow to unset."
 *
 * The useEffect hook is used to set the body overflow to hidden when the showFilter prop is true and
 * the window width is less than 768. The useEffect hook is also used to set the body overflow to
 * hidden when the showFilter prop is true and the hidden prop is true. The useEffect hook is also used
 * to set the body overflow to unset when the showFilter prop is false
 * @param showFilter - boolean
 * @param [hidden=false] - boolean - if true, the overflow will be hidden regardless of the showFilter
 * value
 * @returns Nothing
 */
export const useToggleOverflow = (showFilter, hidden = false) => {
  const windowDimensions = useWindowDimensions()
  useEffect(() => {
    if (showFilter && windowDimensions.width < 768)
      document.body.style.overflow = 'hidden'

    if (showFilter && hidden) document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter, windowDimensions.width])
  return
}
