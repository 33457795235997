import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = props => (
  <div className="res--loader">
    <ContentLoader
      speed={2}
      width={330}
      height={180}
      viewBox="0 0 330 180"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="10" y="4" rx="10" ry="10" width="62" height="26" />
      <rect x="345" y="26" rx="10" ry="10" width="126" height="95" />
      <rect x="20" y="260" rx="0" ry="0" width="195" height="17" />
      <rect x="22" y="362" rx="9" ry="9" width="116" height="23" />
      <rect x="86" y="3" rx="10" ry="10" width="62" height="26" />
      <rect x="163" y="4" rx="10" ry="10" width="62" height="26" />
      <rect x="239" y="4" rx="10" ry="10" width="62" height="26" />
      <rect x="10" y="47" rx="10" ry="10" width="62" height="26" />
      <rect x="86" y="46" rx="10" ry="10" width="62" height="26" />
      <rect x="163" y="47" rx="10" ry="10" width="62" height="26" />
      <rect x="239" y="47" rx="10" ry="10" width="62" height="26" />
    </ContentLoader>
  </div>
)

export default MyLoader
