import { createAction, createReducer } from '@reduxjs/toolkit'
import sorter from '../../utils/Sorter'
import { logout } from './authReducers'

export const getThreads = createAction('GET_THREAD/REQ')
export const getThreadsSuccess = createAction('GET_THREAD_SUCCESS/RES')
export const getThreadsError = createAction('GET_THREAD_ERROR/RES')

export const updateThreads = createAction('UPDATE_THREAD/REQ')
export const updateThreadsSuccess = createAction('UPDATE_THREAD_SUCCESS/RES')
export const updateThreadsError = createAction('UPDATE_THREAD_ERROR/RES')

const initialState = {
  threads: [],
  getThreads: false,
  getThreadsSuccess: false,
  getThreadsError: null,

  updateThread: false,
  updateThreadsSuccess: false,
  updateThreadsError: null,
}

const chatReducers = createReducer(initialState, {
  [getThreads]: state => {
    state.getThreads = true
  },
  [getThreadsSuccess]: (state, action) => {
    state.getThreads = false
    state.getThreadsSuccess = true
    state.threads = action.payload || []
  },
  [getThreadsError]: (state, action) => {
    state.getThreads = false
    state.getThreadsSuccess = false
    state.getThreadsError = action?.payload
  },

  [updateThreads]: state => {
    state.updateThread = true
  },
  [updateThreadsSuccess]: (state, action) => {
    /*
      Updating local State to hold
    */
    //Find index of specific object using findIndex method.
    let objIndex = state.threads.findIndex(
      obj => obj.threadId === action?.payload?.threadId
    )
    state.threads[objIndex].lastMessageTimeStamp =
      action?.payload?.lastMessageTimeStamp
    state.threads = state.threads.sort(sorter)
    state.updateThread = false
  },
  [updateThreadsError]: (state, action) => {
    state.updateThread = false
    state.updateThreadsError = action?.payload
  },
  [logout]: state => {
    state.threads = []
  },
})

export default chatReducers
