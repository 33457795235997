import { createContext, useEffect, useReducer } from 'react'
import 'leaflet/dist/leaflet.css'
import { getReverseGeocodingData } from './reverseGeocoding'
import { useClickOutside } from '../../hooks'

import { MapDivContainer } from './MapDivContainer'
import { MapMarker } from './MapMarker'
import { MapHead } from './MapHead'
import { MapFooter } from './MapFooter'
import { isEmpty } from 'lodash'
import Loader from '../skeletons/Loader'
import { usePoitionUser } from './hooks'

export const OpenStreetmapDispatcher = createContext(null)

const OpenStreetMap = ({
  location,
  setShowLocation,
  onConfirmLocation,
  setUserPinned
}) => {
  const [{ displayName, position, ...events }, updateEvents] = useReducer(
    (prev, next) => {
      return { ...prev, ...next }
    },
    {
      displayName: '',
      position: {
        lat: 0,
        lng: 0
      },
      recenter: false
    }
  )
  const initialPosition = usePoitionUser(location)

  useEffect(() => {
    updateEvents({
      position: initialPosition
    })
    const reverseGeocode = async () => {
      const getLocation = await getReverseGeocodingData(initialPosition)
      updateEvents({
        displayName: getLocation?.data?.display_name
      })
    }
    initialPosition?.lat && initialPosition?.lng && reverseGeocode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPosition])

  let domNode = useClickOutside(() => {
    setShowLocation(false)
  })

  const confirmLocation = (e) => {
    onConfirmLocation(position?.lat, position?.lng, displayName)
    setUserPinned(true)
    setShowLocation(false)
  }

  return (
    <OpenStreetmapDispatcher.Provider
      value={[{ displayName, position, ...events }, updateEvents]}
    >
      <div className='location--picker cropperWrapper' id='map-div'>
        <div className='cropperWrapper--inner' ref={domNode}>
          {!isEmpty(displayName) && !isEmpty(position) ? (
            <>
              <MapHead setShowLocation={setShowLocation} />
              <MapDivContainer scrollWheelZoom={true} height='350px'>
                <MapMarker />
              </MapDivContainer>
              <MapFooter
                confirmLocation={confirmLocation}
                setShowLocation={setShowLocation}
              />
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </OpenStreetmapDispatcher.Provider>
  )
}

export default OpenStreetMap
