import { useClickOutside } from '../../../hooks'
import { useGalleryImgSet } from './hooks'
import { convertImgType } from '../../../helpers'

import { Carousel } from '../../carousel'
import { Image } from '../../image'
import { CloseIcon, RightArrow } from '../../../icons'

const GalleryModel = ({ data, setPreviewImage }) => {
  const { isLoading, mainImage, swap } = useGalleryImgSet(data)

  const domNode = useClickOutside(() => {
    setPreviewImage(false)
  })

  return (
    <div className='model'>
      <div className='model-container'>
        <span className='model-closeIcon'>
          <CloseIcon
            className='closeIcon'
            onClick={() => setPreviewImage(false)}
          />
        </span>
        <div className='model-content' ref={domNode}>
          <div className='model-content-main'>
            {isLoading ? (
              <div className='loader'>
                <div className='lds-ripple'>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : mainImage && !isLoading ? (
              <div className='model-content-main-image'>
                <Carousel
                  className='scroll-slider'
                  rightArrow={<RightArrow className='right-arrow' />}
                  leftArrow={<RightArrow className='left-arrow' />}
                  hideArrows={false}
                  swiping={true}
                  slide={1}
                  show={1}
                >
                  {swap(data?.imageUrl, 'locationKey', data?.productMedia)?.map(
                    (image) => (
                      <Image
                        key={image?.id}
                        src={convertImgType(
                          {
                            imageUrl: image?.locationKey,
                            oldImageUrl: data?.oldImageUrl,
                            oldLocationKey: image?.oldLocationKey,
                            imgSize: '_large'
                          },
                          768,
                          1024,
                          'webp'
                        )}
                        alt={data?.name}
                        className='tileImage'
                      />
                    )
                  )}
                </Carousel>
              </div>
            ) : (
              <div className='no-image'>
                <img
                  style={{ width: '100%', height: '100%' }}
                  src='https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/NoImage.png?x-image-process=image/resize,m_lfit,h_768,w_1024/format,webp'
                  alt='no thumbnail'
                />
              </div>
            )}
          </div>
          {/* <div className='model-content-footer'>
            {swap(data?.imageUrl, 'locationKey', data?.productMedia)?.map(
              (pm, index) => (
                <div
                  className={`model-content-footer-images ${
                    pm?.locationKey === mainImage && 'selected'
                  }`}
                  onClick={() => {
                    changeMainImage(pm?.locationKey)
                    swapHandler(pm?.id)
                  }}
                  key={pm?.id}
                >
                  {data?.deal?.offer && (
                    <span className='label deal-label'>
                      <i className='fal fa-tag'></i> {data?.deal?.offer}% Off
                    </span>
                  )}

                  <Image
                    src={convertImgType({
                      imageUrl: pm?.locationKey,
                      oldImageUrl: data?.oldImageUrl,
                      oldLocationKey: pm?.oldLocationKey,
                      size: 'small'
                    })}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/loading_anu9cy.png'
                    }}
                    alt={data?.name || 'product'}
                  />
                </div>
              )
            )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default GalleryModel
