/**
 * It takes an object and a map of keys, and returns a new object with the keys mapped to the values in
 * the map
 * @param keysMap - an object that maps the keys of the object you want to transform to the keys you
 * want to use in the new object.
 * @param obj - the object you want to map
 */
export const keyValues = (keysMap, obj) => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  )
}
