import { useMutation } from 'react-query'
import { saveWishApi, removeWishApi } from '../api/cartwishlist'
import { popToast } from '../components/common/PopToast'

export const AddRemoveWishItem = (refetch = () => {}) => {
  const { mutate: addWishList, isLoading: isAddRemoveLoading } = useMutation(
    async ({ pId, status }) => {
      if (status === 'add') return await saveWishApi(pId)
      if (status === 'remove') return await removeWishApi(pId)
    },
    {
      onSuccess: (data) => {
        popToast(data?.data?.status?.message?.[0], 'top-right')
        refetch()
      },
      onError: (error) => {
        popToast(
          error?.response?.data?.errors?.ProductId?.[0] ||
            'Unable Save product. Please try again later',
          'top-right'
        )
      }
    }
  )
  const saveOrRemoveItem = (pId, status) => {
    addWishList({ pId, status })
  }
  return { saveOrRemoveItem, isAddRemoveLoading }
}
