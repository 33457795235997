import { Link } from 'react-router-dom'

import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import SquareAds from '../GoogleAds/SquareAds'
import { useGAEventTracker } from '../../../hooks'
import { validateURL } from '../../../utils'

const PatternFive = ({ AdsPattern }) => {
  const adSequenceOne = AdsPattern.filter((ad) => ad?.adSequence === 1)
  const adSequenceTwo = AdsPattern.filter((ad) => ad?.adSequence === 2)
  const newAdsPattern = [
    adSequenceOne?.length > 0 ? adSequenceOne?.[0] : [],
    adSequenceTwo?.length > 0 ? adSequenceTwo?.[0] : []
  ]
  const GAEvent = useGAEventTracker()

  return (
    <div
      className={
        window.innerWidth > 767 ? 'responseGTopBannerContainer' : undefined
      }
    >
      <div className='sideBanner longGad5ItemLeft longWithGadResponsiveLink'>
        {newAdsPattern[0]?.adContentDesktop && (
          <>
            {newAdsPattern[0]?.type === 'Adsense' ? (
              <SquareAds keyProp={`adsense-50`} />
            ) : newAdsPattern[0].linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    newAdsPattern?.[0]?.pageName,
                    newAdsPattern?.[0]?.slotName,
                    newAdsPattern?.[0]?.pageHtml
                  )
                }
                href={validateURL(
                  newAdsPattern[0]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
                data-sequence='ad-sequence-1'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newAdsPattern[0]?.adContentDesktop
                      : newAdsPattern[0]?.adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  newAdsPattern[0].linkType === 'profile' ? 'user' : 'product'
                }/${newAdsPattern[0].link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newAdsPattern[0]?.adContentDesktop
                      : newAdsPattern[0]?.adContentMobile
                  }
                  width={250}
                  height={250}
                />
              </Link>
            )}
          </>
        )}
      </div>
      <div
        className='midBanner longGad5ItemMid longWithGadResponsiveLink'
        style={{
          width: window.innerWidth > 767 ? 920 : 320,
          height: window.innerWidth > 767 ? 250 : 110
        }}
      >
        {newAdsPattern[1]?.adContentDesktop && (
          <>
            {newAdsPattern[1]?.type === 'Adsense' ? (
              <BannerAds keyProp='adsense-51' />
            ) : newAdsPattern[1].linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    newAdsPattern?.[1]?.pageName,
                    newAdsPattern?.[1]?.slotName,
                    newAdsPattern?.[1]?.pageHtml
                  )
                }
                href={validateURL(
                  newAdsPattern[1]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newAdsPattern[1]?.adContentDesktop
                      : newAdsPattern[1]?.adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  newAdsPattern[1].linkType === 'profile' ? 'user' : 'product'
                }/${newAdsPattern[1].link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newAdsPattern[1]?.adContentDesktop
                      : newAdsPattern[1].adContentMobile
                  }
                />
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PatternFive
