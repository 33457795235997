import { Link } from 'react-router-dom'

import { useGAEventTracker } from '../../../hooks'
import { validateURL } from '../../../utils'

import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import SquareAds from '../GoogleAds/SquareAds'

const PatternSix = ({ AdsPattern }) => {
  const adSequenceOne = AdsPattern.filter((ad) => ad?.adSequence === 1)

  const adSequenceTwo = AdsPattern.filter((ad) => ad?.adSequence === 2)

  const adSequenceThree = AdsPattern.filter((ad) => ad?.adSequence === 3)

  const newPatternArr = [
    adSequenceOne?.length > 0 ? adSequenceOne?.[0] : [],
    adSequenceTwo?.length > 0 ? adSequenceTwo?.[0] : [],
    adSequenceThree?.length > 0 ? adSequenceThree?.[0] : []
  ]
  const GAEvent = useGAEventTracker()

  return (
    <div
      className={
        window.innerWidth > 767 ? 'responseGTopBannerContainer' : undefined
      }
    >
      <div
        className='sideBanner longGad6ItemSmall longWithGadResponsiveLink'
        style={{
          height:
            !newPatternArr[0]?.type &&
            (!newPatternArr[1]?.type || !newPatternArr[2]?.type)
              ? '120px'
              : '250px'
        }}
      >
        {newPatternArr[0]?.adContentDesktop && (
          <>
            {newPatternArr?.[0]?.type === 'Adsense' ? (
              <SquareAds keyProp={`adsense-50`} />
            ) : newPatternArr?.[0]?.linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    newPatternArr?.[0]?.pageName,
                    newPatternArr?.[0]?.slotName,
                    newPatternArr?.[0]?.pageHtml
                  )
                }
                href={validateURL(
                  newPatternArr?.[0]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr?.[0]?.adContentDesktop
                      : newPatternArr?.[0]?.adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  newPatternArr?.[0]?.linkType === 'profile'
                    ? 'user'
                    : 'product'
                }/${newPatternArr?.[0]?.link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr?.[0]?.adContentDesktop
                      : newPatternArr?.[0]?.adContentMobile
                  }
                />
              </Link>
            )}
          </>
        )}
      </div>
      <div
        className='longGad6ItemLarge longWithGadResponsiveLink'
        style={{
          width: window.innerWidth > 767 ? 920 : 320,
          height:
            window.innerWidth > 767
              ? newPatternArr[1]?.type || newPatternArr[2]?.type
                ? 250
                : 120
              : 120
        }}
      >
        <div
          style={{
            display: 'flex',
            rowGap: '10px',
            flexDirection: 'column',
            height: window.innerWidth > 767 ? 250 : 120
          }}
        >
          <div
            style={{
              height: window.innerWidth > 767 ? 120 : 55
            }}
          >
            {newPatternArr[1]?.adContentDesktop && (
              <>
                {newPatternArr?.[1]?.type === 'Adsense' ? (
                  <BannerAds keyProp='adsense-41' />
                ) : newPatternArr?.[1]?.linkType === 'external' ? (
                  <a
                    onClick={() =>
                      GAEvent(
                        newPatternArr?.[1]?.pageName,
                        newPatternArr?.[1]?.slotName,
                        newPatternArr?.[1]?.pageHtml
                      )
                    }
                    href={validateURL(
                      newPatternArr?.[1]?.link || 'https://hamrobazaar.com/'
                    )}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <LazyLoadImage
                      src={
                        window.innerWidth > 767
                          ? newPatternArr?.[1]?.adContentDesktop
                          : newPatternArr?.[1]?.adContentMobile
                      }
                    />
                  </a>
                ) : (
                  <Link
                    to={`/${
                      newPatternArr?.[1]?.linkType === 'profile'
                        ? 'user'
                        : 'product'
                    }/${newPatternArr?.[1]?.link}`}
                  >
                    <LazyLoadImage
                      src={
                        window.innerWidth > 767
                          ? newPatternArr?.[1]?.adContentDesktop
                          : newPatternArr?.[1]?.adContentMobile
                      }
                    />
                  </Link>
                )}
              </>
            )}
          </div>
          {newPatternArr[2]?.type && (
            <div
              style={{
                height: window.innerWidth > 767 ? 120 : 55
              }}
            >
              {newPatternArr[2]?.adContentDesktop && (
                <>
                  {newPatternArr?.[2]?.type === 'Adsense' ? (
                    <BannerAds keyProp='adsense-41' />
                  ) : newPatternArr?.[2]?.linkType === 'external' ? (
                    <a
                      onClick={() =>
                        GAEvent(
                          newPatternArr?.[2]?.pageName,
                          newPatternArr?.[2]?.slotName,
                          newPatternArr?.[2]?.link
                        )
                      }
                      href={validateURL(
                        newPatternArr?.[2]?.link || 'https://hamrobazaar.com/'
                      )}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <LazyLoadImage
                        src={
                          window.innerWidth > 767
                            ? newPatternArr?.[2]?.adContentDesktop
                            : newPatternArr?.[2]?.adContentMobile
                        }
                      />
                    </a>
                  ) : (
                    <Link
                      to={`/${
                        newPatternArr?.[2]?.linkType === 'profile'
                          ? 'user'
                          : 'product'
                      }/${newPatternArr?.[2]?.link}`}
                    >
                      <LazyLoadImage
                        src={
                          window.innerWidth > 767
                            ? newPatternArr?.[2]?.adContentDesktop
                            : newPatternArr?.[2]?.adContentMobile
                        }
                      />
                    </Link>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PatternSix
