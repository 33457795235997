export const dynamicTitle = match => {
  const buyArr = [
    'travel-tours-packages',
    'pets-pet-care',
    'fresh-veggies-meat',
    'events-happenings',
    'business-industrial',
    'beauty-health',
  ]
  const findArr = ['services', 'pets-for-Adoption-free-stuff', 'jobs']
  const buyAndSellArr = ['real-estate']
  function isInArray(value, array) {
    return array.indexOf(value) > -1
  }
  const dynamicTitle = isInArray(match?.params?.name, buyArr)
    ? 'Buy'
    : isInArray(match?.params?.name, findArr)
    ? 'Find'
    : isInArray(match?.params?.name, buyAndSellArr)
    ? 'Buy and sell'
    : 'Buy second hand or brand new'
  return dynamicTitle
}
