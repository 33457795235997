import { createAction, createReducer } from '@reduxjs/toolkit'

export const addError = createAction('ADD_ERROR')
export const addErrorMessage = createAction('ADD_ERROR_MESSAGE')
export const removeErrorMessage = createAction('REMOVE_ERROR_MESSAGE')
export const removeError = createAction('REMOVE_ERROR')

const initialState = {
  errors: [],
  verify: true,
  data: '',
  otp: true,
  errorMessage: '',
}

const alertReducers = createReducer(initialState, {
  [addError]: (state, action) => {
    state.data = action.payload?.data?.data
    state.errors =
      action.payload?.status?.message?.[0] ||
      action.payload?.data?.status?.message ||
      action?.payload?.data?.message
    state.verify =
      //if redirect to verify page
      //|| action.payload?.status?.redirect?.toLowerCase()
      action.payload?.data?.status?.redirect === 'verify' ? false : true
    state.otp =
      action.payload?.data?.status?.redirect === 'OTPverify' ? false : true
  },
  [addErrorMessage]: (state, action) => {
    state.errorMessage = action.payload
  },
  [removeError]: state => {
    state.errors = []
    state.verify = true
    state.otp = true
  },
  [removeErrorMessage]: state => {
    state.errorMessage = ''
  },
})

export default alertReducers
