import { Link } from 'react-router-dom'

const NewTabOpenUser = ({ auth, createdById, className, children }) => {
  const isProfile = window.location.pathname.includes('/profile')
  
  return (
    <Link
      to={{ pathname: auth?.user?.userId === createdById ? '/profile' : '/user/' + createdById }}
      className={className}
      target={isProfile ? '_self' : '_blank'}
    >
      {children}
    </Link>
  )
}

export default NewTabOpenUser
