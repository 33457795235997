import { useEffect } from 'react'
import { isEmpty } from 'lodash'

/**
 * This function is used to add product attributes to the product attribute data
 * @param [textChange] - This is the object that contains the values of the form fields.
 * @param [data] - The data that you want to use to map the data.
 * @param [setProductAttributeData] - This is the function that will be called to set the product
 * attribute data.
 */
export const useAddProductAttribute = (
  textChange = () => {},
  data = {},
  setProductAttributeData = () => {}
) => {
  useEffect(() => {
    if (isEmpty(data?.data)) return
    const attributeData = data?.data
      ?.map((item) => {
        if (item?.fieldName in textChange) {
          return {
            attributeId: item?.id,
            value: textChange?.[item?.fieldName],
            required: item?.isRequired,
            name: item?.fieldName
          }
        }
      })
      ?.filter((item) => !isEmpty(item?.value))

    if (!isEmpty(attributeData)) return setProductAttributeData(attributeData)

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, textChange])
}
