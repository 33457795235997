import { useQuery } from 'react-query'
import { axiosInstance } from '../utils/axiosInstance'
/**
 * The function uses the useQuery hook for typeahead/autocomplete/suggestions from react-query.
 * @param UNIQUE_KEY - A unique key for the query.
 * @param URL - The URL to fetch from
 * @param QUERY - The query parameter to use in the URL.
 * @param PARAMS - The search term
 * @returns The data, isLoading, and error.
 */

export const useSearch = (UNIQUE_KEY, URL, QUERY, PARAMS) => {
  async function findWords({ queryKey: [_, term] }) {
    const response = await axiosInstance().get(`${URL}?${QUERY}=${term}`)
    return response.data
  }
  const { data, isLoading, error } = useQuery([UNIQUE_KEY, PARAMS], findWords, {
    enabled: PARAMS?.length > 2, // only fetch search terms longer than 2 characters
    staleTime: 10 * 1000, // refresh cache after 10 seconds (watch the network tab!)
  })
  return { data, isLoading, error }
}
