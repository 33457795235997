import React, { forwardRef, useContext } from 'react'
import { OpenStreetmapDispatcher } from './OpenStreetMap'

const SearchData = forwardRef(({ data, setShowSearchSuggestions }, ref) => {
  const [_, updateEvents] = useContext(OpenStreetmapDispatcher)
  const setLocationHandler = loc => {
    updateEvents({
      recenter: true,
      displayName: loc?.label,
      position: {
        lat: loc?.y,
        lng: loc?.x
      }
    })
    setShowSearchSuggestions(false)
  }
  return (
    <div className='search-location-results' ref={ref}>
      <ul>
        {data?.map((loc, index) => (
          <li key={index} onClick={() => setLocationHandler(loc)}>
            {' '}
            {loc?.label}
          </li>
        ))}
      </ul>
    </div>
  )
})

export default SearchData
