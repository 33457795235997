import {
  useProductAttribute,
  useResetAttributeField,
  useAddProductAttribute
} from './index'

import { ToggleInput } from '../common/InputField'
import { AttributeInputField } from './AttributeInputField'

export const ProductAttributeForm = ({
  categoryId,
  textChange,
  register,
  control,
  errors,
  resetField,
  setProductAttributeData,
  fieldData
}) => {
  const { productAttributes, deliveryDataAttributes, data, isLoading } =
    useProductAttribute(categoryId, fieldData)

  useAddProductAttribute(textChange, data, setProductAttributeData)

  useResetAttributeField(
    resetField,
    fieldData,
    productAttributes,
    deliveryDataAttributes
  )

  return (
    <>
      {isLoading ? null : (
        <>
          <div className='post-attribute'>
            {productAttributes?.map((attribute, i) => (
              <AttributeInputField
                attribute={attribute}
                errors={errors}
                register={register}
                control={control}
                key={i}
              />
            ))}
          </div>
        </>
      )}
      <div className='post-toggle'>
        <div className='post-toggle-title'>
          <span>Delivery</span>
        </div>
        <ToggleInput name='delivery' id='delivery' {...register('delivery')} />
      </div>
      {fieldData?.delivery ? (
        <div className='post-attribute'>
          {deliveryDataAttributes?.map((attributes, i) => (
            <AttributeInputField
              attribute={attributes}
              error={errors}
              register={register}
              control={control}
              key={i}
            />
          ))}
        </div>
      ) : null}
    </>
  )
}
