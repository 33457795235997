import { useEffect, useRef } from 'react'

/**
 * React custom hooks for closing the dropdown menu when clicking outside of it
 * @param {*} handler
 * @returns domNode
 */

export const useClickOutside = handler => {
  let domNode = useRef()
  useEffect(() => {
    let hideShowHandler = event => {
      if (!domNode?.current?.contains(event.target)) {
        handler()
      }
    }
    document.addEventListener('mousedown', hideShowHandler)

    return () => {
      document.removeEventListener('mousedown', hideShowHandler)
    }
  })
  return domNode
}
