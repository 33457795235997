import { useCallback } from 'react'

import {
  SelectInput,
  BetweenInput,
  Input,
  Multiselect
} from '../common/InputField'
export const MapAdvanceFilter = ({
  filter,
  register,
  watchFields,
  control
}) => {
  const filedType = (filedType) => {
    if (filedType === '1') return 'number'
    if (filedType === '2') return 'text'
  }

  const renderAdvanceFilter = useCallback(() => {
    return (
      <>
        {filter?.filterParamType === '1' && (
          <Input
            type={filedType(filter?.fieldType)}
            displayLabel={false}
            label={<label htmlFor=''>{filter?.displayName}</label>}
            placeholder={filter?.displayName}
            name={filter?.fieldName}
            {...register(filter?.fieldName)}
          />
        )}
        {filter?.filterParamType === '2' && (
          <BetweenInput
            register={register}
            fromField={`${filter?.fieldName}From`}
            toField={`${filter?.fieldName}To`}
            label={<label htmlFor=''>{filter?.displayName}</label>}
            inputFieldElement={[
              {
                type: filedType(filter?.fieldType),
                name: `${filter?.fieldName}From`,
                placeholder: 'From',
                validation: { required: false }
              },
              {
                type: filedType(filter?.fieldType),
                name: `${filter?.fieldName}To`,
                placeholder: 'To',
                validation: {
                  required:
                    watchFields?.[`${filter?.fieldName}From`]?.length > 0
                      ? true
                      : false
                }
              }
            ]}
          />
        )}
        {filter?.filterParamType === '3' && (
          <SelectInput
            placeholder={filter?.displayName}
            name={filter?.fieldName}
            defaultValue=''
            displayLabel={false}
            label={<label htmlFor=''>{filter?.displayName}</label>}
            {...register(`${filter?.fieldName}`)}
            options={[
              { value: '', label: filter?.displayName, id: '00' },
              ...filter?.choices?.map((item) => ({
                value: item.choiceText,
                label: item.choiceText,
                id: item?.id
              }))
            ]}
          />
        )}
        {filter?.filterParamType === '4' && (
          <Multiselect
            paramtype={filter?.filterParamType}
            name={filter?.fieldName}
            label={filter?.displayName}
            control={control}
            values={filter?.choices}
          />
        )}
      </>
    )
  }, [])
  return renderAdvanceFilter()
}
