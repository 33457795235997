/**
 *
 * @param {*} value
 * @param {*} index
 * @param {*} self
 * @returns Returns unique
 */

const distinct = array => {
  const result = []
  const map = new Map()
  for (const item of array) {
    if (!map.has(item.id)) {
      map.set(item.id, true) // set any value to Map
      result.push(item)
    }
  }
  return result
}

export default distinct
