import BoostLink from '../../common/link/BoostLink'
import HbSelectLink from '../../common/link/HBSelectLink'

export const ProductTypeTag = ({ data }) => {
  return (
    <>
      {data?.productType === 1 ? (
        <BoostLink classname='card-product-tile-img-boost' />
      ) : data?.isHBSelect ? (
        <HbSelectLink data={data} classname='card-product-tile-img-hbSelect' />
      ) : null}
    </>
  )
}
