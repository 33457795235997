import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { randomArray } from '../../utils'
import { ContentLoader } from './ContentLoader'

export const ButtonLoader = (props) => {
  const { numOfArray = 1, ...rest } = props
  return (
    <>
      {randomArray(numOfArray).map((item) => (
        <div className='res-loader res-loader-tag' key={uuidv4()}>
          <ContentLoader
            speed={2}
            width={92}
            height={31}
            viewBox='0 0 92 31'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            {...rest}
          >
            <rect x='11' y='5' rx='0' ry='0' width='313' height='304' />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}
