import { useMutation } from 'react-query'
import { adsHoldApi } from '../../../api/ads'
import { popToast } from '../PopToast'

export function HoldUnHoldHandler(refetch, setShowMenu) {
  const { mutate: holdUnHoldMutate } = useMutation(
    async (data) => {
      return await adsHoldApi(data)
    },
    {
      onSuccess: ({ data }) => {
        popToast(data?.status?.message?.[0], 'bottom-right')
        refetch()
        setShowMenu(false)
      }
    }
  )

  const HoldUnHoldHandler = (productData, onHold) => {
    holdUnHoldMutate({ productId: productData?.id, onHold })
  }
  return HoldUnHoldHandler
}
