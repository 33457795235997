import { useHistory } from 'react-router-dom'

import { convertImgType } from '../../helpers'
import { useClickOutside, useWindowDimensions } from '../../hooks'

import { useNavActionToggle, PROFILE_TOGGLE } from './useNavActionToggle'

import { ProgressiveImg } from '../image'
import { NavbarAction } from './NavbarAction'
import { ProfileDropdown } from '../common/dropdown/ProfileDropdown'

export const NavMenu = ({
  auth = {},
  displayPostForm = {},
  unreadNotification = 0
}) => {
  const history = useHistory()
  const dimensions = useWindowDimensions()
  const { dropdownToggle, toggleHandler } = useNavActionToggle()

  const profileRef = useClickOutside(() => {
    if (dropdownToggle === PROFILE_TOGGLE) toggleHandler('')
  })

  return (
    <div className='menu'>
      {dimensions?.width > 576 ? (
        <NavbarAction
          auth={auth}
          displayPostForm={displayPostForm}
          unreadNotification={unreadNotification}
        />
      ) : null}

      <div className='menu-divider' />
      {auth?.isAuthenticated ? (
        <div
          className='menu-profile'
          onClick={() => toggleHandler(PROFILE_TOGGLE)}
          ref={profileRef}
        >
          <div className='menu-profile-avatar'>
            <ProgressiveImg
              previewURL={convertImgType(
                auth?.profile?.profileImgLocation ||
                  "/images/loading_anu9cy.png'",
                32,
                32,
                'webp',
                20
              )}
              largeImageURL={convertImgType(
                auth?.profile?.profileImgLocation ||
                  "/images/loading_anu9cy.png'",
                32,
                32,
                'webp'
              )}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/images/loading_anu9cy.png'
              }}
              alt=''
            />
          </div>
          <span className='menu-profile-username'>
            {auth?.profile?.fullName?.split(' ')[0] ||
              auth?.user?.fullName?.split(' ')[0] ||
              auth?.user?.phoneNumber}
          </span>
          {auth?.isAuthenticated && dropdownToggle === PROFILE_TOGGLE ? (
            <ProfileDropdown auth={auth} classname='profileDropdown' />
          ) : null}
        </div>
      ) : dimensions.width < 768 && dimensions.width > 580 ? (
        <div className='menu-actions'>
          <button
            className='menu-actions-singup'
            onClick={() => history.push('/login')}
          >
            Login
          </button>
        </div>
      ) : (
        <div className='menu-actions'>
          <button
            className='menu-actions-signin'
            onClick={() => history.push('/login')}
          >
            Login
          </button>
          <button
            className='menu-actions-singup'
            onClick={() => history.push('/signup')}
          >
            Sign Up
          </button>
        </div>
      )}
    </div>
  )
}
