import { useState } from 'react'

export const useFocusToggle = () => {
  const [show, setShow] = useState(false)

  const toggleHandler = (event) => {
    if (event?.type === 'focus') return setShow(true)
    return setShow(false)
  }

  return { show, toggleHandler }
}
