const API = '/api';
export const CATEGORY = API + '/Category';
export const LOCATION = API + '/Location';
export const TOP_HB_SELECT = API + '/Product/TopHBSelect';
export const APP_DATA_URL = API + '/AppData';
export const FILTERABLE_ATTRIBUTES =
  API + '/Product/GetFilterableAttributes?categoryId=';
export const USER_PRODUCT = API + '/User/GetUserProducts';
export const REVIEWERS = API + '/User/Reviewers?UserId=';
export const REVIEW_TYPE = API + '/AppData/GetReviewTypes';
export const COMMENTS = API + '/Comments';
export const NOTIFICATION = API + '/Notification';
export const PRODUCT_URL = API + '/Product';
export const USER_URL = API + '/User';
export const BLOCK_LIST_URL = USER_URL + '/GetBlockedUsers';
export const ALL_CATEGORIES_URL = API + '/AppData/GetAllCategory';
export const AUTOCOMPLETE_URL = API + '/Product/Autocomplete';
export const CATEGORY_ATTRIBUTE = CATEGORY + '/GetCategoryAttribute';
export const USER_TOP_SEARCH = API + '/Search/UserData';
export const FEATURED_PRODUCT = API + '/Product/Featured';
export const RECOMMEDED_PRODUCT = API + '/Product/Recommended';
export const USER_WISHLIST = USER_URL + '/GetWishlistByUser';
export const GET_SUCCESS_ADS_URL = API + '/FeatureAds/SuccessAds';
