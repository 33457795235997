import React from 'react'
import ContentLoader from 'react-content-loader'
import { v4 as uuidv4 } from 'uuid'

import { randomArray } from '../../utils'

const CardLoader = (props) => {
  const { numOfArray = 4, ...rest } = props
  return (
    <>
      {randomArray(numOfArray).map((item) => (
        <div className='res--loader' key={uuidv4()}>
          <ContentLoader
            speed={2}
            height={203}
            viewBox='0 0 506 203'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            {...rest}
          >
            <rect x='186' y='76' rx='2' ry='2' width='273' height='10' />
            <rect x='186' y='93' rx='2' ry='2' width='140' height='10' />
            <rect x='25' y='17' rx='0' ry='0' width='124' height='119' />
            <rect x='41' y='152' rx='0' ry='0' width='23' height='23' />
            <rect x='76' y='152' rx='0' ry='0' width='23' height='23' />
            <rect x='111' y='152' rx='0' ry='0' width='23' height='23' />
            <rect x='186' y='19' rx='0' ry='0' width='315' height='15' />
            <rect x='187' y='124' rx='0' ry='0' width='106' height='15' />
            <rect x='186' y='160' rx='0' ry='0' width='106' height='14' />
            <rect x='390' y='159' rx='0' ry='0' width='106' height='14' />
            <rect x='187' y='57' rx='2' ry='2' width='207' height='11' />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}

export default CardLoader
