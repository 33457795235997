import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { posttUserDpApi } from '../../api/auth'

import {
  getAdsOfUser,
  getAdsOfUserError,
  getAdsOfUserSuccess,
  getTheme,
  getThemeError,
  getThemeSuccess,
  gettingUserInfo,
  gettingUserInfoError,
  gettingUserInfoSuccess,
  setTheme,
  setThemeError,
  setThemeSuccess,
} from '../reducers/userReducers'
import {
  postingDP,
  postingDPError,
  postingDPSuccess,
  getProfileSuccess,
  getProfileFailed,
} from '../reducers/authReducers'
import { getUserApi } from '../../api/user'
import { getOUserAdApi } from '../../api/ads'
import { ApiError } from '../../utils/error'

/*
    Get the user Info
*/
function* getTheUser(action) {
  try {
    const res = yield call(getUserApi, action.payload)
    yield put(gettingUserInfoSuccess(res))
  } catch (error) {
    yield put(gettingUserInfoError(error))
  }
}

/*
    Uplading DP
*/
function* postTheDp(action) {
  try {
    const res = yield call(posttUserDpApi, action.payload.file)
    yield put(postingDPSuccess(res.data.data))
    const res2 = yield call(getUserApi, action.payload.uid)
    yield put(getProfileSuccess(res2))
  } catch (error) {
    ApiError(error)
    yield put(postingDPError(error))
    yield put(getProfileFailed(error))
  }
}

/**
 *
 * The fetch of user ads
 */
function* getTheAdOfUser(action) {
  try {
    const res = yield call(getOUserAdApi, action.payload)
    yield put(getAdsOfUserSuccess(res))
  } catch (error) {
    console.log(error.response)
    yield put(getAdsOfUserError(error))
  }
}

/**
 * Theme
 */
function* getTheTheme(action) {
  try {
    yield put(getThemeSuccess(localStorage.getItem('theme')))
  } catch (error) {
    yield put(getThemeError(error))
  }
}

/**
 * Set them
 */
function* setTheTheme(action) {
  try {
    yield put(setThemeSuccess(action.payload))
  } catch (error) {
    yield put(setThemeError(error))
  }
}

export default function* userSagas() {
  yield all([
    takeLatest(gettingUserInfo, getTheUser),
    takeLatest(postingDP, postTheDp),
    takeEvery(getAdsOfUser, getTheAdOfUser),
    takeLatest(getTheme, getTheTheme),
    takeLatest(setTheme, setTheTheme),
  ])
}
