import { BackIcon } from '../../icons'

export const NavigationBtn = ({
  formStep = 1,
  setFormStep = () => {},
  isValid = false,
  isLoading = false,
  methods
}) => {
  const { trigger } = methods

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) setFormStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    if (formStep > 1) setFormStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div className='post-footer post-footer-backNext wrapper'>
      {formStep > 1 && (
        <span onClick={handleBack} className='link'>
          <BackIcon />
          <span className='card__title'>Back</span>
        </span>
      )}

      <button
        disabled={isLoading}
        className={`btn btn--form ${isLoading ? 'disabled' : ''}`}
        onClick={handleNext}
        title={
          !isValid
            ? 'Fill all required form before proceed'
            : 'Proceed to next step'
        }
      >
        Next
      </button>
    </div>
  )
}
