import { MenuHandler } from './MenuHandler'
import { productURL } from '../../../utils/urlChanger'

import MODAL_NAME from './modalName.json'

import {
  EyeClosed,
  HoldIcon,
  UnHoldIcon,
  EditIcon,
  FlagIcon
} from '../../../icons'

export const MenuItem = (params) => {
  const { isProfile, data, refetch, setShowMenu, setShowModal, dynamicLinks } =
    params
  const url = window.location.origin + productURL(data)

  const { HoldUnHold } = MenuHandler(refetch, setShowMenu)

  const status = data?.status

  const modalHandler = (modalName) => {
    setShowModal(modalName)
    setShowMenu(false)
  }
  const shareHandler = (e) => {
    modalHandler(MODAL_NAME?.share)
    dynamicLinks(url)
  }

  const menuData = [
    {
      label: 'Mark as Sold',
      icon: <EyeClosed />,
      onclick: () => modalHandler(MODAL_NAME?.sold),
      show: isProfile && data?.status !== 6 ? true : false
    },
    {
      label: status === 5 ? 'Resume Ad' : 'Hold Ad',
      icon: status === 5 ? <UnHoldIcon /> : <HoldIcon />,
      onclick: () => HoldUnHold(data, status === 5 ? false : true),
      show: isProfile && status !== 6 ? true : false
    },

    {
      label: 'Delete Ad',
      icon: <i className='fal fa-trash-alt'></i>,
      className: 'danger',
      onclick: () => modalHandler(MODAL_NAME?.delete),
      show: isProfile ? true : false
    },

    {
      label: 'Edit Ad',
      icon: <EditIcon />,
      onclick: () => modalHandler(MODAL_NAME?.edit),
      show: isProfile && data?.status !== 6 ? true : false
    },
    {
      label: 'Share',
      icon: <i className='fal fa-share-alt'></i>,
      onclick: shareHandler,
      show: data?.status === 5 || data?.status === 6 ? false : true
    },
    {
      label: 'Report',
      icon: <FlagIcon />,
      onclick: () => modalHandler(MODAL_NAME?.report),
      show: !isProfile
    }
  ]
  return { menuData }
}
