import { Link } from 'react-router-dom'
import { productURL } from '../../utils/urlChanger'

const NewTabOpen = ({ productData, className, children }) => {
  const isProfile = window.location.pathname.includes('/profile')
  return (
    <Link
      to={{ pathname: productURL(productData) }}
      className={className}
      target={isProfile ? '_self' : '_blank'}
    >
      {children}
    </Link>
  )
}

export default NewTabOpen
