import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import {
  getCatAdApi,
  getHomeListAdsApi,
  getCategoryListAdsApi,
  getProfilePageAdsApi,
  getSearchListAdsApi,
} from '../../api/ads'
import {
  getadvertisement,
  getadvertisementFail,
  getadvertisementSuccess,
  getHomeAds,
  getHomeAdsSuccess,
  getHomeAdsFail,
  getCategoryAds,
  getCategoryAdsSuccess,
  getCategoryAdsFail,
  getSearchAds,
  getSearchAdsSuccess,
  getSearchAdsFail,
  getProfileAds,
  getProfileAdsSuccess,
  getProfileAdsFail,
} from '../reducers/advertisementReducers'

/**
 * Get category ads
 */
function* getTheadvertisement(action) {
  try {
    const res = yield call(getCatAdApi, action.payload)
    yield put(getadvertisementSuccess(res))
  } catch (error) {
    yield put(getadvertisementFail(error))
  }
}

function* getTheHomeAds() {
  try {
    const res = yield call(getHomeListAdsApi)
    yield put(getHomeAdsSuccess(res.data))
  } catch (error) {
    yield put(getHomeAdsFail(error))
  }
}

function* getTheCategoryAds(action) {
  try {
    const res = yield call(getCategoryListAdsApi, action.payload)
    yield put(getCategoryAdsSuccess(res.data))
  } catch (error) {
    yield put(getCategoryAdsFail(error))
  }
}

function* getTheSearchAds(action) {
  try {
    const res = yield call(getSearchListAdsApi, action.payload)
    yield put(getSearchAdsSuccess(res.data))
  } catch (error) {
    yield put(getSearchAdsFail(error))
  }
}

function* getTheProfileAds(action) {
  try {
    const res = yield call(getProfilePageAdsApi, action.payload)
    yield put(getProfileAdsSuccess(res.data))
  } catch (error) {
    yield put(getProfileAdsFail(error))
  }
}

export default function* advertisementSagas() {
  yield all([
    takeLatest(getadvertisement, getTheadvertisement),
    takeLatest(getHomeAds, getTheHomeAds),
    takeLatest(getCategoryAds, getTheCategoryAds),
    takeLatest(getProfileAds, getTheProfileAds),
    takeLatest(getSearchAds, getTheSearchAds),
  ])
}
