import { useState, forwardRef } from 'react'
import ErrorMessage from './ErrorMessage'

export const PriceInput = forwardRef((props, ref) => {
  let {
    errors,
    type,
    name,
    passtype,
    placeholder,
    limits,
    defaultValue,
    info,
    displayLabel = true,
    ...rest
  } = props
  const [showLabel, setShowLabel] = useState(false)

  const capName = name.charAt(0).toUpperCase() + name.slice(1)
  const newPlaceholder = capName.replace(/([A-Z])/g, ' $1').trim()

  return (
    <div
      className={`form-group ' ${
        showLabel || defaultValue > 0 ? 'show--label lb-auth' : ''
      }`}
      onFocus={() => setShowLabel(true)}
      onBlur={() => setShowLabel(false)}
    >
      {displayLabel && <label className="the--label">{newPlaceholder}</label>}
      <div className="price-category input--wrapper">
        <div className={'price input ' + (defaultValue ? '' : ' warning')}>
          <span>रु</span>
          <input
            type="number"
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder ?? newPlaceholder}
            ref={ref}
            {...rest}
          />
        </div>
      </div>
      {errors && <ErrorMessage message={errors} />}

      {info && <small>For example: Brand, model, color, and size.</small>}
      {limits && (
        <div className="limits">
          <span>{props?.textchange?.length ?? defaultValue?.length}</span>/
          {limits}
        </div>
      )}
    </div>
  )
})
