import LazyLoad from 'react-lazyload'

const LazyLoadImage = (props) => {
  const { alt, ...rest } = props
  return (
    <LazyLoad>
      <img
        alt={alt ?? 'get-all-data'}
        loading='lazy'
        {...rest}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = '/images/loading_anu9cy.png'
        }}
      />
    </LazyLoad>
  )
}

export default LazyLoadImage
