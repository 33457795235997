import { useEffect } from 'react'
import { useState } from 'react'
import { NP_COUNTRY_CODE, UAE_COUNTRY_CODE } from '../../../constants'
import { decryptObjData } from '../../../utils'

export const usePoitionUser = (location) => {
  const [position, setPosition] = useState({
    lat: 27.691129949999997,
    lng: 85.31850360341551
  })

  useEffect(() => {
    let userData = decryptObjData(localStorage.getItem('user_auth'))
    if (location?.coordinates) {
      setPosition({
        lat: location?.coordinates?.lat,
        lng: location?.coordinates?.lng
      })
    } else {
      if (userData?.value?.contryCode) {
        if (userData?.value?.contryCode === NP_COUNTRY_CODE)
          setPosition({
            lat: 27.691129949999997,
            lng: 85.31850360341551
          })
        if (userData?.value?.contryCode === UAE_COUNTRY_CODE)
          setPosition({
            lat: 24.0002488,
            lng: 53.9994829
          })
      }
    }
  }, [location])
  return position
}
