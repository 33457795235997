import { all, takeLatest, put, call } from '@redux-saga/core/effects'
import {
  searchApi,
  searchTagApi,
  removeTagApi,
  getProductsByCategoryId,
} from '../../api/search'

import { toast } from 'react-toastify'

import {
  loadMoreSearch,
  loadMoreSearchError,
  loadMoreSearchSuccess,
  search,
  searchError,
  searchSuccess,
  categorySearchSucess,
  getSearchTag,
  searchTagSuccess,
  searchTagError,
  removeTag,
  removeTagSuccess,
  removeTagError,
  loadMoreCategorySearchSucess,
} from '../reducers/searchReducers'
import { addErrorMessage } from '../reducers/alertReducers'

function* searchWord(action) {
  try {
    let res
    if (action.payload.category) {
      res = yield call(getProductsByCategoryId, action.payload.category)
      yield put(categorySearchSucess(res))
    } else {
      res = yield call(searchApi, action.payload.data)
      yield put(searchSuccess(res))
    }
  } catch (error) {
    if (
      error?.response?.data?.status?.message[0] ===
      'Error: Too many request from this IP. Please try after sometime.'
    ) {
      yield put(addErrorMessage(error?.response?.data?.status?.message[0]))
    }
    yield put(searchError(error))
  }
}

function* loadMSearch(action) {
  try {
    let res
    if (action.payload.category) {
      res = yield call(getProductsByCategoryId, action.payload.category)
      yield put(loadMoreCategorySearchSucess(res))
    } else {
      res = yield call(searchApi, action.payload.data)
      yield put(loadMoreSearchSuccess(res))
    }
  } catch (error) {
    if (
      error?.response?.data?.status?.message[0] ===
      'Error: Too many request from this IP. Please try after sometime.'
    ) {
      yield put(addErrorMessage(error?.response?.data?.status?.message[0]))
    }
    yield put(loadMoreSearchError(error))
  }
}

function* searchTag(action) {
  try {
    let res = yield call(searchTagApi, action.payload)
    yield put(searchTagSuccess(res))
  } catch (error) {
    yield put(searchTagError(error))
  }
}

function* removeTheTag(action) {
  try {
    yield call(
      removeTagApi,
      action.payload.searchKey,
      action.payload.searchText,
      action.payload.userId
    )
    yield put(removeTagSuccess(action.payload.searchText))
  } catch (error) {
    if (error.response.status === 400) {
      toast.error('Search Text Is Already Logged as Deleted For The Device', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-background',
      })
    }
    if (error.response.status === 200) {
      yield put(removeTagSuccess(action.payload.searchText))
    }
    yield put(removeTagError(error))
  }
}

export default function* searchSagas() {
  yield all([
    takeLatest(search, searchWord),
    takeLatest(loadMoreSearch, loadMSearch),
    takeLatest(getSearchTag, searchTag),
    takeLatest(removeTag, removeTheTag),
  ])
}
