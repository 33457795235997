import React from 'react'

import { randomArray, uid } from '../../../utils'
import { ContentLoader } from '../ContentLoader'

export const TileSkeleton = (props) => {
  const { numOfArray = 1, ...rest } = props
  return (
    <>
      {randomArray(numOfArray).map((item) => (
        <div className='res-loader res-loader-tag' key={uid()}>
          <ContentLoader
            speed={2}
            width={120}
            height={200}
            viewBox='0 0 181 285'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            {...rest}
          >
            <rect x='0' y='0' rx='0' ry='0' width='100%' height='140' />
            <rect x='0' y='150' rx='0' ry='0' width='100%' height='32' />
            <rect x='0' y='190' rx='0' ry='0' width='100%' height='16' />
            <rect x='0' y='220' rx='0' ry='0' width='140' height='16' />
            <rect x='0' y='250' rx='0' ry='0' width='64' height='20' />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}
