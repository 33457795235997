import { useState, forwardRef } from 'react'
import { ReactComponent as DownIcon } from '../../../icons/common/down.svg'

export const SelectInput = forwardRef((props, ref) => {
  const {
    options,
    defaultOption,
    initialValue = '',
    watchText,
    className,
    displayLabel = true,
    label,
    ...rest
  } = props
  const [showLabel, setShowLabel] = useState(false)

  return (
    <div
      className={`form-item pos-rel ${
        showLabel || props?.defaultValue ? 'show--label' : ''
      }${className ?? ''}`}
      onFocus={() => setShowLabel(true)}
      onBlur={() => setShowLabel(false)}
    >
      {label && label}
      <div className='select--wrapper'>
        <select className='input' {...rest} ref={ref}>
          {defaultOption && (
            <option value={initialValue} defaultValue disabled>
              {defaultOption}
            </option>
          )}
          {options?.map((opt) => (
            <option value={opt?.value} key={opt?.value}>
              {opt?.label}
            </option>
          ))}
        </select>
        {displayLabel && <label className='the--label'>{props?.name}</label>}
        <DownIcon className='ab__svg' />
      </div>
    </div>
  )
})
