import { Link } from 'react-router-dom'
import { LOGOICON } from '../../../icons'

const HbSelectLink = ({ data, classname = '' }) => {
  const HBSelectTab = () => {
    return (
      <div className={`card__hbSelect ${classname}`}>
        <LOGOICON />
        <p>SELECT</p>
      </div>
    )
  }
  return (
    <>
      {data?.categoryName === 'Cars' ? (
        <Link to='/hb-select' target='_blank'>
          <HBSelectTab />
        </Link>
      ) : (
        <HBSelectTab />
      )}
    </>
  )
}
export default HbSelectLink
