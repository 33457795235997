import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getNotificationApi, readAllNotiApi } from '../../api/notification'
import {
  allnotiRead,
  allnotiReadError,
  allnotiReadSuccess,
  getNotification,
  getNotificationFail,
  getNotificationSuccess,
} from '../reducers/notificationReducers'

/*
    Geting Noti
*/
function* getTheNoti() {
  try {
    const res = yield call(getNotificationApi)
    yield put(getNotificationSuccess(res))
  } catch (error) {
    yield put(getNotificationFail(error))
  }
}

/**
 * All notification read
 */
function* allThenotiRead() {
  try {
    const res = yield call(readAllNotiApi)
    yield put(allnotiReadSuccess(res))
  } catch (error) {
    yield put(allnotiReadError(error))
  }
}

export default function* notificationSagas() {
  yield all([
    takeLatest(getNotification, getTheNoti),
    takeLatest(allnotiRead, allThenotiRead),
  ])
}
