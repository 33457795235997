import React from 'react'
import { Link } from 'react-router-dom'
import FOOTER_DATA from '../../data/footer.json'

const Footer = ({ className, toggleSidebar = () => {} }) => {
  return (
    <>
      <div className={'left-footer ' + (className ? className : ' ')}>
        {FOOTER_DATA?.map((item, index) => (
          <Link
            to={item?.url}
            className='normal--link text-muted'
            key={index}
            onClick={() => {
              if (item?.onClick) window.location.href = item?.onClick
              toggleSidebar()
            }}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </>
  )
}

export default Footer
