import { useState, useEffect } from 'react'

import { useDebounce } from './useDebounce'
import { UpdateState } from '../helpers'

export const useSetSearchValue = (setParams = () => {}, paramName = '') => {
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 300)

  const searchHandler = (e) => {
    e.preventDefault()
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    UpdateState(setParams, paramName, debouncedSearchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue])

  return { searchHandler, debouncedSearchValue, searchValue, setSearchValue }
}
