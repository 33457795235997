import { useFormContext } from 'react-hook-form'

import { useTextChange } from '../../hooks'
import { removeOnKeyPress } from '../../utils'

import { SingleSelect, Input } from '../common/InputField'
import { NavigationBtn } from './NavigationBtn'

import { RULES } from '../pages/Auth/rules'
import { NEGOTIABLE_OPTS, EXPIRY_DATE_OPTS } from './options'

export const StepThree = (props) => {
  const {
    register,
    watch,
    control,
    formState: { errors, isValid }
  } = useFormContext()

  const textChange = useTextChange(watch)

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0
      }
    })
  }

  const expiryDateStyle = {
    control: (base, state) => ({
      ...base,
      height: '100%',
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0
      }
    })
  }

  return (
    <>
      <div className='priceAndNegotiable'>
        <div
          className={`priceAndNegotiable-inputField ${
            errors?.price?.message ? 'priceAndNegotiable-inputField-error' : ''
          }`}
        >
          <span className='currency'>NPR</span>
          <Input
            type='number'
            placeholder='Price*'
            name='price'
            {...register('price', RULES?.price)}
            textchange={textChange?.price || ''}
            onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
            divclass='group'
          />
          <SingleSelect
            name='negotiable'
            errors={errors?.negotiable?.message}
            control={control}
            options={NEGOTIABLE_OPTS}
            placeholder='Negotiable'
            styles={customStyles}
          />
        </div>
      </div>

      <div
        className={`expirtyDate ${
          errors?.expiryDate?.message
            ? 'expirtyDate-error'
            : errors?.date?.message
            ? 'expirtyDate-error'
            : ''
        }`}
      >
        <SingleSelect
          name='expiryDate'
          errors={errors?.expiryDate?.message}
          control={control}
          options={EXPIRY_DATE_OPTS}
          placeholder='Expiry Date'
          rules={{ required: 'Expiry date is required' }}
          styles={expiryDateStyle}
          menuPlacement='top'
        />

        {props?.fieldData?.expiryDate === 4 ? (
          <div className='date-picker'>
            <input
              type='date'
              {...register('date', { required: 'Date is required' })}
            />
          </div>
        ) : null}
      </div>

      <NavigationBtn isValid={isValid} {...props} />
    </>
  )
}
