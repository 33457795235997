import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { useGetHbSelectAds } from './useGetHbSelectAds'
import { useInfiniteFetch } from './useInfiniteFetch'
import { axiosInstance } from '../utils/axiosInstance'
import { searchProductPayload } from '../api'
import { IS_HB_SELECT_ID, PAGE_SIZE } from '../constants'
import { useMemo } from 'react'

export const useProduct = (
  categoryId = '',
  pcategory = '',
  filterPayload = {}
) => {
  const [productAds, setProductAds] = useState([])
  const [payload, setPayload] = useState()
  const [loading, setLoading] = useState(true)

  const isHBSelect = pcategory === IS_HB_SELECT_ID ? true : false

  const {
    data: topHBSelectProduct,
    isLoading,
    isError
  } = useGetHbSelectAds(categoryId, pcategory)

  useEffect(() => {
    if (filterPayload?.isFilterApplied) {
      let searchValue
      try {
        searchValue = decodeURI(filterPayload?.searchValue || '')
      } catch (err) {
        if (err?.name === 'URIError')
          searchValue = filterPayload?.searchValue.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\\{\\}\\[\]\\\\/]/gi,
            ''
          )
      }
      const pack = searchProductPayload({
        ...filterPayload,
        searchValue: searchValue,
        category: categoryId || ''
      })
      setPayload(pack)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPayload])

  const fetchData = async ({ pageParam = 1 }) => {
    const res = await axiosInstance().get(
      `/api/Product?PageSize=${PAGE_SIZE}&CategoryId=${categoryId}&IsHBSelect=${
        pcategory === IS_HB_SELECT_ID ? true : false
      }&PageNumber=${pageParam}`
    )
    return res?.data
  }

  const postData = async ({ pageParam = 1 }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const parsePayload = JSON.parse(payload)
    const jdata = JSON.stringify({
      ...parsePayload,
      pageNumber: pageParam,
      isHBSelect: isHBSelect
    })

    const res = await axiosInstance().post(
      '/api/Search/Products',
      jdata,
      config
    )
    return res?.data
  }

  const {
    isLoading: isProductLoading,
    data: productData,
    fetchNextPage,
    hasNextPage,
    isError: isProductError,
    isFetchingNextPage
  } = useInfiniteFetch(
    filterPayload?.isFilterApplied ? payload : `CATEGORY_DATA_${categoryId}`,
    filterPayload?.isFilterApplied ? postData : fetchData,
    payload
  )
  const memoizedProductData = useMemo(() => {
    return productData?.pages?.map((result) => result?.data).flat()
  }, [productData])

  useEffect(() => {
    if (
      !isHBSelect &&
      !isLoading &&
      !isEmpty(topHBSelectProduct?.data) &&
      !isEmpty(memoizedProductData) &&
      !isError &&
      !isProductLoading
    ) {
      return setProductAds([
        ...topHBSelectProduct?.data,
        ...memoizedProductData
      ])
    }
    if (!isProductLoading) {
      setProductAds(memoizedProductData)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topHBSelectProduct,
    memoizedProductData,
    isProductLoading,
    pcategory,
    isLoading
  ])

  useEffect(() => {
    if (!isProductLoading) {
      if (productAds?.length !== 0) return setLoading(false)
      if (isProductError) return setLoading(false)
      return setLoading(false)
    }
    return setLoading(true)
  }, [productAds, isProductError, isProductLoading])

  return {
    topHBSelectProduct,
    isLoading,
    isError,
    isProductLoading: loading,
    productData,
    fetchNextPage,
    hasNextPage,
    isProductError,
    productAds,
    totalRecords: productData?.pages?.[0]?.meta?.totalRecords,
    isFetchingNextPage
  }
}
