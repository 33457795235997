import { useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const SearchQuery = (
  selectedCategory = {},
  setSelectedCategory = () => {},
  reset = () => {}
) => {
  const location = useLocation()
  const history = useHistory()
  const searchQuery = useSearchQuery()

  function getSearchFor() {
    return location?.search?.split('=').pop()
  }
  function sanitizeSearchFor(searchFor) {
    const splitSearchFor = searchFor.split('-')
    if (splitSearchFor?.length <= 1) return searchFor
    return splitSearchFor.join(' ')
  }

  const getSubIdCatIdFromUrl = () => {
    let [, pathname, searchFor, cId, sId] = history.location.pathname.split('/')
    if (searchQuery.get('sId')) sId = searchQuery.get('sId')
    if (searchQuery.get('cId')) cId = searchQuery.get('cId')
    if (pathname.toString() !== 'category') searchFor = ''
    if (searchQuery.get('prevSearchFor')) searchFor = getSearchFor()
    return [sanitizeSearchFor(searchFor), cId, sId]
  }
  const [searchFor, cId, sId] = getSubIdCatIdFromUrl()

  const splitUrl = location.pathname.split('/')[1]

  const addCatId = selectedCategory?.id
    ? `&cId=${selectedCategory?.id}`
    : ['category', 'search'].some((value) => value === splitUrl) && cId
    ? `&cId=${cId}`
    : ''
  const addSId =
    ['category', 'search'].some((value) => value === splitUrl) && sId
      ? `&sId=${sId}`
      : ''

  useEffect(() => {
    if (!['search'].some((value) => value === splitUrl)) reset()
    setSelectedCategory({})
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reset, splitUrl])

  const searchUrl = (searchValue) => {
    return `/search/product?q=${encodeURIComponent(
      searchValue
    )}&Latitude=0&Longitude=0${addCatId}${addSId}${
      ['category'].some((value) => value === splitUrl)
        ? `&prevSearchFor=${searchFor}`
        : ''
    }`
  }

  return { addCatId, addSId, searchFor, splitUrl, cId, searchUrl }
}

export function useSearchQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
