import { useState, useEffect } from 'react'

/**
 * It takes a watch function as an argument and returns a changeText object
 * @param watch - The watch function from the useForm hook.
 * @returns A function that takes in a watch function and returns a stateful value as funtion.
 */
export const useTextChange = (watch) => {
  const [changeText, setChangeText] = useState()

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setChangeText(value, name, type)
    )
    return () => subscription.unsubscribe()
  }, [watch])
  return changeText
}
