import { useState } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

import {
  addWishList,
  removeWishItem
} from '../../../Redux/reducers/cartwishlistReducers'
import { productCondition, convertImgType } from '../../../helpers'
import { checkTime, combineClassNames } from '../../../utils'
import { getPluralAdForm } from './utils'
import { useSavehandler } from './hooks'

import { ProductTypeTag } from './ProductTypeTag'
import { NewTabOpen } from '../../index'
import PopupMenu from '../../common/menu/PopupMenu'
import { ProgressiveImg, Image } from '../../image'
import GalleryModel from './GalleryModel'
import { ProductPrice } from './ProductPrice'

import { SaveIcon, MaximizeIcon } from '../../../icons'

import {
  ADULT_CONTENT_THUMBNAIL,
  ADULT_CONTENT_THUMBNAIL_PLACEHOLDER
} from '../../../constants'
import NewTabOpenUser from '../../common/NewTabOpenUser'

function LinearCard({ data, refetch, auth }) {
  const [previewImage, setPreviewImage] = useState(false)

  const { saveHandler } = useSavehandler(auth, refetch)

  const linerCardClassNames = combineClassNames(
    'card-product-linear',
    {
      'card-product-linear-boostProduct': data?.productType === 1
    },
    { 'card-product-linear-hbSelectProduct': data?.isHBSelect }
  )

  return (
    <>
      <div className={linerCardClassNames}>
        <div className='card-product-linear-imgContainer'>
          <div className='main-img'>
            <ProductTypeTag data={data} />
            <NewTabOpen className='newTabAnchor' productData={data}>
              <ProgressiveImg
                previewURL={
                  data?.isAdultContent
                    ? ADULT_CONTENT_THUMBNAIL_PLACEHOLDER
                    : convertImgType(
                        {
                          imageUrl: data?.imageUrl,
                          oldImageUrl: data?.oldImageUrl,
                          oldLocationKey: false,
                          imgSize: '_small'
                        },
                        320,
                        320,
                        'webp',
                        40
                      )
                }
                largeImageURL={
                  data?.isAdultContent
                    ? ADULT_CONTENT_THUMBNAIL
                    : convertImgType(
                        {
                          imageUrl: data?.imageUrl,
                          oldImageUrl: data?.oldImageUrl,
                          oldLocationKey: false,
                          imgSize: '_large'
                        },
                        320,
                        320,
                        'webp'
                      )
                }
                alt={data?.name || 'hamrobazaar'}
                className='linear-img'
                // transitionTime={200}
              />
            </NewTabOpen>
            {data?.isAdultContent ? null : (
              <div className='maximize-icon'>
                <MaximizeIcon
                  title='Click to view full image'
                  onClick={() => setPreviewImage(true)}
                />
              </div>
            )}
          </div>
          {previewImage && (
            <GalleryModel data={data} setPreviewImage={setPreviewImage} />
          )}
          <div className='small-img'>
            {!isEmpty(data?.productMedia) ? (
              <>
                {data?.productMedia?.slice(0, 3)?.map((item) => (
                  <>
                    <Image
                      id={item?.id}
                      src={convertImgType(
                        {
                          imageUrl: item?.locationKey,
                          oldImageUrl: false,
                          oldLocationKey: item?.oldLocationKey,
                          imgSize: '_small'
                        },
                        110,
                        120,
                        'webp'
                      )}
                      alt={data?.name}
                      className='small-img-item'
                      key={item?.id}
                    />
                  </>
                ))}
              </>
            ) : null}
          </div>
        </div>
        <div className='card-product-linear-info'>
          <div className='nameAndDropdown'>
            <NewTabOpen productData={data}>
              <h2 className='product-title'>
                {data?.name}
                {data?.brandName && (
                  <small style={{ color: '#101010' }}>
                    {' '}
                    ({data.brandName})
                  </small>
                )}
              </h2>
            </NewTabOpen>
            <PopupMenu
              isAuth={auth?.isAuthenticated}
              data={data}
              refetch={refetch}
              auth={auth}
            />
          </div>
          <p className='description'>{data?.description || 'N/A'}</p>
          <div className='priceAndCondition'>
            <ProductPrice data={data} className='productPrice-liner' />
            <span className='condition'>
              | {productCondition(data?.condition)}
            </span>
          </div>
          <div className='locationAndTime'>
            <span className='location'>
              {data?.location?.locationDescription || 'N/A'}
            </span>
            <span className='time'>
              {checkTime(data?.createdTime) ?? 'N/A'}
            </span>
          </div>
          <div className='divider' />
          <div className='usernameAndSave'>
            <NewTabOpenUser createdById={data?.creatorInfo?.createdById}
             auth={auth}>
              <div
                className='username'
              >
                <span className='username-fullname'>
                  {data?.creatorInfo?.createdByName ||
                    data?.creatorInfo?.createdBy ||
                    'N/A'}
                </span>
                <span className='username-listing'>{`| ${
                  data?.creatorInfo?.creatorAdCount
                } ${getPluralAdForm(data?.creatorInfo?.creatorAdCount)}`}</span>
              </div>
            </NewTabOpenUser>
            {data?.createdById === auth?.user?.userId ? null : (
              <>
                <div
                  className='save'
                  onClick={() => saveHandler(data)}
                  title={`${data?.isSaved ? 'Remove Saved' : 'Save'} Product`}
                >
                  <SaveIcon />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='divider divider-liner'>
        <span className='divider-liner-line' />
      </div>
    </>
  )
}

const mapStateProps = (state) => ({
  auth: state.auth,
  wish: state.cartwish
})

const mapDispatchToProps = {
  addWishList,
  removeWishItem
}

export default connect(mapStateProps, mapDispatchToProps)(LinearCard)
