import React, { useEffect, useState } from 'react'
import { throttle } from '../../../utils/functions'

const Gotop = ({ scrollY = 4500 }) => {
  const [scrolled, setscrolled] = useState(0)
  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handler = throttle(() => {
      setscrolled(document.documentElement.scrollTop)
    }, 300)
    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [])

  return (
    <>
      <div
        className={`gotop--btn ${scrolled > scrollY ? ' active ' : ''}`}
        onClick={goUp}
      >
        <i className="fal fa-arrow-up"></i>
      </div>
    </>
  )
}

export default Gotop
