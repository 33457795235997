import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const getDealsApi = async page => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  let sendData = {
    pageNumber: page,
    pageSize: 10,
    searchParams: {
      searchValue: '',
      searchBy: '',
    },
    filterParams: {
      condition: 0,
      priceFrom: 0,
      priceTo: 0,
      isPriceNegotiable: null,
      category: '',
    },
    sortParam: 0,
  }

  const jdata = JSON.stringify({
    ...sendData,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0,
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Search/Deals`,
    jdata,
    config
  )
  return res
}

export const removeCartItemApi = async pid => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  let pack = {
    productId: pid,
  }

  const jdata = JSON.stringify({
    ...pack,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0,
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Cart/Delete`,
    jdata,
    config
  )
  return res
}

export const checkoutApi = async data => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const chekoutPlayload = {
    deliveryAddress: data?.address,
    deliveryContact: data?.phone,
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const jdata = JSON.stringify({
    ...chekoutPlayload,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0,
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Cart/Checkout`,
    jdata,
    config
  )
  return res
}

export const getOrdersApi = async () => {
  const res = await axiosInstance().get(`${baseAPI}/api/Order/History`)
  return res
}

export const getChargeApi = async () => {
  const res = await axiosInstance().get(`${baseAPI}/api/Setting/DeliveryFee`)
  return res
}
