import { createAction, createReducer } from '@reduxjs/toolkit'

export const addSpinner = createAction('SPINNER/ADDED')
export const removeSpinner = createAction('SPINNER/REMOVED')

const initialState = {
  spinner: false,
}

const spinnerReducers = createReducer(initialState, {
  [addSpinner]: state => {
    state.spinner = true
  },
  [removeSpinner]: state => {
    state.spinner = false
  },
})

export default spinnerReducers
