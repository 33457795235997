import { useEffect, useState } from 'react'

const DEFAULT_ROOT_MARGIN = '0px'
const DEFAULT_THRESHOLD = [0]

/**
 * React hook, which allows you to observe the visibility of an element in the viewport
 * using the IntersectionObserver API.
 *
 * useEffect hook is used to create a new IntersectionObserver instance when the component is rendered,
 * passing in a callback function that updates the entry state. The observer is passed with options provided as props.
 * This hook also pass option to observer to stop observing the element once it is intersected.
 *
 * The disconnect method is called in the cleanup function of useEffect which disconnects the observer when the
 * component is unmounted, to free up resources.
 *
 * However the observer is still being called even after loaded. This might be because the component which is using
 * this hook is getting re-rendered or the component is using useEffect with empty dependency array.
 * This causes the observer to be created every time the component is rendered, even if the options haven't changed
 *
 * It returns an IntersectionObserver entry object when the ref element is visible in the viewport
 * @returns A function that takes in an object with a ref and options.
 */
export function useIntersectionObserver({
  ref,
  options = {
    threshold: DEFAULT_THRESHOLD,
    root: null,
    rootMargin: DEFAULT_ROOT_MARGIN,
    triggerOnce: false
  }
}) {
  const { threshold, root, rootMargin, triggerOnce } = options

  const [entry, setEntry] = useState()
  const frozen = entry?.isIntersecting && triggerOnce

  const updateEntry = ([entry]) => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = ref?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)
    observer.observe(node)

    return () => observer.disconnect()
  }, [root, rootMargin, frozen, ref, threshold])
  return entry
}
