import { useContext, useMemo, useRef } from 'react'
import { Marker, useMapEvents, Popup } from 'react-leaflet'
import L from 'leaflet'

import { getReverseGeocodingData } from './reverseGeocoding'
import { OpenStreetmapDispatcher } from './OpenStreetMap'

export let markerIcon = new L.Icon({
  iconUrl: require('../../assets/images/marker.png'),
  iconSize: [40, 40],
  iconAnchor: [17, 46],
  popupAnchor: [0, -46]
})

export const MapMarker = () => {
  const markerRef = useRef(null)

  const [{ position, displayName }, updateEvents] = useContext(
    OpenStreetmapDispatcher
  )

  const eventHandlers = useMemo(
    () => ({
      async dragend() {
        const marker = markerRef.current
        await locationMarker(marker.getLatLng())
      }
    }),
    []
  )
  useMapEvents({
    async click(e) {
      await locationMarker(e.latlng)
    }
  })

  async function locationMarker(latlng) {
    const marker = markerRef.current
    if (marker != null) {
      try {
        const reverseGeocodingData = await getReverseGeocodingData(latlng)
        updateEvents({
          displayName: reverseGeocodingData?.data?.display_name,
          recenter: false
        })
        marker.openPopup()
      } catch (err) {
        console.log(err)
      } finally {
        updateEvents({ position: latlng })
      }
    }
  }

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={markerIcon}
    >
      <Popup>{displayName}</Popup>
    </Marker>
  )
}
