import InBetweenAds from '../Ads/GoogleAds/InBetweenAds'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import AdImage from '../Ads/GoogleAds/AdImage'
import { useGAEventTracker } from '../../hooks'

export default function SideBarAds({ ads = [] }) {
  const GAEvent = useGAEventTracker()

  return ads.map((ad) => {
    return (
      <div className='mobInfeedGAds' key={ad.id}>
        {ad.type === 'adsence' ? (
          <InBetweenAds keyProp={`adsense-${ad.id}`} />
        ) : ad.linkType === 'external' ? (
          <a
            onClick={() => GAEvent(ad?.pageName, ad?.slotName, ad?.pageHtml)}
            href={'//' + ad.link}
            target='_blank'
            rel='noreferrer'
          >
            <LazyLoad>
              <AdImage
                src={
                  window.innerWidth > 767
                    ? ad.adContentDesktop
                    : ad.adContentMobile
                }
              />
            </LazyLoad>
          </a>
        ) : ad.linkType === 'profile' ? (
          <Link to={`/user/${ad.link}`}>
            <LazyLoad>
              <AdImage
                src={
                  window.innerWidth > 767
                    ? ad.adContentDesktop
                    : ad.adContentMobile
                }
              />
            </LazyLoad>
          </Link>
        ) : (
          ad.linkType === 'product' && (
            <Link to={`/product/${ad.link}`}>
              <LazyLoad>
                <AdImage
                  src={
                    window.innerWidth > 767
                      ? ad.adContentDesktop
                      : ad.adContentMobile
                  }
                />
              </LazyLoad>
            </Link>
          )
        )}
      </div>
    )
  })
}
