import { useState, forwardRef } from 'react'
import ErrorMessage from './ErrorMessage'
import { ReactComponent as EyeIcon } from '../../../icons/auth/eye.svg'

export const Input = forwardRef((props, ref) => {
  let {
    errors,
    type,
    name,
    passtype,
    placeholder,
    limits,
    defaultValue,
    info,
    expired,
    expiredMessage,
    watchText,
    displayLabel = true,
    label,
    divclass = 'item',
    ...rest
  } = props
  const [showLabel, setShowLabel] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  let pwType

  if (passtype === 'password') {
    pwType = showPassword ? 'text' : 'password'
  }
  const capName = name.charAt(0).toUpperCase() + name.slice(1)
  const newPlaceholder = capName.replace(/([A-Z])/g, ' $1').trim()

  return (
    <div
      className={`form-${divclass} pos-rel '  ${
        showLabel || defaultValue?.length > 0 || watchText
          ? 'show--label lb-auth'
          : ''
      }`}
      onFocus={() => setShowLabel(true)}
      onBlur={() => setShowLabel(false)}
    >
      {label && label}
      <input
        className={`input ${errors ? 'warning' : ''}`}
        type={passtype === 'password' ? pwType : type}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder ?? newPlaceholder}
        ref={ref}
        {...rest}
        autoComplete='off'
      />
      {errors && <ErrorMessage message={errors} />}
      {divclass === 'item' && <br />}
      {displayLabel && (
        <label className='the--label'>
          {passtype === 'password'
            ? placeholder ?? 'Password'
            : placeholder ?? newPlaceholder}
        </label>
      )}
      {passtype === 'password' && (
        <span
          className={`eye ${showPassword ? 'close' : 'open'}`}
          onClick={() => setShowPassword(!showPassword)}
        >
          <EyeIcon />
        </span>
      )}
      {info && (
        <small>{info ?? 'For example: Brand, model, color, and size.'}</small>
      )}
      {limits && (
        <div className='limits'>
          <span>{props?.textchange?.length ?? defaultValue?.length}</span>/
          {limits}
        </div>
      )}
      {expired && (
        <small className='input--message warning'>{expiredMessage}</small>
      )}
    </div>
  )
})
