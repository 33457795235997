import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { CloseIcon } from '../../icons'
import { useWindowDimensions } from '../../hooks'
import { removeSearchKeys, storeSearchKeys, uuid } from '../../utils'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { gettingWishList } from '../../Redux/reducers/cartwishlistReducers'
import WishListCard from '../cards/productCard/WishListCard'

const InitialSuggestions = ({
  suggestions,
  setValue,
  searchUrl,
  noResults = false,
  auth,
  wish,
  gettingWishList
}) => {
  const history = useHistory()
  const windowDimension = useWindowDimensions()
  useEffect(() => {
    if (auth?.isAuthenticated) gettingWishList(1)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const numberOfWisheList = windowDimension?.width > 768 ? 3 : 1

  return (
    <div className='search-initial-content'>
      <div className='search-initial-content-heading'>
        <span>{noResults ? 'No Results' : 'Search for anything'}</span>
      </div>
      <div className='search-initial-content-recent'>
        <span>Recent Searches</span>
        <div className='search-initial-content-recent-container'>
          {!isEmpty(suggestions) ? (
            suggestions?.map((suggestion) => (
              <React.Fragment key={suggestion?.id + uuid}>
                {suggestion?.searchValue ? (
                  <div className='recent-searches'>
                    <Link
                      to={`${searchUrl(suggestion?.searchValue)}`}
                      onClick={() => {
                        setValue('searchValue', suggestion?.searchValue)
                        storeSearchKeys({
                          id: suggestion?.id,
                          searchValue: suggestion?.searchValue,
                          close: true
                        })
                      }}
                    >
                      <h2 className='recent-search-title'>
                        {suggestion?.searchValue}
                      </h2>
                    </Link>
                    <span>
                      <CloseIcon
                        className='closeIcon'
                        onClick={() => {
                          removeSearchKeys(suggestion?.id)
                        }}
                      />
                    </span>
                  </div>
                ) : null}
              </React.Fragment>
            ))
          ) : (
            <div className='recent-searches-not-found'> No Recent Search</div>
          )}
        </div>
      </div>
      {auth?.isAuthenticated ? (
        <>
          {wish?.wishlist?.length > 0 ? (
            <div className='search-initial-content-saved'>
              <span>Recently saved</span>
              <div className='search-initial-content-saved-container'>
                {wish?.wishlist.slice(0, numberOfWisheList).map((item, i) => (
                  <WishListCard key={item?.id + uuid + i} data={item} />
                ))}
                <div className='seemore-container'>
                  <div
                    onClick={() =>
                      history.push({
                        pathname: '/profile',
                        state: { mylist: '/' }
                      })
                    }
                    className='see-more'
                    style={{
                      backgroundImage: `url(${wish?.wishlist?.[numberOfWisheList]?.imageUrl})`
                    }}
                  >
                    <span>See More</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : //TODO: new UI for user not logged in
      // <div className='login'>Login</div> */
      null}
    </div>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  wish: state.cartwish
})

const mapDispatchToProps = { gettingWishList }
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(InitialSuggestions)
)
