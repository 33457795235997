import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'
import { decryptObjData } from '../utils'

const baseAPI = appConfig.baseApiURI

export const searchProductPayload = data => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const pack = {
    pageNumber: 1,
    pageSize: data?.pageSize || 10,
    latitude: data?.latitude || 0,
    longitude: data?.longitude || 0,
    isHBSelect: data?.isHBSelect || false,
    searchParams: {
      searchValue: data?.searchValue || '',
      searchBy: data?.searchBy || '',
    },
    filterParams: {
      condition: data?.condition || 0,
      priceFrom: data?.priceFrom || 0,
      priceTo: data?.priceTo || 0,
      isPriceNegotiable: data?.isPriceNegotiable || false,
      category: data.categoryId || '',
      brand: data.brand || '',
      advanceFilter: data?.advanceFilter || '',
    },
    sortParam: data?.sortParam || 0,
  }
  const searchPayload = JSON.stringify({
    ...pack,
    deviceId,
    deviceSource,
    latitude: Number(localStorage.getItem('currentLocationLat')) || 0,
    longitude: Number(localStorage.getItem('currentLocationLng')),
  })

  return searchPayload
}

export const searchApi = async data => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  let pack = {
    pageNumber: data?.pageNumber || 1,
    pageSize: 10,
    latitude: Number(data?.latitude),
    longitude: Number(data?.longitude),
    searchParams: {
      searchValue: data?.searchValue,
      searchBy: '',
      searchByDistance: data?.searchByDistance || 0,
    },
    filterParams: {
      condition: data?.condition || 0,
      priceFrom: data?.priceFrom || 0,
      priceTo: data?.priceTo || 0,
      isPriceNegotiable:
        Number(data?.negotiable) === 1
          ? true
          : Number(data?.negotiable) === 2
          ? false
          : Number(data?.isPriceNegotiable) === 1
          ? true
          : Number(data?.isPriceNegotiable) === 2
          ? false
          : null,
      category: data?.category || '',
      categoryIds: data?.categoryIds || '',
      brandIds: data?.brandIds || '',
      brand: data?.brand || '',
      advanceFilter: data?.advanceFilter || '',
    },
    sortParam: data?.sortParam ?? 0,
    isHBSelect: data?.isHBSelect,
  }

  const jdata = JSON.stringify({
    ...pack,
    deviceId,
    deviceSource,
    latitude: data?.latitude || 0,
    longitude: data?.longitude || 0,
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Search/Products`,
    jdata,
    config
  )
  return res
}

export const searchTagApi = async () => {
  const deviceId = getCookie(DEVICE_ID)
  let userData = decryptObjData(localStorage.getItem('user_auth'))

  const userId = userData?.value?.userId || ''

  const config = {
    headers: {
      'Content-Type': 'application/json',
      latv: localStorage.getItem('currentLocationLat') || 0,
      longv: localStorage.getItem('currentLocationLng') || 0,
      deviceId,
      userId,
    },
  }
  const res = await axiosInstance().get(
    `${baseAPI}/api/Search/UserData`,
    config
  )
  return res
}

export const removeTagApi = async (searchKey, searchText, userId) => {
  const deviceId = getCookie(DEVICE_ID)

  const res = await axiosInstance().post(
    `${baseAPI}/api/Search/DeleteSearchKey`,
    {
      searchKey: 'PRODUCT',
      searchText,
      deviceId,
      userId,
      latitude: localStorage.getItem('currentLocationLat'),
      longitude: localStorage.getItem('currentLocationLng'),
    }
  )
  return res
}

export const searchAutoApi = async query => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Product/Autocomplete?searchText=${query}`
  )
  return res
}

export const getCatQueryApi = async data => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/Search/Categories?searchValue=${data}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0,
    }
  )
  return res
}

export const getSimilarProductApi = async data => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const jdata = searchProductPayload({ ...data, pageSize: 5 })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Search/Products`,
    jdata,
    config
  )
  return res
}

export const getProductsByCategoryId = async ({ id, page, isHBSelect }) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().get(
    `${baseAPI}/api/Product?PageNumber=${page}&PageSize=10&Latitude=${
      localStorage.getItem('latitude') || 0
    }&Longitude=${
      localStorage.getItem('longitude') || 0
    }&CategoryId=${id}&IsHBSelect=${isHBSelect}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0,
    }
  )
  return res
}
