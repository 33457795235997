import { toast } from 'react-toastify'

const ApiError = err => {
  if (err.response?.data?.status) {
    toast?.error(err.response?.data?.status.message[0])
  } else toast?.error(err.message)
}

const ApiSucessToaster = res => {
  toast.success(res.data.status.message[0], {
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
  })
}

export { ApiError, ApiSucessToaster }
