import { useMutation } from 'react-query'

import { editAdApi, postAdApi } from '../../api/ads'
import { popToast } from '../../components/common/PopToast'

export const UpdateProduct = (setShowModal = () => {}, refetch = () => {}) => {
  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await editAdApi(data)
    },
    {
      onSuccess: (data) => {
        refetch()
        setShowModal('')
        popToast(
          data?.data?.status?.message?.[0] ||
            'Successfully Updated the product!',
          'TOP_RIGHT'
        )
      },
      onError: (error) => {
        popToast(
          'Unable to update product! Please try again later.',
          'TOP_RIGHT'
        )
      }
    }
  )
  return { mutate, isLoading }
}

export const AddProductService = (setFormStep = () => {}) => {
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    async (data) => {
      return await postAdApi(data)
    },
    {
      onSuccess: (data) => {
        setFormStep((curr) => curr + 1)
      }
    }
  )
  return { mutate, isLoading, isError, error, isSuccess }
}
