import React from 'react'
import { Link } from 'react-router-dom'

import { useGAEventTracker } from '../../../hooks'
import { validateURL } from '../../../utils'

import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import SquareAds from '../GoogleAds/SquareAds'

const PatternFour = ({ AdsPattern }) => {
  const GAEvent = useGAEventTracker()
  const adSequenceOne = AdsPattern.filter((ad) => ad?.adSequence === 1)
  const adSequenceTwoAndThree = AdsPattern.filter(
    (ad) => ad?.adSequence === 2 || ad?.adSequence === 3
  )
  const adSequenceFour = AdsPattern.filter((ad) => ad.adSequence === 4)

  const style = !adSequenceOne[0] && !adSequenceFour[0] ? 110 : undefined

  return (
    <div
      className={
        window.innerWidth > 767 ? 'responseGTopBannerContainer' : undefined
      }
    >
      <div
        className='sideBanner longGad4ItemSide longWithGadResponsiveLink'
        style={{ height: `${style}px` }}
      >
        {adSequenceOne[0] && (
          <>
            {adSequenceOne[0]?.type === 'Adsense' ? (
              <SquareAds keyProp={`adsense-40`} />
            ) : adSequenceOne[0]?.linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    adSequenceOne?.[0]?.pageName,
                    adSequenceOne?.[0]?.slotName,
                    adSequenceOne?.[0]?.pageHtml
                  )
                }
                href={validateURL(
                  adSequenceOne?.[0]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
                data-sequence='ad-sequence-1'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? adSequenceOne[0]?.adContentDesktop
                      : adSequenceOne[0]?.adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  adSequenceOne[0]?.linkType === 'profile' ? 'user' : 'product'
                }/${adSequenceOne[0]?.link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? adSequenceOne[0]?.adContentDesktop
                      : adSequenceOne[0].adContentMobile
                  }
                />
              </Link>
            )}
          </>
        )}
      </div>
      <div
        className='longGad4ItemMid longWithGadResponsiveLink'
        style={{
          width: window.innerWidth > 767 ? 720 : 320,
          height:
            window.innerWidth > 767
              ? adSequenceTwoAndThree?.length !== 2 &&
                !adSequenceOne[0] &&
                !adSequenceFour[0]
                ? 110
                : 250
              : 110
        }}
      >
        <div
          style={{
            display: 'flex',
            rowGap: '10px',
            flexDirection: 'column',
            height: window.innerWidth > 767 ? 120 : 110
          }}
        >
          {adSequenceTwoAndThree.map((adsPatternTwoThree, index) => (
            <React.Fragment key={adsPatternTwoThree?.id || index}>
              {adsPatternTwoThree?.type === 'Adsense' ? (
                <BannerAds keyProp='adsense-41' />
              ) : adsPatternTwoThree?.linkType === 'external' ? (
                <a
                  onClick={() =>
                    GAEvent(
                      adsPatternTwoThree?.pageName,
                      adsPatternTwoThree?.slotName,
                      adsPatternTwoThree?.pageHtml
                    )
                  }
                  href={validateURL(
                    adsPatternTwoThree?.link || 'https://hamrobazaar.com/'
                  )}
                  target='_blank'
                  rel='noreferrer'
                  data-sequence={`sequence-${adsPatternTwoThree?.adSequence}`}
                >
                  <LazyLoadImage
                    src={
                      window.innerWidth > 767
                        ? adsPatternTwoThree?.adContentDesktop
                        : adsPatternTwoThree.adContentMobile
                    }
                  />
                </a>
              ) : (
                <Link
                  to={`/${
                    adsPatternTwoThree?.linkType === 'profile'
                      ? 'user'
                      : 'product'
                  }/${adsPatternTwoThree?.link}`}
                >
                  <LazyLoadImage
                    src={
                      window.innerWidth > 767
                        ? adsPatternTwoThree?.adContentDesktop
                        : adsPatternTwoThree.adContentMobile
                    }
                  />
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div
        className='sideBanner longGad4ItemSide longWithGadResponsiveLink'
        style={{ height: `${style}px` }}
      >
        {adSequenceFour[0] && (
          <>
            {adSequenceFour[0]?.type === 'Adsense' ? (
              <SquareAds keyProp={`adsense-40`} />
            ) : adSequenceFour[0]?.linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    adSequenceFour?.[0]?.pageName,
                    adSequenceFour?.[0]?.slotName,
                    adSequenceFour?.[0]?.pageHtml
                  )
                }
                href={validateURL(
                  adSequenceFour[0]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
                data-sequence='ad-sequence-4'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? adSequenceFour[0]?.adContentDesktop
                      : adSequenceFour[0]?.adContentMobile
                  }
                  width={250}
                  height={250}
                />
              </a>
            ) : (
              <Link
                to={`/${
                  adSequenceFour[0]?.linkType === 'profile' ? 'user' : 'product'
                }/${adSequenceFour[0]?.link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? adSequenceFour[0]?.adContentDesktop
                      : adSequenceFour[0]?.adContentMobile
                  }
                  width={250}
                  height={250}
                />
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PatternFour
