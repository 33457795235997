import { useRef } from 'react'
import { useIntersectionObserver } from '../../hooks'
import { NO_IMAGE_THUMNAIL } from '../../constants'

export const Image = (props) => {
  const { alt, src, ...rest } = props
  const ref = useRef(null)

  const entry = useIntersectionObserver({
    ref,
    options: {
      threshold: 0.25,
      triggerOnce: true
    }
  })
  const isVisible = !!entry?.isIntersecting

  return (
    <div className='image-container' ref={ref}>
      {isVisible ? (
        <img
          {...rest}
          src={src}
          alt={alt ?? 'hamrobazaar'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = NO_IMAGE_THUMNAIL
          }}
        />
      ) : (
        <div className='no-imgage'>
          <img
            {...rest}
            alt={alt ?? 'hamrobazaar'}
            src={NO_IMAGE_THUMNAIL}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = NO_IMAGE_THUMNAIL
            }}
          />
        </div>
      )}
    </div>
  )
}
