import { AddRemoveWishItem } from '../../../../helpers'
import { popToast } from '../../../common/PopToast'

export const useSavehandler = (auth, refetch) => {
  const { saveOrRemoveItem, isAddRemoveLoading } = AddRemoveWishItem(refetch)

  const saveHandler = (data) => {
    console.log(data)
    if (!auth?.isAuthenticated) return popToast()
    saveOrRemoveItem(data?.id, data?.isSaved ? 'remove' : 'add')
  }
  return { saveHandler, isAddRemoveLoading }
}
