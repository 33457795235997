import { toast } from 'react-toastify'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  addCartApi,
  getWishApi,
  removeWishApi,
  saveWishApi,
} from '../../api/cartwishlist'
import { checkoutApi, getOrdersApi } from '../../api/dealsApi'
import { addWish, removeWish } from '../reducers/adsReducers'
import { addWishInSearch, removeWishInSearch } from '../reducers/searchReducers'
import {
  addWishList,
  addWishListError,
  addWishListSuccess,
  getOrder,
  getOrderError,
  getOrderSuccess,
  gettingCart,
  gettingCartError,
  gettingCartSuccess,
  gettingMoreWishList,
  gettingWishList,
  gettingWishListError,
  gettingWishListSuccess,
  placeOrder,
  placeOrderError,
  placeOrderSuccess,
  removeCartItem,
  removeCartItemError,
  removeCartItemSuccess,
  removeWishItem,
  removeWishItemError,
  removeWishItemSuccess,
  settingCart,
  settingCartError,
  settingCartSuccess,
} from '../reducers/cartwishlistReducers'
import {
  addWishInRecommended,
  removeWishInRecommended,
} from '../reducers/recommendationReducer'
import { userAddWish, userRemoveWish } from '../reducers/userReducers'

/*
    GPosting ad to wishlist
*/
function* addTheWishList(action) {
  try {
    if (action.payload.userAds) {
      yield call(saveWishApi, action.payload.data.id)
      yield put(addWishListSuccess(action.payload.data))
      yield put(userAddWish(action.payload.data))
      yield put(addWishInSearch(action.payload.data))
      yield put(addWishInRecommended(action.payload.data))
    } else {
      yield call(saveWishApi, action.payload.id)
      yield put(addWishListSuccess(action.payload))
      yield put(addWish(action.payload))
      yield put(addWishInSearch(action.payload))
      yield put(addWishInRecommended(action.payload))
    }
  } catch (error) {
    toast.error(' Failed to save Item, try again in few minutes', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(addWishListError(action.payload))
  }
}

function* gettingTheWishList(action) {
  try {
    const res = yield call(getWishApi, action.payload)
    yield put(gettingWishListSuccess(res))
  } catch (error) {
    yield put(gettingWishListError(error))
  }
}

/*
  Get the cart items
*/
function* getTheCart(action) {
  try {
    yield put(gettingCartSuccess(action.payload))
  } catch (error) {
    yield put(gettingCartError(error))
  }
}

/*
  Remove the wish item
*/
function* removeTheWishItem(action) {
  try {
    if (action.payload.userAds) {
      yield call(removeWishApi, action.payload.data.id)
      yield put(removeWishItemSuccess(action.payload.data))
      yield put(userRemoveWish(action.payload.data))
      yield put(removeWishInSearch(action.payload.data))
      yield put(removeWishInRecommended(action.payload.data))
    } else {
      yield call(removeWishApi, action.payload.id)
      yield put(removeWishItemSuccess(action.payload.id))
      yield put(removeWish(action.payload))
      yield put(removeWishInSearch(action.payload))
      yield put(removeWishInRecommended(action.payload))
    }
  } catch (error) {
    console.log(error)
    toast.error(' Failed to Removed Item', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

    yield put(removeWishItemError(action.payload.id))
  }
}

/*
  Set cart
*/
function* setThecart(action) {
  try {
    yield put(settingCartSuccess(action.payload))
    toast.dark('Added to Cart', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } catch (error) {
    yield put(settingCartError(error))
  }
}

/*
  Remove cart
*/
function* removeTheCart(action) {
  try {
    toast.dark('Item Removed from Cart', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(removeCartItemSuccess(action.payload))
    yield gettingCart()
  } catch (error) {
    yield put(removeCartItemError(error))
  }
}

/*
  Place order
*/
function* placeTheOrder(action) {
  try {
    yield call(addCartApi, action.payload)

    try {
      //And now the checkout api
      yield call(checkoutApi, action.payload)
      yield put(placeOrderSuccess())
      yield put(gettingCart([]))
      toast.dark('Order Placed Successfully', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } catch (error) {
      yield put(placeOrderError())
    }
  } catch (error) {
    yield settingCartError()
  }
}

/*
  Get Orders
*/
function* getTheOrder() {
  try {
    const res = yield call(getOrdersApi)
    yield put(getOrderSuccess(res))
  } catch (error) {
    yield put(getOrderError(error))
  }
}

export default function* cartwishSagas() {
  yield all([
    takeLatest(addWishList, addTheWishList),
    takeLatest(gettingWishList, gettingTheWishList),
    takeLatest(gettingCart, getTheCart),
    takeLatest(settingCart, setThecart),
    takeLatest(removeWishItem, removeTheWishItem),
    takeLatest(removeCartItem, removeTheCart),
    takeLatest(placeOrder, placeTheOrder),
    takeLatest(getOrder, getTheOrder),
    takeLatest(gettingMoreWishList, gettingTheWishList),
  ])
}
