import { put, call, takeLatest, all } from 'redux-saga/effects'
import {
  getHomeAds,
  getAd,
  postAdApi,
  uploadImageApi,
  deleteImageApi,
  getUserAdApi,
  adsReportApi,
  adsHoldApi,
  markSoldApi,
  editAdApi,
  deleteAdApi,
} from '../../api/ads'

import { toast } from 'react-toastify'

import {
  gettingAds,
  gettingAdsSuccess,
  gettingAdsError,
  gettingAd,
  gettingAdSuccess,
  gettingAdError,
  loadingmoreAd,
  loadingmoreAdSuccess,
  loadingmoreAdError,
  postAd,
  postAdSuccess,
  postAdError,
  uploadImage,
  uploadImageSuccess,
  uploadImageError,
  deleteImage,
  deleteImageSuccess,
  deleteImageError,
  gettingUserAds,
  gettingUserAdsError,
  gettingUserAdsSuccess,
  adsReport,
  adsReportSuccess,
  adsReportError,
  adsHold,
  adsHoldSuccess,
  adsHoldError,
  adsUnHold,
  adsUnHoldSuccess,
  adsUnHoldError,
  adsSoldSuccess,
  adsSoldError,
  adsSold,
  updateAd,
  updateAdSuccess,
  updateAdError,
  deleteAd,
  deleteAdSuccess,
  deleteAdError,
} from '../reducers/adsReducers'

import { addSpinner, removeSpinner } from '../reducers/spinnerReducers'

//Load Ads for Homepage
function* getHomePageAds(action) {
  try {
    let res = yield call(getHomeAds, action.payload)
    //Checking the Server Status. If its 500 than handling it manually
    if (res.status === 500) {
      toast.error('Server Error', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      yield put(gettingAdsError('Server Error'))
    } else {
      yield put(gettingAdsSuccess(res))
    }
  } catch (error) {
    yield put(gettingAdsError(error))
  }
}

//Load More Ads for Homepage
function* loadingmoreAdSaga(action) {
  try {
    let res = yield call(getHomeAds, action.payload)
    yield put(loadingmoreAdSuccess(res))
  } catch (error) {
    // yield all(
    //   error.map((err) =>
    //     put(
    //       toast.error(err.msg, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       })
    //     )
    //   )
    // );
    yield put(loadingmoreAdError(error))
  }
}

//Load Ads for SIngle Page
function* getSingleAd(action) {
  try {
    let res = yield call(getAd, action.payload)
    //Checking the Server Status. If its 500 than handling it manually
    if (res?.status === 500) {
      toast.error('Server Error', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      yield put(gettingAdError('Server Error'))
    } else {
      yield put(gettingAdSuccess(res))
    }
  } catch (error) {
    yield put(gettingAdError(error))
  }
}

/*
  Posting ads
*/
function* postNewAd(action) {
  try {
    yield put(addSpinner())
    const res = yield call(postAdApi, action.payload)
    yield put(removeSpinner())
    yield put(postAdSuccess(res.data.data))
  } catch (error) {
    toast.dark(error?.response?.data?.title, {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(postAdError(error?.response))
    yield put(removeSpinner())
  }
}

/*
  Uploading Image
*/
function* uploadTheImg(action) {
  try {
    yield put(addSpinner())
    const res = yield call(uploadImageApi, action.payload)
    yield put(removeSpinner())
    yield put(uploadImageSuccess(res))
  } catch (error) {
    yield put(uploadImageError(error))
    yield put(removeSpinner())
    toast.error('Sorry !, There is a Problem Uploading Your Image.', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

/*
  Delete Image
*/
function* deleteTheImg(action) {
  try {
    yield call(deleteImageApi, action.payload)
    yield put(deleteImageSuccess(action.payload.id))
  } catch (error) {
    yield put(deleteImageError())
  }
}

/*
  Get users ad from api
*/
function* getTheUserAd(action) {
  try {
    const res = yield call(getUserAdApi, action.payload)
    yield put(gettingUserAdsSuccess(res))
  } catch (error) {
    yield put(gettingUserAdsError(error))
  }
}

/*
  ads report
*/
function* adsReportSaga(action) {
  try {
    yield put(addSpinner())
    const res = yield call(adsReportApi, action.payload)
    yield put(removeSpinner())
    toast.dark('Report Successfully Submitted', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(adsReportSuccess(res))
  } catch (error) {
    yield put(uploadImageError(error))
    yield put(adsReportError())
  }
}

/*
  ads hold
*/

function* adsHoldSaga(action) {
  const { holdData, refetch } = action.payload
  try {
    yield call(adsHoldApi, holdData)
    toast.dark('Ads Holded', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    refetch()
    yield put(adsHoldSuccess(holdData))
  } catch (error) {
    yield put(adsHoldError())
  }
}

/*
  ads unhold
*/
function* adsUnHoldSaga(action) {
  const { refetch, holdData } = action.payload
  try {
    yield call(adsHoldApi, holdData)
    toast.dark('Ads Resumed', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    refetch()
    yield put(adsUnHoldSuccess(holdData))
  } catch (error) {
    yield put(adsUnHoldError())
  }
}

//Sold Ads
function* soldTheAd(action) {
  console.log(action)
  const { refetch, productId, setConfirm } = action.payload
  console.log(productId)
  try {
    yield put(addSpinner())
    yield call(markSoldApi, productId)
    refetch()
    setConfirm(false)
    toast.dark('Marked as Sold', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(adsSoldSuccess(productId))
    yield put(removeSpinner())
  } catch (error) {
    yield put(adsSoldError(error))
    yield put(removeSpinner())
  }
}

/**
 * Update ad
 */
function* updateTheAd(action) {
  const { pack, refetch } = action.payload

  try {
    yield put(addSpinner())
    const res = yield call(editAdApi, pack)
    yield put(updateAdSuccess(res))
    refetch()
    toast.success('Ads Updated', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(removeSpinner())
  } catch (error) {
    yield put(removeSpinner())
    yield put(updateAdError(error))
  }
}

/**
 * Delete the ad
 */
function* deleteTheAd(action) {
  const { productId, refetch, setconfirmDelete } = action.payload
  try {
    yield put(addSpinner())
    yield call(deleteAdApi, productId)
    yield put(deleteAdSuccess(productId))
    refetch()
    setconfirmDelete(false)
    toast.success('Ads Deleted', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(removeSpinner())
  } catch (error) {
    yield put(deleteAdError())
    yield put(removeSpinner())
  }
}

//The auth Watchers
export default function* adsSaga() {
  yield all([
    takeLatest(gettingAds, getHomePageAds),
    takeLatest(gettingAd, getSingleAd),
    takeLatest(loadingmoreAd, loadingmoreAdSaga),
    takeLatest(postAd, postNewAd),
    takeLatest(uploadImage, uploadTheImg),
    takeLatest(deleteImage, deleteTheImg),
    takeLatest(gettingUserAds, getTheUserAd),
    takeLatest(adsReport, adsReportSaga),
    takeLatest(adsHold, adsHoldSaga),
    takeLatest(adsUnHold, adsUnHoldSaga),
    takeLatest(adsSold, soldTheAd),
    takeLatest(updateAd, updateTheAd),
    takeLatest(deleteAd, deleteTheAd),
  ])
}
