/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'

/**
 *
 * @param {*} param0
 * @returns Unread Count and Title
 */
const UnreadCounter = ({
  title,
  link,
  img,
  description,
  unreadnoti,
  unreadchat,
}) => {
  return (
    <>
      <Helmet>
        {unreadnoti > 0 || unreadchat > 0 ? (
          <title>{`[${
            parseInt(unreadchat) + parseInt(unreadnoti)
          }] ${title}`}</title>
        ) : (
          <title>{title}</title>
        )}

        {title && (
          <meta property="og:title" content={title + ' - Hamrobazar'} />
        )}
        {link && <meta property="og:url" content={link} />}
        <meta property="og:type" content="article" />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {img && <meta property="og:image" content={img} />}
      </Helmet>
    </>
  )
}

const mapStateToProps = state => ({
  unreadnoti: state.noti.unreadNoti,
  unreadchat: state.noti.unreadChat,
})

export default connect(mapStateToProps)(UnreadCounter)
