import { useMutation } from 'react-query'
import { adsReportApi } from '../api/ads'
import { popToast } from '../components/common/PopToast'

export const ReportAd = (setShowModal) => {
  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await adsReportApi(data)
    },
    {
      onSuccess: (data) => {
        popToast(data.data?.status?.message?.[0], 'TOP_RIGHT')
        setShowModal('')
      },
      onError: (error) => {
        popToast(error?.response?.data?.errors?.Remarks?.[0], 'TOP_RIGHT')
        setShowModal('')
      }
    }
  )
  return { mutate, isLoading }
}
