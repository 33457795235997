import { v4 as uuidv4 } from 'uuid'

//TODO: Need to do R&D for browser compatibility
// function generateRandomUID() {
//   const array = new Uint32Array(4)
//   window.crypto.getRandomValues(array)
//   return `${array[0]}.${array[1]}.${array[2]}.${array[3]}`
// }

export const uuid = uuidv4()
