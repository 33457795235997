/* eslint-disable max-len */
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'

import { useFetch, useRecaptcha } from '../../../hooks'
import { ReportAd } from '../../../services'
import { PopupModal } from './PopupModal'
import { RadioInput, Textarea } from '../InputField'
import ErrorMessage from '../InputField/ErrorMessage'
import LoginToContinue from '../../common/LoginToContinue'
import { removeOnKeyPress } from '../../../utils'

import Spinner from '../../loaders/Spinner'
import ListLoader from '../../loaders/ListLoader'

export const ReportMenu = (props) => {
  const [changeText, setChangeText] = useState()
  const [updateRecapatchOnFormSubmit, setupdateRecapatchOnFormSubmit] =
    useState(false)

  const { data, isLoading } = useFetch(
    'GET_REPORT_TYPES',
    'api/AppData/GetReportTypes'
  )
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isDirty }
  } = useForm()

  const { mutate, isLoading: isAdReporting } = ReportAd(props?.setShowModal)

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setChangeText(value, name, type)
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const recaptchaToken = useRecaptcha(updateRecapatchOnFormSubmit)

  const reportAd = (data) => {
    setupdateRecapatchOnFormSubmit((curr) =>
      setupdateRecapatchOnFormSubmit(!curr)
    )
    mutate({
      remarks: data?.reportAd ?? data?.description,
      productId: props?.data?.id,
      recaptchaToken
    })
  }

  console.log('auth', props?.auth)

  return (
    <PopupModal
      title='Report Ad'
      hideFooter='true'
      {...props}
      modalClass='modal--report'
    >
      {props?.auth?.isAuthenticated ? (
        <>
          {isLoading ? (
            <ListLoader />
          ) : (
            <>
              <h2 className='title--normal'>
                Please report this ad with one of the following reasons :
              </h2>
              <form onSubmit={handleSubmit(reportAd)}>
                {data?.data?.map((item) => (
                  <RadioInput
                    key={item?.id}
                    id={item.id}
                    label={item?.title}
                    name='reportAd'
                    defaultValue={item?.title}
                    {...register('reportAd', {
                      required:
                        changeText?.description?.length > 0
                          ? false
                          : 'Please select any option'
                    })}
                  />
                ))}
                {errors?.reportAd?.message &&
                  changeText?.description?.length < 1 && (
                    <div className='error'>
                      <ErrorMessage
                        message={errors?.reportAd?.message}
                        classname='error--msg'
                      />
                    </div>
                  )}

                <Textarea
                  maxLength='100'
                  maxLen={100}
                  placeholder='Additional Description'
                  defaultValue=''
                  errors={errors?.description?.message}
                  name='description'
                  textchange={changeText?.description}
                  {...register('description')}
                  onKeyPress={(e) => removeOnKeyPress(e, ['[<>]'])}
                />

                <div className='form-item'>
                  <button
                    className='btn btn--form'
                    type='submit'
                    disabled={isSubmitting || !isDirty}
                  >
                    <Spinner isLoading={isAdReporting} />{' '}
                    <span> Send Report</span>
                  </button>
                </div>
              </form>
            </>
          )}
        </>
      ) : (
        <LoginToContinue text={'Report'} />
      )}
    </PopupModal>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  wish: state.cartwish
})
export const Report = connect(mapStateToProps)(ReportMenu)
