import EditAdForm from './EditAdForm'
import EditLoader from '../loaders/products/EditLoader'
import { PopupModal } from '../common/menu/PopupModal'
import { useFetch } from '../../hooks'
import { PRODUCT_URL } from '../../constants'

const EditAd = ({ pid, setShowModal, refetch }) => {
  const { data, isLoading } = useFetch(
    'GET_PRODUCT_DATA',
    `${PRODUCT_URL}/${pid}`,
    !!pid,
    {
      pid
    }
  )

  return (
    <PopupModal
      title='Edit Ad'
      hideFooter={true}
      setShowModal={setShowModal}
      modalClass='modal--large modal--edit--ad'
    >
      {isLoading ? (
        <EditLoader />
      ) : (
        <EditAdForm
          gdata={data?.data}
          setShowModal={setShowModal}
          refetch={refetch}
        />
      )}
    </PopupModal>
  )
}

export default EditAd
