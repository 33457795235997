import React from 'react'
const HBLoader = () => {
  return (
    <div className="hbLoader">
      Loading...
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default HBLoader
