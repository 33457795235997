/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import CATEGORY_DATA from '../data/category.json'

export function checkCategoryType(name, condition = '', isSubCategory = false) {
  let newName
  if (isSubCategory) {
    newName = CATEGORY_DATA.filter(
      (item) => item?.id?.toLowerCase() === name?.toLowerCase()
    )?.[0]?.categoryName
  }
  const category = [
    'Apparels & Accessories',
    'Automobiles',
    'Books & Learning',
    'Computers & Peripherals',
    'Electronics, TVs, & More',
    'Furnishings & Appliances',
    'Mobile Phones & Accessories',
    'Music Instruments',
    'Sports & Fitness',
    'Toys & Video Games',
    "Want To Buy (Buyer's List)"
  ]

  const buyTypeCategory = [
    'Travel, Tours & Packages',
    'Fresh Veggies & Meat',
    'Pets & Pet Care',
    'Events & Happenings',
    'Business & Industrial',
    'Beauty & Health'
  ]

  const findTypeCategory = [
    'Services',
    'Pets for Adoption & Free Stuff',
    'Jobs'
  ]

  const buyAndSellTypeCategory = ['Real Estate']

  const HBSelectedTypeCategory = ['HB Select']

  const trimArray = (arr) => {
    const trimFnc = (item) => {
      return item
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .trim()
        .replace(/^(-)+|(-)+$/g, ' ')
        .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, '-')
        .toLowerCase()
    }
    if (typeof arr === 'string') {
      return trimFnc(arr)
    }
    return arr?.map((item) => trimFnc(item))
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1
  }
  const dynamicTitle = isInArray(
    trimArray(newName ?? name),
    trimArray(buyTypeCategory)
  )
    ? 'Buy'
    : isInArray(trimArray(newName ?? name), trimArray(findTypeCategory))
    ? 'Find'
    : isInArray(trimArray(newName ?? name), trimArray(buyAndSellTypeCategory))
    ? 'Buy and sell'
    : isInArray(trimArray(newName ?? name), trimArray(HBSelectedTypeCategory))
    ? 'Buy HB Selected authorized products'
    : `Buy ${condition?.length > 0 ? condition : 'second hand or brand new'}`

  return dynamicTitle
}

export const ProductTitle = (data) => {
  const condition = data?.condition === 1 ? 'brand new' : 'second hand'

  return `${checkCategoryType(data?.categoryId, condition, true)} ${
    data?.name
  } in ${data?.location?.locationDescription} at Rs. ${
    data?.price
  }/- now on Hamrobazar.`
}
