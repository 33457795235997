import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const getAllComments = async (pid) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Comments?ProductId=${pid}`
  )
  return res
}

export const postComments = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Comments/Create`,
    jdata,
    config
  )
  return res
}

export const deleteCommentApi = async (id) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const res = await axiosInstance().post(
    `${baseAPI}/api/Comments/Delete?id=${id}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    },
    config
  )
  return res
}
