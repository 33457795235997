import { useState, useEffect } from 'react'
import { useInfiniteFetch } from '../../hooks'
import { USER_PRODUCT } from '../../constants'
import { objToQueryParam } from '../../helpers'
import { axiosInstance } from '../../utils/axiosInstance'
import { getCookie } from '../../utils/cookies'
import { DEVICE_ID, getDeviceSource } from '../../constants'

export const UserProduct = (params = {}) => {
  const [userProducts, setUserProducts] = useState()
  const url = `${USER_PRODUCT}?${queryParams(params)}`

  const fetchData = async ({ pageParam = 1 }) => {
    const res = await axiosInstance().get(`${url}&PageNumber=${pageParam}`)
    return res?.data
  }
  const {
    isLoading,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage
  } = useInfiniteFetch(params, fetchData, params)

  useEffect(() => {
    if (!isLoading) {
      setUserProducts(data?.pages?.map((result) => result?.data).flat())
    }
  }, [isLoading, data])

  return {
    isLoading,
    data: userProducts,
    refetch,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage
  }
}

const queryParams = (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const paramsFormat = {
    'SearchParams.SearchValue': data?.SearchValue || '',
    'SearchParams.SearchBy': data?.SearchBy || '',
    'SearchParams.SearchByDistance': data?.SearchByDistance || '',
    'FilterParams.Condition': data?.Condition || '',
    'FilterParams.PriceFrom': data?.PriceFrom || '',
    'FilterParams.PriceTo': data?.PriceTo || '',
    'FilterParams.IsPriceNegotiable': data?.IsPriceNegotiable,
    'FilterParams.Category': data?.Category,
    'FilterParams.Brand': data?.Brand || '',
    'FilterParams.CategoryIds': data?.CategoryIds || '',
    'FilterParams.BrandIds': data?.BrandIds || '',
    'FilterParams.AdvanceFilter': data?.AdvanceFilter || '',
    SortParam: String(data?.SortParam || ''),
    PageSize: '10',
    Latitude: data?.Latitude || '',
    Longitude: data?.Longitude || '',
    DeviceId: deviceId ?? '',
    DeviceSource: deviceSource ?? '',
    CategoryId: data?.CategoryId || '',
    IsHBSelect: data?.IsHBSelect || '',
    UserId: data?.UserId || '',
    Status: String(data?.Status || '0')
  }

  return objToQueryParam(paramsFormat)
}
