import appConfig from '../appConfig'
import { axiosInstance } from '../utils/axiosInstance'

//The base api url
const baseAPI = appConfig.baseApiURI

export const getRecommendationApi = async page => {
  // console.log("inside API", sendData);
  const res = await axiosInstance().get(
    `${baseAPI}/api/Product/Recommended?PageNumber=${page}&PageSize=30`
  )
  return res
}
