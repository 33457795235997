import { useState } from 'react'
import { connect } from 'react-redux'
import { ChatListBox } from '../../chat/ChatListBox'

const ChatDropDown = ({ auth, index }) => {
  const [isLoading, setIsLoading] = useState(true)
  const loadingFrame = (state = false) => {
    setIsLoading(state)
  }

  return (
    <div className='dropdown-container-body'>
      <ChatListBox
        auth={auth}
        isLoading={isLoading}
        loadingFrame={loadingFrame}
        index={index}
      />
    </div>
  )
}
const mapStateToPros = (state) => ({
  auth: state.auth
})

export default connect(mapStateToPros)(ChatDropDown)
