import { Link } from 'react-router-dom'

import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import { useGAEventTracker } from '../../../hooks'
import { validateURL } from '../../../utils'

const PatternOne = ({ AdsPattern, patternName }) => {
  return (
    <>
      {AdsPattern.map((ad, index) => {
        return (
          <div className='longGad1' key={index} data-pattern={patternName}>
            <DoublePattern
              ad={ad}
              index={index}
              height={window.innerWidth > 767 ? 250 : 110}
            />
          </div>
        )
      })}
    </>
  )
}

export const DoublePattern = ({ ad, index, height }) => {
  const GAEvent = useGAEventTracker()
  return (
    <>
      {ad?.type === 'adsence' ? (
        <BannerAds keyProp={`adsense-${index}`} />
      ) : ad?.linkType === 'external' ? (
        <a
          onClick={() => GAEvent(ad?.pageName, ad?.slotName, ad?.pageHtml)}
          href={validateURL(ad?.link || 'https://hamrobazaar.com/')}
          target='_blank'
          rel='noreferrer'
        >
          <LazyLoadImage
            src={
              window.innerWidth > 767
                ? ad?.adContentDesktop
                : ad.adContentMobile
            }
            width={window.innerWidth > 767 ? 1200 : 320}
            height={height}
          />
        </a>
      ) : (
        <Link
          to={`/${ad?.linkType === 'profile' ? 'user' : 'product'}/${ad?.link}`}
        >
          <LazyLoadImage
            src={
              window.innerWidth > 767
                ? ad?.adContentDesktop
                : ad?.adContentMobile
            }
            width={window.innerWidth > 767 ? 1200 : 320}
            height={height}
          />
        </Link>
      )}
    </>
  )
}

export default PatternOne
