import { put, call, takeLatest, all } from 'redux-saga/effects'
import { featuredApi } from '../../api/featured'
import {
  gettingFeatured,
  gettingFeaturedSuccess,
  gettingFeaturedError,
} from '../reducers/featureReducer'

//Load Ads for Homepage
function* featured(action) {
  // console.log("saga called");
  try {
    let res = yield call(featuredApi, action.payload)
    //Checking the Server Status. If its 500 than handling it manually
    yield put(gettingFeaturedSuccess(res))
  } catch (error) {
    yield put(gettingFeaturedError(error))
  }
}

//The auth Watchers
export default function* adsSaga() {
  yield all([takeLatest(gettingFeatured, featured)])
}
