import { useFetch } from './useFetch'
import { TOP_HB_SELECT, IS_HB_SELECT_ID } from '../constants'

export const useGetHbSelectAds = (categoryId, parentCategoryId) => {
  const isHBSelect =
    categoryId?.length === 0
      ? false
      : parentCategoryId === IS_HB_SELECT_ID
      ? false
      : true

  const { data, isLoading, isError } = useFetch(
    `TOP_HB_SELECT`,
    `${TOP_HB_SELECT}?CategoryId=${categoryId}&IsHBSelect=true`,
    isHBSelect,
    { categoryId }
  )

  return { data, isLoading, isError }
}
