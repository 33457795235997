import { Link } from 'react-router-dom'

import { useGAEventTracker } from '../../../hooks'
import { validateURL } from '../../../utils'

import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import SquareAds from '../GoogleAds/SquareAds'

const PatternSeven = ({ AdsPattern }) => {
  const adSequenceOne = AdsPattern.filter((ad) => ad?.adSequence === 1)

  const adSequenceTwo = AdsPattern.filter((ad) => ad?.adSequence === 2)

  const newPatternArr = [
    adSequenceOne?.length > 0 ? adSequenceOne?.[0] : [],
    adSequenceTwo?.length > 0 ? adSequenceTwo?.[0] : []
  ]
  const GAEvent = useGAEventTracker()

  return (
    <div
      className={
        window.innerWidth > 767 ? 'responseGTopBannerContainer' : undefined
      }
    >
      <div
        className='midBanner longGad7ItemMid longWithGadResponsiveLink'
        style={{
          width: window.innerWidth > 767 ? 920 : 320,
          height: window.innerWidth > 767 ? 250 : 110
        }}
      >
        {newPatternArr?.[0]?.adContentDesktop && (
          <>
            {newPatternArr[0]?.type === 'Adsense' ? (
              <BannerAds keyProp='adsense-51' />
            ) : newPatternArr[0].linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    newPatternArr?.[0]?.pageName,
                    newPatternArr?.[0]?.slotName,
                    newPatternArr?.[0]?.pageHtml
                  )
                }
                href={validateURL(
                  newPatternArr[0]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr[0]?.adContentDesktop
                      : newPatternArr[0]?.adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  newPatternArr[0].linkType === 'profile' ? 'user' : 'product'
                }/${newPatternArr[0].link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr[0]?.adContentDesktop
                      : newPatternArr[0].adContentMobile
                  }
                />
              </Link>
            )}
          </>
        )}
      </div>
      <div className='sideBanner longGad7ItemRight longWithGadResponsiveLink'>
        {newPatternArr?.[1]?.adContentDesktop && (
          <>
            {newPatternArr[1]?.type === 'Adsense' ? (
              <SquareAds keyProp={`adsense-50`} />
            ) : newPatternArr[1].linkType === 'external' ? (
              <a
                onClick={() =>
                  GAEvent(
                    newPatternArr?.[1]?.pageName,
                    newPatternArr?.[1]?.slotName,
                    newPatternArr?.[1]?.pageHtml
                  )
                }
                href={validateURL(
                  newPatternArr[1]?.link || 'https://hamrobazaar.com/'
                )}
                target='_blank'
                rel='noreferrer'
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr[1]?.adContentDesktop
                      : newPatternArr[1].adContentMobile
                  }
                />
              </a>
            ) : (
              <Link
                to={`/${
                  newPatternArr[1].linkType === 'profile' ? 'user' : 'product'
                }/${newPatternArr[1].link}`}
              >
                <LazyLoadImage
                  src={
                    window.innerWidth > 767
                      ? newPatternArr[1]?.adContentDesktop
                      : newPatternArr[1].adContentMobile
                  }
                  width={250}
                  height={250}
                />
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PatternSeven
