import { createAction, createReducer } from '@reduxjs/toolkit'
import { logout } from './authReducers'

export const gettingUserInfo = createAction('GET_UINFO/REQ')
export const gettingUserInfoSuccess = createAction('GET_UINFO_SUCCESS/RES')
export const gettingUserInfoError = createAction('GET_UINFO_ERROR/RES')

export const getAdsOfUser = createAction('GET_ADS_O/REQ')
export const getAdsOfUserSuccess = createAction('GET_ADS_O_SUCCESS/RES')
export const getAdsOfUserError = createAction('GET_ADS_O_ERROR/RES')

export const getTheme = createAction('GET_THEME/REQ')
export const getThemeSuccess = createAction('GET_THEME_SUCCESS/RES')
export const getThemeError = createAction('GET_THEME_ERROR/RES')

export const setTheme = createAction('SET_THEME/REQ')
export const setThemeSuccess = createAction('SET_THEME_SUCCESS/RES')
export const setThemeError = createAction('SET_THEME_ERROR/RES')

//wish
export const userAddWish = createAction('UP_SAVED_USERADS')
export const userRemoveWish = createAction('DOWN_SAVED_USERADS')
export const clearOads = createAction('CLEAR_OADS')

const initialState = {
  user: {},
  gettingUserInfo: false,
  gettingUserInfoSuccess: false,
  gettingUserInfoError: null,

  ads: [],
  getAdsOfUser: false,
  getAdsOfUserSuccess: false,
  getAdsOfUserError: null,
  uadsFinished: false,

  theme: '',
  gettingTheme: false,
  getThemeSuccess: false,
  getThemeError: null,
}

const userReducer = createReducer(initialState, {
  //User Actions
  [gettingUserInfo]: state => {
    state.gettingUserInfo = true
  },
  [gettingUserInfoSuccess]: (state, action) => {
    state.user = action?.payload?.data?.data
    state.gettingUserInfo = false
    state.gettingUserInfoSuccess = true
  },
  [gettingUserInfoError]: (state, action) => {
    state.gettingUserInfo = false
    state.gettingUserInfoError = action?.payload?.data?.data
    state.gettingUserInfoSuccess = false
  },

  //Adding wish success
  [userAddWish]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.ads.some(a => a.id === action.payload.id)) {
      const theIndex = state.ads.findIndex(a => a.id === action.payload.id)
      state.ads[theIndex].isSaved = true
    }
  },

  //Removing wish success
  [userRemoveWish]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.ads.some(a => a.id === action.payload.id)) {
      const theIndex = state.ads.findIndex(a => a.id === action.payload.id)
      state.ads[theIndex].isSaved = false
    }
  },

  //User Ads Geting
  [getAdsOfUser]: state => {
    state.getAdsOfUser = true
  },
  [getAdsOfUserSuccess]: (state, action) => {
    const ads = action.payload?.data?.data || []
    if (ads?.length === 0 || ads?.length < 10) {
      state.uadsFinished = true
    }
    if (state.ads?.length !== 0) {
      state.ads = [...state.ads, ...ads]
    } else {
      state.ads = ads
    }
    state.getAdsOfUser = false
    state.getAdsOfUserSuccess = true
  },
  [getAdsOfUserError]: (state, action) => {
    state.getAdsOfUser = false
    state.getAdsOfUserSuccess = false
    state.getAdsOfUserError = action.payload?.data?.data
  },

  //Themes
  [getTheme]: state => {
    state.getTheme = true
  },
  [getThemeSuccess]: (state, action) => {
    state.theme = action.payload || 'light'
    state.gettingTheme = false
    state.getThemeSuccess = true
  },
  [getThemeError]: (state, action) => {
    state.gettingTheme = false
    state.getThemeError = action.payload
  },

  [setThemeSuccess]: (state, action) => {
    state.theme = action.payload
    localStorage.setItem('theme', action.payload)
  },

  [clearOads]: state => {
    state.ads = []
    state.uadsFinished = false
  },

  [logout]: state => {
    state.theme = 'light'
  },
})

export default userReducer
