import React from 'react'
import ContentLoader from 'react-content-loader'
import { v4 as uuidv4 } from 'uuid'
import { randomArray } from '../../utils'

const CategoryLoader = props => {
  const { numOfArray = 4, ...rest } = props

  return (
    <>
      {randomArray(numOfArray).map(item => (
        <div className="res--loader" key={uuidv4()}>
          <ContentLoader
            speed={2}
            width={400}
            height={50}
            viewBox="0 0 400 50"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...rest}
          >
            <rect x="70" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="72" y="30" rx="3" ry="3" width="52" height="6" />
            <circle cx="27" cy="23" r="20" />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}

export default CategoryLoader
