import { Link } from 'react-router-dom'
import { BoostIcon } from '../../../icons'

const BoostLink = ({ classname = '' }) => {
  const HBSelectTab = () => {
    return (
      <div className={`card__hbSelect ${classname}`}>
        <BoostIcon className='small' />
      </div>
    )
  }
  return (
    <>
      <Link to='/boost' target='_blank'>
        <HBSelectTab />
      </Link>
    </>
  )
}
export default BoostLink
