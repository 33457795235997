import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { useFetch } from '../hooks'
import { ALL_CATEGORIES_URL } from '../constants'

export const GetCategory = (isSingle = false, cId = '', pcId = '') => {
  const [categoryData, setCategoryData] = useState({})

  const { data, isLoading } = useFetch('ALL_CATEGORIES', ALL_CATEGORIES_URL)

  useEffect(() => {
    if (!isEmpty(data?.data)) {
      if (isSingle) {
        let singleCategory
        if (pcId && cId) {
          singleCategory = data?.data
            ?.filter(
              (category) =>
                category?.id?.replace(/-/g, '') === pcId?.replace(/-/g, '')
            )?.[0]
            ?.categories?.filter(
              (cat) => cat?.id?.replace(/-/g, '') === cId?.replace(/-/g, '')
            )[0]
        }
        if (pcId && !cId) {
          singleCategory = data?.data?.filter(
            (category) =>
              category?.id?.replace(/-/g, '') === pcId?.replace(/-/g, '')
          )?.[0]
        }
        return setCategoryData(singleCategory)
      }
      return setCategoryData(data?.data)
    }

    return () => {}
  }, [pcId, cId, data?.data, isLoading, isSingle])

  return { data, isLoading, categoryData }
}
