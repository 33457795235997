import { put, takeLatest, all } from 'redux-saga/effects'

import { setTabHome, setTabHomeSucess } from '../reducers/tabReducers'

function* setTabHomeSaga(action) {
  try {
    yield put(setTabHomeSucess(action.payload))
  } catch (error) {
    console.log(error)
  }
}

export default function* tabSaga() {
  yield all([takeLatest(setTabHome, setTabHomeSaga)])
}
