import React from 'react'
import { Slider } from 'rsuite'
import { Controller } from 'react-hook-form'

export const RangeInput = ({
  control,
  name,
  label,
  min,
  max,
  disabled = false
}) => {
  return (
    <div className='form-item pos-rel ranger-slider '>
      <label>{label}</label>
      <div className='slider-container'>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Slider
                min={min}
                max={max}
                onChange={(val) => onChange(val)}
                value={value}
                defaultValue={value}
                disabled={disabled}
              />
            )
          }}
        />
      </div>
    </div>
  )
}
