import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const saveWishApi = async (pid) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/User/SaveProductWishlist?ProductId=${pid}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    }
  )
  return res
}

export const removeWishApi = async (pid) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/User/DeleteProductWishlist?ProductId=${pid}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    }
  )
  return res
}

export const getWishApi = async (page) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/User/GetWishlistByUser?PageNumber=${page}&PageSize=10`
  )
  return res
}

export const getCartApi = async (uid) => {
  const res = await axiosInstance().get(`${baseAPI}/api/Cart/User?id=${uid}`)
  return res
}

export const addCartApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const cartPlayload = {
    userId: data?.userId,
    cartProducts: data?.cartProducts
  }
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const jdata = JSON.stringify({
    ...cartPlayload,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Cart/Add `,
    jdata,
    config
  )
  return res
}
