import React, { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

import { getCategoryAds } from '../../../../Redux/reducers/advertisementReducers'

import Footer from '../../../layout/Footer'
import { Sidebar } from '../../../index'
import BrandTags from '../../../Ads/BrandTags'
import Filter from '../../../filter/Filter'
import { StickyFilter } from '../../../filter/StickyFilter'
import { ReactComponent as FilterIcon } from '../../../../icons/common/filter.svg'

import UnreadCounter from '../../../common/UnreadCounter'
import GAdsTop from '../../../Ads/GAdsTop'

import Gotop from '../../../common/utils/Gotop'
import { StickyComponent } from '../../../common/StickyComponent'
import { throttle } from '../../../../utils/functions'
import { arrangeAdsFromApiResponse } from '../../../../utils/ads'
import SideBarAds from '../../../Ads/SideBarAds'
import RoadBlock from '../../../Ads/RoadBlock'
import { useToggle, useProduct, useToggleOverflow } from '../../../../hooks'
import { dynamicTitle } from '../../../../utils'
import { GetCategory } from '../../../../helpers'
import { useWindowDimensions } from '../../../../hooks'
import { useTabToggle } from '../../../layout/HomeProducts/useTabToggle'

import { ProductList } from '../../../product'
import { GRID_VIEW, LINEAR_VIEW } from '../../../../constants'
import { LinearIcon, GridIcon } from '../../../../icons'

const CategorySearchResult = ({
  searchAd,
  match,
  categoryAds,
  getCategoryAds
}) => {
  const [showFilter, setShowFilter] = useToggle(false, true)

  const [TopAds, setTopAds] = useState([])
  const [RightBoxAds, setRightBoxAds] = useState([])
  const [InFeedAds, setInFeedAds] = useState([])
  const [roadBlockAd, setRoadBlockAd] = useState()
  const [showRoadBlockAd, setShowRoadBlockAd] = useState(true)
  const [filterPayload, setFilterPayload] = useState()
  const [height, setHeight] = useState(0)
  const heightRef = useRef(height)
  const windowDimensions = useWindowDimensions()
  const [view, toggleView] = useTabToggle(LINEAR_VIEW)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setHeight(heightRef.current?.clientHeight)
  })
  const { scrollRef, isSticky } = StickyFilter(false, height)

  const { categoryData, isLoading: isCategoryLoading } = GetCategory(
    true,
    match.params.cid,
    match.params.pcid
  )

  const topSwitch = useRef(null)

  useEffect(() => {
    setFilterPayload({
      category:
        match.params.pcid && match.params.cid
          ? match.params.cid
          : match.params.pcid
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.pcid, match.params.cid])

  useEffect(() => {
    if (!(categoryAds?.length > 0)) getCategoryAds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (categoryAds?.length > 0) {
      const categoryAdv = categoryAds?.filter(
        (ad) => ad?.type?.toLowerCase() !== 'admob'
      )
      const { inFeedAds, rightBoxAds, topAds, roadBlockAd } =
        arrangeAdsFromApiResponse(categoryAdv || [])
      setInFeedAds(inFeedAds)
      setRightBoxAds(rightBoxAds)
      setTopAds(topAds)
      setRoadBlockAd(roadBlockAd)
    }
  }, [categoryAds])

  const toggleFilter = () => {
    setShowFilter((filter) => !filter)
  }

  useToggleOverflow(showFilter)

  const categoryId = match.params?.cid || match.params?.pcid

  const {
    productAds,
    isProductLoading,
    isProductError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useProduct(categoryId, match.params.pcid, filterPayload)

  const setBrands = (brands) => {
    if (brands?.length < 0) return
    const joined = brands.map((t) => t.id).join(', ')

    return setFilterPayload({
      ...filterPayload,
      brand: brands[0]?.id || '',
      brandIds: joined || '',
      isFilterApplied: joined?.length > 0 ? true : false
    })
  }

  useEffect(() => {
    const handler = throttle(() => {
      if (topSwitch.current !== null) {
        topSwitch.current.classList.toggle(
          'active',
          topSwitch.current.getBoundingClientRect().y < 30
        )
      }
    }, 300)
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])
  return (
    <RoadBlock
      ad={roadBlockAd}
      show={showRoadBlockAd}
      close={() => setShowRoadBlockAd(false)}
    >
      <div className='container search--page category--page'>
        <UnreadCounter
          title={
            !isCategoryLoading && !isEmpty(categoryData)
              ? `${categoryData?.name} - ${dynamicTitle(match)} ${
                  categoryData?.name
                } in Nepal now on hamrobazaar`
              : `Hamrobazar - Nepal's Online Marketplace for Shopping`
          }
        />
        <section
          className={`sliding--image ${
            TopAds?.[0]?.patternSettingCount === 2 ? '' : 'desktop--only'
          }`}
        >
          <GAdsTop ads={TopAds} />
        </section>
        <div className='hb__body '>
          {windowDimensions?.width > 768 ? (
            <Sidebar
              selectedCategory={match.params.pcid}
              selectedSubCategory={match?.params?.cid}
            />
          ) : (
            <aside
              className={`aside--categories cate--aside ${
                showFilter ? 'show' : ''
              }`}
            >
              <div className='side--filter'>
                <div className='filter--box'>
                  <div className='filter--head'>
                    <h4>Add Filter</h4>
                    <span onClick={toggleFilter}>
                      <i className='fal fa-times'></i>
                    </span>
                  </div>
                  <div className='filter--form'>
                    <Filter
                      match={match}
                      toggleFilter={toggleFilter}
                      showFilter={showFilter}
                      setFilterPayload={setFilterPayload}
                      filterPayload={filterPayload}
                    />
                  </div>
                </div>
              </div>
            </aside>
          )}
          <section className='home--listings' ref={scrollRef}>
            <div className='search--top sticky--part'>
              <div className='search--titles'>
                <h3>
                  Category :{' '}
                  {!isCategoryLoading && !isEmpty(categoryData) ? (
                    <>
                      {categoryData?.name}{' '}
                      {categoryData?.count !== 0 ? (
                        <span>({categoryData?.count})</span>
                      ) : null}
                    </>
                  ) : null}
                </h3>

                <div className='view-icon'>
                  {view === GRID_VIEW ? (
                    <LinearIcon
                      title='Grid view'
                      onClick={() => toggleView(LINEAR_VIEW)}
                    />
                  ) : view === LINEAR_VIEW ? (
                    <GridIcon
                      title='Linear view'
                      onClick={() => toggleView(GRID_VIEW)}
                    />
                  ) : null}
                </div>
                <span className='side--filter' onClick={toggleFilter}>
                  <FilterIcon />
                </span>
              </div>
              <div className='divider divider-horizontal' />
            </div>
            <ProductList
              data={productAds}
              isLoading={isProductLoading}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              feedAds={InFeedAds}
              isFetchingNextPage={isFetchingNextPage}
              isProductError={isProductError}
              view={view}
            />
          </section>
          {windowDimensions?.width > 1200 ? (
            <div className='divider divider-vertical divider-sidebar' />
          ) : null}

          <aside className='aside--ad'>
            <div className='sticky--part vh--part' ref={heightRef}>
              {windowDimensions?.width > 768 ? (
                <StickyComponent
                  setShow={setShowFilter}
                  isSticky={isSticky}
                  classname='stickyContainer'
                >
                  <Filter
                    match={match}
                    toggleFilter={toggleFilter}
                    showFilter={showFilter}
                    setFilterPayload={setFilterPayload}
                    filterPayload={filterPayload}
                    isSticky={isSticky}
                  />
                </StickyComponent>
              ) : null}

              {match.params?.cid && match.params?.pcid && (
                <BrandTags
                  id={
                    match.params?.cid && match.params?.pcid
                      ? match.params?.cid
                      : match.params?.pcid
                  }
                  match={match}
                  setBrands={setBrands}
                />
              )}
              {windowDimensions?.width > 768 ? (
                <>
                  <SideBarAds ads={RightBoxAds} />
                  <Footer className='common--pt' />
                </>
              ) : null}
            </div>
          </aside>
        </div>
        <Gotop />
      </div>
    </RoadBlock>
  )
}

const mapStateToProps = (state) => ({
  categoryAds: state.advertisement.categoryAds
})

const mapDispatchToProps = {
  getCategoryAds
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySearchResult)
