import { combineReducers } from 'redux'
import adsReducers from './adsReducers'
import authReducer from './authReducers'
import dealsReducers from './dealsReducers'
import cartwishlistReducers from './cartwishlistReducers'
import categoryReducers from './categoryReducers'
import alertReducers from './alertReducers'
import notificationReducers from './notificationReducers'
import searchReducers from './searchReducers'
import spinnerReducers from './spinnerReducers'
import userReducer from './userReducers'
import recommendationReducer from './recommendationReducer'
import chatReducers from './chatReducers'
import featureReducer from './featureReducer'
import advertisementReducer from './advertisementReducers'
import uiReducer from './uiReducers'

export default combineReducers({
  auth: authReducer,
  spinner: spinnerReducers,
  alert: alertReducers,
  ads: adsReducers,
  categories: categoryReducers,
  search: searchReducers,
  cartwish: cartwishlistReducers,
  noti: notificationReducers,
  deals: dealsReducers,
  user: userReducer,
  recommendation: recommendationReducer,
  chats: chatReducers,
  features: featureReducer,
  advertisement: advertisementReducer,
  ui: uiReducer,
})
