import { useState, useEffect, useRef } from 'react'

export const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(lowQualitySrc)
  const observerRef = useRef(null)

  useEffect(() => {
    setSrc(lowQualitySrc)
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setSrc(highQualitySrc)
            observer.disconnect()
          }
        })
      },
      { threshold: 0.1 }
    )
    observer.observe(observerRef.current)
    return () => observer.disconnect()
  }, [lowQualitySrc, highQualitySrc, observerRef])

  return [src, { blur: src === lowQualitySrc }, observerRef]
}
