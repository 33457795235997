import axios from 'axios'

import { getDeviceSource } from '../constants'
import { decryptObjData } from './encryptDecryptData'
import { refreshToken } from './refreshToken'

export const setAxiosDeviceId = (id) => {
  axios.defaults.headers.common['DeviceId'] = id

  const deviceSource = getDeviceSource()
  axios.defaults.headers.common['DeviceSource'] = deviceSource
}

export const axiosInstance = () => {
  const baseURL =
    process.env.REACT_APP_BASE_API_URI ?? 'https://api.hamrobazaar.com'

  let headers = {
    'Access-Control-Allow-Origin': '*',
    'Strict-Transport-Security': 'max-age=2592000',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'SAMEORIGIN'
  }

  axios.defaults.headers.common['country_code'] =
    window.localStorage.getItem('country_code')

  let localAuth = localStorage.getItem('user_auth')
  let decryptLocalAuth
  try {
    if (localAuth) {
      decryptLocalAuth = decryptObjData(localAuth)
      headers.Authorization = `Bearer ${decryptLocalAuth.value.access_token}`
    }
  } catch (error) {
    localStorage.removeItem('user_auth')
    window.location.reload()
  }
 

  const axiosInstance = axios.create({
    baseURL,
    headers
  })

  refreshToken(axiosInstance, decryptLocalAuth, headers)

  return axiosInstance
}
