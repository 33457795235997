export { ReactComponent as EyeClosed } from './card/eyeClosed.svg';
export { ReactComponent as HoldIcon } from './card/pause.svg';
export { ReactComponent as UnHoldIcon } from './card/play.svg';
export { ReactComponent as EditIcon } from './card/edit.svg';
export { ReactComponent as CloseIcon } from './common/close.svg';
export { ReactComponent as FlagIcon } from './user/flag.svg';
export { ReactComponent as MessageIcon } from './mobilenav/message.svg';
export { ReactComponent as CallIcon } from './common/call.svg';
export { ReactComponent as LOGOICON } from './common/logo-small.svg';
export { ReactComponent as BackIcon } from './common/back.svg';
export { ReactComponent as EllipsesIcon } from './card/verticalEllipses.svg';
export { ReactComponent as LocationIcon } from './common/location.svg';
export { ReactComponent as SecondLocationIcon } from './common/second-location .svg';
export { ReactComponent as ReviewIcon } from './profile/not-review.svg';
export { ReactComponent as GooglePlayStore } from './events/google_play_store.svg';
export { ReactComponent as IosAppStore } from './events/ios_app_store.svg';
export { ReactComponent as NoComment } from './chat/no-comment.svg';
export { ReactComponent as Ellipse } from './chat/send.svg';
export { ReactComponent as TrashIcon } from './user/trash.svg';
export { ReactComponent as ChatIcon } from './analytics/chat.svg';
export { ReactComponent as NotificationIcon } from './notification/notification.svg';
export { ReactComponent as MessageBox } from './chat/message.svg';
export { ReactComponent as BellIcon } from './mobilenav/notification.svg';
export { ReactComponent as NotificationActiveIcon } from './mobilenav/notificationActive.svg';
export { ReactComponent as PlusIcon } from './common/plus.svg';
export { ReactComponent as HBIcon } from './common/hbinside.svg';
export { ReactComponent as NoBlockList } from './profile/no-block.svg';
export { ReactComponent as NoProduct } from './profile/no-ad.svg';
export { ReactComponent as NoList } from './profile/no-list.svg';
export { ReactComponent as NoReview } from './profile/not-review.svg';
export { ReactComponent as BurgerLine } from './common/burgerLine.svg';
export { ReactComponent as HBLogo } from './common/logo.svg';
export { ReactComponent as HBFullLogo } from './common/fulllogo.svg';
export { ReactComponent as BoostIcon } from './common/boost-icon.svg';
export { ReactComponent as BoostFullIcon } from './common/boost-icon-full.svg';
export { ReactComponent as BulbIcon } from './postads/bulb.svg';
export { ReactComponent as CongratsIcon } from './postads/congrats.svg';
export { ReactComponent as BriefcaseIcon } from './common/briefcase.svg';
export { ReactComponent as MobileAppIcon } from './common/mobile-app-icon.svg';
export { ReactComponent as NormalSearchIcon } from './common/searchnormal1.svg';
export { ReactComponent as AddIcon } from './common/addsquare.svg';
export { ReactComponent as NotificatonIcon } from './common/notification.svg';
export { ReactComponent as Message } from './common/message2.svg';
export { ReactComponent as RightArrow } from './common/arrowright.svg';
export { ReactComponent as Contact } from './common/contact.svg';
export { ReactComponent as SettingIcon } from './profile/setting.svg';
export { ReactComponent as LogoutIcon } from './profile/logout.svg';
export { ReactComponent as ChevronIcon } from './common/chevron.svg';
export { ReactComponent as CategoryIcon } from './common/category-icon.svg';
export { ReactComponent as SaveIcon } from './common/save.svg';
export { ReactComponent as VerticalDotsIcon } from './common/dots-vertical.svg';
export { ReactComponent as BookmarkIcon } from './common/BookmarkIcon.svg';
export { ReactComponent as TrendingIcon } from './common/trending-icon.svg';
export { ReactComponent as SavedIcon } from './common/saved-icon.svg';
export { ReactComponent as MaximizeIcon } from './common/maximize.svg';
export { ReactComponent as GridIcon } from './common/grid.svg';
export { ReactComponent as LinearIcon } from './common/linear-icon.svg';
export { ReactComponent as RecommendedIcon } from './common/recommended-icon.svg';
export { ReactComponent as NepalFlag } from './common/nepal-flag.svg';
export { ReactComponent as UaeFlag } from './common/uae-flag.svg';
export { ReactComponent as PlayIcon } from './common/play.svg';
export { ReactComponent as BusinessIcon } from './common/hbBusiness.svg';
export { ReactComponent as MarketingIcon } from './common/marketingIcon.svg';
export { ReactComponent as HBSelectIcon } from './common/hbicon.svg';
export { ReactComponent as EmailIcon } from './common/sms.svg';
export { ReactComponent as FlashIcon } from './common/flash.svg';
export { ReactComponent as TrendUpIcon } from './common/trendup.svg';
export { ReactComponent as CalculatorBroIcon } from './Calculator-bro.svg';
