import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { useFetch } from '../../hooks'
import { CATEGORY_ATTRIBUTE } from '../../constants'

/**
 * This function is used to fetch the product attributes based on the category id
 * @param categoryId - The category id of the product
 * @param textChange - This is the object that contains the text change event.
 */
export const useProductAttribute = (categoryId = '', textChange = {}) => {
  const [productAttributes, setProductAttributes] = useState([])
  const [deliveryDataAttributes, setDeliveryDataAttributes] = useState([])

  const { data, isLoading, isError } = useFetch(
    'GET_CATEGORY_ATTRIBUTE',
    `${CATEGORY_ATTRIBUTE}/?CategoryId=${categoryId}`,
    !!categoryId,
    { categoryId }
  )

  useEffect(() => {
    if (!isLoading)
      if (!isEmpty(data?.data)) {
        return setProductAttributes(
          data?.data?.filter((item) => !item.displayName?.includes('Delivery'))
        )
      }
    return setProductAttributes([])
  }, [data?.data, isLoading])

  useEffect(() => {
    if (!isLoading)
      if (!isEmpty(data?.data)) {
        const deliveryAttributeData = data?.data?.filter((item) =>
          item.displayName?.includes('Delivery')
        )
        if (textChange?.delivery && !isEmpty(deliveryAttributeData))
          setDeliveryDataAttributes(deliveryAttributeData)
      }
  }, [data?.data, isLoading, textChange?.delivery])

  return { productAttributes, deliveryDataAttributes, data, isLoading, isError }
}
