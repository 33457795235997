import { useContext } from 'react'
import { OpenStreetmapDispatcher } from './OpenStreetMap'

export const MapFooter = ({ confirmLocation, setShowLocation }) => {
  return (
    <div className='button--group'>
      <button
        type='button'
        className='btn btn--form cancel'
        onClick={() => setShowLocation(false)}
      >
        Cancel
      </button>
      <button type='button' className='btn btn--form' onClick={confirmLocation}>
        Confirm Location
      </button>
    </div>
  )
}
