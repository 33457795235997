import { useRef, useState, useEffect } from 'react'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

export const StickyComponent = ({ setShow, children, isSticky, classname }) => {
  const { width } = useWindowDimensions()
  const [classRef, setClassRef] = useState('')
  const stickyRef = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setClassRef(stickyRef.current?.className)
  })

  useEffect(() => {
    if (width > 1200)
      if (classRef === classname) {
        return setShow(false)
      }

    if (classRef === '') {
      return setShow(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classRef, width])

  return (
    <>
      {width > 1200 ? (
        <div className={`${isSticky ? classname : ''}`} ref={stickyRef}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
