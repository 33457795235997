import { put, call, takeLatest, all } from 'redux-saga/effects'
import { getRecommendationApi } from '../../api/recommendationApi'
import {
  gettingRecommendation,
  recommendationSuccess,
  recommendationError,
  getmoreRecommendation,
  getmoreRecommendationSuccess,
  getmoreRecommendationError,
} from '../reducers/recommendationReducer'

//Load deals for Homepage
function* getRecommend(action) {
  try {
    let res = yield call(getRecommendationApi, action.payload)
    yield put(recommendationSuccess(res))
  } catch (error) {
    yield put(recommendationError(error))
  }
}

/**
 * More recomendation loader
 */
function* getTheMoreRecommendation(action) {
  try {
    let res = yield call(getRecommendationApi, action.payload)
    yield put(getmoreRecommendationSuccess(res))
  } catch (error) {
    yield put(getmoreRecommendationError(error))
  }
}

//The auth Watchers
export default function* adsSaga() {
  yield all([
    takeLatest(gettingRecommendation, getRecommend),
    takeLatest(getmoreRecommendation, getTheMoreRecommendation),
  ])
}
