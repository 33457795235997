import { AD_SLOT, AD_TYPE } from '../constants'

const NUMBER_OF_ADSENCE = 10

export function arrangeAdsFromApiResponse(ads) {
  const adscenceItem = ads?.find(
    (item) => item?.type?.toLowerCase() === AD_TYPE?.ADSENCE
  )
  const concatedAds = ads?.concat(Array(NUMBER_OF_ADSENCE).fill(adscenceItem))

  const inFeedAds = []
  const inFeed = concatedAds?.filter(
    (item) => item?.slotName?.toLowerCase() === AD_SLOT?.IN_BETWEEN
  )
  if (inFeed && inFeed?.length > 0) {
    inFeed.forEach((ad) => {
      if (ad.patternSettingCount === 10) {
        const lastSlotTenEntry = inFeedAds.find((f) =>
          f.some((item) => item.patternSettingCount === 10)
        )
        if (!lastSlotTenEntry) {
          inFeedAds.push([ad])
        } else {
          lastSlotTenEntry.push(ad)
        }
      } else {
        inFeedAds.push([ad])
      }
    })
  }
  const rightBoxAds = ads.filter((item) => item.slotName === 'right_box')
  const topAds = ads.filter((item) => item.slotName === 'top_banner')
  const roadBlockAd = ads.filter((item) => item.slotName === 'road_block')

  return {
    roadBlockAd: roadBlockAd[0],
    inFeedAds,
    rightBoxAds,
    topAds
  }
}
