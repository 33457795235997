import { forwardRef } from 'react'

export const Checkbox = forwardRef((props, ref) => {
  return (
    <div className='form-group'>
      <div className='options'>
        <div className='options-item'>
          <div className='option-info'>
            <h2>{props?.title}</h2>
            {props?.description && <p>{props?.description}</p>}
          </div>
          <div className='option-radio'>
            <label className='switch' htmlFor={props?.name}>
              <input type='checkbox' ref={ref} {...props} />
              <div className='slider round'></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
})

export const MultiSelectCheckbox = forwardRef((props, ref) => {
  const { label, ...rest } = props
  return (
    <label key={rest?.value}>
      <input type='checkbox' {...rest} ref={ref} />
      {label}
    </label>
  )
})
