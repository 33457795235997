import { createAction, createReducer } from '@reduxjs/toolkit'
import { encryptData, decryptObjData } from '../../utils/encryptDecryptData'

export const login = createAction('LOGIN/REQUEST')
export const loginSuccess = createAction('LOGIN_SUCCESS/RESPONSE')
export const loginFailed = createAction('LOGIN_FAILED/RESPONSE')
export const logout = createAction('LOGOUT')
export const loadUser = createAction('LOAD_USER/REQUEST')
export const loadUserSuccess = createAction('LOAD_USER_SUCCESS/RESPONSE')
export const loadUserFail = createAction('LOAD_USER_FAIL/RESPONSE')

export const signup = createAction('SIGNUP/REQUEST')
export const signupSuccess = createAction('SIGNUP_SUCCESS/RESPONSE')
export const signupFailed = createAction('SIGNUP_FAILED/RESPONSE')
export const loginResponseSucess = createAction(
  'LOGIN_RESPONSE_SUCCESS/RESPONSE'
)

export const verifyOtp = createAction('VERIFY_OTP/REQUEST')
export const resendOtp = createAction('RESEND_OTP')
export const verifyOtpSuccess = createAction('VERIFY_OTP_SUCCESS/RESPONSE')
export const verifyOtpFailed = createAction('VERIFY_OTP_FAILED/RESPONSE')

export const getProfile = createAction('GET_PROFILE/REQUEST')
export const getProfileSuccess = createAction('GET_PROFILE_SUCCESS/RESPONSE')
export const getProfileFailed = createAction('GET_PROFILE_FAILED/RESPONSE')

export const updateProfile = createAction('UPD_GET_PROFILE/REQUEST')
export const updateProfileSuccess = createAction(
  'UPD_GET_PROFILE_SUCCESS/RESPONSE'
)
export const updateProfileFailed = createAction(
  'UPD_GET_PROFILE_FAILED/RESPONSE'
)

export const updatePassword = createAction('UPD_PASSWORD/REQUEST')
export const updatePasswordSuccess = createAction(
  'UPD_PASSWORD_SUCCESS/RESPONSE'
)
export const updatePasswordFailed = createAction('UPD_PASSWORD_FAILED/RESPONSE')

export const forgotPassword = createAction('FORGOT_PSWD/REQ')
export const forgotPasswordSuccess = createAction('FORGOT_PSWD/RES')
export const forgotPasswordError = createAction('FORGOT_PSWD_ERROR/RES')
export const forgotPasswordToInitialState = createAction('FORGOT_PSWD_RESET')

export const resetPassword = createAction('RESET_PSWD/REQ')
export const resetPasswordSuccess = createAction('RESET_PSWD/RES')
export const resetPasswordError = createAction('RESET_PSWD_ERR/RES')
export const resetPasswordToInitialState = createAction(
  'RESET_PASSWORD_INITIAL_STATE'
)

export const postingDP = createAction('POST_DP/REQ')
export const postingDPSuccess = createAction('POST_DP_SUCCESS/RES')
export const postingDPError = createAction('POST_DP_ERROR/RES')

export const getToken = createAction('REFRESH_TOKEN/REQ')
export const getTokenSuccess = createAction('REFRESH_TOKEN_SUCCESS/RES')
export const getTokenError = createAction('REFRESH_TOKEN_ERROR/RES')

export const getChatToken = createAction('GET_CTOKEN/REQ')
export const getChatTokenSuccess = createAction('GET_CTOKEN_SUCCESS/RES')
export const getChatTokenError = createAction('GET_CTOKEN_FAIL/RES')

export const updateUser = createAction('UPDT_USER')

export const firebaseLogged = createAction('FIREBASE_AUTH')

const initialState = {
  token: null,
  isAuthenticated: false,
  loading: true,
  user: {},

  // This is for sent the user to signup page on mannual hitting /otp
  signupStep: false,

  // LoginResponse is for storing the login credentials
  loginResponse: null,

  // If otpSuccess then sent the user to login page.
  otpSuccess: false,
  firebaseAuth: false,

  profile: {},
  getProfile: false,
  getProfileSuccess: false,
  getProfileFailed: null,

  forgotPasswordSuccess: false,
  forgotPasswordError: [],
  resetPasswordSuccess: false,
  resetPasswordError: [],

  chatAuthToken: null,
  gettingChatToken: false,

  postingDP: false,
  postingDPSuccess: false,
  postingDPError: null,
}

const authReducers = createReducer(initialState, {
  [login]: state => {
    state.loading = true
  },
  [loginSuccess]: (state, action) => {
    const item = {
      value: action.payload?.data?.data,
      expiry: action.payload?.data?.data?.tokenExpiry,
    }

    localStorage.setItem('user_auth', encryptData(item))
    state.token = action.payload?.data?.data?.access_token
    state.isAuthenticated = true
    state.loading = false
    state.user = action.payload?.data?.data
    state.chatAuthToken = action.payload?.data?.data?.messageToken
  },
  [loginFailed]: (state, action) => {
    state.loading = false
    state.isAuthenticated = false
  },

  [firebaseLogged]: state => {
    state.firebaseAuth = true
  },

  [updateUser]: (state, action) => {
    state.user.username = action.payload?.username
    state.user.fullName = action.payload?.fullName
    //Updating localStorage
    const localAuth = decryptObjData(localStorage.getItem('user_auth'))
    localAuth.value.username = action.payload?.username
    localAuth.value.fullName = action.payload?.fullName
    localStorage.setItem('user_auth', encryptData(localAuth))
  },

  //REfresh token
  [getTokenSuccess]: (state, action) => {
    state.token = action?.payload?.data?.data?.access_token
    state.user = action?.payload?.data?.data
    //Updating token in localStorage
    const item = {
      value: action.payload?.data?.data,
      expiry: action.payload?.data?.data?.tokenExpiry,
    }
    localStorage.setItem('user_auth', encryptData(item))
  },

  [loadUser]: state => {
    state.loading = true
  },
  [loadUserSuccess]: (state, action) => {
    state.token = action.payload?.access_token
    state.isAuthenticated = true
    state.loading = false
    state.chatAuthToken = action.payload?.messageToken
    state.user = action?.payload
  },
  [loadUserFail]: state => {
    state.isAuthenticated = false
    state.loading = false
    state.user = {}
  },
  [signupSuccess]: state => {
    // state.isAuthenticated = true;
    state.signupStep = true
    state.loading = false
  },
  [signupFailed]: state => {
    state.isAuthenticated = false
    state.signupStep = false
    state.loading = true
  },
  [logout]: state => {
    localStorage.removeItem('user_auth')
    localStorage.removeItem('ctoken')
    localStorage.removeItem('theme')
    localStorage.removeItem('latitude')
    localStorage.removeItem('longitude')
    localStorage.removeItem('searchBarLatitude')
    localStorage.removeItem('searchBarLongitude')
    state.token = null
    state.isAuthenticated = false
    state.profile = {}
    state.loading = false
    state.user = []
    state.firebaseAuth = false
    state.gettingChatToken = true
    state.chatAuthToken = ''
  },

  [getProfile]: state => {
    state.getProfile = true
  },
  [getProfileSuccess]: (state, action) => {
    state.profile = action?.payload?.data?.data
    state.getProfile = false
    state.getProfileSuccess = true
  },
  [getProfileFailed]: (state, action) => {
    state.getProfile = false
    state.getProfileSuccess = false
    state.getProfileFailed = action?.payload?.data?.data
  },
  [verifyOtpSuccess]: state => {
    state.signupStep = false
    state.user.phoneNumberConfirmed = true
    state.otpSuccess = true
  },
  [loginResponseSucess]: (state, action) => {
    state.loginResponse = action.payload
  },
  [forgotPasswordToInitialState]: state => {
    state.forgotPasswordSuccess = false
  },
  [resetPasswordToInitialState]: state => {
    state.resetPasswordError = []
    state.resetPasswordSuccess = false
  },
  [forgotPasswordSuccess]: state => {
    state.forgotPasswordSuccess = true
    state.resetPasswordError = []
  },
  [resetPasswordSuccess]: state => {
    state.resetPasswordSuccess = true
    state.forgotPasswordSuccess = false
    state.resetPasswordError = []
  },
  [resetPasswordError]: (state, action) => {
    state.resetPasswordSuccess = false
    state.forgotPasswordSuccess = false
    state.resetPasswordError = action?.payload?.data?.status?.message
  },

  [getChatToken]: state => {
    state.gettingChatToken = true
  },
  [getChatTokenSuccess]: (state, action) => {
    localStorage.setItem(
      'ctoken',
      action?.payload?.data?.data?.userToken || action?.payload
    )
    state.chatAuthToken =
      action?.payload?.data?.data?.userToken || action?.payload
    state.gettingChatToken = false
  },
  [getChatTokenError]: state => {
    state.gettingChatToken = false
  },

  //Updating DP
  [postingDP]: state => {
    state.postingDP = true
  },
  [postingDPSuccess]: (state, action) => {
    state.postingDP = false
    state.postingDPSuccess = true

    state.user.profileImgKey = action.payload?.locationKey
    //Updating localStorage
    const localAuth = decryptObjData(localStorage.getItem('user_auth'))
    localAuth.value.profileImgKey = action.payload?.locationKey
    localStorage.setItem('user_auth', encryptData(localAuth))
  },
  [postingDPError]: (state, action) => {
    state.postingDP = false
    state.postingDPSuccess = false
    state.postingDPError = action?.payload?.data?.data
  },
})

export default authReducers
