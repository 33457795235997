import { useRef } from 'react'

import { useProgressiveImg } from '../../hooks'
import { convertImgType } from '../../helpers'
import { NO_IMAGE_THUMNAIL } from '../../constants'

export const ProgressiveImg = (props) => {
  let { previewURL, largeImageURL, alt, ...rest } = props
  const ref = useRef(null)

  const [src, { blur }, observerRef] = useProgressiveImg(
    previewURL,
    largeImageURL
  )

  const default_image = 'https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/NoImage.png'

  return (
    <div className='image-container' ref={ref}>
      <img
        src={src}
        ref={observerRef}
        style={{
          filter: blur ? 'blur(20px)' : 'none',
          transition: blur ? 'none' : 'filter 0.3s ease-out'
        }}
        alt={alt ?? 'hamrobazar'}
        {...rest}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = default_image
        }}
        onLoad={() => (previewURL = largeImageURL)}
      />
    </div>
  )
}
