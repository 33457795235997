import { randomArray, uid } from '../../utils'
import { BulletListSkeleton } from './presets'

export const CategorySkeleton = (props) => {
  const { numOfArray = 4, ...rest } = props

  return (
    <>
      {randomArray(numOfArray).map(() => (
        <BulletListSkeleton key={uid()} {...rest} />
      ))}
    </>
  )
}
