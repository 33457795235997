import React, { useState, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

import SearchLocation from '../map/SearchLocation'
import { SelectInput, BetweenInput, RangeInput } from '../common/InputField'
import { useWindowDimensions, useFetch } from '../../hooks'
import { scrollToTop } from '../../helpers'

import { FILTERABLE_ATTRIBUTES } from '../../constants'

import { AdvanceFilter } from './AdvanceFilter'
import { advanceFilterParams } from './advanceFilterParams'
import { resetFilterData } from './resetFilterData'

import { ReactComponent as FilterIcon } from '../../icons/common/filter.svg'
import { ReactComponent as DownIcon } from '../../icons/common/down.svg'

const Filter = ({
  isSearchResult = false,
  categories,
  match,
  showFilter,
  toggleFilter,
  setFilterPayload,
  filterPayload,
  isSticky = false
}) => {
  const location = useLocation()
  const windowDimensions = useWindowDimensions()
  const categoryId = location?.pathname?.split('/')
  const [watchFields, setWatchFields] = useState()
  const [subcategory, setSubcategory] = useState('')
  const [resetLocation, setResetLocation] = useState()
  const [CategoryId, setCategoryId] = useState(
    categoryId?.[4] ?? categoryId?.[3]
  )
  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
    setValue,
    formState: { isSubmitted, isDirty }
  } = useForm({ mode: 'all' })

  useEffect(() => {
    if (categoryId?.[4] ?? categoryId?.[3]) {
      setCategoryId(categoryId?.[4] ?? categoryId?.[3])
    }
  }, [categoryId])

  const { data: advanceFilterData, isLoading } = useFetch(
    `GET_FILTERABLE_ATTRIBUTES`,
    FILTERABLE_ATTRIBUTES + CategoryId,
    !!CategoryId,
    { CategoryId }
  )

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setWatchFields(value, name, type)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (getValues('category')) {
      const a = categories.filter(cat => {
        return cat.id === getValues('category')
      })
      setSubcategory(a[0].categories)
      setCategoryId()
    } else {
      setSubcategory('')
    }
    if (getValues('subcategory')) {
      setCategoryId(getValues('subcategory'))
    } else {
      setCategoryId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('category'), getValues('subcategory')])

  const toggleFilterForm = () => {
    if (windowDimensions?.width <= 1200 && toggleFilter) return toggleFilter()
    return
  }

  const filterSubmit = filterData => {
    const { category, subcategory, searchByDistance, ...rest } = filterData
    const advFilter = advanceFilterParams(
      advanceFilterData?.data,
      rest,
      setValue
    )

    const searchRadius =
      rest?.location === undefined
        ? 0
        : rest?.location && searchByDistance === 0
        ? 1500
        : searchByDistance

    const filterParams = {
      ...filterPayload,
      ...rest,
      searchByDistance: searchRadius,
      category: subcategory ? subcategory : category ? category : '',
      advanceFilter: `${advFilter}`,
      longitude: rest?.location?.locationLongitude || 0,
      latitude: rest?.location?.locationLatitude || 0,
      isFilterApplied: true
    }

    setFilterPayload(filterParams)
    toggleFilterForm()
    scrollToTop()
  }

  const resetFilter = () => {
    setResetLocation(true)
    setFilterPayload({
      searchValue: filterPayload?.searchValue || '',
      isFilterApplied: isSearchResult ? true : false
    })
    resetFilterData(advanceFilterData, reset)
    scrollToTop()
    // toggleFilterForm()
  }

  useEffect(() => {
    resetFilter()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.pcid, match?.params?.cid])

  useEffect(() => {
    if (
      watchFields?.sortParam &&
      watchFields?.sortParam !== '0' &&
      watchFields?.sortParam !== undefined &&
      windowDimensions?.width > 768
    ) {
      const filterParams = {
        ...filterPayload,
        sortParam: `${watchFields?.sortParam}`,
        isFilterApplied: true
      }
      scrollToTop()
      setFilterPayload(filterParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions?.width, watchFields?.sortParam])

  return (
    <div className='filter--box'>
      <form onSubmit={handleSubmit(filterSubmit)}>
        {windowDimensions?.width > 768 ? (
          <div className='filter--head'>
            <h4 role='button' onClick={toggleFilter}>
              Add Filter <FilterIcon />
            </h4>
            <div className='form-item sort--desktop'>
              <select
                className='input'
                {...register('sortParam')}
                defaultValue='0'
              >
                <option value='0'>Sort Ads by</option>
                <option value='1'>A to Z</option>
                <option value='2'>Z to A</option>
                <option value='3'>Low to High (Price)</option>
                <option value='4'>High to Low (Price)</option>
                <option value='5'>Recent</option>
                <option value='6'>Older</option>
              </select>
              <DownIcon className='ab__svg' />
            </div>
          </div>
        ) : null}

        <div
          className={`filter--form ${showFilter ? 'show' : 'hide'} ${
            isSticky ? 'stickyFilter' : ''
          }`}
        >
          {windowDimensions?.width < 768 && (
            <SelectInput
              placeholder='Select Category'
              defaultValue='0'
              displayLabel={false}
              options={[
                { value: '0', label: 'Sort Ads by' },
                { value: '1', label: 'A to Z' },
                { value: '2', label: 'Z to A' },
                { value: '3', label: 'Low to High (Price)' },
                { value: '4', label: 'High to Low (Price)' },
                { value: '5', label: 'Recent' },
                { value: '6', label: 'Older' }
              ]}
              {...register('sortParam')}
            />
          )}
          <SelectInput
            placeholder='Condition'
            displayLabel={false}
            defaultValue='0'
            label={<label htmlFor=''>Condition</label>}
            options={[
              { value: 0, label: 'Any' },
              { value: 1, label: 'Brand New' },
              { value: 2, label: 'Like New' },
              { value: 3, label: 'Used' },
              { value: 4, label: 'Not Working' }
            ]}
            {...register('condition')}
          />

          <div className='form-item form-item--location'>
            <label htmlFor='location'>Location</label>
            <Controller
              control={control}
              name='location'
              render={({ field: { onChange, value } }) => (
                <SearchLocation
                  setResetLocation={setResetLocation}
                  resetLocation={resetLocation}
                  inSearchBar={false}
                  onChangeLocationPicker={onChange}
                  placeholder={value?.locationDescription ?? ''}
                  setLocationValue={setValue}
                  location={value}
                />
              )}
            />
          </div>
          <RangeInput
            control={control}
            name='searchByDistance'
            label='Location Radius (500m to 10000m)'
            min={500}
            max={10000}
            disabled={watchFields?.location === undefined ? true : false}
          />

          <BetweenInput
            register={register}
            fromField={watchFields?.priceFrom}
            toField={watchFields?.priceTo}
            fieldValueSign='रु'
            label={<label htmlFor=''>Price Range</label>}
            inputFieldElement={[
              {
                type: 'number',
                name: 'priceFrom',
                placeholder: 'Min',
                validation: { required: false },
                defaultValue: ''
              },
              {
                type: 'number',
                name: 'priceTo',
                placeholder: 'Max',
                defaultValue: '',
                validation: {
                  required: watchFields?.priceFrom?.length > 0 ? true : false
                }
              }
            ]}
          />
          <SelectInput
            placeholder='Negotiable'
            name='isPriceNegotiable'
            defaultValue=''
            displayLabel={false}
            label={<label htmlFor=''>Negotiable</label>}
            options={[
              { value: '', label: 'Any' },
              { value: 1, label: 'Yes' },
              { value: 2, label: 'Fixed Price' }
            ]}
            {...register('isPriceNegotiable')}
          />

          {isSearchResult && (
            <>
              <SelectInput
                placeholder='Category'
                name='category'
                defaultValue=''
                displayLabel={false}
                label={<label htmlFor=''>Category</label>}
                options={[
                  { value: '', label: 'Category' },
                  ...categories.map(cat => ({
                    value: cat.id,
                    label: cat.name
                  }))
                ]}
                {...register('category', {
                  onChange: () => setValue('subcategory', '')
                })}
              />

              {subcategory?.length !== 0 && (
                <SelectInput
                  placeholder='SubCategory'
                  name='subcategory'
                  defaultValue=''
                  displayLabel={false}
                  label={<label htmlFor=''>SubCategory</label>}
                  options={[
                    { value: '', label: 'Sub Category' },
                    ...subcategory.map(cat => ({
                      value: cat.id,
                      label: cat.name
                    }))
                  ]}
                  {...register('subcategory')}
                />
              )}
            </>
          )}

          {!isLoading &&
            CategoryId?.length > 0 &&
            !isEmpty(advanceFilterData?.data) && (
              <AdvanceFilter
                register={register}
                categoryId={CategoryId}
                watchFields={watchFields}
                name={match?.params?.name}
                control={control}
                advanceFilterData={advanceFilterData?.data}
              />
            )}

          <div className='form-item form-item--filterBtn'>
            <button
              className='btn'
              disabled={
                isDirty
                  ? Number(watchFields?.priceFrom) >=
                    Number(watchFields?.priceTo)
                    ? watchFields?.priceFrom === '' &&
                      watchFields?.priceTo === ''
                      ? false
                      : true
                    : false
                  : true
              }
            >
              <strong>Filter</strong>
            </button>

            {isSubmitted && (
              <button
                className='btn'
                type='submit'
                onClick={() => resetFilter()}
              >
                <strong>Reset Filter</strong>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
const mapStateToProps = state => ({
  categories: state.categories.categories,
  searchAd: state.search
})

export default connect(mapStateToProps)(Filter)
