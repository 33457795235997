const AdImage = ({ src }) => {
  return (
    <object data={src} type="image/png" style={{ width: '20em' }}>
      <img
        src="https://hamrobazaar.blr1.cdn.digitaloceanspaces.com/Assets/NoImage.png"
        alt="Not found."
      />
    </object>
  )
}

export default AdImage
