import { toast } from 'react-toastify'
import loadImage from 'blueimp-load-image'
import { compress } from 'image-conversion'

/**
 *
 * @param {string} setLoaderImage
 * @param {function(string)} uploadImage
 * @param {event} e
 *
 * It takes state to store a image , reducer and onChange event to store a image
 * It return a function to upload a image
 */

const addImage = (
  setLoaderImage = () => {},
  uploadImage,
  e,
  setDeleteState
) => {
  e.preventDefault()
  const filesArray = Array.from(e.target.files)
  filesArray.forEach(async (file, i) => {
    if (file) {
      setDeleteState && setDeleteState(false)
      let size = file.size / 1024 / 1024
      if (size < 25) {
        let reader = new FileReader()
        reader.onload = (a) => {
          if (a) {
            setLoaderImage(a.target.result)
            loadImage(file, {
              maxHeight: 768,
              maxWidth: 1024,
              canvas: true
            })
              .then(function (data) {
                return new Promise(function (resolve) {
                  data.image.toBlob(function (blob) {
                    data.blob = blob
                    resolve(data)
                  }, 'image/jpeg')
                })
              })
              .then(async function (blob) {
                let compressedImageBlob = await compress(blob.blob, {
                  quality: 0.7,
                  type: 'image/jpeg'
                })
                uploadImage(compressedImageBlob)
              })
              .catch((err) => {
                if (err instanceof TypeError) {
                  toster('Try another Image')
                }
              })
          }
        }
        reader.readAsDataURL(file)
      } else {
        toster('Image size should be less than 25 MB or try app')
      }
    }
  })
}

export default addImage

const toster = (msg) => {
  return toast.success(msg, {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: 'toast-background'
  })
}
