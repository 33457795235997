import { popToast } from '../components/common/PopToast'

function copy(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy)
  } else {
    let textArea = document.createElement('textarea')
    textArea.value = textToCopy
    textArea.style.display = 'none'
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej()
      textArea.remove()
    })
  }
}

export function copyToClipboard(text, setShowModal) {
  copy(text)
    .then(() => {
      popToast('Linked Copied!', 'bottom_right')
      setShowModal('')
    })
    .catch(() => {
      popToast('Sorry! Unable to copy link', 'bottom_right')
      setShowModal('')
    })
}
