import { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { useTextChange } from '../../hooks'
import { removeOnKeyPress } from '../../utils'

import { NavigationBtn } from './NavigationBtn'
import { ProductAttributeForm } from './ProductAttributeForm'
import { SingleSelect, Textarea, ToggleInput } from '../common/InputField'
import SearchLocation from '../map/SearchLocation'
import PopupModal from '../common/PopupModal'

import { CONDITION_OPTIONS } from './options'
import { RULES } from '../pages/Auth/rules'

export const StepTwo = props => {
  const {
    setProductAttributeData,
    categoryOptions,
    subCategoryOptions,
    brandOptions,
    isLoading,
    fetchingBrand,
    fieldData,
    ...rest
  } = props

  const [isAdult, setIsAdult] = useState(false)

  const {
    register,
    watch,
    control,
    setValue,
    resetField,
    formState: { errors, isValid }
  } = useFormContext()

  const textChange = useTextChange(watch)

  return (
    <>
      {!isLoading ? (
        <div
          className={`category-select ${
            textChange?.category ? 'category-select-selected' : ''
          }`}
        >
          <SingleSelect
            name='category'
            errors={errors?.category?.message}
            rules={{ required: 'Category is required' }}
            control={control}
            options={categoryOptions}
            placeholder='Category*'
          />
          {subCategoryOptions?.length > 0 ? (
            <SingleSelect
              isClearable
              name='subCategory'
              errors={errors?.subCategory?.message}
              rules={{ required: 'Sub Category is required' }}
              control={control}
              options={subCategoryOptions}
              placeholder='Sub Category*'
            />
          ) : null}
        </div>
      ) : null}

      {fetchingBrand ? null : isEmpty(brandOptions) ? null : (
        <>
          {!isEmpty(fieldData?.subCategory) ? (
            <div className='brand-select'>
              <SingleSelect
                name='brand'
                errors={errors?.brand?.message}
                rules={{ required: 'Brand is required' }}
                control={control}
                options={brandOptions}
                placeholder='Brand*'
              />
            </div>
          ) : null}
        </>
      )}

      <SingleSelect
        name='condition'
        errors={errors?.condition?.message}
        rules={{ required: 'Condition is required' }}
        control={control}
        options={CONDITION_OPTIONS}
        placeholder='Condition*'
      />

      <div
        className={`form-item form-item--location form-item--location-post ${
          errors?.location?.message ? 'form-item--location-post-error' : ''
        } `}
      >
        <Controller
          control={control}
          name='location'
          rules={{ required: 'Location is required' }}
          render={({ field: { onChange, value } }) => (
            <SearchLocation
              inSearchBar={false}
              onChangeLocationPicker={onChange}
              placeholder='Location*'
              setLocationValue={setValue}
              formError={errors}
              location={value}
            />
          )}
        />
      </div>

      <div className='post-toggle'>
        <div className='post-toggle-title post-toggle-title-locationRedius'>
          <span>Location Radius</span>
          <span className='info'>Hide my precise location</span>
        </div>
        <ToggleInput
          name='locationRadius'
          id='locationRadius'
          {...register('locationRadius')}
        />
      </div>

      <div className='post-description'>
        <Textarea
          maxLength='500'
          placeholder='Description'
          errors={errors?.description?.message}
          name='description'
          info='Add all the product or service specifications.'
          textchange={textChange?.description}
          {...register('description', RULES.description)}
          onKeyPress={e => removeOnKeyPress(e, ['[<>]'])}
        />
      </div>

      <ProductAttributeForm
        categoryId={textChange?.subCategory || fieldData?.subCategory || ''}
        textChange={textChange}
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        resetField={resetField}
        setProductAttributeData={setProductAttributeData}
        fieldData={fieldData}
      />

      <div className='post-toggle'>
        <div className='post-toggle-title'>
          <span>Is Adult Content?</span>
        </div>
        <ToggleInput
          name='isAdultContent'
          id='isAdultContent'
          {...register('isAdultContent')}
          onClick={() => {
            textChange?.isAdultContent ? setIsAdult(false) : setIsAdult(true)
          }}
        />
      </div>

      <NavigationBtn isValid={isValid} {...rest} />

      <PopupModal
        isOpen={isAdult}
        setIsOpen={state => {
          setIsAdult(state)
        }}
        header='Yes/No'
        title='वयस्क सामग्री (Adult Content) भनेको कुनै पनि विज्ञापन, जसमा अश्लील वा नग्नता, स्पष्ट यौन छविहरू समावेश छन्। के तपाइँको विज्ञापन वयस्क सामग्री हो?'
        confirm='Yes'
        cancel='No'
        confirmClick={e => {
          e.preventDefault()
          setValue('isAdultContent', true)
          setIsAdult(false)
        }}
        dangerConfirm={true}
        cancelClick={() => {
          setValue('isAdultContent', false)
        }}
      />
    </>
  )
}
