import { HoldUnHoldHandler } from './HoldUnHoldHandler'
import { SoldHandler } from './SoldHandler'
import { DeleteHandler } from './DeleteHandler'

export const MenuHandler = (refetch, setShowMenu) => {
  const HoldUnHold = HoldUnHoldHandler(refetch, setShowMenu)
  const { MarkAsSold, isLoading: isSoldLoading } = SoldHandler(
    refetch,
    setShowMenu
  )
  const { DeleteProduct, isLoading: isDeleteLoading } = DeleteHandler(
    refetch,
    setShowMenu
  )
  return {
    HoldUnHold,
    MarkAsSold,
    isSoldLoading,
    DeleteProduct,
    isDeleteLoading,
    isLoading: isDeleteLoading || isSoldLoading
  }
}
