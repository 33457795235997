import appConfig from '../appConfig'
import { axiosInstance } from '../utils/axiosInstance'

//The base api url
const baseAPI = appConfig.baseApiURI

//Get All Category
export const getAllCategory = async () => {
  const res = await axiosInstance().get(`${baseAPI}/api/AppData/GetAllCategory`)
  return res
}

//Get the SUb Category
export const getSubCategory = async cid => {
  const res = await axiosInstance().get(`${baseAPI}/api/AppData/${cid}`)
  return res
}

/*
  Get the Brands by category id
*/
export const getBrand = async bid => {
  const res = await axiosInstance().get(`${baseAPI}/api/AppData/?Id=${bid}`)
  return res
}
