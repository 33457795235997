import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { useFetch } from '../../../hooks'
import { ViewAllNotifications } from '../../../services'
import NotificationBar from '../../common/NotificationBar'
import EmptyAdMessage from '../../common/EmptyAdMessage'
import SmallLoader from '../../loaders/SmallLoader'
import CategoryLoader from '../../loaders/CategoryLoader'
import { NOTIFICATION } from '../../../constants'

const NotificationList = ({
  auth,
  numberOfNotifications = -1,
  className = ''
}) => {
  const { data, isLoading, isError, refetch } = useFetch(
    'NOTIFICATIONS',
    NOTIFICATION,
    auth?.isAuthenticated
  )
  const { mutate, isLoading: isViewing } = ViewAllNotifications(refetch)
  const numberOfUnreadNotifications = data?.data?.filter((elem) => {
    return !elem.viewed
  })?.length

  return (
    <div className={className}>
      <div className='notification-head'>
        <h2 className='title'>
          Notification{' '}
          {numberOfUnreadNotifications?.length >= 1
            ? `(${numberOfUnreadNotifications})`
            : ''}
        </h2>
        {data?.data?.some((a) => a.viewed === false) && (
          <span className='readAll' onClick={mutate}>
            {isViewing ? (
              <SmallLoader />
            ) : (
              <>
                <i className='fal fa-check'></i>
                Mark all read
              </>
            )}
          </span>
        )}
      </div>
      <div className='notification-main-content'>
        {isLoading ? (
          <CategoryLoader numOfArray={8} />
        ) : isEmpty(data?.data) || isError ? (
          <div className='noti-bar  drop__item '>
            <EmptyAdMessage
              message={
                isError
                  ? "Sorry Couldn't load notification."
                  : 'You have no notifications available yet.'
              }
              imageName='no-review'
            />
          </div>
        ) : (
          <>
            {data?.data.slice(0, numberOfNotifications).map((item, i) => (
              <NotificationBar
                data={item}
                key={item?.id || i}
                refetch={refetch}
              />
            ))}
            {numberOfNotifications !== -1 && (
              <Link
                to={{
                  pathname: `/notifications`
                }}
                className='notification-main-content-footer'
              >
                <p>
                  <span>See All</span>
                </p>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NotificationList
