import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import { useFetch } from '../../hooks'
import { addRevewApi } from '../../api/user'
import { popToast } from '../../components/common/PopToast'
import { REVIEWERS, STATIC_ERROR_MESSAGE } from '../../constants'

export const ReviewService = (setShowModal) => {
  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await addRevewApi(data)
    },
    {
      onSuccess: (data) => {
        popToast(data.data?.status?.message?.[0], 'TOP_RIGHT')
        setShowModal('')
      },
      onError: (error) => {
        popToast(
          error?.response?.data?.errors?.reviewDetails?.[0] ||
            error?.response?.data?.status?.message?.[0] ||
            STATIC_ERROR_MESSAGE,
          'TOP_RIGHT'
        )
        setShowModal('')
      }
    }
  )
  return { mutate, isLoading }
}

export const GetOwnReviewService = (uid, auth) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [ownReview, setOwnReview] = useState(true)
  const { data, isLoading } = useFetch(
    'USER_REVIEWS',
    `${REVIEWERS}${uid}`,
    !!uid,
    { uid }
  )

  useEffect(() => {
    if (!isLoading) {
      setOwnReview(
        data?.data?.filter(
          (data) => data?.creatorId === auth?.profile?.userId
        )?.[0]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data?.data])

  useEffect(() => {
    if (!isEmpty(ownReview)) {
      setIsUpdate(true)
    }
  }, [ownReview])

  return { ownReview, isUpdate, isLoading }
}
