import DISTRICTS from '../data/districts.json'

function checkDistrict(str) {
  str = str?.toLowerCase()
  for (var i = 0; i !== DISTRICTS?.length; i++) {
    var substring = DISTRICTS[i].name?.toLowerCase()

    if (str?.indexOf(substring) !== -1) {
      return substring
    }
  }
  return 'nepal'
}

export const url = (path = null, params) => {
  params = params.map((param) => urlChanger(param))
  const joined = params.join('/')
  return `${path ? `${path}'/'}` : '/'}${joined}`
}

export const productURL = (dataParams, expired = false) => {
  const address = checkDistrict(dataParams?.location?.locationDescription)
  return url(
    null,
    [
      `${dataParams?.categoryName || 'product'}`,
      `${dataParams?.brandName || ''}`,
      `${
        dataParams?.name || dataParams?.productName || dataParams?.text
      } in ${address}`,
      expired ? '' : dataParams?.id || dataParams?.postId
    ].filter(Boolean)
  )
}

const urlChanger = (data) => {
  if (data) {
    return (
      data
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .trim()
        .replace(/^(-)+|(-)+$/g, ' ')
        // eslint-disable-next-line no-useless-escape
        .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, '-')
        .toLowerCase()
    )
  } else {
    return null
  }
}

export default urlChanger
