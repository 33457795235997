import React from 'react'
import Spinner from '../../loaders/Spinner'
import { CloseIcon } from '../../../icons'

export const PopupModal = ({
  setShowModal = () => {},
  onClick = () => {},
  isLoading = false,
  title = '',
  info = '',
  btnClass = '',
  children,
  modalClass = '',
  hideFooter = false,
  refProp
}) => {
  return (
    <div className='modal--backdrop'>
      <div
        className={`modal modal--common modal--share ${modalClass}`}
        ref={refProp}
      >
        <div className='modal-wrapper'>
          <div className='modal--header'>
            <h2 className='modal--title'>{title}</h2>
            <button
              className='close '
              onClick={() => {
                setShowModal('')
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <hr />
          <div className='modal--body'>
            {children && children}
            {!hideFooter ? (
              <>
                <h2 className='title--normal'>{info}</h2>
                <div className='button--groups'>
                  <button className={`btn ${btnClass}`} onClick={onClick}>
                    <Spinner isLoading={isLoading} /> Yes
                  </button>
                  <button
                    className='btn'
                    onClick={() => {
                      setShowModal('')
                    }}
                  >
                    No, Close this
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
