import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { getNotification } from '../../Redux/reducers/notificationReducers'
import { useWindowDimensions } from '../../hooks'
import { combineClassNames } from '../../utils'

import { popToast } from '../common/PopToast'
import AddProduct from '../addProduct/AddProduct'
import { MainNav } from './index'
import TopCategories from '../topCategories/TopCategories'
import { Sidebar } from '../sidebar'
import TopSubNav from './TopSubNav'
import { getProfile } from '../../Redux/reducers/authReducers'

const Navbar = ({ auth, getNotification, unreadNotification, getProfile }) => {
	const location = useLocation()

	const [showPostForm, setShowPostForm] = useState(false)
	const [showSidebar, setShowSidebar] = useState(false)
	const windowDimension = useWindowDimensions()
	const displayPostForm = () => {
		if (auth?.isAuthenticated) {
			setShowPostForm(curr => !curr)
		} else {
			popToast()
		}
	}

	const toggleSidebar = () => {
		if (windowDimension?.width > 768) return

		setShowSidebar(curr => !curr)
	}

	useEffect(() => {
		if (auth?.user?.userId) {
			getProfile(auth?.user?.userId)
		}
		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.user?.userId])

	return (
		<>
			{/* <TopSubNav /> */}
			<div className='navbar navbar-sticky '>
				<MainNav
					auth={auth}
					displayPostForm={displayPostForm}
					unreadNotification={unreadNotification}
				/>
				{windowDimension?.width <= 768 ? (
					<TopCategories
						className='topCategories-topNav container'
						toggleSidebar={toggleSidebar}
					/>
				) : null}
			</div>
			{windowDimension?.width <= 768 && showSidebar ? (
				<div
					className={combineClassNames('mob-sidebar', {
						'mob-sidebar-show': showSidebar,
					})}
				>
					<Sidebar
						className='sidebar-mobSidebar'
						selectedCategory={
							location?.pathname?.split('/').length === 4
								? location.pathname.split('/')?.slice(-1)?.[0]
								: location.pathname.split('/')?.slice(-2)?.[0]
						}
						selectedSubCategory={location?.pathname?.split('/')?.[4]}
						toggleSidebar={toggleSidebar}
					/>
					<div onClick={toggleSidebar} className='empty-content' />
				</div>
			) : null}
			{showPostForm ? <AddProduct auth={auth} click={setShowPostForm} /> : null}
		</>
	)
}

const mapStateToProps = state => ({
	auth: state.auth,
	unreadNotification: state.noti.unreadNoti,
})

const mapDispatchToProps = {
	getNotification,
	getProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
