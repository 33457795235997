import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  ViberShareButton,
  WhatsappShareButton
} from 'react-share'

import { productURL } from '../../../utils/urlChanger'
import { useClickOutside } from '../../../hooks'
import { ProductTitle, copyToClipboard } from '../../../helpers'

import { PopupModal } from '../menu/PopupModal'
import CircleLoader from '../../loaders/CircleLoader'
export const SocialMediaShare = (props) => {
  const quote = props?.quote ?? ProductTitle(props?.data)
  const url = props?.url ?? window.location.origin + productURL(props?.data)

  let domNode = useClickOutside(() => {
    props.setShowModal('')
  })

  return (
    <PopupModal
      modalClass='share'
      title='Share via:'
      {...props}
      refProp={domNode}
    >
      <div className='share-container'>
        {props?.isLoading ? (
          <CircleLoader numOfArray={6} />
        ) : (
          <>
            <FacebookShareButton
              url={props?.link?.shortLink || url}
              quote={quote}
            >
              <span className='social-btn fb'>
                <i className='fab fa-facebook-f'></i>
              </span>
            </FacebookShareButton>
            <TwitterShareButton
              url={props?.link?.shortLink || url}
              title={quote}
            >
              <span className='social-btn tw'>
                <i className='fab fa-twitter'></i>
              </span>
            </TwitterShareButton>
            <RedditShareButton
              url={props?.link?.shortLink || url}
              title={quote}
            >
              <span className='social-btn rd'>
                <i className='fab fa-reddit-alien'></i>
              </span>
            </RedditShareButton>
            <ViberShareButton url={props?.link?.shortLink || url} title={quote}>
              <span className='social-btn vb'>
                <i className='fab fa-viber'></i>
              </span>
            </ViberShareButton>
            <WhatsappShareButton
              url={props?.link?.shortLink || url}
              title={quote}
            >
              <span className='social-btn wa'>
                <i className='fab fa-whatsapp'></i>
              </span>
            </WhatsappShareButton>
            <button>
              <span
                className='social-btn cu'
                onClick={() => {
                  copyToClipboard(
                    props?.link?.shortLink || url,
                    props.setShowModal
                  )
                }}
              >
                <i className='far fa-link'></i>
              </span>
            </button>
          </>
        )}
      </div>
    </PopupModal>
  )
}
