import appConfig from '../appConfig'

export const IMAGES = {
  LOAD_ERROR: {
    PLACEHOLDER_SRC: '/images/loading_anu9cy.png'
  }
}

export const ADS = {
  IMAGE_LOAD_ERROR: {
    PLACEHOLDER_SRC: IMAGES.LOAD_ERROR.PLACEHOLDER_SRC
  },
  ALT_TEXT: {
    DEFAULT: 'Advertisement'
  }
}

export const LOCATION = {
  DEFAULTS: {
    LAT: 27.691138,
    LNG: 85.318579,
    ZOOM: 15
  }
}

export const MONITORING = {
  SENTRY: {
    DSN: 'https://282d23d942734a65be26b233af75c291@o1357045.ingest.sentry.io/6668556'
  }
}

export const APP = {
  VERSION: appConfig.app.version
}

export const ENV = {
  NAME: process.env.REACT_APP_ENV,
  IS_PROD: process.env.REACT_APP_ENV === 'production',
  IS_DEV: process.env.REACT_APP_ENV === 'development',
  IS_STAGE: process.env.REACT_APP_ENV === 'stage',
  IS_TEST: process.env.REACT_APP_ENV === 'test',
  IS_DEBUG: process.env.REACT_APP_ENV === 'debug'
}
