import appConfig from "../../appConfig";
import { axiosInstance } from "../../utils";

const baseAPI = appConfig.baseApiURI;

export const getReverseGeocodingData = async ({ lat, lng }) => {
  try {
    const res = await axiosInstance().get(
      `${baseAPI}/location/openstreet?lat=${lat}&lon=${lng}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
