const ErrorMessage = ({ message, classname }) => {
  return (
    <>
      <small
        className={`${
          classname?.length > 0
            ? classname
            : 'input--message input--message--no-margin'
        } warning`}
      >
        {message}
      </small>
    </>
  )
}

export default ErrorMessage
