import appConfig from '../appConfig'
import { axiosInstance } from '../utils/axiosInstance'
// import setToken from "./setToken";

//The base api url
const baseAPI = appConfig.baseApiURI

/*
  Get All Home Ads
*/
export const featuredApi = async page => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Product/Featured?PageNumber=${page}&PageSize=30`
  )
  return res
}
