import { keyValues } from '../../utils'
import {
  SingleSelect,
  MultiSelectCheckbox,
  TextNumberField
} from '../common/InputField'

export const AttributeInputField = ({
  attribute,
  errors,
  register,
  control
}) => {
  const keysMap = {
    choiceValue: 'value',
    choiceText: 'label'
  }
  return (
    <>
      {attribute.fieldType === 1 ? (
        <TextNumberField
          register={register}
          errors={errors}
          attribute={attribute}
        />
      ) : attribute.fieldType === 2 ? (
        <TextNumberField
          register={register}
          errors={errors}
          attribute={attribute}
        />
      ) : attribute?.fieldType === 3 ? (
        <div className='post-choice'>
          <span className='checkbox-head'>
            {attribute?.fieldName + attribute?.isRequired ? '*' : ''}
          </span>
          <div className='post-choice-choices'>
            {attribute?.attributeChoices?.map((choice) => (
              <MultiSelectCheckbox
                key={choice?.id}
                label={choice?.choiceText}
                value={choice.choiceText}
                id={choice?.id}
                name={attribute?.fieldName}
                {...register(`${attribute?.fieldName}}`, {
                  required: {
                    value: attribute?.isRequired,
                    message: `${attribute?.fieldName} is required`
                  }
                })}
              />
            ))}
          </div>
        </div>
      ) : attribute?.fieldType === 4 ? (
        <SingleSelect
          name={attribute?.fieldName}
          errors={errors?.[`${attribute?.fieldName}`]?.message}
          rules={{
            required: {
              value: attribute?.isRequired,
              message: `${attribute?.fieldName} is required`
            }
          }}
          control={control}
          options={attribute?.attributeChoices?.map((attrChoice) =>
            keyValues(keysMap, attrChoice)
          )}
          placeholder={` ${attribute?.placeholder}${
            attribute?.isRequired ? '*' : ''
          }`}
        />
      ) : null}
    </>
  )
}
