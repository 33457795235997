import { useState, useEffect } from 'react'
import { useFetch, useWindowDimensions } from '../../hooks'
import { isEmpty } from 'lodash'

import { moveArrayPosition, uid } from '../../utils'

import { SidebarItem } from './SidebarItem'
import { CategorySkeleton } from '../skeletons'

import { CategoryIcon, CloseIcon } from '../../icons'
import { ALL_CATEGORIES_URL, IS_HB_SELECT_ID } from '../../constants'
import Footer from '../layout/Footer'

export const Sidebar = ({
  selectedCategory,
  selectedSubCategory,
  className = '',
  toggleSidebar = () => {}
}) => {
  const windowDimension = useWindowDimensions()
  const [clickedCategory, setClickedCategory] = useState(selectedCategory ?? '')

  const { data, isLoading, isError } = useFetch(
    'GET_ALL_CATEGORY',
    ALL_CATEGORIES_URL
  )

  const toggleSidebarItem = (id) => {
    setClickedCategory(id)
  }

  useEffect(() => {
    selectedCategory ?? setClickedCategory(selectedCategory)
  }, [selectedCategory])

  return (
    <>
      <div className={`sidebar ${className}`}>
        <div className='sidebar-container'>
          <div className='sidebar-head'>
            <div className='sidebar-header'>
              <CategoryIcon />
              <h2>Category</h2>
              <span className='close-icon'>
                <CloseIcon onClick={toggleSidebar} />
              </span>
            </div>
            <div className='divider divider-horizontal' />
          </div>
          <div className='sidebar-category'>
            {isLoading ? (
              <CategorySkeleton numOfArray={6} />
            ) : isError || isEmpty(data?.data) ? null : (
              <>
                {moveArrayPosition([...data?.data], IS_HB_SELECT_ID, 0)?.map(
                  (item) => (
                    <SidebarItem
                      key={item?.id + uid()}
                      item={item}
                      clickedCategory={clickedCategory}
                      selectedSubCategory={selectedSubCategory}
                      isActive={clickedCategory === item?.id ? true : false}
                      toggleSidebarItem={toggleSidebarItem}
                      toggleSidebar={toggleSidebar}
                    />
                  )
                )}
              </>
            )}
          </div>
          {windowDimension.width < 768 ? (
            <Footer
              toggleSidebar={toggleSidebar}
              className='left-footer-sidebar'
            />
          ) : null}
        </div>
      </div>
      <div className='divider divider-vertical divider-sidebar' />
    </>
  )
}
