import { Link } from 'react-router-dom'

import { ViewNotificationService } from '../../services'

import { productURL } from '../../utils/urlChanger'
import { NO_IMAGE_THUMNAIL } from '../../constants'

const NotificationBar = ({ data, refetch }) => {
  const { mutate } = ViewNotificationService(refetch)

  const viewNotification = (id) => {
    if (!data?.viewed) {
      return mutate(id)
    }
    return
  }

  const notificationPath = (type) => {
    const lowerType = type.toLowerCase()
    if (lowerType === 'commented' || lowerType === 'replied')
      return {
        pathname: productURL(data?.product),
        state: {
          comment: data?.activityId
        }
      }

    if (lowerType === 'reviewed')
      return {
        pathname: `/profile`,
        state: { reviews: data?.activityId }
      }
    return '/'
  }

  const notificationType = (type) => {
    const lowerType = type.toLowerCase()
    if (lowerType === 'commented') return `${lowerType} on your Post`
    if (lowerType === 'replied') return `${lowerType} on your comment`
    if (lowerType === 'reviewed') return `${lowerType} on your profile`
  }

  return (
    <Link
      to={notificationPath(data?.notificationType)}
      onClick={() => viewNotification(data?.id)}
      className='notification-main-content-link'
    >
      <div
        className={`noti-bar  drop__item ${
          data.viewed ? ' seen ' : ' unseen '
        }`}
      >
        <div className='avatar'>
          <img
            className='circle-img fake-dp'
            src={data?.notifiedUser?.notifiedByImg || NO_IMAGE_THUMNAIL}
            alt={
              data?.notifiedUser?.notifiedByName ||
              data?.notifiedUser?.notifiedUsername ||
              'hamrobazar-user-profile'
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = NO_IMAGE_THUMNAIL
            }}
          />
        </div>

        <div className='noti__body'>
          <div className='not__typo'>
            <b>
              {data?.notifiedUser?.notifiedByName ||
                data?.notifiedUser?.notifiedUsername}
            </b>{' '}
            <span className='lower--Me'>
              {notificationType(data?.notificationType)}
            </span>
          </div>
          <div className='text-muted'>
            <small>{data?.createdTime}</small>
          </div>
        </div>
        {data?.product?.productImg && (
          <div className='noti__image'>
            <img
              src={data.product.productImg || NO_IMAGE_THUMNAIL}
              alt={data?.product?.productName || 'product thumbnail'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = NO_IMAGE_THUMNAIL
              }}
            />
          </div>
        )}
      </div>
    </Link>
  )
}

export default NotificationBar
