//Set New Vars
export const setVariablesDark = gettingroot => {
  gettingroot.style.setProperty('--black', '#fff')
  gettingroot.style.setProperty('--white', '#272727')
  gettingroot.style.setProperty('--green', ' #30ac07')
  gettingroot.style.setProperty('--red', ' #fb4e4e')
  gettingroot.style.setProperty('--darkgrey', '#c3bec1')
  gettingroot.style.setProperty('--whitegrey', ' #424141')
  gettingroot.style.setProperty('--lightgreyorg', ' #2e2e2e')
  gettingroot.style.setProperty('--lightgrey', '#43434378')
  gettingroot.style.setProperty('--grey--f1', '#403f3f')
  gettingroot.style.setProperty('--black42', '#c4c3c3')
  gettingroot.style.setProperty('--black33', ' #d3d2d2')
  gettingroot.style.setProperty('--black33--05', '#d3d2d20d')
  gettingroot.style.setProperty('--black33--10', '#d3d2d20f')
  gettingroot.style.setProperty('--black33--15', '#d3d2d226')
  gettingroot.style.setProperty('--black33--20', '#d3d2d230')
  gettingroot.style.setProperty('--black33--30', '#d3d2d24d')
  gettingroot.style.setProperty('--black33--50', '#d3d2d280')
  gettingroot.style.setProperty('--black33--60', '#d3d2d299')
  gettingroot.style.setProperty('--black33--80', '#d3d2d2cc')
  gettingroot.style.setProperty('--black--68', '#fdf7f7ad')
  gettingroot.style.setProperty('--black--80', ' #ecebebcc')
  gettingroot.style.setProperty('--chat-bubble', ' #5e5e5f')
}
export const setVariablesLight = gettingroot => {
  gettingroot.style.setProperty('--black', '#000')
  gettingroot.style.setProperty('--white', '#fff')
  gettingroot.style.setProperty('--red', ' #e80000')
  gettingroot.style.setProperty('--green', ' #30ac07')
  gettingroot.style.setProperty('--darkgrey', '#5b5759')
  gettingroot.style.setProperty('--whitegrey', ' #f0f0f0')
  gettingroot.style.setProperty('--lightgreyorg', ' #f4f4f4')
  gettingroot.style.setProperty('--lightgrey', ' #f4f4f478')
  gettingroot.style.setProperty('--grey--f1', ' #f1f1f1')
  gettingroot.style.setProperty('--black42', '#424242')
  gettingroot.style.setProperty('--black33', ' #333')
  gettingroot.style.setProperty('--black33--05', '#3333330d')
  gettingroot.style.setProperty('--black33--10', '#3333330f')
  gettingroot.style.setProperty('--black33--15', '#33333326')
  gettingroot.style.setProperty('--black33--20', '#33333330')
  gettingroot.style.setProperty('--black33--30', '#3333334d')
  gettingroot.style.setProperty('--black33--50', '#33333380')
  gettingroot.style.setProperty('--black33--60', '#33333399')
  gettingroot.style.setProperty('--black33--80', '#333333cc')
  gettingroot.style.setProperty('--black--68', '#000000ad')
  gettingroot.style.setProperty('--black--80', ' #000000cc')
  gettingroot.style.setProperty('--chat-bubble', ' #f4f5f7')
}
