import { useMutation } from 'react-query'

import { getBrandNames } from '../../api/ads'
import { popToast } from '../../components/common/PopToast'

export const GetBrandService = () => {
  const { mutate, isLoading, data } = useMutation(
    async (id) => {
      return await getBrandNames(id)
    },
    {
      onError: (error) => {
        popToast(
          'Unable to get brands name! Please try again later.',
          'TOP_RIGHT'
        )
      }
    }
  )
  return { mutate, isLoading, data }
}
