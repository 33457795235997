import React from 'react'
import { connect } from 'react-redux'

const Spinner = ({ spinner, isLoading }) => {
  if (spinner === true || isLoading) {
    return (
      <>
        <div className='lds-ripple'>
          <div></div>
          <div></div>
        </div>
      </>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  spinner: state.spinner.spinner
})

export default connect(mapStateToProps)(Spinner)
