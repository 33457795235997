import { useHistory } from 'react-router-dom'
import CategoryLoader from '../loaders/CategoryLoader'

import { MessageBox } from '../../icons'

export const ChatListBox = ({
  auth,
  loadingFrame,
  isLoading = true,
  index = 1
}) => {
  const history = useHistory()

  return (
    <div className='chat-body'>
      {auth?.isAuthenticated ? (
        <>
          {isLoading ? (
            <div className='chat-loader'>
              <CategoryLoader numOfArray={15} />
            </div>
          ) : null}
          <iframe
            key={index}
            width='100%'
            height='100%'
            src={`https://chat.hamrobazaar.com?me=${auth?.profile?.userId}`}
            title='Hamrobazar Chats'
            loading='eager'
            onLoad={() => {
              loadingFrame(false)
            }}
          />
        </>
      ) : (
        <div className='no-chat-bg'>
          <div className='no-chat-wrapper'>
            <MessageBox />
            <span>Log in to use the in-app messaging.</span>

            <span className='no-login-message'>
              Click here to{' '}
              <span
                className='login-button'
                onClick={() => {
                  history.push('/login')
                }}
              >
                Login
              </span>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
