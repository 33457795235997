import { Link } from 'react-router-dom'

import { useGAEventTracker } from '../../../hooks'
import LazyLoadImage from '../../common/LazyLoadImage'
import BannerAds from '../GoogleAds/BannerAds'
import SquareAds from '../GoogleAds/SquareAds'
import { validateURL } from '../../../utils'

const PatternThree = ({ AdsPattern }) => {
  const adSequenceOne = AdsPattern.filter((ad) => ad?.adSequence === 1)

  const adSequenceTwo = AdsPattern.filter((ad) => ad?.adSequence === 2)

  const adSequenceThree = AdsPattern.filter((ad) => ad?.adSequence === 3)

  const newPatternArr = [
    adSequenceOne?.length > 0 ? adSequenceOne?.[0] : null,
    adSequenceTwo?.length > 0 ? adSequenceTwo?.[0] : null,
    adSequenceThree?.length > 0 ? adSequenceThree?.[0] : null
  ].filter(Boolean)

  const GAEvent = useGAEventTracker()

  return (
    <div
      className={
        window.innerWidth > 767 ? 'responseGTopBannerContainer' : undefined
      }
    >
      {newPatternArr?.map((ad, index) => {
        return (
          <div
            className={
              index === 1
                ? 'midAdd longGad3Item longWithGadResponsiveLink'
                : 'sideAdd longGad3Item longWithGadResponsiveLink'
            }
            key={index}
            style={{
              width:
                index !== 1 ? 250 : window.innerWidth > 767 ? undefined : 320,
              height: index !== 1 ? 250 : window.innerWidth > 767 ? 250 : 110,
              flexGrow: index !== 1 ? '0' : '1',
              flexShrink: index === 1 ? '1' : undefined,
              flexBasis: index !== 1 ? 250 : window.innerWidth > 767 ? 720 : 320
            }}
          >
            {ad?.link && (
              <>
                {ad?.type === 'adsence' ? (
                  <>
                    {index !== 1 ? (
                      <SquareAds keyProp={`adsense-${index}`} />
                    ) : (
                      <BannerAds keyProp={`adsense-${index}`} />
                    )}
                  </>
                ) : ad?.linkType === 'external' ? (
                  <a
                    onClick={() =>
                      GAEvent(ad?.pageName, ad?.slotName, ad?.pageHtml)
                    }
                    href={validateURL(ad?.link || 'https://hamrobazaar.com/')}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <LazyLoadImage
                      src={
                        window.innerWidth > 767
                          ? ad?.adContentDesktop
                          : ad?.adContentMobile
                      }
                    />
                  </a>
                ) : (
                  <Link
                    to={`/${ad?.linkType === 'profile' ? 'user' : 'product'}/${
                      ad?.link
                    }`}
                  >
                    <LazyLoadImage
                      src={
                        window.innerWidth > 767
                          ? ad?.adContentDesktop
                          : ad?.adContentMobile
                      }
                    />
                  </Link>
                )}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PatternThree
