import { useState, useEffect, useRef, useCallback } from 'react'

export const StickyFilter = (defaultSticky = false, height) => {
  const [isSticky, setIsSticky] = useState(defaultSticky)
  const scrollRef = useRef(null)
  const toggleSticky = useCallback(
    ({ x, y }) => {
      if (height > 0)
        if (x >= 0 && y <= -height) {
          !isSticky && setIsSticky(true)
        } else {
          isSticky && setIsSticky(false)
        }
    },
    [isSticky, height]
  )
  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(scrollRef.current.getBoundingClientRect())
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [toggleSticky])
  return { scrollRef, isSticky }
}
