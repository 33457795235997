export function resetFilterData(advanceFilterData, reset) {
  const advanceFilterNames = advanceFilterData?.data
    ?.map(filter => {
      if (filter?.filterParamType === '2') {
        return [filter?.fieldName + 'To', filter?.fieldName + 'From']
      }

      return filter.fieldName
    })
    ?.flat()

  const defaultValues = advanceFilterNames
    ?.map(item => {
      return {
        [item]: '',
      }
    })
    ?.reduce((acc, curr) => {
      return { ...acc, ...curr }
    }, {})
  return reset({
    sortParam: '0',
    condition: '0',
    priceFrom: '',
    priceTo: '',
    isPriceNegotiable: '',
    category: '',
    subcategory: '',
    searchByDistance: 0,
    ...defaultValues,
  })
}
