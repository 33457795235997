import { DoublePattern } from './PatternOne'

const PatternTwo = ({ AdsPattern }) => {
  return (
    <>
      {AdsPattern[0] && (
        <div
          className='longGad1'
          style={{ height: window.innerWidth > 767 ? 120 : 55 }}
          data-sequence='sequence-one'
        >
          {AdsPattern[0]?.adContentDesktop && (
            <DoublePattern
              ad={AdsPattern[0]}
              index={0}
              height={window.innerWidth > 767 ? 120 : 55}
            />
          )}
        </div>
      )}
      {AdsPattern[1] && (
        <div
          className='longGad1'
          style={{ height: window.innerWidth > 767 ? 120 : 55 }}
          data-sequence='sequence-two'
        >
          {AdsPattern[1]?.adContentDesktop && (
            <DoublePattern
              ad={AdsPattern[1]}
              index={1}
              height={window.innerWidth > 767 ? 120 : 55}
            />
          )}
        </div>
      )}
    </>
  )
}

export default PatternTwo
