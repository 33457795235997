/**
 * Checks whether the given string is a valid JSON string.
 *
 * @param {String} Data
 * @returns {boolean} boolean
 */

export function isJson(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}
