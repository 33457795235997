import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AuthPopToast = ({ closeToast }) => {
  return (
    <div className='toast-auth'>
      <p className='toast-auth-message'>To use this feature please login.</p>
      <div className='toast-auth-container'>
        <span onClick={closeToast}>
          <Link to='/login'>Login</Link>
        </span>
        <span onClick={closeToast}> Cancel</span>
      </div>
    </div>
  )
}
export const popToast = (
  msg,
  position = 'top-right',
  autoClose = 1000,
  type = 'info'
) =>
  toast[`${type}`](msg ?? <AuthPopToast />, {
    position: 'top-right',
    autoClose: typeof autoClose === 'number' ? autoClose : 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'colored'
  })
