import React from 'react'

export const BetweenInput = props => {
  const {
    fromField,
    toField,
    label,
    fieldValueSign,
    inputFieldElement,
    register,
  } = props

  return (
    <div className="form-item">
      {label && <label htmlFor="">{label}</label>}
      <div className={`inline-form show--label`}>
        {inputFieldElement?.map((field, item) => (
          <React.Fragment key={item}>
            <div
              className={`input ${
                item === 1
                  ? `show--label ${
                      Number(fromField) >= Number(toField)
                        ? fromField === '' && toField === ''
                          ? ''
                          : 'warning'
                        : ''
                    }`
                  : ''
              }`}
            >
              {fieldValueSign && <label htmlFor="">{fieldValueSign}</label>}
              <input
                type={field?.type}
                name={field?.name}
                placeholder={field?.placeholder}
                onWheel={e => e.target.blur()}
                {...register(field?.name, field?.validation)}
                autoComplete="off"
              />
            </div>
            {item === 0 && <span>To</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
