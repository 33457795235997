import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'

const baseAPI = appConfig.baseApiURI

export const getHomeAds = async (page) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Product?PageNumber=${page}&PageSize=100&Latitude=${
      localStorage.getItem('latitude') || 0
    }&Longitude=${localStorage.getItem('longitude') || 0}`
  )
  return res
}

export const getAd = async (pid) => {
  try {
    const res = await axiosInstance().get(`${baseAPI}/api/Product/${pid}`)

    return res
  } catch (error) {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      window.location.replace('/product-not-found')
    }
  }
}

export const getBrandNames = async (cid) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const res = await axiosInstance().post(`${baseAPI}/api/AppData?ID=${cid}`, {
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const getBrandAttributes = async (cid) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Category/GetCategoryAttribute?CategoryId=${cid}`
  )
  return res
}

export const uploadImageApi = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const myFile = new File([data], 'image.webp', {
    type: 'image/webp',
    size: data.size
  })

  var fd = new FormData()
  fd.append('file', myFile)

  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  fd.append('deviceId', deviceId)
  fd.append('deviceSource', deviceSource)
  fd.append('latitude', localStorage.getItem('currentLocationLat') || 0)
  fd.append('longitude', localStorage.getItem('currentLocationLng') || 0)

  const res = await axiosInstance().post(
    `${baseAPI}/api/Bucket/UploadImage`,
    fd,
    config
  )
  return res
}

export const deleteImageApi = async (image) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  let newId

  if (typeof image === 'string') {
    newId = [image]
  } else if (typeof image === 'object' && image?.locationKey) {
    newId = [image?.locationKey]
  } else newId = image

  const res = await axiosInstance().post(
    `${baseAPI}/api/Bucket/Delete`,
    {
      deviceId,
      deviceSource,
      objectKey: newId,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    },
    config
  )
  return res
}

export const postAdApi = async (data) => {
  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // }

  // let realData = {
  //   name: data.title,
  //   description: data.description,
  //   price: parseFloat(data.price),
  //   categoryId: data.category,
  //   location: data.location,
  //   condition: parseInt(data.condition),
  //   imageUrl: data.photos[0].locationKey,
  //   negotiable: data.negotiable,
  //   delivery: data.delivery,
  //   productMedia: data.photos,
  //   expiryDate: data.expDate,
  //   isAdultContent: data.isAdultContent,
  //   locationRadius: data.locationRadius,
  //   recaptchaToken: data.recaptchaToken
  // }

  // realData.currentLocation = {
  //   latitude: localStorage.getItem('currentLocationLat') || 0,
  //   longitude: localStorage.getItem('currentLocationLng') || 0
  // }

  // if (data.brand !== '') {
  //   realData.brandCategoryId = data.brand
  // }

  // let filteredAt = []
  // data.productAttribute.forEach((a) => {
  //   if (a.value !== '') {
  //     filteredAt.push(a)
  //   }
  // })
  // if (filteredAt?.length !== 0) {
  //   realData.productAttribute = filteredAt
  // }

  // const deviceId = getCookie(DEVICE_ID)
  // const deviceSource = getDeviceSource()

  // const jdata = JSON.stringify({
  //   ...realData,
  //   deviceId,
  //   deviceSource,
  //   latitude: localStorage.getItem('currentLocationLat') || 0,
  //   longitude: localStorage.getItem('currentLocationLng') || 0
  // })

  const res = await axiosInstance().post(`${baseAPI}/api/Product/Add`, data)
  return res
}

/**
 * Edit Ads
 */
export const editAdApi = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const pack = {
    ...data,
    productMedia: [...data.productMedia],
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  }
  const jdata = JSON.stringify(pack)
  const res = await axiosInstance().post(
    `${baseAPI}/api/Product/Update`,
    jdata,
    config
  )
  return res
}

/**
 *
 * Delete product
 */
export const deleteAdApi = async (id) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/Product/Delete?Id=${id}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    }
  )
  return res
}

export const getUserAdApi = async (pagenum) => {
  if (pagenum) {
    const res = await axiosInstance().get(
      `${baseAPI}/api/User/GetProductByUser?PageNumber=${pagenum}&PageSize=10`
    )
    return res
  } else {
    const res = await axiosInstance().get(
      `${baseAPI}/api/User/GetProductByUser`
    )
    return res
  }
}

export const getOUserAdApi = async (data) => {
  const config = {
    headers: {
      Authorization: ''
    }
  }
  const res = await axiosInstance().get(
    `${baseAPI}/api/User/GetProductByUser?PageNumber=${data.page}&PageSize=10&UserId=${data.uid}`,
    config
  )
  return res
}

export const adsReportApi = async (a) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  let data = {
    remarks: a.remarks,
    productId: a.productId,
    recaptchaToken: a.recaptchaToken
  }

  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  data = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Product/Report`,
    data,
    config
  )
  return res
}

export const adsHoldApi = async ({ productId, onHold }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/User/UserProductHold?ProductId=${productId}&onHold=${onHold}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    },
    config
  )

  return res
}

export const getReviewTypeApi = async () => {
  const res = await axiosInstance().get(`${baseAPI}/api/AppData/GetReviewTypes`)
  return res
}

export const getReportTypeApi = async () => {
  const res = await axiosInstance().get(`${baseAPI}/api/AppData/GetReportTypes`)
  return res
}

export const getSliderAdApi = async (page) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Advertisement/GetAdLinks?page=${page}`
  )
  return res
}

export const getCatSliderAdApi = async (data) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Advertisement/AdLinks/Position?page=${data.page}&CategoyId=${data.categoryId}&Position=${data.position}`
  )
  return res
}

export const getCatAdApi = async (data) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Advertisement/GetAdLinks?page=${data.page}&CategoyId=${data.categoryId}&Position=${data.position}`
  )
  return res
}

export const getHomeListAdsApi = async () => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/FeatureAds/GetAll`, {
    pageName: 'Home',
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const getCategoryListAdsApi = async (payload) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/FeatureAds/GetAll`, {
    pageName: 'Category',
    deviceId,
    deviceSource,
    categoryId: payload,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const getSearchListAdsApi = async () => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/FeatureAds/GetAll`, {
    pageName: 'Search',
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const getProfilePageAdsApi = async () => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/FeatureAds/GetAll`, {
    pageName: 'user_profile',
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const getFeatureAdsApi = async (pageName, slotName) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(`${baseAPI}/api/FeatureAds/GetAll`, {
    pageName,
    slotName,
    deviceId,
    deviceSource,
    latitude: localStorage.getItem('currentLocationLat') || 0,
    longitude: localStorage.getItem('currentLocationLng') || 0
  })
  return res
}

export const markSoldApi = async (pid) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const res = await axiosInstance().post(
    `${baseAPI}/api/Product/MarkAsSold/?ProductId=${pid}`,
    {
      deviceId,
      deviceSource,
      latitude: localStorage.getItem('currentLocationLat') || 0,
      longitude: localStorage.getItem('currentLocationLng') || 0
    }
  )
  return res
}
