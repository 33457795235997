import { useEffect, useState } from 'react';
import { CongratsIcon, BulbIcon } from '../../icons';
import { isEmpty } from 'lodash';
import { useGAEventTracker, useWindowDimensions } from '../../hooks';

export const SuccessScreen = ({
  closeForm = () => {},
  succesAd = [],
  product = {},
}) => {
  const [ad, setAd] = useState({});
  const windowDimesion = useWindowDimensions();
  const GAEvent = useGAEventTracker();
  const isSmallScreen = windowDimesion?.width < 576;

  useEffect(() => {
    const filterAd = succesAd?.filter(
      (ad) => ad?.categoryId === product?.subCategory,
    );
    setAd(filterAd?.[0]);
  }, [product?.subCategory, succesAd]);

  return (
    <>
      <div className="post--ads final--screen">
        <div className="central">
          <div className="congrats">
            <div className="icon">
              <CongratsIcon />
            </div>
            <div className="desc">
              <h3>Congratulation</h3>
              <p>Your Ad. has been posted.</p>
            </div>
          </div>
          <div className="hb--tips">
            <div className="tip__title">
              <BulbIcon />
              <span>Do you know ?</span>
            </div>
            <div className="tip__desc">
              <p>In every second, something is sold or bought in Hamrobazar.</p>
            </div>
            {isEmpty(ad) ? null : (
              <div class="success-ads" data-name="inbetween">
                <a
                  onClick={() =>
                    GAEvent(
                      'Add Product',
                      'add_product',
                      ad?.pageHtml || 'add product',
                    )
                  }
                  href={ad?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div class="image-container">
                    <img
                      height="120"
                      width="100%"
                      src={isSmallScreen ? ad?.image_mb : ad?.image}
                      alt="static-products"
                    />
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="post-footer post-footer-backNext wrapper">
        <button onClick={closeForm} type="button" className={`btn btn--form`}>
          Close
        </button>
      </div>
    </>
  );
};
