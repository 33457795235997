/**
 * It takes a link as an argument, checks if it starts with http:// or https://, and if it doesn't, it
 * adds https:// to the beginning of the link
 * @param link - The link that the user has entered.
 * @returns the link if it starts with http:// or https://. If it doesn't, it is returning the link
 * with https:// prepended to it.
 */
export function validateURL(link) {
  if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
    return link
  }

  return 'https://' + link
}
