import moment from 'moment'

export function checkExpiryTime(dataTime) {
  const now = moment()

  let exp = moment.utc(dataTime)

  return now.isAfter(exp, 'date')
}

export const checkTime = (time) => {
  let createdTime = time?.split(' ')

  if (createdTime?.[1] === 'seconds') {
    if (parseInt(createdTime[0]) <= 60) {
      return 'Just Now'
    }
  }
  return time
}
