import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { APP, ENV, MONITORING } from './config'

export function bootMonitoringService() {
  Sentry.init({
    dsn: MONITORING.SENTRY.DSN,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new BrowserTracing()
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tracesSampleRate: ENV.IS_DEV ? 1.0 : 0.5,
    release: APP.VERSION,
    environment: ENV.NAME
  })
}
