import { useCallback, useRef } from 'react'

import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect'

/**
 * It returns a memoized callback that is guaranteed to be stable across renders
 * @param [fn] - The function to be called.
 * @returns A function that calls the function passed in as an argument.
 */
export function useEventCallback(fn = () => {}) {
  const ref = useRef(() => {
    throw new Error('Cannot call an event handler while rendering.')
  })

  useIsomorphicLayoutEffect(() => {
    ref.current = fn
  }, [fn])

  return useCallback((...args) => ref.current(...args), [ref])
}
