import { useFetch } from '../../hooks'
import { getCookie, decryptObjData } from '../../utils'

import { USER_TOP_SEARCH, DEVICE_ID } from '../../constants'

export const GetTopSearchCategorySerice = () => {
  const deviceId = getCookie(DEVICE_ID)
  let userData = decryptObjData(localStorage.getItem('user_auth'))

  const userId = userData?.value?.userId || ''

  const config = {
    headers: {
      latv: localStorage.getItem('currentLocationLat') ?? 0,
      longv: localStorage.getItem('currentLocationLng') ?? 0,
      deviceId,
      userId
    }
  }
  const { isLoading, data, isError } = useFetch(
    'GET_TOP_SEARCH_CATEGORY',
    USER_TOP_SEARCH,
    true,
    { userId },
    config
  )

  return { isLoading, data, isError }
}
