import { Link } from 'react-router-dom'
import { STEP_HEAD } from './options'

export const StepInfoCard = ({ formStep = 1, isProfileComplete = false }) => {
  const circle = {
    strokeDasharray: `${100 + 25 * formStep} , 270`
  }

  return (
    <>
      <article className='ad--post__loading'>
        <div className='left'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -1 34 34'>
            <circle
              cx='16'
              cy='16'
              r='15.9155'
              className='progress-bar__background'
            ></circle>

            <circle
              cx='16'
              cy='16'
              r='15.9155'
              className='progress-bar__progress
							js-progress-bar'
              profile-complete='45'
              style={circle}
            ></circle>
          </svg>
          <small>{formStep} of 4</small>
        </div>

        <div className='right'>
          <h2 className='card__title'>{STEP_HEAD[formStep]?.heading}</h2>
          <p className='post--para text-muted'>
            {STEP_HEAD[formStep]?.subHeading}
          </p>
        </div>
      </article>
      {isProfileComplete ? null : (
        <small className='text--mute pills'>
          Did you know? Completing your profile increases your sales.{' '}
          <Link
            className='normal--link'
            to={{ pathname: '/profile', state: 'edit' }}
          >
            Complete your profile
          </Link>
        </small>
      )}
    </>
  )
}
