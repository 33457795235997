import { useMutation } from 'react-query'

export const ChatService = () => {
  const postComment = async ({ content, sent_by, sent_to }) => {
    var formdata = new FormData()
    formdata.append('content', content)
    formdata.append('sent_by', sent_by)
    formdata.append('sent_to', sent_to)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    const res = await fetch(
      'https://chat.hamrobazaar.com/chat.php',
      requestOptions
    )
    return res
  }

  const { mutate, isLoading } = useMutation(async (data) => {
    return await postComment(data)
  })
  return { mutate, isLoading }
}
