import { isEmpty } from 'lodash'

const filterParams = (id, type, value) => {
  return `[{${id}},{${type}},{${value}}]`
}

export const advanceFilterParams = (data, searchData, setValue) => {
  if (isEmpty(data)) return ''

  const advanceFilterParams = data
    ?.map((item) => {
      if (item.filterParamType === '2') {
        if (`${item.fieldName}From` in searchData) {
          if (
            searchData[`${item.fieldName}From`] !== '' ||
            searchData[`${item.fieldName}To`] !== ''
          ) {
            let from =
              searchData[`${item.fieldName}From`]?.length > 0
                ? searchData[`${item.fieldName}From`]
                : searchData[`${item.fieldName}To`]
            let to =
              searchData[`${item.fieldName}To`]?.length > 0
                ? searchData[`${item.fieldName}To`]
                : searchData[`${item.fieldName}From`]

            //check grater value and reverse
            if (Number(from) > Number(to)) {
              setValue(`${item.fieldName}From`, to)
              setValue(`${item.fieldName}To`, from)
            }
            if (Number(from) > Number(to)) [from, to] = [to, from]

            return filterParams(
              item?.id,
              item?.filterParamType,
              `between ${from} and ${to}`
            )
          }
        }
      }

      if (`${item.fieldName}` in searchData) {
        if (!isEmpty(searchData[`${item.fieldName}`])) {
          return filterParams(
            item?.id,
            item?.filterParamType,
            searchData[`${item.fieldName}`]
          )
        }
      }
    })
    .filter(Boolean)
  return advanceFilterParams
}
