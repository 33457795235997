import dayjs from 'dayjs'

import { decryptObjData, encryptData } from './encryptDecryptData'
import { refreshTokenApi } from '../api/auth'
import { loadUser, logout } from '../Redux/reducers/authReducers'

import store from '../Redux/store'

const errorMessages = [
  'Error:Refresh Token Already Used',
  'Refresh Token Already Used',
  'Error:API Error Occurred while generating token.',
  'API Error Occurred while generating token.',
  'Refresh Token Has Expired',
  'Error:Refresh Token Has Expired',
  'Error:Fail to refresh token!',
]

export async function refreshToken(axiosInstance, decryptLocalAuth, headers) {
  const pathname = window.location.pathname

  let userEncryptedData = localStorage.getItem('user_auth')
  let userData = decryptObjData(userEncryptedData)

  const data = {
    token: userData?.value?.access_token,
    refreshToken: userData?.value?.refreshToken,
  }

  async function refresh_token() {
    return await refreshTokenApi(data)
  }

  let refreshing_token = null

  const isExpired =
    dayjs.unix(decryptLocalAuth?.value?.tokenExpiry).diff(dayjs()) < 1

  if (isExpired) {
    axiosInstance.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        const config = error.config
        if (error.response && !config._retry) {
          config._retry = true

          try {
            refreshing_token = refreshing_token
              ? refreshing_token
              : refresh_token()
            let res = await refreshing_token
            refreshing_token = null
            if (res?.data?.data) {
              const tokenData = {
                value: res?.data?.data,
                expiry: res?.data?.data?.tokenExpiry,
              }
              localStorage.setItem('user_auth', encryptData(tokenData))
              headers.Authorization = `Bearer ${tokenData.value.access_token}`
              axiosInstance.defaults.headers.common = headers
              store.dispatch(loadUser())
              return axiosInstance(config)
            }
          } catch (err) {
            if (errorMessages.includes(err?.response?.data?.data?.message))
              logMeout(pathname)

            if (errorMessages.includes(err?.response?.data?.status?.message[0]))
              logMeout(pathname)

            return Promise.reject(err)
          }
        }
        logMeout(pathname)
        return Promise.reject(error)
      }
    )
  }
}

const logMeout = async pathname => {
  let localAuth = decryptObjData(localStorage.getItem('user_auth'))

  try {
    store.dispatch(logout(localAuth?.value?.userId))
    if (pathname === '/profile') window.location.replace('/login')
  } catch (error) {
    store.dispatch(logout(localAuth?.value?.userId))
    window.location.replace('/login')
  }
  return
}
