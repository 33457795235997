import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { GetBrandService } from '../../services'
import { useFetch, useTextChange } from '../../hooks'
import { keyValues } from '../../utils'

import { ALL_CATEGORIES_URL } from '../../constants'

export const useGetCategoryAndBrand = (methods) => {
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [brandOptions, setBrandOptions] = useState([])

  const textChange = useTextChange(methods?.watch)

  const { data, isLoading } = useFetch('ALL_CATEGORIES', ALL_CATEGORIES_URL)

  const { data: brand, isLoading: fetchingBrand, mutate } = GetBrandService()

  const keysMap = {
    id: 'value',
    name: 'label'
  }

  useEffect(() => {
    if (!isLoading) {
      setCategoryOptions(
        data?.data
          ?.filter((data) => data.id !== '00000000-0000-0000-0000-000000000000')
          ?.map((category) => keyValues(keysMap, category))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, isLoading])

  useEffect(() => {
    if (textChange?.category) {
      setSubCategoryOptions(
        data?.data
          ?.filter((category) => category?.id === textChange?.category)?.[0]
          ?.categories?.map((category) => keyValues(keysMap, category))
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChange?.category])

  useEffect(() => {
    if (textChange?.subCategory)
      if (!!textChange?.category) methods?.setValue('subCategory', '')

    if (textChange?.brand)
      if (!!textChange?.category) methods?.setValue('brand', '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChange?.category])

  useEffect(() => {
    if (textChange?.brand)
      if (!!textChange?.category) methods?.setValue('brand', '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChange?.subCategory])

  useEffect(() => {
    if (!isEmpty(textChange?.subCategory)) {
      mutate(textChange?.subCategory)
    }
  }, [mutate, textChange?.subCategory])

  useEffect(() => {
    if (!fetchingBrand)
      return setBrandOptions(
        brand?.data?.data?.map((brand) =>
          keyValues(
            {
              id: 'value',
              brandName: 'label'
            },
            brand
          )
        )
      )
    return () => {}
  }, [brand?.data?.data, fetchingBrand])

  return {
    categoryOptions,
    subCategoryOptions,
    brandOptions,
    isLoading,
    fetchingBrand
  }
}
