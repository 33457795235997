import { useMutation } from 'react-query'

import { popToast } from '../../components/common/PopToast'
import { postComments, deleteCommentApi } from '../../api/comments'

/**
 * It returns a mutate function that takes in data and posts it to the server
 * @param refetch - This is the refetch function that we get from the useQuery hook.
 * @param replyHandler - This is a function that will be called when the comment is posted
 * successfully.
 * @param reset - This is a function that resets the form.
 * @returns An object with two properties: mutate and isLoading.
 */
export const PostCommentService = (refetch, replyHandler, reset) => {
  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await postComments(data)
    },
    {
      onSuccess: (data) => {
        refetch()
        popToast(
          data?.meta?.message?.[0] || 'Comment Added Succesfully',
          'TOP_RIGHT'
        )
        replyHandler('', '')
        reset()
      },
      onError: (error) => {
        popToast(
          error.response.data?.status?.message?.[0] ||
            'Unable to post comment! Please try again later.',
          'TOP_RIGHT'
        )
        replyHandler('', '')
        reset()
      }
    }
  )
  return { mutate, isLoading }
}

/**
 * It returns a mutate function that deletes a comment and a boolean isLoading that indicates whether
 * the mutation is in progress
 * @param refetch - This is the refetch function that we get from the useQuery hook.
 * @param setShow - This is a function that sets the show state of the modal to false.
 * @returns An object with two properties: mutate and isLoading.
 */
export const DeleteCommentService = (refetch, setShow) => {
  const { mutate, isLoading } = useMutation(
    async (id) => {
      return await deleteCommentApi(id)
    },
    {
      onSuccess: (data) => {
        refetch()
        popToast(
          data?.meta?.message?.[0] || 'Comment Deleted Succesfully',
          'TOP_RIGHT'
        )
        setShow(false)
      },
      onError: (error) => {
        popToast(
          'Unable to delete comment! Please try again later.',
          'TOP_RIGHT'
        )
        setShow(false)
      }
    }
  )
  return { mutate, isLoading }
}
