import { createAction, createReducer } from '@reduxjs/toolkit'
import distinct from '../../utils/distinct'

export const search = createAction('SEARCH/REQUEST')
export const searchSuccess = createAction('SEARCH/SUCCESS')
export const searchError = createAction('SEARCH/FAILES')
export const categorySearchSucess = createAction('SEARCH_CATEGORY/SUCESS')
export const loadMoreCategorySearchSucess = createAction(
  'LOAD_MORE_CATEGORY/SUCESS'
)

//wish
export const addWishInSearch = createAction('UP_SAVED_SEARCH')
export const removeWishInSearch = createAction('DOWN_SAVED_SEARCH')

export const loadMoreSearch = createAction('loadMoreSEARCH/REQUEST')
export const loadMoreSearchSuccess = createAction('loadMoreSEARCH/SUCCESS')
export const loadMoreSearchError = createAction('loadMoreSEARCH/FAILES')

// search tags init
export const getSearchTag = createAction('SEARCHTAG/REQUEST')
export const searchTagSuccess = createAction('SEARCHTAG/SUCCESS')
export const searchTagError = createAction('SEARCHTAG/FAILES')

export const removeTag = createAction('REMOVE_TAG/REQUEST')
export const removeTagSuccess = createAction('REMOVE_TAG/SUCCESS')
export const removeTagError = createAction('REMOVE_TAG/FAILES')

export const clearSearch = createAction('CLEAR_SEARCH')

//Search autocomplete
export const searchAuto = createAction('SEARCH_AUTO')

const initialState = {
  searches: [],
  searching: false,
  searchingSuccess: false,
  searchingError: null,
  searchedDataFinished: false,
  totalItem: 0,
  totalPages: 0,
  searchDataLoadedFromApi: false,

  loadMoreSearch: false,
  loadMoreSearchSuccess: false,
  loadMoreSearchError: null,

  // search tag initialState
  searchTag: [],
  gettingSearchTag: false,
  searchTagSuccess: false,
  searchTagError: null,
  removeTagError: null,
}

const searchReducers = createReducer(initialState, {
  [search]: state => {
    state.searching = true
    state.searchingSuccess = false
    state.searchDataLoadedFromApi = false
  },
  [categorySearchSucess]: (state, action) => {
    const srch = Object.values(action.payload?.data?.data || {})
    state.searches = srch
    state.totalPages = action.payload?.data?.totalPages
    if (action.payload.data.pageNumber === action.payload.data.totalPages) {
      state.searchedDataFinished = true
    } else state.searchedDataFinished = false
    if (state.searches?.length === 0) {
      state.searchedDataFinished = true
    }
    state.totalItem = action.payload?.data?.totalRecords
    state.searchingSuccess = true
    state.searching = false
    state.searchDataLoadedFromApi = true
  },
  [searchSuccess]: (state, action) => {
    const srch = Object.values(action.payload?.data?.data || {})
    state.searches = srch
    state.totalPages = action.payload?.data?.meta.totalPages
    if (
      action.payload.data.meta.pageNumber ===
      action.payload.data.meta.totalPages
    ) {
      state.searchedDataFinished = true
    } else state.searchedDataFinished = false
    if (state.searches?.length === 0) {
      state.searchedDataFinished = true
    }
    state.totalItem = action.payload?.data?.meta.totalRecords
    state.searchingSuccess = true
    state.searching = false
    state.searchDataLoadedFromApi = true
  },
  [searchError]: (state, action) => {
    state.searching = false
    state.searchedDataFinished = true
    state.searchingSuccess = false
    state.searchingError = action.payload?.data?.data
    state.totalItem = 0
    state.searchDataLoadedFromApi = false
  },

  [loadMoreSearch]: state => {
    state.loadMoreSearch = true
  },
  [loadMoreCategorySearchSucess]: (state, action) => {
    const srch = Object.values(action.payload?.data?.data || {})
    state.searches = [...state.searches, ...srch]
    state.searches = distinct(state.searches)
    if (action.payload.data.pageNumber === action.payload.data.totalPages) {
      state.searchedDataFinished = true
    }
    state.totalItem = action.payload?.data?.totalRecords
    state.totalPages = action.payload?.data?.totalPages
    state.loadMoreSearch = false
    state.loadMoreSearchSuccess = true
  },
  [loadMoreSearchSuccess]: (state, action) => {
    const srch = Object.values(action.payload?.data?.data || {})
    state.searches = [...state.searches, ...srch]
    state.searches = distinct(state.searches)
    if (
      action.payload.data.meta.pageNumber ===
      action.payload.data.meta.totalPages
    ) {
      state.searchedDataFinished = true
    }
    state.totalItem = action.payload?.data?.meta.totalRecords
    state.totalPages = action.payload?.data?.meta.totalPages
    state.loadMoreSearch = false
    state.loadMoreSearchSuccess = true
  },
  [loadMoreSearchError]: (state, action) => {
    state.loadMoreSearch = false
    state.loadMoreSearchSuccess = false
    state.loadMoreSearchError = action.payload
  },

  // search tag reducer
  [getSearchTag]: state => {
    state.gettingSearchTag = true
  },
  [searchTagSuccess]: (state, action) => {
    state.searchTag = Object.values(action.payload?.data?.data) || []
    state.gettingSearchTag = false
    state.searchTagSuccess = true
  },
  [searchTagError]: (state, action) => {
    state.gettingSearchTag = false
    state.searchTagSucess = false
  },

  [addWishInSearch]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.searches.some(a => a.id === action.payload.id)) {
      const theIndex = state.searches.findIndex(a => a.id === action.payload.id)
      state.searches[theIndex].isSaved = true
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = true
  },
  //Remove wish
  [removeWishInSearch]: (state, action) => {
    //Finding if it exist in array of ad
    if (state.searches.some(a => a.id === action.payload.id)) {
      const theIndex = state.searches.findIndex(a => a.id === action.payload.id)
      state.searches[theIndex].isSaved = false
    }
    //Finding if it exist in the single ad
    if (state.ad && state.ad.id === action.payload.id) state.ad.isSave = false
  },

  //Tag actions
  [removeTagSuccess]: (state, action) => {
    state.searchTag = state.searchTag.filter(
      s => s.searchText !== action.payload
    )
  },

  //Clear search
  [clearSearch]: state => {
    state.searches = []
    state.searchValue = ''
    state.searchedDataFinished = false
  },
})

export default searchReducers
