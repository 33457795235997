import axios from 'axios'
import appConfig from '../appConfig'
import { DEVICE_ID, getDeviceSource } from '../constants'
import { axiosInstance } from '../utils/axiosInstance'
import { getCookie } from '../utils/cookies'
import { ApiError } from '../utils/error'

//The base api url
const baseAPI = appConfig.baseApiURI
let latitude = localStorage.getItem('currentLocationLat') || 0
let longitude = localStorage.getItem('currentLocationLng') || 0

//Headers for the Request
const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

/*
  Login Api
*/
export const loginUser = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/LoginToken`,
    jdata,
    config
  )
  return res
}

/*
  Signup Api
*/
export const signupUser = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  let filterData = {
    fullName: '',
    password: '',
    phoneNumber: '',
    userName: '',
    email: '',
    appId: '',
    role: 'user',
    recaptchaToken: null
  }
  filterData.fullName = data.fullName
  filterData.password = data.password
  filterData.phoneNumber = data.phNo
  filterData.email = data.email
  filterData.userName = data.username
  filterData.recaptchaToken = data.recaptchaToken

  const jdata = JSON.stringify({
    ...filterData,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/RegisterClient`,
    jdata,
    config
  )
  return res
}

/*
  Send OTP Api
*/
export const resendOtpCode = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })

  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/ResendOTP`,
    jdata,
    config
  )
  return res
}

/*
  Verify Otp Api
*/
export const verifyOtpCode = async (data) => {
  const res = await axiosInstance().get(
    `${baseAPI}/api/Account/VerifyOTP?PhoneNumber=${data.PhoneNumber}&OTP=${data.OTP}`
  )
  return res
}

/*
  Forgot Password Api
*/
export const forgotPasswordApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/ForgotPassword`,
    jdata,
    config
  )
  return res
}

/*
  Reset Password
*/
export const resetPasswordApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/ResetPassword`,
    jdata,
    config
  )
  return res
}

/**
 * Update password
 */
export const updatePassApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/ChangePassword`,
    jdata,
    config
  )
  return res
}

/*
  Check Username Api
*/
export const checkUsername = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  try {
    const res = await axiosInstance().post('/signup', jdata, config)
    return res
  } catch (error) {
    //throw error.response.data.errors;
    return error
  }
}

/*
 Chat Token Request
*/
export const getChatTokenAPi = async (uid) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  let data
  if (uid) {
    data = {
      users: [uid]
    }
  } else {
    data = {
      users: []
    }
  }

  data = {
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  }

  if (uid) {
    //Converting the object to JSON
    const jdata = JSON.stringify(data)
    const res = axiosInstance().post(
      `${baseAPI}/api/Message/GenerateToken`,
      jdata,
      config
    )
    return res
  } else {
    const res = axiosInstance().post(
      `${baseAPI}/api/Message/GenerateToken`,
      data,
      config
    )
    return res
  }
}

/*
  Get user info
*/
export const getUserInfoApi = async (uid) => {
  if (uid) {
    const res = await axiosInstance().get(`${baseAPI}/api/User?UserId=${uid}`)
    return res
  } else {
    const res = await axiosInstance().get(`${baseAPI}/api/User`)
    return res
  }
}

//update user profile picture
export const posttUserDpApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  //Creating formdata for image file
  var fd = new FormData()
  fd.append('file', data)

  fd.append('deviceId', deviceId)
  fd.append('deviceSource', deviceSource)

  fd.append('latitude', latitude)
  fd.append('longitude', longitude)

  const res = await axiosInstance().post(
    `${baseAPI}/api/User/UploadProfilePicture`,
    fd,
    config
  )
  return res
}

/*
  Update user info
*/
export const updateUserInfoApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  let actualData = {}

  if (data.fullName) {
    actualData.fullName = data.fullName
  }
  if (data.email) {
    actualData.email = data.email
  }
  if (data.profileImgKey) {
    actualData.profileImgKey = data.profileImgKey
  }
  if (data.dob) {
    actualData.dob = data.dob
  }
  if (data.pan) {
    actualData.pan = data.pan
  }
  if (data.identification) {
    actualData.identification = data.identification
  }
  if (data.issuedDate) {
    actualData.issuedDate = data.issuedDate
  }
  if (data.issuedPlace) {
    actualData.issuedPlace = data.issuedPlace
  }
  if (data.grandfathername) {
    actualData.grandfathername = data.grandfathername
  }
  if (data.fathername) {
    actualData.fathername = data.fathername
  }
  if (data.mothername) {
    actualData.mothername = data.mothername
  }
  if (data.province) {
    actualData.province = data.province
  }
  if (data.district) {
    actualData.district = data.district
  }
  if (data.city) {
    actualData.city = data.city
  }
  if (data.locality) {
    actualData.locality = data.locality
  }
  if (data.occupation) {
    actualData.occupation = data.occupation
  }
  if (data.latitude) {
    actualData.latitude = data.latitude
  }
  if (data.alternateNumber) {
    actualData.alternateNumber = data.alternateNumber
  }
  if (data.hidePhoneNumber) {
    actualData.hidePhoneNumber = data.hidePhoneNumber
  }

  const jdata = JSON.stringify({
    ...actualData,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = axiosInstance().post(
    `${baseAPI}/api/User/UpdateUserInfo`,
    jdata,
    config
  )
  return res
}

/**
 * Refresh token
 */
export const refreshTokenApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axios.post(
    `${baseAPI}/api/Account/RefreshToken`,
    jdata,
    config
  )
  return res
}

/**
 * Check email for verification
 */
export const verifyEmailApi = async (email) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/VerifyEmail?Email=${email}`,
    {
      deviceId,
      deviceSource,
      latitude,
      longitude
    }
  )
  return res
}

/**
 * Register old user
 */
export const regOldUserApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/RegisterOldUser`,
    jdata,
    config
  )
  return res
}

/**
 * Verify otp of old user
 */
export const verifyoOtpApi = async (data) => {
  const deviceId = getCookie(DEVICE_ID)
  const deviceSource = getDeviceSource()

  const jdata = JSON.stringify({
    ...data,
    deviceId,
    deviceSource,
    latitude,
    longitude
  })
  const res = await axiosInstance().post(
    `${baseAPI}/api/Account/RegisterOldUserFinal`,
    jdata,
    config
  )

  return res
}

export const blockUserByIdApi = async (uid) => {
  const res = await axiosInstance().post(
    `${baseAPI}/api/user/BlockUser?BlockUserId=${uid}`
  )
  return res
}

export const unblockUserByIdApi = async (uid) => {
  const res = await axiosInstance().post(
    `${baseAPI}/api/User/UnBlockUser?BlockedUserId=${uid}`
  )
  return res
}

export const invalidateTokenApi = async (data) => {
  const jdata = JSON.stringify({
    ...data
  })

  try {
    const res = await axios.post(
      `${baseAPI}/api/Account/InvalidateToken`,
      jdata,
      config
    )
    return res
  } catch (error) {
    ApiError(error)
  }
}
