import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { useLocalStorage, useReadLocalStorage } from '../../hooks'

import { combineClassNames } from '../../utils'
import { SUB_NAV_DATA } from './data'
import {
  NEPAL_COUNTRY_TEXT,
  UAE_COUNTRY_TEXT,
  NP_COUNTRY_CODE,
  UAE_COUNTRY_CODE
} from '../../constants'
import { NepalFlag, UaeFlag } from '../../icons'

const TopSubNav = ({ auth }) => {
  // eslint-disable-next-line no-unused-vars
  const [storedValue, setValue] = useLocalStorage('country_code', '977')
  const countryCode = useReadLocalStorage('country_code')

  const countryName =
    countryCode === NP_COUNTRY_CODE
      ? NEPAL_COUNTRY_TEXT
      : countryCode === UAE_COUNTRY_CODE
      ? UAE_COUNTRY_TEXT
      : null

  const countryHandler = (name) => {
    if (name === countryName) return
    const country_code =
      name === NEPAL_COUNTRY_TEXT
        ? NP_COUNTRY_CODE
        : name === UAE_COUNTRY_TEXT
        ? UAE_COUNTRY_CODE
        : NP_COUNTRY_CODE
    setValue(country_code)
    window.location.reload(false)
  }

  useEffect(() => {
    if (auth?.isAuthenticated) {
      if (auth?.user?.countryCode) return setValue(auth?.user?.countryCode)
    }
    if (!countryCode || countryCode === undefined || countryCode === null) {
      return setValue(NP_COUNTRY_CODE)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated, auth?.user?.countryCode, countryCode])

  return (
    <div className='top-subNav-parent'>
      <div className='top-subNav container'>
        <div className='top-subNav-container'>
          {SUB_NAV_DATA?.map((item) => (
            <Link
              to={{ pathname: item?.link }}
              className='top-subNav-item'
              key={item?.name}
              target={item?.target}
              rel='noopener noreferer'
            >
              {item?.icon}
              <p>{item?.name}</p>
            </Link>
          ))}
        </div>
        <div className='country'>
          <button
            onClick={() => countryHandler(NEPAL_COUNTRY_TEXT)}
            className={combineClassNames('countery-btn', {
              'countery-btn-active': countryCode === NP_COUNTRY_CODE
            })}
            type='submit'
          >
            <span className='country-flag'>
              <NepalFlag />
            </span>
            {NEPAL_COUNTRY_TEXT}
          </button>
          <div className='divider' />
          <button
            onClick={() => countryHandler(UAE_COUNTRY_TEXT)}
            className={combineClassNames('countery-btn', {
              'countery-btn-active': countryCode === UAE_COUNTRY_CODE
            })}
            type='submit'
          >
            <span className='country-flag country-flag-uae'>
              <UaeFlag />
            </span>
            {UAE_COUNTRY_TEXT}
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(TopSubNav)
