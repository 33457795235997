import { SlideDirection } from '../constants'

class Circular {
  constructor(arr, currentIndex) {
    this.arr = arr
    this.currentIndex = currentIndex
  }

  next() {
    const i = this.currentIndex
    const arr = this.arr
    this.currentIndex = i < arr.length - 1 ? i + 1 : 0
    return this.current()
  }

  prev() {
    const i = this.currentIndex
    const arr = this.arr
    this.currentIndex = i > 0 && i < arr.length ? i - 1 : arr.length - 1
    return this.current()
  }

  current() {
    return this.arr[this.currentIndex]
  }
}

export const rotateItems = (
  items = [],
  showingItems = [],
  start,
  show,
  slide,
  direction
) => {
  const circular = new Circular(items, start)
  const newItems = Array.from(showingItems)

  switch (+direction) {
    case SlideDirection.Left:
      for (let i = slide; i >= 0; i--) {
        if (slide - i < 0 || !newItems[i - slide]) {
          newItems.unshift(circular.current())
        }
        circular.prev()
      }
      break
    case SlideDirection.Right:
      for (let i = 0; i < show + slide; i++) {
        if (!newItems[2 * slide + i]) {
          newItems.push(circular.current())
        }
        circular.next()
      }
      break
    default:
      break
  }

  return newItems
}

export const getTransformAmount = (width, slideCount, direction) => {
  return direction * width * slideCount
}

export const getCurrent = (current, slide, length, direction) => {
  const slideTo = current - direction * slide
  if (slideTo < 0) {
    return length + slideTo
  } else if (length <= slideTo) {
    return slideTo - length
  }

  return slideTo
}

export const getShowArrow = (props) => {
  const {
    itemCount,
    itemsToShow,
    infinite,
    current,
    hideArrows = false
  } = props

  if (hideArrows) {
    return {
      left: false,
      right: false
    }
  }

  const hasMoreItems = itemCount > itemsToShow

  if (infinite) {
    return {
      left: hasMoreItems,
      right: hasMoreItems
    }
  }

  return {
    left: hasMoreItems && current !== 0,
    right: hasMoreItems && current + itemsToShow < itemCount
  }
}

export const cleanItems = (showingItems, slide, direction) => {
  if (direction === SlideDirection.Left) {
    return showingItems.slice(0, -1 * slide)
  }
  return showingItems.slice(slide)
}

export const cleanNavigationItems = (showingItems, slide, direction) => {
  if (direction === SlideDirection.Left) {
    return showingItems.slice(0, slide)
  }

  return showingItems.slice(slide)
}

export const initItems = (items, slide, infinite) => {
  if (!infinite) {
    return items
  }

  const newArray = Array.from(items)
  const circular = new Circular(items, 0)
  for (let i = 0; i < slide; i++) {
    newArray.unshift(circular.prev())
  }

  return newArray
}

function isTouchEvent(e) {
  return e && 'touches' in e
}

function isMouseEvent(e) {
  return e && 'screenX' in e
}

export function getPageX(event) {
  event.persist()
  if (isTouchEvent(event)) {
    return event.nativeEvent.changedTouches[0].pageX
  }
  if (isMouseEvent(event)) {
    return event.nativeEvent.pageX
  }
  return 0
}

export function getOuterWidth(el) {
  const style = getComputedStyle(el)

  return (
    el.offsetWidth +
    (parseInt(style.marginLeft, 10) || 0) +
    (parseInt(style.marginRight, 10) || 0)
  )
}

export function updateNodes(oldItems, newItems, prevChildren, slide, infinite) {
  if (prevChildren && prevChildren.length < newItems.length) {
    return initItems(newItems, slide, infinite)
  }

  const matchedItems = oldItems.map((oldItem) => {
    return newItems.find((newItem) => oldItem.key === newItem.key)
  })

  if (areItemsNotMatched(matchedItems)) {
    return initItems(newItems, slide, infinite)
  }

  return matchedItems
}

export function areItemsNotMatched(items) {
  return items.some((item) => item === undefined)
}

export const getStartIndex = (start, slide, items) => {
  const startIndex =
    start + slide >= items?.length
      ? start + slide - items?.length
      : start + slide
  if (startIndex < 0) {
    return items?.length + startIndex
  }
  return startIndex
}

export const rotateNavigationItems = (
  items,
  showingItems,
  start,
  show,
  slide,
  direction
) => {
  const startIndex = getStartIndex(start, slide, items)
  const current = Math.floor(showingItems.length / 2)
  const circular = new Circular(items, startIndex)
  const newItems = Array.from(showingItems)

  switch (+direction) {
    case SlideDirection.Left:
      for (let i = 0; i < current; i++) {
        const idx = current - (Math.abs(slide) + i) - show
        if (idx < 0 || !newItems[idx]) {
          newItems.unshift(circular.current())
        }
        circular.prev()
      }
      break
    case SlideDirection.Right:
      for (let i = 0; i < current; i++) {
        if (!newItems[current + slide + i + show]) {
          newItems.push(circular.current())
        }
        circular.next()
      }
      break
    default:
      break
  }

  return newItems
}

export const getNavigationSlideAmount = (
  target,
  next,
  slideAmount,
  direction
) => {
  if (typeof target === 'number') {
    if (direction === SlideDirection.Right) {
      return target - next + 1
    }

    return slideAmount
  }

  return direction * -1
}
