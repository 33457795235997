/**
 * It takes a string and an array of characters and returns a string with the characters removed
 * @param str - The string you want to remove the characters from.
 * @param charNames - an array of Characters strings
 * @returns the string with the characters removed.
 */
export function removeChar(str, charNames) {
  const rgx = newRegex(charNames)
  const remove = str.replace(rgx, '')
  return remove
}

/**
 * It takes a keypress event and an array of characters, and if the keypress event matches any of the
 * characters in the array, it prevents the default action of the event.
 * @param e - The event object
 * @param charNames - A string of characters to be removed.
 */
export function removeOnKeyPress(e, charNames) {
  if (newRegex(charNames).test(e.key)) {
    e.preventDefault()
  }
}

function newRegex(charNames) {
  return new RegExp(
    charNames
      .map(function (r) {
        return (r + '').replace(/\//g, '')
      })
      .join('|'),
    'g'
  )
}
