import { useEffect, useRef } from 'react'
import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect'

/**
 * use EventListener with simplicity by React Hook.
 * Supports Window, Element and Document and custom events with almost the same parameters
 * as the native addEventListener options. See examples below.
 *
 * @param eventName - A string representing the event type to listen for.
 * @param handler - The function that will be called when the event is fired.
 * @param element - The element to listen to the event on. If not provided, it defaults to window.
 * @param options - An optional options object that specifies characteristics about the event listener.
 */
export function useEventListener(eventName, handler, element, options) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler)

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current ?? window

    if (!(targetElement && targetElement.addEventListener)) return

    // Create event listener that calls handler function stored in ref
    const listener = (event) => savedHandler.current(event)

    targetElement.addEventListener(eventName, listener, options)

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener, options)
    }
  }, [eventName, element, options])
}
