/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import React, { useEffect } from 'react'
import { loadUser } from './Redux/reducers/authReducers'
import { v4 as uuidv4 } from 'uuid'
import Routes from './components/Routes'
import { connect } from 'react-redux'
import { getTheme } from './Redux/reducers/userReducers'
import { setVariablesDark, setVariablesLight } from './utils/Colors'
import { getCookie, setCookie } from './utils/cookies'
import { setAxiosDeviceId } from './utils/axiosInstance'
import { setRecaptchaToken } from './Redux/reducers/uiReducers'
import { QueryClientProvider } from 'react-query'
import { useGeoLocation } from './hooks/useGeoLocation'
import { queryClient } from './config'
import { HBVersion } from './helpers'

const App = ({ loadUser, getTheme, theme, setRecaptchaToken }) => {
  useEffect(() => {
    //Sets theme
    let gettingroot = document.querySelector(':root')
    if (theme.theme === 'dark') {
      //Setting Dark theme
      setVariablesDark(gettingroot)
      gettingroot.classList.add('darkSys')
      gettingroot.classList.remove('lightSys')
    } else {
      //Setting Light theme
      setVariablesLight(gettingroot)
      gettingroot.classList.remove('darkSys')
      gettingroot.classList.add('lightSys')
    }
    return () => {}
  }, [theme.theme])

  /**
   * Get the theme option if it exist in the localstorage and set the theme
   * Load user data if it exist in the localStorage and load it to the redux store
   */

  const location = useGeoLocation()
  const latitude = location.coordinates?.lat
  const longitude = location.coordinates?.lng

  useEffect(async () => {
    getTheme()
    localStorage.removeItem('searchBarLatitude')
    localStorage.removeItem('searchBarLongitude')

    localStorage.setItem('currentLocationLat', latitude ?? 0)
    localStorage.setItem('currentLocationLng', longitude ?? 0)

    return () => {}
  }, [location])

  useEffect(() => {
    loadUser()
    const cookie = getCookie('deviceId')
    const grecaptcha = window.grecaptcha
    if (grecaptcha) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCH_KEY, {
            action: 'submit',
          })
          .then(function (token) {
            setRecaptchaToken(token)
          })
          .catch(err => console.log(err))
      })
    }
    if (!cookie) {
      // Expires in 20 years
      var id = uuidv4()
      setCookie('deviceId', id, 365 * 20)
      setAxiosDeviceId(id)
    } else {
      setCookie('deviceId', cookie, 365 * 20)
      setAxiosDeviceId(cookie)
    }
    return () => {}
  }, [])

  window.HBVersion = HBVersion

  return (
    <HelmetProvider>
      <>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Routes />
            </Switch>
          </Router>
        </QueryClientProvider>
      </>
    </HelmetProvider>
  )
}

//Redux state for this component
const mapStateToProps = state => ({
  theme: state.user,
})

//Redux reducer actions
const mapDispatchToProps = {
  loadUser,
  getTheme,
  setRecaptchaToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
