import React from 'react'
import ContentLoader from 'react-content-loader'
import { v4 as uuidv4 } from 'uuid'

import { randomArray } from '../../utils'

const CircleLoader = (props) => {
  const { numOfArray = 4, ...rest } = props
  return (
    <>
      {randomArray(numOfArray).map((item) => (
        <div className='res--loader' key={uuidv4()}>
          <ContentLoader
            speed={2}
            width={500}
            height={100}
            viewBox='0 0 100 100'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            {...rest}
          >
            <circle cx='193' cy='324' r='14' />
            <circle cx='244' cy='325' r='14' />
            <circle cx='294' cy='325' r='14' />
            <circle cx='344' cy='325' r='14' />
            <circle cx='45' cy='34' r='33' />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}

export default CircleLoader
