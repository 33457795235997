import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { useFetch } from '../../hooks'
import {
  getSearchKeysFromLocalStorage,
  storeSearchKeys
} from '../../utils/searchKeysStore'
import { SearchQuery } from './searchQuery'
import { AUTOCOMPLETE_URL } from '../../constants'

import InitialSuggestions from './InitialSuggestions'
import { useEffect, useState } from 'react'
import { isJson } from '../../utils'
import { CategorySkeleton } from '../skeletons'

export const SearchSuggestion = ({ searchQuery, setValue, getValues }) => {
  const { data, isLoading } = useFetch(
    'GET_AUTOCOMPLETE_DATA',
    `${AUTOCOMPLETE_URL}?searchText=${searchQuery}`,
    !!searchQuery,
    { searchText: searchQuery }
  )

  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    if (getSearchKeysFromLocalStorage()) {
      setSuggestions(() => {
        let filteredData = getSearchKeysFromLocalStorage()?.map((d) => {
          if (isJson(d)) return JSON.parse(d)
          else return d
        })
        return filteredData?.slice(0, 5)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, localStorage.getItem('search')])

  const { searchUrl } = SearchQuery()
  return (
    <div className='search-initial'>
      {searchQuery?.length === 0 ? (
        <InitialSuggestions
          suggestions={suggestions}
          searchUrl={searchUrl}
          setValue={setValue}
        />
      ) : isLoading ? (
        <CategorySkeleton className='search-skeleton' numOfArray={1} />
      ) : !isEmpty(data?.data?.results) ? (
        <ul>
          {data?.data?.results ? (
            <>
              {data?.data?.results?.slice(0, 8)?.map((searchKey) => (
                <li key={searchKey?.id}>
                  <Link
                    to={searchUrl(searchKey.text)}
                    onClick={() => {
                      setValue('searchValue', searchKey.text)
                      storeSearchKeys({
                        id: searchKey.id,
                        searchValue: searchKey.text,
                        close: true
                      })
                    }}
                  >
                    {searchKey.text}
                  </Link>
                </li>
              ))}
            </>
          ) : null}
        </ul>
      ) : (
        <InitialSuggestions
          suggestions={suggestions}
          searchUrl={searchUrl}
          setValue={setValue}
          noResults={true}
        />
      )}
    </div>
  )
}
